import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AppSettings, Services} from '../app.settings';
import {AppContext} from '../app.context';
import {Org, OrgMembership} from '../orgs/org.service';
import {Alarm} from '../alarms/alarms.service';


export interface Thresholds {
    alert: any;
    warning: any;
}

export interface AlarmModel {
    comparison: string;
    window: number;
    aggregation: any;
    thresholds: Thresholds;

}

export interface MetricAnalyticsConfigModel {
    field: string;
    alarm: AlarmModel;
}

export interface MetricAnalyticsModel {
    type: string;
    config: MetricAnalyticsConfigModel;
}

export interface Monitor {
    name: string;
    id: string;
    deviceTypeId: string;
    enabled: boolean;
    analytics: MetricAnalyticsModel;
}

@Injectable()
export class MonitorsService {
    constructor(
        private http: HttpClient,
        private appSettings: AppSettings,
        private appContext: AppContext) {
    }

    getMonitors(): Promise<Monitor[]> {
        const self = this;
        return new Promise(function(resolve, reject) {
            self.appContext.getOrganization().then(function(orgMembership: OrgMembership) {
                const endpoint = self.appSettings.getEndpoint(Services.MONITORS, {orgId: orgMembership.orgId});
                self.http.get(endpoint)
                    .subscribe(function (value: any) {
                        resolve(value.Items ? value.Items : null);
                    }, reject);
            }, reject);
        });


    }

    getMonitor(monitorId): Promise<Monitor> {
        const self = this;
        return new Promise(function(resolve, reject) {
            self.appContext.getOrganization().then(function(orgMembership: OrgMembership) {
                const endpoint = self.appSettings.getEndpoint(Services.MONITOR, {orgId: orgMembership.orgId, monitorId: monitorId});
                self.http.get(endpoint)
                    .subscribe(function(value: any) {
                        resolve(value.Item);
                    }, reject);
            }, reject);
        });
    }

    createMonitor(monitor): Promise<any> {
        const self = this;
        return new Promise(function(resolve, reject) {
            self.appContext.getOrganization().then(function(orgMembership: OrgMembership) {
                const endpoint = self.appSettings.getEndpoint(Services.MONITORS, {orgId: orgMembership.orgId});
                self.http.post(endpoint, monitor)
                    .subscribe(function(value) {
                        resolve(value);
                    }, reject);
            }, reject);
        });


    }

    deleteMonitor(monitorId): Promise<any> {
        const self = this;
        return new Promise(function(resolve, reject) {
            self.appContext.getOrganization().then(function(orgMembership: OrgMembership) {
                const endpoint = self.appSettings.getEndpoint(Services.MONITOR, {orgId: orgMembership.orgId, monitorId: monitorId});
                self.http.delete(endpoint)
                    .subscribe(function(value) {
                        resolve(value);
                    }, reject);
            }, reject);
        });


    }

    updateMonitor(monitor): Promise<any> {
        const self = this;
        return new Promise(function(resolve, reject) {
            self.appContext.getOrganization().then(function(orgMembership: OrgMembership) {
                const endpoint = self.appSettings.getEndpoint(Services.MONITOR, {orgId: orgMembership.orgId, monitorId: monitor.id});
                self.http.put(endpoint, monitor)
                    .subscribe(function(value) {
                        resolve(value);
                    }, reject);
            }, reject);
        });
    }

    upsertMonitor(monitor): Promise<any> {
        if (monitor.id) {
            return this.updateMonitor(monitor);
        }
        return this.createMonitor(monitor);


    }
}
