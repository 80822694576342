import {AfterViewInit, Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
    selector : 'sen-progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ProgressBarComponent {
    @Input() message;
    @Input() value;
    @Input() mode = 'determinate';

}
