import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {AbstractGraphDataProvider, AbstractGraphView} from '../../graph.component';
import {IDataSeriesQueryResult} from '../../../deviceData/device-data.service';
import {BreakpointObserver} from '@angular/cdk/layout';

@Component({
    selector: 'd3-graph',
    templateUrl: './d3-graph.component.html',
    styleUrls: ['./d3-graph.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class D3GraphComponent extends AbstractGraphView<IDataSeriesQueryResult> implements OnInit {

    constructor(dataProvider: AbstractGraphDataProvider<IDataSeriesQueryResult>,
                breakpointObserver: BreakpointObserver) {
        super(dataProvider, breakpointObserver);
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

}
