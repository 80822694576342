import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppSettings, Services} from '../app.settings';
import {AppContext} from '../app.context';
import {User} from '../users/users.service';
import {isBoolean, get} from 'lodash';
import {BehaviorSubject, Subject} from 'rxjs';
import { SensorService } from '../sensors/sensors.service';

export interface NewOrgRegistration {
    account: {
        name: string;
    };
    owner: {
        user: {
            email: string;
            name: string;
            phone: string;
        }
        options: {
            notification: string;
            link?: string
        };
    };
}

export interface PendingInvite {
    id: string;
    name?: string;
    email: string;
}

export interface Org {
    id: string;
    users: OrgUser[];
    clientIds: string[];
    invites?: PendingInvite[];
}

export interface OrgMembership {
    orgId: string;
    userId: string;
    orgName: string;
    role: string;
}

export interface OrgDeviceType {
    id: string;
    name: string;
}

export interface OrgUser {
    email: string;
    name: string;
    status: string;
    role: string;
    userName: string;
}

@Injectable()
export class OrgService {
    sensorService;
    constructor(private http: HttpClient,
                private appSettings: AppSettings,
                private appContext: AppContext,
                @Inject(SensorService) sensorService) {
                    this.sensorService = sensorService;
                }

               
    registerOrganization(newOrg: NewOrgRegistration): Promise<any> {
        const endpoint = this.appSettings.getEndpoint(Services.ORGS);
        const self = this;
        return new Promise(function(resolve, reject) {
            self.http.post(endpoint, newOrg)
                .subscribe(function(value) {
                    resolve(value);
                }, function(err) {
                    reject(err);
                });
        });
    }

    async addUserToOrg(email, orgId, role): Promise<any> {
        const endpoint = this.appSettings.getEndpoint(Services.ORG_USERS, {orgId: orgId});
        const self = this;
        const body = {
            role: role,
            email: email
        };

        return new Promise(function(resolve, reject) {
            self.http.post(endpoint, body)
                .subscribe(function () {
                    resolve();
                }, function(err) {
                    reject(err);
                });
        });
    }

    async addClientId(clientId): Promise<any> {
        const orgId = await this.fetchContextOrgId();
        const endpoint = this.appSettings.getEndpoint(Services.CLIENT_IDS, {orgId: orgId});
        return new Promise((resolve, reject) => {
            this.http.post(endpoint, {clientId: clientId})
                .subscribe(resolve, reject);
        });
    }

    async removeClientId(clientId): Promise<any> {
        const orgId = await this.fetchContextOrgId();
        const endpoint = this.appSettings.getEndpoint(Services.CLIENT_ID, {orgId: orgId, clientId: clientId});
        return new Promise((resolve, reject) => {
            this.http.delete(endpoint)
                .subscribe(resolve, reject);
        });
    }

    async removeUserFromOrg(userId, orgId): Promise<any> {
        const endpoint = this.appSettings.getEndpoint(Services.ORG_USER, {orgId: orgId, userId: userId});
        const self = this;


        return new Promise(function(resolve, reject) {
            self.http.delete(endpoint)
                .subscribe(function () {
                    resolve();
                }, function(err) {
                    reject(err);
                });
        });
    }

    async getOrg(id, expand?): Promise<Org> {
        if (isBoolean(id)) {
            expand = id;
            id = null;
        }
        if (!id) {
            id = await this.fetchContextOrgId();
        }
        const endpoint = this.appSettings.getEndpoint(Services.ORG, {orgId: id});
        const self = this;
        const params = {};
        if (expand) {
            params['expand'] = 'users,clientIds,invites';
        }
        return new Promise<Org>(function(resolve, reject) {
            self.http.get(endpoint, {params:  params})
                .subscribe(function (value: any) {
                    if (value.Item) {
                        resolve(value.Item as Org);
                    } else {
                        // Something is wrong on the backend
                        reject(new Error('Url ' + endpoint + ' returned OK yet has null data?'));
                    }
                }, reject);
        });
    }

    async updateOrg(org): Promise<any> {
        const endpoint = this.appSettings.getEndpoint(Services.ORG, {orgId: org.id});
        return new Promise<any>(async (resolve, reject) => {
            this.http.put(endpoint, org)
                .subscribe(function (value: any) {
                    resolve(value);
                }, reject);
        });
    }

    async fetchContextOrgId(): Promise<any> {
        const self = this;
        return new Promise(async function(resolve, reject) {
            const orgMembership = await self.appContext.getOrganization();
            resolve(orgMembership.orgId);
        });

    }

    async getDeviceTypes(orgId?): Promise<any> {
        if (!orgId) {
            orgId = await this.fetchContextOrgId();
        }
        const endpoint = this.appSettings.getEndpoint(Services.ORG_DEVICE_TYPES, {orgId: orgId});
        const self = this;

        return new Promise(function(resolve, reject) {
            self.http.get(endpoint)
                .subscribe(function (value: any) {
                    resolve(value.Items as OrgDeviceType[]);
                }, reject);
        });
    }

    async updateOrgUser(userId, orgId, newRole): Promise<any> {
        const endpoint = this.appSettings.getEndpoint(Services.ORG_USER, {orgId: orgId, userId: userId});
        const self = this;

        return new Promise(function(resolve, reject) {
            self.http.put(endpoint, {role: newRole})
                .subscribe(function (value: any) {
                    resolve();
                }, function(err) {
                    reject(err);
                });
        });
    }

    async approveRequest(token, requestId, orgId): Promise<any> {
        const endpoint = this.appSettings.getEndpoint(Services.APPROVE_REQUEST, {requestId: requestId});
        const self = this;
        return new Promise(function(resolve, reject) {
            self.http.post(endpoint, {token: token, orgId: orgId})
                .subscribe(function () {
                    resolve();
                }, function(err) {
                    reject(err);
                });
        });
    }

    async getInviteRequest(requestId): Promise<any> {
        const endpoint = this.appSettings.getEndpoint(Services.INVITE_REQUEST, {requestId: requestId});
        const self = this;
        return new Promise(function(resolve, reject) {
            self.http.get(endpoint)
                .subscribe(function (result: any) {
                    resolve(result.Item);
                }, function(err) {
                    reject(err);
                });
        });
    }

    async getInvite(inviteId): Promise<any> {
        const endpoint = this.appSettings.getEndpoint(Services.INVITES, {inviteId: inviteId});
        const self = this;
        return new Promise(function(resolve, reject) {
            self.http.get(endpoint)
                .subscribe(function (result: any) {
                    if (result.Item) {
                        resolve(result.Item);
                    }
                }, reject);
        });
    }

    async deleteInvite(inviteId): Promise<any> {
        const endpoint = this.appSettings.getEndpoint(Services.INVITES, {inviteId: inviteId});
        const self = this;
        return new Promise(function(resolve, reject) {
            self.http.delete(endpoint)
                .subscribe(function (result: any) {
                    resolve();
                }, reject);
        });
    }

    async acceptInvite(inviteId, token, email, newUser?): Promise<any> {
        let endpoint = null;
        const parms = {
            token: token,
            email: email
        };
        if (newUser) {
            endpoint = this.appSettings.getEndpoint(Services.REGISTER_ACCEPT_INVITE, {inviteId: inviteId});
            parms['userRegistration'] = newUser;
        } else {
            endpoint = this.appSettings.getEndpoint(Services.ACCEPT_INVITE, {inviteId: inviteId});
        }
        return new Promise((resolve, reject) => {
            this.http.post(endpoint, parms)
                .subscribe(function (result: any) {
                    resolve();
                }, function (err) {
                    reject(err);
                });
        });
    }

    async inviteUser(email, orgId, role): Promise<any> {
        const endpoint = this.appSettings.getEndpoint(Services.INVITE_USER);
        return new Promise((resolve, reject) => {
            this.http.post(endpoint, {email: email, orgId: orgId, role: role})
                .subscribe(function (result: any) {
                    resolve(result);
                }, reject);
        });
    }

    async getUsers(orgId?: string) {
        if (!orgId) {
            orgId = await this.fetchContextOrgId();
        }
        const endpoint = this.appSettings.getEndpoint(Services.ORG_USERS, {orgId: orgId});
        const self = this;

        return new Promise(function(resolve, reject) {
            self.http.get(endpoint)
                .subscribe(function (value: any) {
                    resolve(value.Items as User[]);
                }, reject);
        });
    }

    async getQueryableAttributes(deviceTypeId: string, orgId?: string) {
        if (!orgId) {
            orgId = await this.fetchContextOrgId();
        }
        const endpoint = this.appSettings.getEndpoint(Services.QUERYABLE_ATTRIBUTES, {orgId: orgId, deviceTypeId: deviceTypeId});
        const self = this;

        return new Promise(function(resolve, reject) {
            self.http.get(endpoint)
                .subscribe(function (value: any) {
                    resolve(value);
                }, reject);
        });
    }

    async createIntegration(integrationRequest: any) {
        const orgId = await this.fetchContextOrgId();
        const endpoint = this.appSettings.getEndpoint(Services.ORG_INTEGRATIONS, {orgId: orgId});
        const self = this;

        return new Promise(function(resolve, reject) {
            self.http.post(endpoint, integrationRequest)
                .subscribe(function (value: any) {
                    resolve(value);
                }, reject);
        });
    }

    async getIntegrations() {
        const orgId = await this.fetchContextOrgId();
        const endpoint = this.appSettings.getEndpoint(Services.ORG_INTEGRATIONS, {orgId: orgId});
        const self = this;

        return new Promise(function(resolve, reject) {
            self.http.get(endpoint)
                .subscribe(function (value: any) {
                    resolve(value.Items);
                }, reject);
        });
    }

    async getIntegration(intId, qs?): Promise<any> {
        const orgId = await this.fetchContextOrgId();
        const endpoint = this.appSettings.getEndpoint(Services.ORG_INTEGRATION, {orgId: orgId, intId: intId});
        const self = this;

        return new Promise(function(resolve, reject) {
            self.http.get(endpoint, {params : qs || {}})
                .subscribe(function (value: any) {
                    resolve(value.Item);
                }, reject);
        });
    }

    async deleteIntegration(intId) {
        const orgId = await this.fetchContextOrgId();
        const endpoint = this.appSettings.getEndpoint(Services.ORG_INTEGRATION, {orgId: orgId, intId: intId});
        const self = this;

        return new Promise(function(resolve, reject) {
            self.http.delete(endpoint)
                .subscribe(function () {
                    resolve();
                }, reject);
        });
    }

    async updateIntegration(integration) {
        const orgId = await this.fetchContextOrgId();
        const endpoint = this.appSettings.getEndpoint(Services.ORG_INTEGRATION, {orgId: orgId, intId: integration.id});
        const self = this;

        return new Promise(function(resolve, reject) {
            self.http.put(endpoint, integration)
                .subscribe(function () {
                    resolve();
                }, reject);
        });
    }

    async upsertDeviceTree(tree, deletedSensors) {
        const orgId = await this.fetchContextOrgId();
        const endpoint = this.appSettings.getEndpoint(Services.DEVICE_TREE, {orgId: orgId});
        const self = this;

        let body = {
            "tree": tree,
            "deletedSensors": deletedSensors
        }
        this.sensorService._clearCache();
        return new Promise((resolve, reject) => {
            self.http.put(endpoint, body)
                .subscribe((value: any) => {
                    resolve();
                }, reject);
        });
    }

    async getDeviceTree() {
        const orgId = await this.fetchContextOrgId();
        const endpoint = this.appSettings.getEndpoint(Services.DEVICE_TREE, {orgId: orgId});
        const self = this;

        return new Promise(function(resolve, reject) {
            self.http.get(endpoint)
                .subscribe(function (value: any) {
                    resolve(get(value, 'Item.tree'));
                }, (err) => {
                    if (err.status === 404) {
                        return resolve();
                    }
                    reject(err);
                });
        });
    }
}
