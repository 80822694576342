import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {IncidentManagementService} from '../incident-management.service';

@Component({
    selector: 'im-record-view',
    templateUrl: './im-record-view.component.html',
    styleUrls: ['./im-record-view.component.scss'],
})

export class IncidentManagementRecordViewComponent implements OnInit {
    @Input() link;
    @Output() recordFetched = new EventEmitter<any>();
    result;
    loading = false;
    constructor(private imService: IncidentManagementService) {}

    ngOnInit(): void {
        this.loading = true;
        this.imService.getIncident(this.link.integrationId, this.link.resourceId).then((result) => {
            this.result = result;
        }).finally(() => {
            this.recordFetched.emit(this.result);
            this.loading = false;
        });
    }
}
