import {Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {Form, NgForm} from '@angular/forms';
import {OrgService} from '../../org.service';
import {IntegrationsService} from '../../../integrations/integrations.service';
import {forEach, find} from 'lodash';

const CATEGORY_MAP = {
    'incident.management' : 'Incident Management'
}

@Component({
    selector: 'edit-org-integrations',
    templateUrl: './edit-org-int.component.html',
    styleUrls: ['./edit-org-int.component.scss'],
    encapsulation: ViewEncapsulation.None
})



export class EditOrgIntegrations implements OnInit {
    @Input() org;
    working = false;
    integrationGroups = {};
    objectKeys = Object.keys;
    constructor(private route: ActivatedRoute,
                private orgService: OrgService,
                private integrationsService: IntegrationsService) {}

    ngOnInit() {
        this.working = true;
        Promise.all([this.integrationsService.getIntegrations(), this.orgService.getIntegrations()]).then((results) => {

            forEach(results[0], (item) => {
                const category = CATEGORY_MAP[item.typeId] || 'Unknown';
                const members = this.integrationGroups[category] = this.integrationGroups[category] || [];
                const found = find(members, function(int) {
                    return int.id === item.id;
                })
                if (!found) {

                    // Add installation details
                    const installed = find(results[1], (installedIntegration) => {
                        return installedIntegration.platformIntId === item.id;
                    })
                    if (installed) {
                        item.installed = installed;
                    }
                    members.push(item);
                }
            });
        }).finally(() => {
            this.working = false;
        });
    }
    ok() {
    }

    cancel() {

    }
}
