import {Component, forwardRef, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, NgForm, ValidationErrors, Validator} from '@angular/forms';
import {FormValidationService} from '../../../shared/form-validation.service';
import {forEach, isNil} from 'lodash';
const ALERT_TYPE_DESCRIPTIONS = {
    simple : 'Triggers an alert when all of the conditions are met',
    multi: 'Triggers an alert on each individual condition that is met'
}

const WINDOW_CHOICES = [{
        displayName: '1 minute',
        value: 1
    },
    {
        displayName: '5 minutes',
        value: 5
    },
    {
        displayName: '10 minutes',
        value: 10
    },
    {
        displayName: '15 minutes',
        value: 15
    },
    {
        displayName: '30 minutes',
        value: 30
    },
    {
        displayName: '1 hour',
        value: 60
    },
    {
        displayName: '2 hours',
        value: 120
    }
];

const ALERT_TYPES = [{
    display: 'Simple Alert',
    value: 'simple'
}, {
    display: 'Multi Alert',
    value: 'multi'
}];

@Component({
    selector: 'condition-editor',
    templateUrl: './condition-editor.component.html',
    styleUrls: ['./condition-editor.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ConditionEditorComponent),
            multi: true
        }, {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => ConditionEditorComponent),
            multi: true,
        }]
})

export class ConditionEditorComponent implements OnInit, ControlValueAccessor, Validator {
    multiSensor = false;
    group;
    windowChoices = WINDOW_CHOICES;
    alertTypes = ALERT_TYPES;
    alertTypeDescriptions = ALERT_TYPE_DESCRIPTIONS;
    @ViewChild('editorForm', {static: true}) editorForm: NgForm;
    constructor(private formValidation: FormValidationService) {}

    onChange: any = () => {
    }
    onTouched: any = () => {
    }

    ngOnInit(): void {
        this.editorForm.valueChanges.subscribe(val => {
            this.onChange(this.group);
        });
    }

    registerOnChange(fn) {
        this.onChange = fn;
    }

    registerOnTouched(fn) {
        this.onTouched = fn;
    }

    validate(control: AbstractControl): ValidationErrors | null {
        const e = this.formValidation.collectFormErrors(this.editorForm);
        return this.formValidation.collectFormErrors(this.editorForm);
    }

    writeValue(value: any): void {
        if (value) {
            this.group = value;
            if (isNil(this.group.multi)) {
                let isMulti = true;
                forEach(this.group.conditions, function (c) {
                    if (!c.platformDeviceId) {
                        isMulti = false;
                        return false;
                    }
                });
                this.group.multi = isMulti;
            }
            this.multiSensor = this.group.multi;

        }
    }

    onMultiSensorChange() {
        this.group.conditions = [];
        this.multiSensor = this.group.multi = !this.multiSensor;
        if (!this.multiSensor) {
            this.group.alertType = ALERT_TYPES[0].value;
        }
    }
}
