import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppState } from './app.global';
import {NavigationEnd, Router} from '@angular/router';
import {RoutingStoreService} from './shared/route-store.service';
import {SplashScreen} from '@ionic-native/splash-screen';
declare let ga: Function;
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.scss']
})
export class AppComponent {

  constructor(
    public router: Router,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public global: AppState,
    routingStoreService: RoutingStoreService
  ) {
    routingStoreService.loadRouting();
    // this.global.set('theme', 'default');
    this.router.events.subscribe(function(event) {

      if (event instanceof NavigationEnd) {
        ga('set', 'page', event.urlAfterRedirects);
        ga('send', 'pageview');

      }
    });
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }


}
