import {
  Component,
  OnInit
} from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'src/app/error/message.service';
import { UserService } from 'src/app/users/users.service';
import {
  NG_VALIDATORS
} from '@angular/forms';

@Component({
  selector: 'join-existing-org',
  templateUrl: './join-existing-org.component.html',
  styleUrls: ['./join-existing-org.component.scss'],
  providers: [
    { provide: NG_VALIDATORS,
      useExisting: JoinExistingOrgComponent,
      multi: true
    }
  ]
})
export class JoinExistingOrgComponent implements OnInit {

  constructor(
    private router: Router,
    private userService: UserService,
    private messageService: MessageService,
  ) {}
  name;
  email;
  loading;
  adminEmail;

  ngOnInit() {
   }

  sendRequest() {
    this.loading = true;
    this.userService
      .inviteRequest(this.name, this.email, this.adminEmail)
      .then(value => {
        // tslint:disable-next-line: max-line-length
        this.messageService.handleSuccess(
          'A request to ' + this.adminEmail + ' was successfully submitted.'
        );
        this.router.navigate(['/login']);
      })
      .finally(() => {
        this.loading = false;
      });
  }
  
}
