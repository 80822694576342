import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {IGraphAction} from '../../graph.component';


@Component({
    selector: 'graph-tool-item',
    templateUrl: './graph-tool-item.component.html',
    styleUrls: ['./graph-tool-item.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class GraphToolItemComponent {
    @Input() action: IGraphAction;

    @Output() clicked = new EventEmitter();

    onClicked(action) {
        this.clicked.emit(action);
    }
}
