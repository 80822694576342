import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {MatDialog, MatDialogConfig, MatSlideToggleChange} from '@angular/material';
import {EditProfileComponent} from '../../dialogs/edit-profile/edit-profile.component';
import {ActivatedRoute, Router} from '@angular/router';
import {AppContext} from '../../../app.context';
import {MessageService} from '../../../error/message.service';
import {AuthService} from '../../../auth/auth.service';
import {AlarmUpdateService} from '../../../alarms/alarm-update.service';

@Component({
    selector: 'profile-menu-item',
    templateUrl: './profile-menu-item.component.html',
    styleUrls: ['./profile-menu-item.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ProfileMenuItemComponent implements OnInit {
    user;
    constructor(
        private messageService: MessageService,
        private dialog: MatDialog,
        private appContext: AppContext,
        private auth: AuthService,
        private router: Router
    ) {
        this.appContext.user.subscribe(async (user) => {
            this.user = user;
        });
    }


    ngOnInit(): void {
        this.appContext.getUser().then((user) => {
            this.user = user;
        });
    }

    logout() {
        this.auth.logout();
        this.router.navigate(['login']).finally();
    }

    showFeaturesChanged(event: MatSlideToggleChange) {
        this.appContext.setShowExperimentalFeatures(event.checked);
    }
}
