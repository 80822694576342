import {AfterViewInit, Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
    selector : 'loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class LoadingComponent implements AfterViewInit, OnInit {
    constructor(private spinner: NgxSpinnerService) {}
    @Input() message;
    @Input() class;
    @Input() diameter;
    ngAfterViewInit(): void {
        this.spinner.show();
    }

    ngOnInit(): void {
        this.diameter = this.diameter || 30;
    }
}
