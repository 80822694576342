import {
    AfterViewInit,
    Component,
    EventEmitter,
    forwardRef,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewEncapsulation
} from '@angular/core';
import {forEach, has, isObject, find, startCase} from 'lodash';
import {flatten} from 'flat';
import {OrgService} from '../../orgs/org.service';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
    selector : 'org-device-type-select',
    templateUrl: './org-device-type-select.component.html',
    styleUrls: ['./org-device-type-select.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => OrgDeviceTypeSelectComponent),
            multi: true
        }
    ]
})

export class OrgDeviceTypeSelectComponent implements OnInit, ControlValueAccessor {
    types = null;
    loading = false;

    @Input('value') selectedType;
    @Input() floatLabel = 'always';
    @Input() label;
    @Output() selectionChange = new EventEmitter();
    @Output() ngModelChange = new EventEmitter();

    onChange: any = () => { };
    onTouched: any = () => { };

    constructor(private orgService: OrgService) {}

    onSelectionChanged() {
        this.selectionChange.emit(this.selectedType);
    }

    onNgModelChange($event) {
        this.ngModelChange.emit($event);
    }

    ngOnInit(): void {
        const self = this;
        this.loading = true;
        this.orgService.getDeviceTypes().then(function(types) {
            self.types = types;
        }).finally(function() {
            self.loading = false;
        });
    }

    get value() {
        return this.selectedType;
    }

    set value(val) {
        this.selectedType = val;
        this.onChange(val);
        this.onTouched();
    }

    registerOnChange(fn) {
        this.onChange = fn;
    }

    registerOnTouched(fn) {
        this.onTouched = fn;
    }

    writeValue(value) {
        this.selectedType = value;
    }
}
