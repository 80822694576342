import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppSettings, Services} from '../../app.settings';
import {AppContext} from '../../app.context';
import {OrgDeviceType} from '../../orgs/org.service';

@Injectable()
export class IncidentManagementService {


    constructor(private http: HttpClient,
                private appSettings: AppSettings,
                private appContext: AppContext) {

    }

    async getProjects(intId): Promise<any[]> {
        return new Promise<any[]>(async (resolve, reject) => {
            this.appContext.getOrgId().then(async (orgId) => {
                const endpoint = this.appSettings.getEndpoint(Services.INCIDENT_MANAGEMENT_PROJECTS, {orgId: orgId, intId: intId});
                this.http.get(endpoint)
                    .subscribe(function (value: any) {
                        resolve(value.Items);
                    }, reject);
            });
        });
    }

    async getUsers(intId): Promise<any[]> {
        return new Promise<any[]>(async (resolve, reject) => {
            this.appContext.getOrgId().then(async (orgId) => {
                const endpoint = this.appSettings.getEndpoint(Services.INCIDENT_MANAGEMENT_USERS, {orgId: orgId, intId: intId});
                this.http.get(endpoint)
                    .subscribe(function (value: any) {
                        resolve(value.Items as any[]);
                    }, reject);
            });
        });
    }

    async getRecordTypes(intId, projectId): Promise<any[]> {
        return new Promise<any[]>(async (resolve, reject) => {
            this.appContext.getOrgId().then(async (orgId) => {
                const endpoint = this.appSettings.getEndpoint(Services.INCIDENT_MANAGEMENT_RECORD_TYPES, {orgId: orgId, intId: intId, projectId: projectId});
                this.http.get(endpoint)
                    .subscribe(function (value: any) {
                        resolve(value.Items as any[]);
                    }, reject);
            });
        });
    }

    async getRecordTypeSchema(intId, projectId, recordTypeId): Promise<any[]> {
        return new Promise<any[]>(async (resolve, reject) => {
            this.appContext.getOrgId().then(async (orgId) => {
                const endpoint = this.appSettings.getEndpoint(Services.INCIDENT_MANAGEMENT_RECORD_TYPE_SCHEMA, {orgId: orgId, intId: intId, projectId: projectId, recordTypeId: recordTypeId});
                this.http.get(endpoint)
                    .subscribe(function (value: any) {
                        resolve(value);
                    }, reject);
            });
        });
    }

    async getIncident(intId, incidentId): Promise<any[]> {
        return new Promise<any[]>(async (resolve, reject) => {
            this.appContext.getOrgId().then(async (orgId) => {
                const endpoint = this.appSettings.getEndpoint(Services.INCIDENT_MANAGEMENT_RECORD, {orgId: orgId, intId: intId, incidentId: incidentId});
                this.http.get(endpoint)
                    .subscribe(function (value: any) {
                        resolve(value.Item);
                    }, reject);
            });
        });
    }
}
