import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {Routes, RouterModule} from '@angular/router';
import {MaterialModule} from '../material/material.module';
import {FlexLayoutModule} from '@angular/flex-layout';
import {IonicModule} from '@ionic/angular';
import {SharedModule} from '../shared/shared.module';
import {NgxLoadingModule} from 'ngx-loading';
import {TagSelectorDialogComponent} from '../shared/dialogs/tag-selector/tag-selector-dialog.component';
import {IntegrationsModule} from '../integrations/integrations.module';
import {MonitorsComponent} from './monitors.component';
import {MonitorsService} from './monitors.service';
import {MonitorFactory} from './monitor.factory';
import {EditMonitorComponent} from './edit/edit-monitor.component';
import {CreateMonitorComponent} from './create/create-monitor.component';
import {MonitorEditorComponent} from './editor/monitor-editor.component';
import {ConditionSelectorComponent} from './editor/condition-editor/condition-selector/condition-selector.component';
import {ConditionEditorComponent} from './editor/condition-editor/condition-editor.component';
import {SingleConditionEditorComponent} from './editor/condition-editor/single/single-condition-editor.component';
import {MultiConditionEditorComponent} from './editor/condition-editor/multiple/multi-condition-editor.component';

const routes: Routes = [
    {
        path: '',
        component: MonitorsComponent
    }
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild(routes),
        FlexLayoutModule,
        MaterialModule,
        NgxLoadingModule,
        SharedModule,
        IntegrationsModule
    ],
    providers: [MonitorsService, MonitorFactory],
    declarations: [MonitorsComponent, EditMonitorComponent, CreateMonitorComponent,
        MonitorEditorComponent, ConditionSelectorComponent,
        ConditionEditorComponent, SingleConditionEditorComponent, MultiConditionEditorComponent],
    entryComponents: [EditMonitorComponent, TagSelectorDialogComponent]
})
export class MonitorsModule {
}
