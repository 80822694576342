import {AbstractUnitConverterFactory, IUnitConverter} from './converter';

class CelsiusToFahrenheitUnitConverter implements IUnitConverter {
    convert(value: any): any {
        return Math.round((value * 1.8) + 32);
    }

    getUnit(): string {
        return 'F';
    }
}

class FahrenheitToCelsiusUnitConverter implements IUnitConverter {
    convert(value: any): any {
        return Math.round((value - 32) * .55);
    }

    getUnit(): string {
        return 'C';
    }

}

const cToF = new CelsiusToFahrenheitUnitConverter();
const fToC = new FahrenheitToCelsiusUnitConverter();

export class TemperatureConverterFactory extends AbstractUnitConverterFactory {
    constructor() {
        super(['C', 'F'], 'conversion.temperature');
    }

    buildUnitConverter(preferredUnit, fieldUnit): IUnitConverter {

        switch (preferredUnit.toUpperCase()) {
            case 'F' :
                return cToF;
            case 'C' :
                 return fToC;
            default:
                return null;
        }
    }
}
