import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {forEach} from 'lodash';
import * as moment from 'moment';
import {Alarm, AlarmLevel, AlarmNotification} from '../alarms.service';
import {MatTableDataSource} from '@angular/material';


interface AlarmHistoryEntry {
    alarmLevel: string;
    dateNotified: string;
    notifyLevel: string;
    comment?: string;
}


@Component({
    selector : 'alarm-history',
    templateUrl: './alarm-history.component.html',
    styleUrls: ['./alarm-history.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class AlarmHistoryComponent implements OnInit {
    @Input() alarm: Alarm;
    dataSource = new MatTableDataSource<AlarmHistoryEntry>();
    displayedColumns: string[] = ['alarmLevel', 'notifyLevel', 'dateNotified','comment'];
    private history: AlarmHistoryEntry[] = [];


    flattenAlarmLevel(level: AlarmLevel): AlarmHistoryEntry[] {
        const entries: AlarmHistoryEntry[] = [];
        forEach(level.notifications, (notification: AlarmNotification, notifyLevel) => {
            entries.push({
                alarmLevel: level.name,
                dateNotified: notification.dateNotified,
                notifyLevel: notifyLevel,
                comment: level.comment
            });
        });
        console.log("entries", entries);
        return entries;
    }

    ngOnInit(): void {

        let levelHistory = [this.alarm.alarmLevel];
        if (this.alarm.previousAlarmLevel) {
            levelHistory.push(this.alarm.previousAlarmLevel);
        }
        levelHistory = levelHistory.concat(this.alarm.history || []);
        levelHistory[0].comment = this.alarm.comment;
        forEach(levelHistory, (entry) => {
            this.history = this.history.concat(this.flattenAlarmLevel(entry));

        })

        this.history.sort(function(a, b) {
            const bDate = moment(b.dateNotified);
            const aDate = moment(a.dateNotified);
            return bDate.isSame(aDate) ? 0 : bDate.isBefore(aDate) ? -1 : 1;
        });

        this.dataSource.data = this.history;
    }

}
