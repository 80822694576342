import { FormControl, Validators } from '@angular/forms';
import { OrgService } from './../../orgs/org.service';
import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppContext } from 'src/app/app.context';
import { MessageService } from 'src/app/error/message.service';

@Component({
  selector: 'approve-invite-request',
  templateUrl: './approve-invite-request.component.html',
  styleUrls: ['./approve-invite-request.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ApproveInviteRequestComponent implements OnInit, Validators {
  working = false;
  token;
  requestId;
  selectedOrg = null;
  user = null;
  notFound = false;
  inviteRequest = null;
  completed = false;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private messageService: MessageService,
    private orgService: OrgService,
    private appContext: AppContext
  ) {  }

  ngOnInit() {
    const self = this;
    this.working = true;
    this.route.queryParamMap.subscribe(queryParams => {
      this.requestId = queryParams.get('id');
      this.token = queryParams.get('token');
    });
    Promise.all([this.appContext.getUser(), this.orgService.getInviteRequest(this.requestId)]).then((results) => {
      this.user = results[0];
      this.inviteRequest = results[1];
      if (this.user.orgMemberships.length === 1) {
        this.selectedOrg = this.user.orgMemberships[0].orgId;
        this.approve();
      }
    }, (err) => {
      if (err.status === 404) {
        this.notFound = true;
      }

    }).finally(() => {
      this.working = false;
    });
  }

  approve() {
    const self = this;
    self.working = true;
    self.orgService
        .approveRequest(
            self.token,
            self.requestId,
            self.selectedOrg
        )
        .then(() => {
          this.messageService.handleSuccess(`An invite has been sent to ${this.inviteRequest.email}`);
          this.completed = true;
        }).finally(() => {
          this.working = false;
        });
  }

}
