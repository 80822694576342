import {Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
    selector : 'tags',
    templateUrl: './tags.component.html',
    styleUrls: ['./tags.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TagsComponent),
            multi: true
        }
    ]
})

export class TagsComponent implements ControlValueAccessor {
    @Input('value') tags: any;
    @Input() removable;
    @Input() disabled;
    @Input() filter = null;

    @Output() removeTag = new EventEmitter();
    @Output() clickTag = new EventEmitter();

    onChange: any = () => { };
    onTouched: any = () => { };

    get value() {
        return this.tags;
    }

    set value(val) {
        this.tags = val;
        this.onChange(val);
        this.onTouched();
    }

    registerOnChange(fn) {
        this.onChange = fn;
    }

    registerOnTouched(fn) {
        this.onTouched = fn;
    }

    writeValue(value) {
        if (value) {
            this.tags = value;
        }

    }

    onRemoveTag(tag) {
        this.removeTag.emit(tag);
        this.onChange(this.tags);
        this.onTouched();
    }

    onClickTag(tag){
        this.clickTag.emit(tag);
        // this.onChange(this.tags);
        // this.onTouched();
    }
}
