import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {AppContext} from '../app.context';
import {startsWith, forEach, set, get} from 'lodash';
import {User, UserService} from '../users/users.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {RoutingStoreService} from '../shared/route-store.service';
import {Router} from '@angular/router';
import {MessageService} from '../error/message.service';
import {PreferencesService} from '../preferences/preferences.service';

export class PropertyChoice {
    displayName?: string;
    value: string;

    constructor(value, displayName?) {
    }
}

export class UserProperty {
    id: string;
    name: string;
    description: string;
    choices: PropertyChoice[];

    constructor(id, group, name, description, choices) {
    }
}

export class PropertyGroup {
    name: string;
    properties: UserProperty[];
}


const USER_PROPERTIES = [{
    name: 'Graph Unit Conversion',
    properties: [{
        id: 'conversion.temperature',
        name: 'Temperature',
        description: 'Show temperature values in either Celsius or Fahrenheit',
        choices: [{
            displayName: 'Celsius',
            value: 'C'
        }, {
            displayName: 'Fahrenheit',
            value: 'F'
        }]
    }]
}];

@Component({
    selector: 'profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
    encapsulation: ViewEncapsulation.None,
    host: {class: 'main-page-content'},
})
export class ProfileComponent implements OnInit, OnDestroy {
    user: User;
    working = false;
    profileForm: FormGroup;
    propertyGroups = USER_PROPERTIES;
    preferences;

    constructor(private appContext: AppContext,
                private userService: UserService,
                private router: Router,
                private routingStoreService: RoutingStoreService,
                private messageService: MessageService,
                private preferenceService: PreferencesService) {

        this.profileForm = new FormGroup({
            userName: new FormControl('', [Validators.required]),
            phone: new FormControl('', [Validators.required]),
            email: new FormControl('', [Validators.required])
        });


    }

    ngOnDestroy(): void {
    }

    ngOnInit(): void {
        Promise.all([this.appContext.getUser(), this.appContext.getUserPreferences()]).then((results) => {
            this.user = results[0];
            this.profileForm.controls.userName.setValue(this.user.name);
            this.profileForm.controls.phone.setValue(this.user.phoneNumber);
            this.profileForm.controls.email.setValue(this.user.email);

            this.preferences = results[1] || {};

            forEach(this.propertyGroups, (group) => {
                forEach(group.properties, (p) => {
                    this.profileForm.addControl(p.id, new FormControl(get(this.preferences, p.id), []));
                });
            });
        });
    }

    async ok() {
        this.user.name = this.profileForm.controls.userName.value;
        this.user.phoneNumber = this.profileForm.controls.phone.value;
        this.user.email = this.profileForm.controls.email.value;
        forEach(this.propertyGroups, (group) => {
            forEach(group.properties, (p) => {
                const control = this.profileForm.controls[p.id];
                if (control) {
                    set(this.preferences, p.id, control.value);
                }
            });
        });
        this.working = true;
        Promise.all([this.userService.update(this.user.userName, this.user),
            this.preferenceService.updatePreferences(this.user.userName, this.preferences)]).then(() => {
            this.messageService.handleSuccess('The user profile has been updated');
        }).finally(() => {
            this.working = false;
        });

    }

    cancel() {
        const prev = this.routingStoreService.getPreviousUrl();
        const urlAndQuery = prev.split('?');
        const qParms = {};
        if (urlAndQuery.length > 1) {
            const parms = urlAndQuery[1].split('&');
            parms.forEach(function (parm) {
                const keyAndValue = parm.split('=');
                if (keyAndValue.length === 2) {
                    qParms[keyAndValue[0]] = keyAndValue[1];
                }
            });
        }
        this.router.navigate([urlAndQuery[0]], {queryParams: qParms}).finally();
    }
}
