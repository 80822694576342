import {Component, Inject, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {Subject} from 'rxjs';
import {FormBuilder, FormControl, FormGroup, NgModel, Validators} from '@angular/forms';
import { MessageService } from 'src/app/error/message.service';
import { CoordinateMethod, GeocodeService } from 'src/app/services/geocode.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LayerService, Layer } from '../../layers.service';
import {CustomValidators} from '../../../shared/directives/custom.validator';
import {cloneDeep, isMatch} from 'lodash';
import { ThrowStmt } from '@angular/compiler';

declare const google: any;

const DEFAULT_BUILDING_ICON_URL ='/assets/icon/layer_icon.png';
interface Marker {
  lat: number;
  lng: number;
  icon: string;
}

const signatures = {
  iVBORw0KGgo: "image/png",
  "/9j/": "image/jpg"
};

@Component({
  selector: 'app--layer',
  templateUrl: './layer-form.component.html',
  styleUrls: ['./layer-form.component.scss'],
})
export class LayerFormComponent implements OnInit {

  working = false;
  // Map
  map: any;
  markerLat: number = -1;
  markerLng: number = -1;
  zoom = 4;
  // initial center position for the map
  lat = 39.8781;
  lng = -87.623177;

  imageURL:string;
  coordinateMethod = CoordinateMethod.MANUAL;
  coordinateMethods = [{name: 'Manual', value: CoordinateMethod.MANUAL}, {name: 'Map' , value: CoordinateMethod.MAP}];
  layerForm: FormGroup;
  
  layerId: string;
  editMode: boolean;
  layer: any;

  copy: any;
  notFound: boolean;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private layerService: LayerService,
    private messageService: MessageService){}

  ngOnInit(): void {

    //Updating Layer
    if(this.route.snapshot.paramMap.get('layerId')){
      this.editMode = true;
      this.layerId = this.route.snapshot.paramMap.get('layerId');
      this.working = true;

      this.layerForm = new FormGroup({
        image: new FormControl(null, [Validators.required, CustomValidators.RequiredImage()]),
        name:  new FormControl('', [Validators.required]),
        coordinateMethod:  new FormControl(this.coordinateMethod, [Validators.required]),
        longitude:  new FormControl('',[Validators.required, Validators.pattern(/^-?\d*\.?\d*$/), CustomValidators.Coordinates('longitude')]),
        latitude:  new FormControl('',[Validators.required, Validators.pattern(/^-?\d*\.?\d*$/), CustomValidators.Coordinates('latitude')]),
      });

      this.layerService.getLayer(this.layerId).then((fetched) => {
        this.notFound = false;
        var blob = this.dataURItoBlob(fetched.image);
        fetched.image = new File([blob], "fetched", {
          type: this.detectMimeType(fetched.image)
        });
        this.showPreview(fetched.image, true);

        this.layer = fetched;
        this.copy = cloneDeep(this.layer);

        this.layerForm.patchValue({
          name: this.layer.name,
          image: this.layer.image,
          longitude: this.layer.coordinates.longitude,
          latitude: this.layer.coordinates.latitude,
        });
      }, function(err) {
          if (err.status === 404) {
              this.notFound = true;
              console.log(err);
              this.messageService.handleError(err.error.message, 'Layer not Found');
          }
      }).finally(()=>{
        this.working = false;
      })
    }
    //New Layer
    else{
      this.editMode = false;
      this.layerForm = new FormGroup({
        image: new FormControl(null, [Validators.required, CustomValidators.RequiredImage()]),
        name:  new FormControl('', [Validators.required]),
        coordinateMethod:  new FormControl(this.coordinateMethod, [Validators.required]),
        longitude:  new FormControl('',[Validators.required, Validators.pattern(/^-?\d*\.?\d*$/), CustomValidators.Coordinates('longitude')]),
        latitude:  new FormControl('',[Validators.required, Validators.pattern(/^-?\d*\.?\d*$/), CustomValidators.Coordinates('latitude')]),
      });
    }
  }

  get name() { return this.layerForm.get('name'); }
  get image() { return this.layerForm.get('image'); }
  get longitude() { return this.layerForm.get('longitude'); }
  get latitude() { return this.layerForm.get('latitude'); }

  showPreview(event, manuallyTriggered = false){
      let file: Blob
      if(manuallyTriggered){
        file = event;
      }
      else{
      file = (event.target as HTMLInputElement).files[0];
      this.layerForm.patchValue({
        image: file
      })
    }
 
    this.layerForm.get('image').updateValueAndValidity();

    const reader = new FileReader();
    reader.onload = () => {
      this.imageURL = reader.result as string;
    }
    reader.readAsDataURL(file);
  }

  changeCoordinateMethod(event){
    this.coordinateMethod = event.value;
    this.layerForm.patchValue({
      longitude: '',
      latitude: '',
    });

  }

  mapReady(event){
    this.map = event;
    this.map.controls[google.maps.ControlPosition.TOP_CENTER].push(document.getElementById('map-instruction'));
    this.map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(document.getElementById('map-coordinates'));
  }

  mapClicked(event){
    this.layerForm.patchValue({
      longitude: event.coords.lng,
      latitude: event.coords.lat,
    });

    this.markerLat = event.coords.lat;
    this.markerLng = event.coords.lng;
  }

  async ok() {
    if(this.editMode == true)
      this.save();
    else{
      this.working = true;
      this.layer = {
        name: this.layerForm.value.name,
        coordinates: {
          longitude: this.layerForm.value.longitude,
          latitude: this.layerForm.value.latitude
        }
      }
      //covnerts image to base64 string
      await this.getBase64(this.layerForm.value.image).then(data=> this.layer.image = data);

      this.layerService.addLayer(this.layer).then(()=>{
        this.messageService.handleSuccess(
          'Layer added successfully!'
        );
        this.router.navigate(['sensors']).finally();
      }).finally(() => {
        this.working = false;
      });
    }
  }

  async save() {

    this.layer = {
      name: this.layerForm.value.name,
      coordinates: {
        longitude: this.layerForm.value.longitude,
        latitude: this.layerForm.value.latitude
      },
      image: this.layerForm.value.image
    }
    //copy contains more properties than layer which is why iEqual was not used
    if (!this.layerForm.dirty || isMatch(this.copy,this.layer)) {
      if(this.copy.image.name === this.layer.image.name){
      this.cancel();
      return
      }
    } 
    this.working = true;
    await this.getBase64(this.layerForm.value.image).then(data=> this.layer.image = data);
    this.layer.id = this.copy.id;
    this.layerService.updateLayer(this.layer).then(function(updated) {
      this.messageService.handleSuccess(
        'Layer updated successfully!'
      );
    }).finally(()=> {
        this.working = false;
        this.cancel();
    });
  }
  
  cancel() {
      this.router.navigate(['sensors']).finally();
  }

  getBase64(file) {
    //converts file to base64 string
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  dataURItoBlob(dataURI) {
    //converts base64 string to blob - will after be converted back to a file
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/png' });    
    return blob;
 }

  detectMimeType(b64) {
    //get the MineType (png, jpg etc...) - for now only 3 types are allows for images - Check CustomValidators class 
    for (var s in signatures) {
      if (b64.indexOf(s) === 0) {
        return signatures[s];
      }
    }
  }
}
