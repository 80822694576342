import {Injectable} from '@angular/core';
import {isNil, isEmpty, keys, values, cloneDeep, set, get, find, forEach, omit, startCase, indexOf} from 'lodash';

const DEFAULT_MONITOR_MODEL = {
    analytics: {
        config: {
        }
    },
    notifications: {
        templates: {}
    }
};


const DEFAULT_METRIC_CONDITION_MODEL = {
    filters: [],
    threshold: {
        comparisonOp: '$gt',
        aggregation: '$once',
        values: {}
    }
};

const DEFAULT_GROUP_TYPE = 'metric';

@Injectable()
export class MonitorFactory {
    constructor() {
    }

    newMonitor(groupType?) {
        const monitor = cloneDeep(DEFAULT_MONITOR_MODEL);
        const group = this.newGroup(groupType);
        monitor.analytics.config.groups = [group];
        return monitor;
    }

    newGroup(groupType?) {
        return {
            type: groupType || DEFAULT_GROUP_TYPE,
            alertType: 'simple',
            window: 1,
            conditions: [this.newCondition(groupType)]
        };
    }

    newCondition(type?) {
        if (!type || type === 'metric') {
            return cloneDeep(DEFAULT_METRIC_CONDITION_MODEL);
        }
        throw new Error(`The condition type: ${type} is not known`);

    }
}
