import {Component, ElementRef, Inject, OnChanges, OnInit, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef} from '@angular/material';
import {OrgService} from '../../../orgs/org.service';
import {isNil, isEmpty, keys, values, cloneDeep, set, get, find, forEach, omit, startCase, indexOf} from 'lodash';

const OMIT_ATTRIBUTES_LIST = ['tags.Category' , 'platformDeviceId'];
@Component({
    selector: 'tag-selector-dialog',
    templateUrl: './tag-selector-dialog.component.html',
    styleUrls: ['./tag-selector-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class TagSelectorDialogComponent implements OnInit {
    private triggerElementRef;
    private deviceTypeId;
    private selectableFields;
    private queryableAttributes;
    private selectableValues;
    public targetField;
    private selectedValue;
    loading = false;
    constructor(private dialogRef: MatDialogRef<TagSelectorDialogComponent>,
                @Inject(MAT_DIALOG_DATA) data: { trigger: ElementRef, deviceTypeId: string },
                private orgService: OrgService) {
        this.triggerElementRef = data.trigger;
        this.deviceTypeId = data.deviceTypeId;
    }

    onMouseOverField(item) {
        this.targetField = item;
        this.selectableValues = this.queryableAttributes[item.value];
    }

    valueSelected(value) {
        this.selectedValue = value;
        this.dialogRef.close({key: this.targetField.value, value: this.selectedValue.value});
    }

    ngOnInit(): void {
        const matDialogConfig: MatDialogConfig = new MatDialogConfig();
        const rect = this.triggerElementRef.nativeElement.getBoundingClientRect();
        matDialogConfig.position = { left: `${rect.left}px`, top: `${rect.bottom}px` };
        this.dialogRef.updateSize(matDialogConfig.width, matDialogConfig.height);
        this.dialogRef.updatePosition(matDialogConfig.position);
        const fieldsAsGroups = [];
        const self = this;
        this.loading = true;
        this.orgService.getQueryableAttributes(this.deviceTypeId).then(function(atts) {
            atts = omit(atts, OMIT_ATTRIBUTES_LIST);
            // Only select the first segment of the fieldname as a group
            forEach(keys(atts), function (name) {
                const segments = name.split('.');
                if (segments.length > 1) {
                    const groupName = startCase(segments[0]);
                    let found = find(fieldsAsGroups, function (item) {
                        return item.name === groupName;
                    });
                    if (!found) {
                        found = {name: groupName, items: []};
                        fieldsAsGroups.push(found);
                    }
                    found.items.push({
                        viewValue: segments.slice(1),
                        value: name
                    });
                } else {
                    fieldsAsGroups.push({
                        viewValue: name,
                        value: name
                    });
                }

            });
            self.selectableFields = fieldsAsGroups.sort(function (a, b) {
                if (a.name && !b.name) {
                    return 1;
                }
                if (b.name && !a.name) {
                    return -1;
                }
                if (b.name && a.name) {
                    return b.name.localeCompare(a.name);
                }
                return a.viewValue.localeCompare(b.viewValue);
            });
            self.queryableAttributes = atts;
        }).finally(function() {
            self.loading = false;
        });
    }
}
