import {
    Component,
    ComponentFactoryResolver,
    ElementRef,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {ViewHostDirective} from '../../shared/directives/view-host.directive';
import {COMPACT_GRAPH_CONTAINER_HEIGHT, IGraph, IGraphAction, IGraphContainer, IGraphView, ITimeRange} from '../graph.component';
import {isFunction, cloneDeep, forEach} from 'lodash';

const LOADING_DELAY = 500;

@Component({
    selector: 'graph-container',
    templateUrl: './graph-container.component.html',
    styleUrls: ['./graph-container.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class GraphContainerComponent implements OnInit, OnChanges, IGraphContainer {
    @Input() graph: IGraph;
    @Input() height: number;
    @Input() timeRange: ITimeRange;
    @Input() actions: IGraphAction[];
    @Input() hideHeader: boolean;
    @Input() preview: boolean;
    @Input() compact: boolean;

    noData = false;
    // @ts-ignore
    @ViewChild(ViewHostDirective, {static: true}) graphHost: ViewHostDirective;
    @ViewChild('container', {static: true}) container: ElementRef;
    graphView: IGraphView<any>;
    loading = false;
    showLoading = false;
    constructor(private componentFactoryResolver: ComponentFactoryResolver) {}

    ngOnInit(): void {
        const graphType = this.graph.typeDescriptor;

        if (this.actions && isFunction(graphType.filterActions)) {
            this.actions = graphType.filterActions(cloneDeep(this.actions));
        }
        const graphComponent = this.graph.typeDescriptor.getViewComponent();
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(graphComponent);

        const viewContainerRef = this.graphHost.viewContainerRef;
        viewContainerRef.clear();

        const componentRef = viewContainerRef.createComponent(componentFactory);
        this.graphView = <IGraphView<any>>componentRef.instance;
        this.graphView.graph = this.graph;
        this.graphView.compact = this.compact;
        this.graphView.preview = this.preview;
        // Account for the toolbar
        if (this.height) {
            this.graphView.height = this.height - (this.hideHeader ? 0 : 40);
        }

        this.graphView.timeRange = this.timeRange;
        this.graphView.loading.subscribe((isLoading) => {
            this.loading = isLoading;
            // Delay the showing of the loading gif in case we have a quick graph
            // there would be lots of flashing going on
            if (isLoading) {
             setTimeout(() => {
                 if (this.loading) {
                     this.showLoading = true;
                 }
             }, LOADING_DELAY);
            } else {
                this.showLoading = false;
            }
        });
        componentRef.changeDetectorRef.detectChanges();
    }

    onActionClicked(action: IGraphAction) {
        action.run(this.graph, this.graphView);
    }

    onResize($event) {
        if ($event.newWidth) {
            this.graphView.setWidth($event.newWidth);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.graph && this.graphView) {
            this.graphView.setGraph(changes.graph.currentValue);
        }
        if (changes.timeRange && this.graphView) {
            this.graphView.setTimeRange(changes.timeRange.currentValue);
        }
    }
}
