import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {NewOrgRegistration, OrgService} from '../../orgs/org.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MessageService} from '../../error/message.service';

function phoneNormalize(phone: string) {
    let i;
    let result = '';
    phone = phone.trim();
    for (i = 0; i < phone.length; i++) {
        if (phone.charAt(i) !== ' ') {
            result += phone.charAt(i);
        }
    }
    return result;
}

@Component({
    selector: 'register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class RegisterComponent implements OnInit {
    name;
    company;
    phone;
    email;
    loading;
    href;
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private orgService: OrgService,
        private messageService: MessageService
    ) {}

    ngOnInit(): void {
        this.href = window.location.href;
        // this.router.routeReuseStrategy.shouldReuseRoute = function () {
        //     return false;
        // };
    }

    register() {
        const orgRegistration: NewOrgRegistration = {
            account: {
                name: this.company
            },
            owner: {
                user: {
                    email: this.email,
                    name: this.name,
                    phone: phoneNormalize(this.phone)
                },
                options: {
                    notification: 'email',
                    link: window.location.href
                }
            }
        };
        this.loading = true;
        this.orgService.registerOrganization(orgRegistration)
            .then((value) => {
                // tslint:disable-next-line: max-line-length
                this.messageService.handleSuccess(`Registration was successfully submitted.  Check your e-mail at ${this.email} for registration verification.`, 'Registration Successful');
                this.router.navigate(['/login']);
            })
            .finally(() => {
                this.loading = false;
            });

    }
}
