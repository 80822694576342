import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'sen-expansion-panel',
    templateUrl: './expansion-panel.component.html',
    styleUrls: ['./expansion-panel.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ExpansionPanelComponent implements OnInit {
    @Input() title;
    @Input() ionIconName;
    @Input() matIconName;
    @Input() iconClass;
    @Input() hideBackground;
    @Input() hideToggle;
    @Input() hideBorder;
    @Input() step;
    @Input() expanded;
    constructor() { }

    ngOnInit() {
    }

}
