import {HttpClient} from '@angular/common/http';
import {AppSettings, Services} from '../app.settings';
import {AppContext} from '../app.context';
import {Injectable} from '@angular/core';
import {User} from '../users/users.service';
import {forEach} from 'lodash';
import {Observable} from 'rxjs';
import {UrlUtils} from '../shared/url-utils';
import {map, publishReplay, refCount} from 'rxjs/operators';
import {get} from 'lodash';

export interface Layer {
    id: string;
    name: string;
    image: string | File;
    coordiantes: {
        longitude: number,
        latitude: number
    };
    orgId: string,
}

@Injectable()
export class LayerService {
    // By orgId
    layerCache: { [key: string]: Observable<any> } = {};

    constructor(
        private http: HttpClient,
        private appSettings: AppSettings,
        private appContext: AppContext) {
    }

    async queryLayers(query?): Promise<any> {
        const orgId = await this.appContext.getOrgId();
        if (!orgId) {
            return Promise.reject(`Org could not be obtained`);
        }
        const endpoint = this.appSettings.getEndpoint(Services.LAYERS, {orgId: orgId});
        const qString = UrlUtils.mapToQueryString(query);
        const cacheKey = endpoint + (qString.length > 0 ? '?' : '') + qString;
        let obs = this.layerCache[cacheKey];
        if (!obs) {
            obs = this.http.get(endpoint, {params: query || {}}).pipe(
                map(res => res),
                publishReplay(1),
                refCount());
            this.layerCache[cacheKey] = obs;
        }

        return new Promise((resolve, reject) => {
            obs.subscribe(function (value: any) {
                resolve(value);
            }, reject);
        });
    }

    updateLayer(layer: Layer): Promise<Layer> {
        return new Promise((resolve, reject) => {
            this.appContext.getOrganization().then((orgMembership) => {
                const endpoint = this.appSettings.getEndpoint(Services.LAYER, {orgId: orgMembership.orgId, layerId: layer.id});
                this.http.put(endpoint, layer)
                    .subscribe((value: Layer) => {
                        this._clearCache();
                        resolve(value);
                    }, reject);
            }, reject);
        });

    }

    addLayer(layer: Layer): Promise<Layer>{
        return new Promise((resolve, reject) => {
            this.appContext.getOrganization().then((orgMembership) => {
                const endpoint = this.appSettings.getEndpoint(Services.LAYERS, {orgId: orgMembership.orgId});
                this.http.post(endpoint, layer)
                    .subscribe((value: any) => {
                        this._clearCache();
                        resolve(value);
                    }, reject);
            });
        });
    }

    getLayer(layerId: string): Promise<Layer> {
        return new Promise((resolve, reject) => {
            this.appContext.getOrganization().then((orgMembership) => {
                const endpoint = this.appSettings.getEndpoint(Services.LAYER, {orgId: orgMembership.orgId, layerId: layerId});
                this.http.get(endpoint)
                    .subscribe(function (value: any) {
                        resolve(value.Item);
                    }, function (err) {
                        reject(err);
                    });
            }, reject);
        });
    }

    deleteLayer(layerId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.appContext.getOrganization().then((orgMembership) => {
                const endpoint = this.appSettings.getEndpoint(Services.LAYER, {orgId: orgMembership.orgId, layerId: layerId});
                this.http.delete(endpoint)
                    .subscribe((value: any) => {
                        this._clearCache();
                        resolve(true);
                    }, reject);
            }, reject);
        });
    }

    _clearCache() {
        this.layerCache = {};
    }

    sortLayerDevices(devices, layerId){
        let layerSensors = [];
        let remainingSensors = []
        if(devices){
            devices = devices.Items;
            for (let i = 0; i < devices.length; i++) {
              const device = devices[i];
              if(get(device, 'layerId', false) == layerId){
                  layerSensors.push(device);
              }
              else{
                remainingSensors.push(device)
              } 
           }
        }
        return ({layerSensors, remainingSensors});
    }
}
