import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {AbstractGraphBuilder, IDeclarativeGraphBuilder} from '../../graph.component';
import {FormControl} from '@angular/forms';
import {forEach, cloneDeep, set, hasIn} from 'lodash';

@Component({
    selector: 'declarative-graph-builder',
    templateUrl: './declarative-graph-builder.component.html',
    styleUrls: ['./declarative-graph-builder.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class DeclarativeGraphBuilderComponent extends AbstractGraphBuilder implements IDeclarativeGraphBuilder, OnInit {
    graphBuilderSpec;

    ngOnInit(): void {
        // Set the constants if there are no values already set
        forEach(this.graphBuilderSpec.constants, (constant) => {
            if (!hasIn(this.graphSpec, constant.specPath)) {
                set(this.graphSpec, constant.specPath, constant.value);
            }
        });

        this.graphBuilderSpec = cloneDeep(this.graphBuilderSpec);
        forEach(this.graphBuilderSpec.steps, (step, idx) => {
           step.id = `step_${idx}`;
           this.builderForm.addControl(step.id, new FormControl());
        });
    }

    getNumberOfSteps() {
        return this.graphBuilderSpec.steps.length;
    }
}
