import {Component, Inject, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Monitor, MonitorsService} from '../monitors.service';
import {isNil, isEmpty, keys, values, cloneDeep, set, get, find, forEach, omit, startCase, indexOf} from 'lodash';
import {flatten} from 'flat';
import {DeviceTypeService} from '../../deviceType/deviceType.service';
import {OrgService} from '../../orgs/org.service';
import {ActivatedRoute, NavigationStart, Router} from '@angular/router';
import {filter, map} from 'rxjs/operators';


@Component({
    selector : 'edit-monitor',
    templateUrl: './edit-monitor.component.html',
    styleUrls: ['./edit-monitor.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class EditMonitorComponent implements OnInit {
    monitor;
    notFound = false;
    loading = false;
    constructor(private router: Router,
                private route: ActivatedRoute,
                private monitorService: MonitorsService) {}

    ngOnInit(): void {
        const self = this;
        const monitorId = this.route.snapshot.paramMap.get('monitorId');
        this.loading = true;
        this.monitorService.getMonitor(monitorId).then(function(fetched) {
            self.monitor = fetched;
        }, function(err) {
            if (err.status === 404) {
                self.notFound = true;
            }
        }).finally(() => {
            this.loading = false;
        });
    }

    backToMonitors() {
        this.router.navigate(['monitors']).finally();
    }

    createMonitor() {
        this.router.navigate(['monitors', 'create']).finally();
    }
}
