import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'localDate'
})
export class LocalDateDirective implements PipeTransform {

    transform(utcDate: string): string {
        return utcDate ? moment(utcDate).format('MM-DD-YYYY hh:mm:ssa') : utcDate;
    }


}
