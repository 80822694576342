import { NgModule } from '@angular/core';
import {CommonModule, TitleCasePipe} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { IonicModule } from '@ionic/angular';
import { NgxLoadingModule } from 'ngx-loading';
import { MaterialModule} from '../material/material.module';
import { SensorGroupComponent } from './groups/sensor-group/sensor-group.component';
import { SensorsPage } from './sensors.page';
import { SensorService} from './sensors.service';
import { LayerService } from './layers.service';
import { TagsComponent } from './tags/tags.component';
import {SharedModule} from '../shared/shared.module';
import {RegisterSensorComponent, TypeNameFilterPipe} from './register/register-sensor.component';
import {SensorEditorComponent} from './edit/sensor-editor.component';
import { SensorSearchFilterPipe } from './sensor-search.filter.pipe';
import {NgMatSearchBarModule} from 'ng-mat-search-bar';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FilterComponent} from '../shared/filter/filter.component';
import {AgmCoreModule} from '@agm/core';
import {SensorMapComponent} from './map/sensor-map.component';
import {SensorGroupsComponent} from './groups/sensor-groups.component';
import {SensorTreeComponent} from './tree/sensor-tree.component';
import {SensorDetailComponent} from './detail/sensor-detail.component';
import { LayerFormComponent } from './map/layer-form/layer-form.component';
import { LayerComponent } from './map/layer/layer.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { AddLayerDeviceComponent } from './map/layer/add-layer-device/add-layer-device.component';
const routes: Routes = [
  {
    path: '',
    component: SensorsPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    FlexLayoutModule,
    NgxLoadingModule,
    MaterialModule,
    SharedModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
    NgMatSearchBarModule,
    AgmCoreModule,
    LeafletModule
  ],
  providers: [SensorService, LayerService, TitleCasePipe, SensorSearchFilterPipe],
  entryComponents: [FilterComponent,AddLayerDeviceComponent],
  declarations: [
    SensorsPage, 
    SensorGroupComponent, 
    TagsComponent, 
    RegisterSensorComponent,
    SensorEditorComponent,
    SensorSearchFilterPipe, 
    SensorMapComponent, 
    SensorGroupsComponent, 
    SensorTreeComponent, 
    SensorDetailComponent,
    LayerFormComponent,
    LayerComponent,
    AddLayerDeviceComponent,
    TypeNameFilterPipe]
})
export class SensorsPageModule {}
