import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material';
import {ConfirmDialogComponent} from '../../../shared/dialogs/confirm/confirm-dialog.component';
import {IncidentManagementRecordDialogComponent} from '../dialog/im-record-dialog.component';

@Component({
    selector: 'im-link',
    templateUrl: './im-link.component.html',
    styleUrls: ['./im-link.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class IncidentManagementLink {
    @Input() link;
    @Input() showIcon = false;
    @Input() showText = false;
    @Input() expandable = false;
    constructor(private dialog: MatDialog) {

    }

    showInDialog($event) {
        $event.stopPropagation();
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = '500px';
        dialogConfig.data = {
            link: this.link
        }

        const dialogRef = this.dialog.open(IncidentManagementRecordDialogComponent, dialogConfig);
    }
}
