import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ChartJSGraphComponent} from '../chartjs-graph.component';
import {IDataSeries, IDataSeriesDevice, IDataSeriesQueryResult} from '../../../../deviceData/device-data.service';
import {XYGraphDataProvider} from '../../../xyseries/xyseries.dataprovider';
import {IGraph} from '../../../graph.component';
import {IAxis, IScaling, XYSeriesGraphSpec} from '../../../xyseries/xyseries.graph.spec';
import {ChartOptions} from '../chartjs';
import {BreakpointObserver} from '@angular/cdk/layout';

const OBSERVATIONS_BEFORE_NO_POINTS = 20;

interface XYPoint {
    x: any;
    y: any;
}

const MESSAGE_DOT = 'message.';

const COLORS = ['#a6cee3', '#1f78b4', '#b2df8a', '#33a02c', '#fb9a99',
    '#e31a1c', '#fdbf6f', '#ff7f00', '#cab2d6', '#6a3d9a', '#ffff99', '#b15928'];


@Component({
    selector: 'chartjs-xy-series',
    templateUrl: '../chartjs-graph.component.html',
    styleUrls: ['../chartjs-graph.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ChartJSLineGraphComponent extends ChartJSGraphComponent<IDataSeriesQueryResult> implements OnInit {

    private COLOR_INDEX = 0;
    private seriesHoverDetails: string[] = [];

    constructor(dataProvider: XYGraphDataProvider,
                breakpointObserver: BreakpointObserver) {
        super(dataProvider, breakpointObserver);
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    buildChart(queryResult: IDataSeriesQueryResult, graph: IGraph): Promise<any> {
        return Promise.resolve(this.provideConfiguration(graph, queryResult));
    }

    private getColor() {
        return COLORS[ this.COLOR_INDEX++ % COLORS.length];
    }

    private provideConfiguration(graph: IGraph, deviceSeriesData: IDataSeriesQueryResult): any {
        const xyGraphSpec = <XYSeriesGraphSpec>graph.spec;
        this.COLOR_INDEX = 0;

        const maintainAspectRatio = false;
        // if (this.xyGraphSpec.layout && this.xyGraphSpec.layout.focusType === IFocusType.CONTAINER_DEFINED) {
        //    maintainAspectRatio = false;
        // }
        // let aspectRatio;
        // if (xyGraphSpec.layout && xyGraphSpec.layout.aspectRatio) {
        //     aspectRatio = xyGraphSpec.layout.aspectRatio;
        // }

        let maxObservations = 0;
        deviceSeriesData.responses.forEach((response) => {
            if (response.times.length > maxObservations) {
                maxObservations = response.times.length;
            }
        });

        const config: any = {
            type: 'line',
            data: {
                datasets: this.buildDatasets(deviceSeriesData, xyGraphSpec)
            },
            options: <ChartOptions> {
                responsive: true,
                scales: {
                    yAxes: this._buildAxisConfigurationForY(xyGraphSpec),
                    xAxes: this._buildAxisConfigurationForX(xyGraphSpec)
                },
                maintainAspectRatio: maintainAspectRatio,
                // aspectRatio: aspectRatio,
                legend: {
                    display: true,
//                    onHover: this.onHoverLegend.bind(this),
                    position: 'bottom'
                }
            }
        };

        if (maxObservations > OBSERVATIONS_BEFORE_NO_POINTS) {
            config.options.elements = {
                point: {
                    radius: 0
                }
            };
        }

        return config;
    }

    // private onHoverLegend(event: MouseEvent, legendItem: Chart.ChartLegendLabelItem) {
    //    this.getEventEmitter().emit(new SeriesHoverEvent(this.seriesHoverDetails[legendItem.datasetIndex]));
    // }

    private _buildAxisConfigurationForX(graphSpec: XYSeriesGraphSpec): any[] {
        const xAxis = [];
        xAxis.push({
            type: IScaling.TIME.toString(),
        });
        return xAxis;
    }

    // See if we can push this out
    private _buildAxisConfigurationForY(graphSpec: XYSeriesGraphSpec): any[] {
        if (graphSpec.axes.length <= 1) {
            return undefined;
        }
        if (graphSpec.axes.length > 3) {
            throw new Error('Only support for maximum 2 Y-axis');
        }

        const yAxes = [];
        for (let i = 1; i < graphSpec.axes.length; i++) {
            const axis: IAxis = graphSpec.axes[i];
            const scaling: IScaling = axis.scaling ? axis.scaling : IScaling.LINEAR;
            const id = axis.id ? axis.id : undefined;
            const position = i === 1 ? 'left' : 'right';

            const chartAxis: any = {
                type: scaling.toString(),
                position: position,
                scaleLabel: {
                    display: true,
                    labelString: (axis.name ? axis.name : '')
                },
                id: id
            };

            if (scaling === IScaling.LINEAR && axis.recommendedMin !== undefined) {
                const recommendedMin = axis.recommendedMin !== undefined ? axis.recommendedMin : undefined;
                const recommendedMax = axis.recommendedMax !== undefined ? axis.recommendedMax : undefined;
                chartAxis.ticks = {
                    suggestedMin: recommendedMin,
                    suggestedMax: recommendedMax
                };
            }
            if (chartAxis.ticks === undefined) {
                chartAxis.ticks = {};
            }

            yAxes.push(chartAxis);
        }

        return  yAxes;
    }

    private buildDatasets(deviceSeriesData: IDataSeriesQueryResult, graphSpec: XYSeriesGraphSpec): any {
        this.seriesHoverDetails = [];
        const datasets = [];
        deviceSeriesData.responses.forEach((response) => {
            response.series.forEach((series) => {
                this.addDeviceDatasets(response.device, series, response.times, datasets);
            });
        });
        return datasets;
    }

    private addDeviceDatasets(device: IDataSeriesDevice, dataSeries: IDataSeries, times: string[], datasets: any) {
        const dataset = {};
        datasets.push(dataset);

        dataset['fill'] = false;

        let fieldText = dataSeries.field;
        if (fieldText.startsWith(MESSAGE_DOT)) {
            fieldText = fieldText.substr(MESSAGE_DOT.length);
        }


        dataset['label'] = `${device.name} (${fieldText})`;

        dataset['lineTension'] = 0;
        dataset['borderColor'] = this.getColor();

        const xyData = new Array<XYPoint>(times.length);
        dataset['data'] = xyData;
        for (let i = 0; i < times.length; i++) {
            xyData[i] = {
                x: new Date(times[i]),
                y: dataSeries.values[i]
            };
        }
    }
}
