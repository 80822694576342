import { MessageService } from './../../../error/message.service';
import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { UserService } from 'src/app/users/users.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChangePasswordComponent implements OnInit {
    password;
    newpassword;
    confirmpassword;
    user = null;
    constructor(
        private userService: UserService,
        private messageService: MessageService,
        private dialogRef: MatDialogRef<ChangePasswordComponent>,
        @Inject(MAT_DIALOG_DATA) data) {
          this.user = data.user;
        }

    cancel() {
      this.dialogRef.close();
    }

    ok() {
      try {
        this.userService.changePassword(this.password, this.confirmpassword, this.user);
        this.dialogRef.close(true);
      } catch (e) {
        this.messageService.handleError(e, 'Error updating values');
      }
    }

  ngOnInit() {}

}
