import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DeviceTypeService} from '../deviceType/deviceType.service';
import {MessageService} from '../error/message.service';
import {DashboardsService, IDashboard} from './dashboards.service';
import {MatDialog, MatDialogConfig} from '@angular/material';
import {ConfirmDialogComponent} from '../shared/dialogs/confirm/confirm-dialog.component';
import {CreateDashboardComponent} from './create/create-dashboard.component';
import {cloneDeep, values, isUndefined} from 'lodash';
import {SensorService} from '../sensors/sensors.service';
import {
    COMPACT_GRAPH_CONTAINER_HEIGHT,
    COMPACT_GRAPH_HEIGHT,
    Graph, GRAPH_ACTION_IDS,
    GraphContext,
    GraphSpec,
    IGraph,
    IGraphContext, IGraphView, ITimeRange, TIME_DURATION_CHOICES, TIME_DURATIONS
} from '../graph/graph.component';
import {GraphEditorComponent} from '../graph/editor/graph-editor.component';
import {IYField} from '../graph/xyseries/xyseries.graph.spec';
import {GraphTypeRegistryService} from '../graph/graph-type.registry';
import {findIndex, isEqual, forEach} from 'lodash';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {GraphDialogComponent} from './graph-dialog/graph-dialog.component';
import {AppContext} from '../app.context';
import {Roles} from '../app.settings';
import {Observable, Observer} from 'rxjs';
import {GraphLegendService} from '../shared/legend.service';
import {ResizeEvent} from 'angular-resizable-element';

export interface IDashboardLayout {
    removeGraph();
}

@Component({
    selector: 'dashboard',
    templateUrl: './dashboard.page.html',
    styleUrls: ['./dashboard.page.scss'],
    encapsulation: ViewEncapsulation.None,
    host: {class: 'main-page-content'},
})

export class DashboardPage implements OnInit, OnDestroy {
    @ViewChild('legend', {static: false}) legendRef: ElementRef;
    @ViewChild('graphLayout', {static: false}) graphLayout: IDashboardLayout;
    timeChoices = TIME_DURATION_CHOICES;
    selectedTime = TIME_DURATIONS.ONE_HOUR;
    selectedTimeRange: ITimeRange = {
        duration: this.selectedTime
    };
    loading = false;
    isMobile = false;
    edit = false;
    dashboard: IDashboard = null;

    graphs: IGraph[];
    private graphContext: IGraphContext;
    actions;

    constructor(
        protected msgService: MessageService,
        protected router: Router,
        protected dashboardsService: DashboardsService,
        protected sensorService: SensorService,
        protected dialog: MatDialog,
        protected graphTypeRegistry: GraphTypeRegistryService,
        protected breakpointObserver: BreakpointObserver,
        protected appContext: AppContext
    ) {
        breakpointObserver.observe([Breakpoints.Handset, Breakpoints.Small]).subscribe(result => {
            this.isMobile = result.matches;
        });
    }

    deleteGraph(graphToDelete: IGraph) {
        const dialogConfig = new MatDialogConfig();
        const graphSpecToDelete = graphToDelete.spec;
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            title: 'Confirm Delete',
            message: `Are you sure you want to remove the graph '${graphSpecToDelete.title}'?`
        };

        const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                if (!this.edit) {
                    const foundIdx = findIndex(this.dashboard.graphs, function (g) {
                        return (g.id && graphSpecToDelete.id && g.id === graphSpecToDelete.id) ||
                            (g.graphId && graphSpecToDelete.graphId && g.graphId === graphSpecToDelete.graphId);
                    });
                    // This is to compensate for graphs that lack an id
                    // if (foundIdx === -1) {
                    //     const copy = cloneDeep(this.dashboard);
                    //     const idx = findIndex(copy.graphs, (existing) => {
                    //         return isEqual(existing, graphSpecToDelete);
                    //     });
                    //     if (idx > -1) {
                    //         copy.graphs.splice(idx, 1);
                    //         this.dashboardsService.updateDashboard(copy).then(() => {
                    //             this.dashboard = copy;
                    //         });
                    //     }
                    // } else {
                    //
                    // }
                    if (foundIdx > -1) {
                        this.dashboardsService.deleteGraph(this.dashboard.id, graphSpecToDelete.id || graphSpecToDelete.graphId).then(() => {
                            this.dashboard.graphs.splice(foundIdx, 1);
                            const temp = cloneDeep(this.graphs);
                            temp.splice(foundIdx, 1);
                            this.graphs = temp;
                        });
                    }
                } else {
                    // Remove from just the dashboard graph and the displayed graphs
                    const idx = findIndex(this.dashboard.graphs, (existing) => {
                        return isEqual(existing, graphSpecToDelete);
                    });
                    if (idx > -1) {
                        this.dashboard.graphs.splice(idx, 1);
                        const clone = cloneDeep(this.graphs);
                        clone.splice(idx, 1);
                        this.graphs = clone;
                    }

                }
            }
        });
    }

    buildGraph(graphSpec: any): IGraph {
        const type = this.graphTypeRegistry.get(graphSpec);
        const resolvedSpec = type.resolve(graphSpec, this.graphContext);
        return new Graph(resolvedSpec, type);
    }

    async buildActions() {
        this.actions = [{
            id: GRAPH_ACTION_IDS.EXPAND,
            name: 'Expand',
            matIcon: 'fullscreen',
            run: this.expandGraph.bind(this)
        }, {
            id: GRAPH_ACTION_IDS.REFRESH,
            name: 'Refresh',
            matIcon: 'autorenew',
            run: this.refreshGraph.bind(this)
        }];
        return new Promise(async (resolve, reject) => {
            const hasEditPermissions = await this.appContext.isUserInRole([Roles.OWNER, Roles.ADMIN, Roles.USER]);
            if (hasEditPermissions && this.dashboard.modifiable && !this.isMobile) {
                this.actions.push({
                    name: '',
                    matIcon: 'more_vert',
                    alwaysVisible: true,
                    children: [{
                        id: GRAPH_ACTION_IDS.EDIT,
                        name: 'Edit',
                        matIcon: 'edit',
                        run: this.editGraph.bind(this)
                    }, {
                        id: GRAPH_ACTION_IDS.DELETE,
                        name: 'Delete',
                        matIcon: 'delete',
                        run: this.deleteGraph.bind(this)
                    }]
                });
            }
            resolve();
        });

    }

    ngOnInit() {
        this.loading = true;
        Promise.all([this.fetchDashboard(), this.getGraphContext()]).then((results) => {
            this.dashboard = results[0];
            this.graphContext = results[1] as IGraphContext;
            this.dashboard.graphs = this.dashboard.graphs || [];
            if (this.dashboard.graphs) {
                this.dashboard.graphs.sort((a, b) => {
                    if (isUndefined(a.ordinal) || isUndefined(b.ordinal)) {
                        return 0;
                    }
                    return a.ordinal - b.ordinal;
                });
            }
            this.graphs = this.dashboard.graphs.map((graphDefinition: any) => {
                return this.buildGraph(graphDefinition);
            });
            this.buildActions();
            if (this.graphs.length === 0 && this.dashboard.modifiable) {
                this.edit = true;
            }
        }).finally(() => {
            this.loading = false;
        });
    }

    protected fetchDashboard(): Promise<IDashboard> {
        throw new Error('Fetch dashboard needs to be implemented');
    }

    protected getGraphContext(): Promise<IGraphContext> {
        return null;
    }

    refreshGraph(graphToRefresh: IGraph, graphView: IGraphView<any>) {
        graphView.refresh();
    }

    expandGraph(graphToExpand: IGraph) {
        const dialogHeight = window.innerHeight - 40;
        const dialogWidth = window.innerWidth - 40;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = dialogWidth + 'px';
        dialogConfig.height = dialogHeight + 'px';
        dialogConfig.disableClose = false;
        dialogConfig.data = {
            graph: graphToExpand,
            graphHeight: dialogHeight - 2,
            timeRange: this.selectedTimeRange
        };

        const dialogRef = this.dialog.open(GraphDialogComponent, dialogConfig);
    }

    editGraph(graphToEdit: IGraph) {
        this.showGraphEditor(cloneDeep(graphToEdit.spec)).subscribe((result) => {
            if (result) {
                this.dashboardsService.updateGraph(this.dashboard.id, result.graphSpec).then(() => {
                    const idx = findIndex(this.dashboard.graphs, (spec) => {
                        return spec.id === result.graphSpec.id;
                    });
                    if (idx > -1) {
                        this.dashboard.graphs.splice(idx, 1, result.graphSpec);
                        const temp = cloneDeep(this.graphs);
                        temp.splice(idx, 1, this.buildGraph(result.graphSpec));
                        this.graphs = temp;
                    }
                }).finally();
            }
        });
    }

    ngOnDestroy(): void {
    }

    showGraphEditor(graphSpec?: GraphSpec, type?: string): Observable<any> {
        const dialogHeight = window.innerHeight - 40;
        const dialogWidth = window.innerWidth - 40;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            graphSpec: graphSpec,
            type: type
        };
        dialogConfig.minWidth = '75%';
        const dialogRef = this.dialog.open(GraphEditorComponent, dialogConfig);
        return dialogRef.afterClosed();
    }

    onChangeTime() {
        this.selectedTimeRange = {
            duration: this.selectedTime
        };
    }

    validateLegendResize(event: ResizeEvent): boolean {
        return true;
    }

    onLegendResizeEnd(event: ResizeEvent): void {
        if (typeof event.edges.top === 'number') {
            const newTop = this.legendRef.nativeElement.offsetTop + event.edges.top;
            if (newTop >= 0) {
                this.legendRef.nativeElement.style['top'] = newTop + 'px';
            }
        }
    }

    saveDashboard() {
        // this.loading = true;
        this.dashboardsService.updateDashboard(this.dashboard).then((result) => {
            this.edit = false;
        }).finally(() => {
            // this.loading = false;
        });

    }

    /**
     * Iterates over the graphs array, and assigns an ordinal to the graph spec in the order
     * that the specs are in.  It is assumed that the specs have maintained their order.  This
     * simply enforces that order by assigning the ordinal according to its index.  Additionally
     * the graphs are sorted to also maintain their order
     */
    assignOrdinals() {
        forEach(this.dashboard.graphs, (graph, idx) => {
            graph.ordinal = idx;
        });
        this.graphs.sort((a, b) => {
            return a.spec.ordinal - b.spec.ordinal;
        });
    }

    /**
     * An event is fired by the layout to notify that the graph order has changed.  The graph is then
     * swapped out of its current position and inserted into its new position, then the ordinals are assigned afterwards
     * @param $event
     */
    onGraphOrdinalChanged($event) {
        const idx = findIndex(this.dashboard.graphs, (spec) => {
            return spec === $event.graph.spec;
        });
        if (idx > -1 && idx !== $event.newOrdinal) {
            this.dashboard.graphs.splice(idx, 1);
            this.dashboard.graphs.splice($event.newOrdinal, 0, $event.graph.spec);
            this.assignOrdinals();
        }
    }

    onAddGraph($event) {
        // Add the graph into the insert index, change the ordinals
        this.dashboard.graphs = this.dashboard.graphs.slice(0, $event.insertIdx).concat($event.spec).concat(this.dashboard.graphs.slice($event.insertIdx));
        this.assignOrdinals();
        this.graphs = this.graphs.slice(0, $event.insertIdx).concat(this.buildGraph($event.spec)).concat(this.graphs.slice($event.insertIdx));
    }
}

@Component({
    selector: 'custom-dashboard',
    templateUrl: './dashboard.page.html',
    styleUrls: ['./dashboard.page.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CustomDashboardPage extends DashboardPage implements OnInit, OnDestroy {
    dashboardId = null;

    constructor(protected msgService: MessageService,
                protected router: Router,
                protected dashboardsService: DashboardsService,
                protected sensorService: SensorService,
                protected dialog: MatDialog,
                protected route: ActivatedRoute,
                protected graphTypeRegistry: GraphTypeRegistryService,
                protected breakpointObserver: BreakpointObserver,
                protected appContext: AppContext) {
        super(msgService, router, dashboardsService, sensorService, dialog, graphTypeRegistry, breakpointObserver, appContext);
    }

    protected fetchDashboard(): Promise<IDashboard> {
        return this.dashboardsService.getDashboard(this.dashboardId);
    }

    ngOnInit(): void {
        this.dashboardId = this.route.snapshot.params.dashboardId;
        super.ngOnInit();
    }

}

/**
 * The device dashboard shows graphs for a device type scoped to all devices of that type.  The device ids
 * need to be resolved for the graphs
 */
@Component({
    selector: 'device-type-dashboard',
    templateUrl: './dashboard.page.html',
    styleUrls: ['./dashboard.page.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DeviceTypeDashboardPage extends DashboardPage implements OnInit, OnDestroy {
    protected deviceTypeId = null;

    constructor(protected deviceTypeService: DeviceTypeService,
                protected msgService: MessageService,
                protected router: Router,
                protected dashboardsService: DashboardsService,
                protected sensorService: SensorService,
                protected dialog: MatDialog,
                protected route: ActivatedRoute,
                protected graphTypeRegistry: GraphTypeRegistryService,
                protected breakpointObserver: BreakpointObserver,
                protected appContext: AppContext) {
        super(msgService, router, dashboardsService, sensorService, dialog, graphTypeRegistry, breakpointObserver, appContext);
    }

    protected fetchDashboard(): Promise<IDashboard> {
        return this.deviceTypeService.getDashboard(this.deviceTypeId);
    }

    /**
     * Build a context that has the type and all platform device ids for that type
     */
    protected getGraphContext(): Promise<IGraphContext> {
        return this.sensorService.querySensors().then((results) => {
            const platformDeviceIds = results.Items.filter((sensor) => {
                return sensor.typeId === this.deviceTypeId;
            }).map((sensor) => {
                return sensor.id;
            });
            return new GraphContext(this.deviceTypeId, platformDeviceIds);
        });

    }

    ngOnInit(): void {
        this.deviceTypeId = this.route.snapshot.params.deviceTypeId;
        super.ngOnInit();
    }

}


/**
 * The device dashboard shows graphs for a device type with a scope on a particular device.
 */
@Component({
    selector: 'device-dashboard',
    templateUrl: './dashboard.page.html',
    styleUrls: ['./dashboard.page.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DeviceDashboardPage extends DeviceTypeDashboardPage implements OnInit, OnDestroy {
    platformDeviceId = null;

    constructor(protected deviceTypeService: DeviceTypeService,
                protected msgService: MessageService,
                protected router: Router,
                protected dashboardsService: DashboardsService,
                protected sensorService: SensorService,
                protected dialog: MatDialog,
                protected route: ActivatedRoute,
                protected graphTypeRegistry: GraphTypeRegistryService,
                protected breakpointObserver: BreakpointObserver,
                protected appContext: AppContext) {
        super(deviceTypeService, msgService, router, dashboardsService,
            sensorService, dialog, route, graphTypeRegistry, breakpointObserver, appContext);
    }

    /**
     * Build a context that has the type and platform device id
     */
    protected getGraphContext(): Promise<IGraphContext> {
        return Promise.resolve(new GraphContext(this.deviceTypeId, [this.platformDeviceId]));
    }

    ngOnInit(): void {
        this.platformDeviceId = this.route.snapshot.params.platformDeviceId;
        super.ngOnInit();
    }
}
