import {SensorService} from '../../sensors/sensors.service';
import {Component, Inject, Input, ViewEncapsulation} from '@angular/core';
import {User} from '../users.service';
import {OrgService} from '../../orgs/org.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {AppSettings, Roles} from '../../app.settings';
import { find, forEach } from 'lodash';
import {MessageService} from '../../error/message.service';

@Component({
    selector: 'edit-user-dialog',
    templateUrl: './edit-user.component.html',
    styleUrls: ['./edit-user.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class EditUserComponent {
    roleAndDescList = [{
        name: Roles.OWNER,
        description: 'Has full permissions to the entire organization'
    }, {
        name: Roles.ADMIN,
        description: 'Has most permissions to the entire organization'
    }, {
        name: Roles.USER,
        description: 'Can add/remove monitors owned by the organization'
    }, {
        name: Roles.READ_ONLY,
        description: 'Has a read-only view of the organizations resources'
    }];
    selectedRole: string;
    orgUsers = null;
    users = null;
    orgId = null;
    constructor(
        private orgService: OrgService,
        private errorService: MessageService,
        private dialogRef: MatDialogRef<EditUserComponent>,
        @Inject(MAT_DIALOG_DATA) data ) {
        this.orgId = data.orgId;
        this.users = data.users;
        this.orgUsers = data.orgUsers.filter(function(orgUser) {
            const found = find(data.users, function( selectedUser ) {
                return orgUser.userName === selectedUser.userName;
            });
            return !found;
        });
    }

    cancel() {
        this.dialogRef.close();
    }

    isThereStillAnAdmin() {
        return this.orgUsers.filter(function(orgUser) {
            return orgUser.role === Roles.OWNER || orgUser.role === Roles.ADMIN;
        }).length > 0;
    }

    validateSelection() {
        if (this.selectedRole !== Roles.OWNER && this.selectedRole !== Roles.ADMIN) {
            const stillAdmin = this.isThereStillAnAdmin();
            if (!stillAdmin) {
                throw new Error('Changing the users to the selected role will remove all organization owners or admins');
            }
        }
    }

    async ok() {
        try {
            this.validateSelection();
            const self = this;
            for (const user of this.users) {
                await self.orgService.updateOrgUser(user.userName, self.orgId, self.selectedRole);
            }
            this.dialogRef.close(true);
        } catch (e) {
            this.errorService.handleError(e, 'Error changing roles');
        }
    }
}
