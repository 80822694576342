import {Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {OrgService} from '../../orgs/org.service';
import {SensorService} from '../../sensors/sensors.service';

@Component({
    selector : 'deviceid-select',
    templateUrl: './deviceid-select.component.html',
    styleUrls: ['./deviceid-select.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DeviceidSelectComponent),
            multi: true
        }
    ]
})

export class DeviceidSelectComponent implements OnInit, ControlValueAccessor, OnChanges {
    loading = false;
    @Input() label;
    @Input() deviceTypeId;
    @Input('value') selected;
    @Input() floatLabel = 'always';
    @Output() selectionChange = new EventEmitter();
    @Output() ngModelChange = new EventEmitter();

    devices;
    onChange: any = () => { };
    onTouched: any = () => { };

    constructor(private sensorService: SensorService) {}

    onNgModelChange($event) {
        this.ngModelChange.emit($event);
    }

    onSelectionChanged() {
        this.selectionChange.emit(this.selected);
    }

    fetchDevices() {
        if (this.deviceTypeId) {
            const self = this;
            this.loading = true;
            this.sensorService.querySensors({typeId: this.deviceTypeId}).then(function (devices) {
                self.devices = devices;
            }).finally(function () {
                self.loading = false;
            });
        }
    }

    ngOnInit(): void {
        this.fetchDevices();
    }

    get value() {
        return this.selected;
    }

    set value(val) {
        this.selected = val;
        this.onChange(val);
        this.onTouched();
    }

    registerOnChange(fn) {
        this.onChange = fn;
    }

    registerOnTouched(fn) {
        this.onTouched = fn;
    }

    writeValue(value) {
        this.selected = value;
    }

    ngOnChanges(changes: SimpleChanges): void {
        const change = changes['deviceTypeId'];
        if (!change.firstChange) {
            this.fetchDevices();
        }

    }

}
