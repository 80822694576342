import {Component, OnInit, Output, EventEmitter, ViewEncapsulation} from '@angular/core';
import {AppSettings, MenuExtension} from '../../app.settings';
import {Router} from '@angular/router';
import {AppContext} from '../../app.context';
import {AuthService} from '../../auth/auth.service';

@Component({
    selector: 'app-sidenav-list',
    templateUrl: './sidenav-list.component.html',
    styleUrls: ['./sidenav-list.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SidenavListComponent implements OnInit {
    @Output() sidenavClose = new EventEmitter();
    menuItems = AppSettings.MENU_ITEMS.slice(0);
    user = null;
    constructor(private router: Router,
                private auth: AuthService,
                private appContext: AppContext) { }

    ngOnInit() {
        const self = this;
        this.appContext.user.subscribe(function(user) {
            self.user = user;
        });
    }

    public closeSideNav = () => {
        this.sidenavClose.emit();
    }

    logOut() {
        this.closeSideNav();
        this.auth.logout();
        this.router.navigate(['login']);
    }

    itemSelected(item) {
        this.closeSideNav();
        this.router.navigate([item.href]).finally();

    }

    orgChanged() {
        this.closeSideNav();
    }

}
