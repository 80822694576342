import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

export interface IGraphLegend {
    labels: {
        color: string;
        name: string;
    }[];
}

@Injectable()
export class GraphLegendService {
    legendSource = new Subject<IGraphLegend>();
    public legend = this.legendSource.asObservable();

    constructor() {}

    setFocusedGraphLegend(legend: IGraphLegend) {
        this.legendSource.next(legend);
        // console.log('New Legend');
    }

}
