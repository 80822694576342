import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {AppContext} from '../../app.context';
import {Router} from '@angular/router';

@Component({
    selector: 'nav-menu-item',
    templateUrl: './nav-menu-item.component.html',
    styleUrls: ['./nav-menu-item.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class NavMenuItemComponent {
    @Input() item;
    user;
    constructor(private appContext: AppContext,
                private router: Router) {
        this.appContext.user.subscribe((user) => {
            this.user = user;
        });
    }
}
