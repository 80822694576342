import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, ValidationErrors, FormGroup } from '@angular/forms';

import { MustNotMatch } from './must-not-match.validator';

@Directive({
    selector: '[mustNotMatch]',
    providers: [{ provide: NG_VALIDATORS, useExisting: MustNotMatchDirective, multi: true }]
})
export class MustNotMatchDirective implements Validator {
    @Input('mustNotMatch') mustNotMatch: string[] = [];

    validate(formGroup: FormGroup): ValidationErrors {
        return MustNotMatch(this.mustNotMatch[0], this.mustNotMatch[1])(formGroup);
    }
}
