import {Component, forwardRef, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';

const DEFAULT_LABEL_WIDTH = '100px';

@Component({
    selector: 'sen-input',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputComponent),
            multi: true
        }
    ]
})

export class InputComponent implements ControlValueAccessor, OnInit {
    value;
    @Input() label;
    @Input() labelWidth;
    @Input() required;
    onChange: any = () => { };
    onTouched: any = () => { };
    constructor() {}

    registerOnChange(fn) {
        this.onChange = fn;
    }

    registerOnTouched(fn) {
        this.onTouched = fn;
    }

    writeValue(value: any): void {
        this.value = value;
    }

    ngOnInit(): void {
        this.labelWidth = this.labelWidth || DEFAULT_LABEL_WIDTH;
    }

    modelChanged() {
        this.onChange(this.value);
    }
}
