import { Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { RequestPasswordResetComponent } from './request-password-reset/request-password-reset.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {
  loading;

  constructor(
    private router: Router
  ) {}
  
  ngOnInit() {
  }

}
