import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppContext } from 'src/app/app.context';
import { OrgService } from 'src/app/orgs/org.service';
import {Roles} from '../../app.settings';
import { StatisticsService } from '../statistics.service';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StatisticsComponent implements OnInit {

  statistics = {
    "Locations": -1,
    "Buildings": -1,
    "Assets": -1,
    "ActiveAlerts": -1,
    "CriticalAssets": -1,
    "OfflineAssets": -1
  };
  locations = [];
  
  working = true;
  selectedIdx = 0;
  
  constructor(private statisticsService: StatisticsService) {}


  ngOnInit():void {
    this.working = true;
    this.fetchStatistics().finally(() => {
        this.working = false;
    });
  }

  fetchStatistics(): Promise<void> {
    return new Promise((resolve, reject) => {
        this.statisticsService.fetchStatistics().then((results: any) => {
          if(results.statistics){
            for (const [key, value] of Object.entries(results.statistics)) {
              this.statistics[key] = value;
            }
          }
          if(results.locations){
            this.locations = results.locations;
          }
          resolve();
        }, reject);
    });
  }

  loading(working){
    this.working = working;
  }

}
