import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AppSettings, Services} from '../app.settings';
import {AppContext} from '../app.context';
import {LoadingComponent} from '../shared/loading/loading.component';
import {OrgMembership} from '../orgs/org.service';
import {Monitor} from '../monitors/monitors.service';
import {merge} from 'lodash';
import { SlowBuffer } from 'buffer';


export interface AlarmNotification {
    dateNotified: string;
}

export interface AlarmLevel {
    name: string;
    dateTriggered: string;
    notifications: {
        [key: string]: AlarmNotification
    };
    comment?:string;
}

export interface Alarm {
    id: string;
    status: string;
    dateTriggered: string;
    alarmLevel: AlarmLevel;
    previousAlarmLevel: AlarmLevel;
    history: AlarmLevel[];
    comment?: string;
}

@Injectable()
export class AlarmsService {
    constructor(
        private http: HttpClient,
        private appSettings: AppSettings,
        private appContext: AppContext) {
    }

    getAlarmSchema(): Promise<any> {
        const self = this;
        return new Promise(function(resolve, reject) {
            const endpoint = self.appSettings.getEndpoint(Services.ALARM_SCHEMA);
            self.http.get(endpoint).subscribe(function (value: any) {
                resolve(value);
            }, reject);
        });
    }

    getAlarms(query?): Promise<Alarm[]> {
        const self = this;
        return new Promise(function(resolve, reject) {
            self.appContext.getOrganization().then(function(orgMembership: OrgMembership) {
                const endpoint = self.appSettings.getEndpoint(Services.ALARMS, {orgId: orgMembership.orgId});
                self.http.get(endpoint, {
                    params: merge({'expand' : 'true'}, query || {})
                }).subscribe(function (value: any) {
                        resolve(value.Items ? value.Items : null);
                    }, reject);
            }, reject);
        });
    }

    getAlarmHistory(query?): Promise<Alarm[]> {
        const self = this;
        return new Promise(function(resolve, reject) {
            self.appContext.getOrganization().then(function(orgMembership: OrgMembership) {
                const endpoint = self.appSettings.getEndpoint(Services.ALARM_HISTORY, {orgId: orgMembership.orgId});
                self.http.get(endpoint, {
                    params: query || {}
                }).subscribe(function (value: any) {
                    resolve(value.Items ? value.Items : null);
                }, reject);
            }, reject);
        });
    }

    resolveAlarm(alarmId, comment = ''): Promise<Alarm[]> {
        const self = this;
        return new Promise(function(resolve, reject) {
            self.appContext.getOrganization().then(function(orgMembership: OrgMembership) {
                const endpoint = self.appSettings.getEndpoint(Services.ALARM_RESOLVE, {orgId: orgMembership.orgId, alarmId: alarmId});
                self.http.post(endpoint, {comment: comment})
                    .subscribe(function (value: any) {
                        resolve();
                    }, reject);
            }, reject);
        });
    }
}
