import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-comment-dialog',
  templateUrl: './comment-dialog.component.html',
  styleUrls: ['./comment-dialog.component.scss'],
})
export class CommentDialogComponent implements OnInit {

  comment;
  constructor(private dialogRef: MatDialogRef<CommentDialogComponent>) { }

  ngOnInit() {}

  resolve(){
    this.dialogRef.close({
      comment: this.comment
  });
  }
}
