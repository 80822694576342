import {Injectable} from '@angular/core';
import {GraphSpec, IGraphTypeDescriptor} from './graph.component';
import {find} from 'lodash';
import {XYGraphTypeDescriptor} from './xyseries/xyseries.component';
import {RadialGaugeGraphTypeDescriptor} from './gauge/radial/radial-gauge.component';
import {LinearGaugeGraphTypeDescriptor} from './gauge/linear/linear-gauge.component';

const REGISTRY = [new RadialGaugeGraphTypeDescriptor(), new LinearGaugeGraphTypeDescriptor(), new XYGraphTypeDescriptor()];


@Injectable()
export class GraphTypeRegistryService {

    public getAll() {
        return REGISTRY;
    }

    public get(spec): IGraphTypeDescriptor<GraphSpec> {
        return find(REGISTRY, (desc) => {
           return desc.id.toLowerCase() === spec.type.toLowerCase();
        });
    }
}
