import { UserService } from './../../users/users.service';
import { MessageService } from './../../error/message.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'confirm-password-reset',
  templateUrl: './confirm-password-reset.component.html',
  styleUrls: ['./confirm-password-reset.component.scss'],
})
export class ConfirmPasswordResetComponent implements OnInit {
  resettoken;
  username;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private messageService: MessageService,
    private userService: UserService
  ) {
    this.route.queryParamMap.subscribe(queryParams => {
      this.resettoken = queryParams.get('resetToken');
      this.username = queryParams.get('email');
    });
    console.log(this.resettoken + '  ' + this.username);
    this.userService.resetPassword(this.resettoken, this.username)
    .then(() => {
      this.messageService.handleSuccess('An email with your temporary password has been sent');
      this.router.navigate(['/login']);
    })
    .catch(e => {
      this.messageService.handleError(e, 'Error updating values');
    });
  }

  ngOnInit() {
    // this.resetToken = this.route.snapshot.queryParamMap.get('resetToken');
    // this.email = this.route.snapshot.queryParamMap.get('email');
  }
}
