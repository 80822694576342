import {
    Component,
    EventEmitter,
    Input,
    Output,
    ViewEncapsulation,
    OnInit,
    forwardRef,
    ViewChild
} from '@angular/core';
import {
    Validators,
    FormControl,
    NG_VALUE_ACCESSOR,
    ControlValueAccessor,
    NgControl,
    Validator,
    NG_VALIDATORS,
    NgForm,
    AbstractControl, FormGroup, ValidationErrors
} from '@angular/forms';
import {startWith, map, catchError} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {forEach, isEmpty, merge} from 'lodash';
import { environment } from '../../../environments/environment';

const DEFAULT_COUNTRY_CODE = environment.env == 'prod' ? '+971' : '+1';


function isFormGroup(control: AbstractControl): control is FormGroup {
    return !!(<FormGroup>control).controls;
}

function collectErrors(control: AbstractControl): any | null {
    if (isFormGroup(control)) {
        return Object.entries(control.controls)
            .reduce(
                (acc, [key, childControl]) => {
                    const childErrors = collectErrors(childControl);
                    if (childErrors) {
                        acc = {...acc, [key]: childErrors};
                    }
                    return acc;
                },
                null
            );
    } else {
        const errors = {};
        if (!control.pristine) {
            forEach(control.errors, function (value, key) {
                errors[key] = {valid: false};
            });
        }
        return errors;

    }
}

@Component({
    selector: 'phone',
    templateUrl: './phone.component.html',
    styleUrls: ['./phone.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PhoneComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => PhoneComponent),
            multi: true,
        }
    ]
})
export class PhoneComponent implements OnInit, ControlValueAccessor, Validator {

    // tslint:disable-next-line: no-input-rename
    @Input('value') phoneNumber;
    @Input() floatLabel = 'auto';
    @ViewChild('phoneForm', {static: false}) phoneForm: NgForm;
    @Output() phoneChange = new EventEmitter<any>();
    phoneValue: string;
    countryCodeValue: string;
    countrycodes: string[] = [
        // tslint:disable-next-line: max-line-length
        '+93', '+355', '+213', '+376', '+244', '+672', '+54', '+374', '+297', '+61',
        '+43', '+994', '+973', '+880', '+375', '+32', '+501', '+229', '+975', '+591',
        '+387', '+267', '+55', '+673', '+359', '+226', '+257', '+855', '+237', '+238', '+236', '+235',
        '+56', '+86', '+53', '+61', '+57', '+269', '+243', '+242', '+682', '+506', '+225', '+385', '+53', '+357', '+420',
        '+45', '+253', '+670', '+593', '+20', '+503', '+240', '+291', '+372', '+251', '+500',
        '+298', '+679', '+358', '+33', '+594', '+689', '+241', '+220', '+995', '+49', '+233', '+350', '+30', '+299',
        '+590', '+502', '+224', '+245', '+592', '+509', '+504', '+852', '+36', '+354', '+91', '+62', '+98', '+964',
        '+353', '+972', '+39', '+81', '+962', '+7', '+254', '+686', '+850', '+82', '+965', '+996', '+856', '+371',
        '+961', '+266', '+231', '+218', '+423', '+370', '+352', '+853', '+389', '+261', '+265', '+60', '+960', '+223', '+356',
        '+692', '+596', '+222', '+230', '+269', '+52', '+691', '+373', '+377', '+976', '+212', '+258', '+95', '+264',
        '+674', '+977', '+31', '+599', '+687', '+64', '+505', '+227', '+234', '+683', '+672', '+47', '+968', '+92',
        '+680', '+970', '+507', '+675', '+595', '+51', '+63', '+48', '+351', '+974', '+262', '+40', '+7',
        '+250', '+290', '+508', '+685', '+378', '+239', '+966', '+221', '+248', '+232', '+65"',
        '+421', '+386', '+677', '+252', '+27', '+34', '+94', '+249', '+597', '+268', '+46', '+41', '+963', '+886', '+992',
        '+255', '+66', '+690', '+676', '+216', '+90', '+993', '+688', '+256', '+380', '+971', '+44', '+1',
        '+598', '+998', '+678', '+418', '+58', '+84', '+681', '+967', '+260', '+263'
    ].sort(function (a, b) {
        return a.localeCompare(b);
    });

    onChange: any = () => {
    }
    onTouched: any = () => {
    }

    constructor() {
    }

    validate(control: AbstractControl): ValidationErrors {
        const errors = {};
        if (this.phoneForm) {
            forEach(this.phoneForm.controls, function (c) {
                merge(errors, collectErrors(c));
            });
        }

        return isEmpty(errors) ? null : errors;
    }

    ngOnInit() {
        if (!this.countryCodeValue) {
            this.countryCodeValue = DEFAULT_COUNTRY_CODE;
        }
    }

    updateCountryCode() {
        this.updatePhone();
    }

    updatePhone() {
        this.onChange(this.countryCodeValue + this.phoneValue);
    }

    registerOnChange(fn) {
        this.onChange = fn;
    }

    registerOnTouched(fn) {
        this.onTouched = fn;
    }

    writeValue(value: any): void {
        if (value) {
            this.phoneValue = value;
            if (value.length > 11) {
                this.phoneValue = value.slice(-10);
                this.countryCodeValue = value.substring(0, value.length - 10);
            }
        }
    }
}
