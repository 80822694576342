import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {COMPACT_GRAPH_CONTAINER_HEIGHT, IGraph, IGraphAction, IGraphLayout, ITimeRange} from '../../../graph/graph.component';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {forEach, get} from 'lodash';

interface IGridItem {
    graph: IGraph;
    layout?: any;
}

const FULL_SCREEN_COLS = 12;
const NUM_OF_GRAPHS_PER_ROW = 2;
const DEFAULT_COLS = 6;

const SMALL_COL = 1;
const MED_COL = 6;
const LARGE_COL = 12;

@Component({
    selector: 'dashboard-graph-grid',
    templateUrl: './dashboard-grid-layout.component.html',
    styleUrls: ['./dashboard-grid-layout.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DashboardGridLayoutComponent implements OnInit, OnChanges {
    @Input() graphs: IGraph[];
    @Input() actions: IGraphAction[];
    @Input() timeRange: ITimeRange;
    graphContainerHeight = COMPACT_GRAPH_CONTAINER_HEIGHT;
    numCols = LARGE_COL;
    isMobile = false;

    constructor(protected breakpointObserver: BreakpointObserver) {
        breakpointObserver.observe([Breakpoints.Medium]).subscribe(result => {
            if (result.matches) {
                this._setNumCols(MED_COL);
            }
        });
        breakpointObserver.observe([Breakpoints.Handset, Breakpoints.Small]).subscribe(result => {
            if (result.matches) {
                this._setNumCols(SMALL_COL);
            }
        });
        breakpointObserver.observe([Breakpoints.Large]).subscribe(result => {
            if (result.matches) {
                this._setNumCols(LARGE_COL);
            }
        });
    }

    _setNumCols(num) {
        if (num !== this.numCols) {
            this.numCols = num;
            this.layout();
        }
    }

    getNumCols() {
        return this.numCols;
    }

    onItemChange(items, graph) {
        console.log('Changed: ', items, graph);
    }

    layout(graphChange?) {
        let currentColIdx = 1;
        let currentRow = 1;
        const totalScreenCols = this.getNumCols();
        forEach(this.graphs, (graph, idx) => {
            const spec = graph.spec;
            const layout = {} as any;
            let colWidth = get(spec, 'layout.columns', DEFAULT_COLS);
            if (colWidth > totalScreenCols) {
                colWidth = totalScreenCols;
            }
            if (colWidth + (currentColIdx - 1) > totalScreenCols) {
                currentRow++;
                currentColIdx = 1;
            }
            layout.row = currentRow;
            layout.col = currentColIdx;
            layout.sizex = colWidth;
            layout.fixed = true;
            graph.layout = layout;
            currentColIdx += colWidth;
        });

    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.graphs) {
            this.layout(true);
        }
    }

}
