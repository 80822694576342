import {Component, OnInit } from '@angular/core';
import { ActivatedRoute} from '@angular/router';
import * as L from 'leaflet';
import { MessageService } from 'src/app/error/message.service';
import { LayerService } from '../../layers.service';
import {get, cloneDeep} from 'lodash';
import { Roles } from 'src/app/app.settings';
import { AppContext } from 'src/app/app.context';
import { SensorService } from '../../sensors.service';
import {MatDialog, MatDialogConfig} from '@angular/material';
import { AddLayerDeviceComponent } from './add-layer-device/add-layer-device.component';
@Component({
  selector: 'app-layer',
  templateUrl: './layer.component.html',
  styleUrls: ['./layer.component.scss'],
})

export class LayerComponent implements OnInit{
    map;
    working = false;
    searchValue: string = '';
    layer: any;
    layerId: string;
    canEdit = false;
    editMode = false;
    markerOptions = L.icon({
      iconUrl: '/assets/sensor.png',
      iconSize: [10, 10]
    });
    zoomedMarkerOptions = L.icon({
      iconUrl: '/assets/sensor.png',
      iconSize: [36, 36]
    });
    selectedMarkerOptions = L.icon({
      iconUrl: '/assets/sensor_selected.png',
      iconSize: [36, 36]
    });
    markers: any[] = [];
    sensorList: any;
    layerSensors: any[] = [];
    visualCopy: any [] = [];
    copy;
    remainingSensors: any[] = [];
    updatedSensors: any[] = [];
    width;
    height;

  constructor(
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private layerService: LayerService,
    private sensorService: SensorService,
    private messageService: MessageService,
    public appContext: AppContext){}

    ngOnInit(){
        this.layerId = this.route.snapshot.paramMap.get('layerId');
        this.working = true;
        Promise.all([this.loadLayer(), this.appContext.isUserInRole([Roles.OWNER, Roles.ADMIN])]).then(async (results) => {
            this.canEdit = results[1];
            await this.loadSensors();
            await this.initMap(this.layer.signedURL);
          }, function(err) {
              if (err.status === 404) {
                  this.notFound = true;
                  console.log(err);
                  this.messageService.handleError(err.error.message, 'Layer not Found');
                  this.working = false;
              }
          })
    }

  loadSensors(){
    return new Promise((resolve, reject) => {
      this.sensorService.querySensors().then((fetched) => {
        if(fetched){
          this.initSensors(fetched);
          resolve(fetched);
        }
        else
          resolve(false);
    }, () => {});
  });
  }

  initSensors(sensorList){
    //filters sensors between those that belong to this layer and the available sensors
    let results = this.layerService.sortLayerDevices(sensorList, this.layer.id)
    this.layerSensors = results.layerSensors;
    this.remainingSensors = results.remainingSensors;
    this.copy = cloneDeep(this.layerSensors);
  }

  loadLayer(){
    return new Promise((resolve, reject) => {
      this.layerService.getLayer(this.layerId).then((fetched) => {
        this.layer = fetched;
        resolve(true);
      }, () => {});
    });
  }
  
  initMap(imageURL) {

    return new Promise((resolve, reject)=> {
      this.getMeta(imageURL, (width, height)=> {

        this.width = width;
        this.height = height;

        this.map = L.map('image-map', {
          minZoom: 1,
          maxZoom: 6,
          center: [0, 0],
          zoom: 1,
          crs: L.CRS.Simple
        });
    
      var southWest = this.map.unproject([0, height], this.map.getMaxZoom() - 1);
      var northEast = this.map.unproject([width, 0], this.map.getMaxZoom() - 1);
      var bounds = new L.LatLngBounds(southWest, northEast);
    
      L.imageOverlay(imageURL, bounds).addTo(this.map);
  
      this.map.setMaxBounds(bounds);

      //map events can only be called once the map object is instantiated
      this.map.whenReady(() => this.setMarkers())
      this.map.on('zoomend', this.onZoomChanged.bind(this, this.map));
      });


      resolve(true);
    })
  } 

  getMeta(url, callback) {
    const img = new Image();
    img.src = url;
    img.addEventListener("load", function(){ callback(this.naturalWidth, this.naturalHeight)});
  }

  setMarkers(){
    if(this.layerSensors.length > 0){
      this.working = true;
      for(let i = 0; i < this.layerSensors.length; i++){
        let device = this.layerSensors[i];
        const layerCoord = get(device, 'tags.layerCoord', get(device, 'tags.layerCoord'));
        if(layerCoord){
          var Arraybounds = layerCoord.split(',');
          var markerBounds = new L.LatLng(Arraybounds[0], Arraybounds[1]);
          let marker = L.marker(markerBounds, {icon: this.markerOptions, draggable:false}).addTo(this.map);
          this.markers[device.id] = marker;
          marker.bindPopup(`<div>${device.name}</div>`);
          marker.on('dragend', this.onMarkerDragEnd.bind(this))
        }
      }
    }
    this.working = false;
  }

  onZoomChanged(map, event){
    if (map.getZoom() > 4)
    {
      this.layerSensors.map((device)=> {
        let marker = this.markers[device.id]
        var icon = marker.options.icon;
        icon.options.iconSize = [36, 36];
        marker.setIcon(icon);
      })
    }else {
      console.log("zoomed out", this.layerSensors)
      console.log("markers: ", this.markers)
      this.layerSensors.map((device)=> {
        let marker = this.markers[device.id]
        var icon = marker.options.icon;
        icon.options.iconSize = [10, 10];
        marker.setIcon(icon);
      })
    }
  }

  onMarkerDragEnd(event){
    if(this.editMode){
      var marker = event.target;
      var position = marker.getLatLng();
      marker.setLatLng(new L.LatLng(position.lat, position.lng),{draggable:'true'});
    }
  }
  search(){
    this.copy = this.layerSensors.filter(device=> device.name.toLowerCase().includes(this.searchValue.toLowerCase()));
  }

  onDeviceClick(device){
    try{
      this.layerSensors.map((device) => {        
        this.markers[device.id].setIcon(this.zoomedMarkerOptions)
      })

      let selectedMarker = this.markers[device.id];
      selectedMarker.setIcon(this.selectedMarkerOptions);

      const layerCoord = get(device, 'tags.layerCoord', get(device, 'tags.layerCoord'));
      if(layerCoord){
        var Arraybounds = layerCoord.split(',');
        var markerBounds = new L.LatLng(Arraybounds[0], Arraybounds[1]);
        this.map.setView(markerBounds, 6);
      }
      else{
        this.messageService.handleError("Couldn't load marker", "Missing coordinates");
      }
    }
    catch(e){
      this.messageService.handleError("Error", e.message);
    }
  }

  onEditLayer(){
    this.editMode = true;
    this.layerSensors.map((device)=>{
      this.markers[device.id].dragging.enable();
    })
  }

  onCancel(){
    this.editMode = false;
      this.layerSensors.map((device)=>{
        this.markers[device.id].dragging.disable();
      })
  }

  onAddDevice(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '400px';
    dialogConfig.data = this.remainingSensors;
    const self = this;
    const dialogRef = this.dialog.open(AddLayerDeviceComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
        if (result) {
          var markerBounds = this.map.unproject([this.width/2, this.height/2], this.map.getMaxZoom() - 1);
          let device = result.chosenSensor;
          if(device){
            this.working = true;
            device.layerId = this.layer.id;
            device.tags.layerCoord = markerBounds.lat + "," + markerBounds.lng;
            this.layerSensors.push(device);
            this.searchValue = ''
            this.copy = cloneDeep(this.layerSensors);

            var index = this.remainingSensors.findIndex(x => x.id == device.id);
            if (index > -1) {
              this.remainingSensors.splice(index, 1);
            }
            let marker = L.marker(markerBounds, {icon: this.selectedMarkerOptions, draggable:false}).addTo(this.map);
            this.markers[device.id] = marker;
            marker.bindPopup(`<div>${device.name}</div>`);
            marker.on('dragend', this.onMarkerDragEnd.bind(this))
            this.map.setView(markerBounds, 6);
            this.onEditLayer();

            this.sensorService.update(device).finally(()=> this.working = false);
          }
        }
    });
  }

  async onSaveLayer(){
    
    this.onCancel();
    this.updatedSensors = [];
    this.searchValue = ''
    this.copy = cloneDeep(this.layerSensors);
    if(this.copy.length > 0){
      this.copy.map((device) => {
        //Check differences in coords for updates
        let initialCoords = get(device, 'tags.layerCoord', get(device, 'tags.layerCoord'));
        var Arraybounds = initialCoords.split(',');
        let newCoords = this.markers[device.id].getLatLng();
        if(Arraybounds[0] != newCoords.lat || Arraybounds[1] != newCoords.lng){
          device.tags.layerCoord = newCoords.lat +","+ newCoords.lng;
          //update it in the layerSensors array so it's reflected in the list
          var foundIndex = this.layerSensors.findIndex(x => x.id == device.id);
          this.layerSensors[foundIndex] = device;
          //this.layerSensors.filter((sensor)=> sensor.id == device.id)
          this.updatedSensors.push(device);
        }
      })
    }
    if(this.updatedSensors.length > 0){

      this.updatedSensors.map((device)=>{
        this.working = true;
        this.sensorService.update(device).finally(()=>{this.working = false})
      })
    }
  }

  onDeleteDevice(device){
    device.tags.coord
    delete device.layerId;
    delete device.tags.layerCoord;

    this.working = true;
    this.sensorService.update(device).then(async () => {
      var index = this.layerSensors.findIndex(x => x.id == device.id);
      if (index > -1) {
        this.layerSensors.splice(index, 1);
      }
      this.remainingSensors.push(device);
      this.removeMarker(device.id);
      this.copy = cloneDeep(this.layerSensors);
      }).finally(() => {
          this.working = false;
      });
  }


  removeMarker(deviceId){
    this.map.removeLayer(this.markers[deviceId]);
  }
}