import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {forEach, find, get} from 'lodash';
import {SelectionModel} from '@angular/cdk/collections';
import {Device} from '../sensors.service';


@Component({
    selector : 'sensor-groups',
    templateUrl: './sensor-groups.component.html',
    styleUrls: ['./sensor-groups.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class SensorGroupsComponent implements OnInit, OnChanges {
    public groups = {};
    selection = new SelectionModel<Device>(true, []);
    @Input() sensorList;
    @Output() selectionChanged = new EventEmitter();
    constructor() {}

    groupSensors() {
        this.groups = {};
        forEach(this.sensorList, (sensor) => {
            let category = get(sensor, `tags.Category`);
            if (!category) {
                category = `Category:Unknown`;
            }
            const members = this.groups[category] = this.groups[category] || [];
            members.push(sensor);
        });
    }

    onSelectionChanged(event) {
        forEach(event.removed, (sensor) => {
            this.selection.deselect(sensor);
        });
        forEach(event.added, (sensor) => {
            this.selection.select(sensor);
        });

        this.selectionChanged.emit(this.selection.selected);
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.sensorList) {
            this.groupSensors();
        }
    }
}
