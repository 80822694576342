import {Component, forwardRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {ControlValueAccessor, FormArray, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {forEach} from 'lodash';

@Component({
    selector: 'multi-device-select',
    templateUrl: './multi-device-select.component.html',
    styleUrls: ['./multi-device-select.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MultiDeviceSelectComponent),
            multi: true
        }
    ]
})

export class MultiDeviceSelectComponent implements OnInit, OnChanges, ControlValueAccessor, OnDestroy {
    @Input() deviceTypeId: string;
    deviceForms: FormArray;
    onChange: (value: any) => {};
    onTouched: () => {};
    formSubscription: Subscription;
    selectedDevices: string[] = [];

    constructor() {
        this.deviceForms = new FormArray([]);
        // Add the first one
        this.addDevice();
    }

    addDevice() {
        this.deviceForms.push(new FormGroup({
            platformDeviceId: new FormControl('', [Validators.required])
        }));
    }

    removeDevice(idx) {
        // this.deviceForms.splice(idx, 1);
        this.deviceForms.removeAt(idx);
    }

    ngOnChanges(changes: SimpleChanges): void {
    }

    ngOnInit(): void {
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    writeValue(obj: any): void {

        this.formSubscription = this.deviceForms.valueChanges.subscribe((formValueChange) => {
            this.selectedDevices = [];
            forEach(this.deviceForms.controls, (deviceForm: FormGroup, idx) => {
                this.selectedDevices.push(deviceForm.controls['platformDeviceId'].value);
            });
            this.onChange(this.selectedDevices);
        });
    }

    ngOnDestroy(): void {
        if (this.formSubscription) {
            this.formSubscription.unsubscribe();
        }
    }

}
