import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpClientModule } from '@angular/common/http';
import { NgxLoadingModule } from 'ngx-loading';
import { IonicModule } from '@ionic/angular';
import { UserService } from './users.service';
import { UsersPage } from './users.page';
import { MaterialModule} from '../material/material.module';
import {EditUserComponent} from './edit/edit-user.component';
import {ConfirmDialogComponent} from '../shared/dialogs/confirm/confirm-dialog.component';
import {AddUserComponent} from './add/add-user.component';
import {SharedModule} from '../shared/shared.module';

const routes: Routes = [
  {
    path: '',
    component: UsersPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    HttpClientModule,
    NgxLoadingModule,
    MaterialModule,
    FlexLayoutModule,
    SharedModule,
    RouterModule.forChild(routes)
  ],
  providers: [UserService],
  declarations: [UsersPage, EditUserComponent, AddUserComponent],
  entryComponents: [EditUserComponent, AddUserComponent, ConfirmDialogComponent]
})
export class UsersPageModule {}
