import {DeviceDataService} from './device-data.service';
import {UnitConversionFactory} from './unitConversion.factory';
import {NgModule} from '@angular/core';


@NgModule({
    imports: [
    ],
    providers: [DeviceDataService, UnitConversionFactory],
    declarations: []
})
export class DeviceDataModule {}
