import {Component, ComponentFactoryResolver, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';

import {OrgDeviceType, OrgService} from '../../orgs/org.service';

import { DatePipe } from '@angular/common';
import {FormControl, FormGroup, NgForm, NgModel, Validators} from '@angular/forms';
import {MessageService} from '../../error/message.service';

import {SensorService} from '../../sensors/sensors.service';
import {DeviceTypeService} from '../../deviceType/deviceType.service';
import {keys, merge, cloneDeep, forEach, get, hasIn, set, find, isEqual} from 'lodash';
import {flatten} from 'flat';
import {
    COMPACT_GRAPH_CONTAINER_HEIGHT,
    Graph,
    GraphSpec,
    IDeclarativeGraphBuilder,
    IGraphBuilder,
    IGraphLayout,
    IGraphTypeDescriptor,
    ITimeRange, TIME_DURATIONS
} from '../../graph/graph.component';
import {ViewHostDirective} from '../../shared/directives/view-host.directive';

import {DeclarativeGraphBuilderComponent} from '../builder/declarative/declarative-graph-builder.component';
import {GraphTypeRegistryService} from '../graph-type.registry';
import {Observer, PartialObserver, Subscription} from 'rxjs';
import {title} from 'ionic/lib/color';

const DEFAULT_GRAPH_PREVIEW_HEIGHT = 280;


interface ITypeDescriptorItem {
    name: string;
    type?: IGraphTypeDescriptor<any>;
    children?: IGraphTypeDescriptor<any>[];
}

@Component({
    selector: 'graph-editor',
    templateUrl: './graph-editor.component.html',
    styleUrls: ['./graph-editor.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class GraphEditorComponent implements OnInit, OnDestroy {

    addForm: FormGroup;

    graphSpec: GraphSpec;

    // graphTypes: IGraphTypeDescriptor<any>[];
    graphTypes: ITypeDescriptorItem[];

    formSubscription: Subscription;

    oldGraphSpec: GraphSpec;

    previewGraph;

    numberOfBuilderSteps = 0;

    graphBuilder: IGraphBuilder;

    graphPreviewHeight = DEFAULT_GRAPH_PREVIEW_HEIGHT;

    timeRange: ITimeRange = {
        duration: TIME_DURATIONS.ONE_HOUR
    };

    titleControl: FormControl;

    isNew = false;
    oldGraphType;
    selectedGraphType: IGraphTypeDescriptor<any>;
    // @ts-ignore
    @ViewChild(ViewHostDirective, {static: true}) builderHost: ViewHostDirective;
    constructor(
                private msgService: MessageService,
                private orgService: OrgService,
                private sensorService: SensorService,
                private deviceTypeService: DeviceTypeService,
                private datePipe: DatePipe,
                private dialog: MatDialog,
                private dialogRef: MatDialogRef<GraphEditorComponent>,
                private componentFactoryResolver: ComponentFactoryResolver,
                private graphTypeRegistry: GraphTypeRegistryService,
                @Inject(MAT_DIALOG_DATA) private data) {


        if (!data.graphSpec) {
            this.isNew = true;
        } else {
            this.graphSpec = data.graphSpec;
        }



        this.titleControl = new FormControl(this.graphSpec ? this.graphSpec.title : null, [Validators.required]);

        this.addForm = new FormGroup({
            graphType: new FormControl(this.graphSpec ? this.graphSpec.type : data.type ? data.type : null, []),
            title: this.titleControl
        });

    }

    ngOnInit() {
        // Group the graph types
        const groups = [];
        const allTypes = this.graphTypeRegistry.getAll();
        forEach(allTypes, (type) => {
            if (type.parent) {
                let found = find(groups, (group) => {
                    return group.name === type.parent;
                });
                if (!found) {
                    found = { name: type.parent, children : []};
                    groups.push(found);
                }
                found.children.push({
                    type: type,
                    name: type.name,
                });
                found.children.sort(function(a, b) {
                    return a.name.localeCompare(b.name);
                });
            } else {
                groups.push({
                    type: type,
                    name: type.name,
                });
            }
        });

        groups.sort(function(a, b) {
            return a.name.localeCompare(b.name);
        })
        this.graphTypes = groups;

        // Set the selectedGraphType
        // If there is no new type, then insect the current spec for the type,
        // if one is not there, use a default one
        const existingType = this.addForm.controls.graphType.value;
        // const existingType = this.graphSpec ? this.graphSpec.type : null;
        if (existingType) {
            this.selectedGraphType = find(allTypes, (type) => {
                return type.id === existingType;
            });
        } else if (allTypes.length === 1) {
            this.selectedGraphType = allTypes[0];

        }
        this.addForm.get('graphType').setValue(this.selectedGraphType);

        this.formSubscription = this.addForm.valueChanges.subscribe(val => {
            setTimeout(() => {
                // Cheating on this.  The title is never shown, but could cause a ton of redrawing
                // if allowed to create the new previewGraph with each keystroke
                if ((!this.oldGraphSpec || this.oldGraphSpec.title === this.graphSpec.title) &&
                    (!isEqual(this.oldGraphSpec, this.graphSpec)) && this.addForm.valid) {
                    this.previewGraph = new Graph(this.graphSpec, this.selectedGraphType);
                }
                // Makes a copy for comparison later on.  This must be by value and not reference,
                // otherwise it would be the same value all the time :-)
                this.oldGraphSpec = cloneDeep(this.graphSpec);
            }, 100);
        });


        this.setupGraphType();
    }

    setupGraphType() {
        if (this.oldGraphType) {
            this.addForm.removeControl(this.oldGraphType.id);
        }
        if (!this.selectedGraphType) {
            return;
        }
        if (!this.graphSpec || this.graphSpec.type !== this.selectedGraphType.id) {
            this.graphSpec = this.selectedGraphType.newSpec();
            this.graphSpec.type = this.selectedGraphType.id;
            const dateNow = new Date();
            this.graphSpec.title = 'Untitled ' + this.datePipe.transform(dateNow, 'MM/dd/yyyy hh:mm:ss');
            this.addForm.get('title').setValue(this.graphSpec.title);
        }

        this.oldGraphType = this.selectedGraphType;
        this.previewGraph = null;

        const builder = this.selectedGraphType.builder;

        let builderComponent = null;
        if (builder.specification) {
            builderComponent = DeclarativeGraphBuilderComponent;
        } else {
            builderComponent = builder.component;
        }
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(builderComponent);
        const viewContainerRef = this.builderHost.viewContainerRef;
        viewContainerRef.clear();
        const componentRef = viewContainerRef.createComponent(componentFactory);

        this.graphBuilder = <IGraphBuilder>componentRef.instance;
        this.graphBuilder.graphSpec = this.graphSpec;
        if (builder.specification) {
            (<IDeclarativeGraphBuilder>this.graphBuilder).graphBuilderSpec = builder.specification;
        }

        this.numberOfBuilderSteps = this.graphBuilder.getNumberOfSteps();
        const builderForm = this.graphBuilder.builderForm;

        if (builderForm) {
            setTimeout(() => {
                this.addForm.addControl(this.selectedGraphType.id, builderForm);
            }, 0);
        }
    }

    cancel() {
        this.dialogRef.close(undefined);
    }

    add() {
        this.graphSpec.title = this.addForm.get('title').value;
        this.dialogRef.close({graphSpec: this.graphSpec});
    }

    onGraphTypeSelected($event) {
        if ($event.value !== this.oldGraphType) {
            this.selectedGraphType = $event.value;
            this.setupGraphType();
        }
    }

    ngOnDestroy(): void {
        if (this.formSubscription) {
            this.formSubscription.unsubscribe();
        }
    }
}
