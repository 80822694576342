import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppSettings, Services} from '../app.settings';
import {AppContext} from '../app.context';
import {OrgDeviceType} from '../orgs/org.service';
import { environment } from '../../environments/environment';

@Injectable()
export class IntegrationsService {


    constructor(private http: HttpClient,
                private appSettings: AppSettings,
                private appContext: AppContext) {

    }

    async getIntegrations() {
        const endpoint = this.appSettings.getEndpoint(Services.PLATFORM_INTEGRATIONS);
        const self = this;
        const params = {
            stage: environment.env
        };

        return new Promise(function(resolve, reject) {
            self.http.get(endpoint, {params: params})
                .subscribe(function (value: any) {
                    resolve(value.Items);
                }, reject);
        });
    }

    async getIntegration(id) {
        const endpoint = this.appSettings.getEndpoint(Services.PLATFORM_INTEGRATION, {intId: id});
        const self = this;
        const params = {
            stage: environment.env
        };

        return new Promise(function(resolve, reject) {
            self.http.get(endpoint)
                .subscribe(function (value: any) {
                    resolve(value.Item);
                }, reject);
        });
    }
}
