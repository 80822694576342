import { StringMap } from '@angular/compiler/src/compiler_facade_interface';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute} from '@angular/router';
import { Lightbox } from 'ngx-lightbox'; 
import { SensorService } from 'src/app/sensors/sensors.service';

interface Asset {
  expandable: boolean;
  name: string;
  level: number;
  type: any;
  id: string;
  subtype?: string;
}

interface AssetInfo {
  notes: String;
  sensorList: any[];
  documents: any[];
  images: any[];
}

@Component({
  selector: 'app-asset-documentation',
  templateUrl: './asset-documentation.component.html',
  styleUrls: ['./asset-documentation.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AssetDocumentationComponent implements OnInit {

  working: boolean;
  asset: Asset;

  editMode: boolean = false;

  images: any[] = [];
  notes: string;
  sensorList: any[] = [];

  constructor(private route: ActivatedRoute, private _lightbox: Lightbox, private sensorService: SensorService) { }

  ngOnInit() {


    this.asset = {
      expandable: JSON.parse(this.route.snapshot.queryParamMap.get('expandable')),
      name: this.route.snapshot.queryParamMap.get('name'),
      level: parseInt(this.route.snapshot.queryParamMap.get('level')),
      type: this.route.snapshot.queryParamMap.get('type'),
      id: this.route.snapshot.queryParamMap.get('id'),
      subtype: this.route.snapshot.queryParamMap.get('subtype')
    }

    this.working = true;
    this.prepareSensors();

  }

  onEdit(){
    this.editMode = true;
  }

  onCancel(){
    this.editMode = false;
  }

  onSave(){
    this.editMode = false;
  }


  //Images
  prepareGallery(){

    let src = '/assets/icon/favicon.png';
    let thumb = '/assets/icon/favicon.png';

    src = '/assets/icon/test2.jpeg';
    thumb = '/assets/icon/test2.jpeg';

   const image_5 = {src: src, thumb:thumb};
   this.images.push(image_5)

   src = '/assets/icon/home_drone.png';
   thumb = '/assets/icon/home_drone.png';


  const image_6 = {src: src, thumb:thumb};
  this.images.push(image_6)
  }

  
  open(index: number): void { 
    this._lightbox.open(this.images, index, { wrapAround: true, centerVertically: true });
  } 
  
  close(): void { // close lightbox programmatically 
  this._lightbox.close(); 
  } 

  //Notes
  prepareNotes(){
    this.notes = "Hello World! \n\nLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
  }

  //Sensors
  prepareSensors(){
    this.sensorService.querySensors().then((devices) => {
      this.sensorList = devices.Items;
      this.prepareGallery();
      this.prepareNotes();
    }).finally(() => {
      console.log("sensoList: ", this.sensorList)
        this.working = false;
    });
  }
  

}
