import {Component, OnInit, TemplateRef, ViewChild, ContentChild, Input, ElementRef, Output, EventEmitter, OnDestroy} from '@angular/core';
import {LoginFailureClassifications} from '../../io/iapi-definitions';
import {FormBuilder, FormControl, FormGroup, Validator, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../auth/auth.service';
import { get } from 'lodash';
import { RequestPasswordResetComponent } from '../request-password-reset/request-password-reset.component';
import { UserService } from 'src/app/users/users.service';
import {AutofillMonitor} from '@angular/cdk/text-field';
import {Subscription} from 'rxjs';

const NO_ERROR = '';

@Component({
    selector: 'signin',
    templateUrl: './signin.component.html',
    styleUrls: ['./signin.component.scss'],
})
export class SigninComponent implements OnInit, OnDestroy {
    loginForm: FormGroup;
    username;
    password;
    newPassword;
    newPasswordConfirmation;
    submitted;
    responseError;
    loading;
    routingInProgress;
    returnUrl;
    newPasswordRequired = false;
    @ViewChild('userName', {static: true}) userNameElement: ElementRef<HTMLElement>;
    @ViewChild('password', {static: true}) passwordElement: ElementRef<HTMLElement>;
    isUserNameAutoFilled = false;
    isPasswordAutoFilled = false;
    userNameAFSubscription: Subscription;
    passwordAFSubscription: Subscription;
    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private auth: AuthService,
        private userService: UserService,
        private autoFillMonitor: AutofillMonitor
    ) {
        this.loginForm = new FormGroup({
            userName: new FormControl('', Validators.required),
            password: new FormControl('', Validators.required),
        });
    }

    login() {
        if (this.submitted) {
            return;
        }
        this.isUserNameAutoFilled = false;
        this.isPasswordAutoFilled = false;

        this.submitted = true;
        this.responseError = NO_ERROR;
        this.loading = true;

        this.auth.login(
            this.loginForm.controls.userName.value,
            this.loginForm.controls.password.value,
            this.loginForm.controls.newPassword ? this.loginForm.controls.newPassword.value : null
        ).then(() => {
            this.routeToDestination();
        }, (err) => {
            const reason = get(err, 'error.reason');
            if (reason === LoginFailureClassifications.NEW_PASSWORD_REQUIRED) {
                this.loginForm.addControl('newPassword', new FormControl('', []));
                this.loginForm.addControl('newPasswordConfirmation', new FormControl('', []));
                this.newPasswordRequired = true;

            }
            const errMsg = get(err, 'error.message');
            this.loading = false;
            this.submitted = false;
            this.responseError = errMsg;
        }).finally(() => {
            this.loading = false;
            this.submitted = false;
        });
    }

    private routeToDestination() {
        this.routingInProgress = true;
        const urlAndQuery = this.returnUrl.split('?');
        const qParms = {};
        if (urlAndQuery.length > 1) {
            const parms = urlAndQuery[1].split('&');
            parms.forEach(function(parm) {
                const keyAndValue = parm.split('=');
                if (keyAndValue.length === 2) {
                    qParms[keyAndValue[0]] = keyAndValue[1];
                }
            });
        }
        this.router.navigate([urlAndQuery[0]], {queryParams: qParms}).finally();
    }

    isFormValid() {
        return (!this.loginForm.controls.newPassword || this.loginForm.controls.newPassword.valid) && (!this.loginForm.controls.newPasswordConfirmation || this.loginForm.controls.newPasswordConfirmation.valid) && (this.isUserNameAutoFilled || this.loginForm.controls.userName.valid) && (this.isUserNameAutoFilled || this.loginForm.controls.password.valid);
    }

    ngOnInit(): void {
        this.auth.logout();
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        this.userNameAFSubscription = this.autoFillMonitor.monitor(this.userNameElement).subscribe((e) => {
            this.isUserNameAutoFilled = e.isAutofilled;
        });
        this.passwordAFSubscription = this.autoFillMonitor.monitor(this.passwordElement).subscribe((e) => {
            this.isPasswordAutoFilled = e.isAutofilled;
        });
    }

    ngOnDestroy(): void {
        if (this.userNameAFSubscription) {
            this.userNameAFSubscription.unsubscribe();
        }
        if (this.passwordAFSubscription) {
            this.passwordAFSubscription.unsubscribe();
        }
    }

}
