import {Injectable, OnInit} from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';
import {Subject} from 'rxjs';
import {ApiAuthLoginResponseError, IApiAuthLoginResponse, LoginFailureClassifications} from '../io/iapi-definitions';
import {ApiRequestsService} from '../io/api-requests.service';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {AppSettings, Services} from '../app.settings';
import {isNil} from 'lodash';



const jwtHelper = new JwtHelperService();
/**
 * Key in local storage if user is logged in.
 */
const ID_TOKEN_KEY = 'idToken';

@Injectable()
export class AuthService {
    /**
     * Don't send a request near the exact time it could expire.
     */
    private EXPIRED_OFFSET_SECS = 30;
    private authStateSource = new Subject<boolean>();
    public authState = this.authStateSource.asObservable();

    constructor(private api: ApiRequestsService,
                private appSettings: AppSettings,
                private http: HttpClient) {
        if (this.isAuthenticated()) {
            this.authStateSource.next(true);
        }

    }

    isAuthenticated(): boolean {
        const idToken = localStorage.getItem(ID_TOKEN_KEY);
        if (idToken) {
            const isExpired = jwtHelper.isTokenExpired(idToken, this.EXPIRED_OFFSET_SECS);
            return !isExpired;
        } else {
            return false;
        }
    }

    isExistsAndExpired(): boolean {
        const idToken = localStorage.getItem(ID_TOKEN_KEY);
        if (idToken && jwtHelper.isTokenExpired(idToken, this.EXPIRED_OFFSET_SECS)) {
            return true;
        } else {
            return false;
        }
    }

    getAuthToken(): string {
        return localStorage.getItem(ID_TOKEN_KEY);
    }

    setAuthToken(token: string): void {
        localStorage.setItem(ID_TOKEN_KEY, token);
    }

    logout() {
        localStorage.removeItem(ID_TOKEN_KEY);
        this.authStateSource.next(false);
    }

    sendLoginLink(username: string) {
        const endpoint = this.appSettings.getEndpoint(Services.REQUEST_PASSWORD_RESET);
        const self = this;
        console.log(' username>>> ' + username );
        return new Promise(function(resolve, reject) {
        self.http.post(endpoint, {email: username})
                .subscribe(function(value: any) {
                    resolve();
                }, function(err) {
                    reject(err);
            });
        });
    }

    login(username: string, password: string, newPassword?: string): Promise<any> {
        const body = {
            userid: username.trim(),
            password: password.trim()
        };
        if (!isNil(newPassword) && newPassword.length > 0) {
            body['newPassword'] = newPassword.trim();
        }
        const self = this;
        return new Promise<IApiAuthLoginResponse>((resolve, reject) => {
            const endpoint = self.appSettings.getEndpoint(Services.LOGIN);
            this.http.post(
                endpoint,
                body)
                .subscribe(function (value) {
                    if (value.hasOwnProperty('idToken')) {
                        self.setAuthToken(value['idToken']);
                        self.authStateSource.next(true);
                        return resolve();
                    }
                    reject(new Error('server returned 200 from login without idToken' ));
                }, reject);
        });
    }


}
