import { Component, Input, OnChanges, OnInit, Output, EventEmitter } from '@angular/core';
import { StatisticsService } from '../statistics.service';

interface Locations{
  name: string,
  id: string,
}

@Component({
  selector: 'app-location-wise',
  templateUrl: './location-wise.component.html',
  styleUrls: ['./location-wise.component.scss'],
})


export class LocationWiseComponent implements OnInit, OnChanges {

  working = false;
  isSelected = [];

  statistics = {
    "Buildings": -1,
    "Assets": -1,
    "ActiveAlerts": -1,
    "CriticalAssets": -1,
    "OfflineAssets": -1
  };
  @Input() locations: Locations[];
  @Output() isLoaded = new EventEmitter<Boolean>();
  constructor(private statisticsService: StatisticsService) { }

  ngOnInit():void {

  }


  fetchStatistics(id: string): Promise<void> {
    this.working = true;
    this.isLoaded.emit(this.working);
    return new Promise((resolve, reject) => {
        this.statisticsService.fetchLocationStatistics(id).then((results: any) => {
          for (const [key, value] of Object.entries(results)) {
            this.statistics[key] = value;
          }
          this.working = false;
          this.isLoaded.emit(this.working);
          resolve();
        }, reject);
    });
  }

  chipClicked(index: number, locationId:string){
    if(!this.isSelected[index] === true){
      this.isSelected.fill(false);
      this.isSelected[index] = true;
      this.fetchStatistics(locationId);
    }
  }


  ngOnChanges(changes) {
    this.isSelected = new Array(this.locations.length).fill(false);
  }
}
