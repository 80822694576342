import {
    AfterViewInit,
    Component,
    EventEmitter,
    forwardRef,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewEncapsulation
} from '@angular/core';

import {forEach, has, isObject, find, startCase, keys} from 'lodash';
import {flatten} from 'flat';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
    selector : 'im-select',
    templateUrl: './incident-select.component.html',
    styleUrls: ['./incident-select.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => IncidentSelectComponent),
            multi: true
        }
    ]
})

export class IncidentSelectComponent implements OnInit, ControlValueAccessor {
    value;
    @Input('choices') choices;
    onChange: any = () => { };
    onTouched: any = () => { };
    @Input('allowBlankChoice') allowBlankChoice = true;
    @Output() selectionChange = new EventEmitter();
    constructor() {}


    ngOnInit(): void {
    }

    registerOnChange(fn) {
        this.onChange = fn;
    }

    registerOnTouched(fn) {
        this.onTouched = fn;
    }

    onSelectionChange(event: any) {
        this.onChange(this.value);
        this.onTouched();
        this.selectionChange.emit(event);
    }

    getChoice(id) {
        return find(this.choices || [], function (item) {
            return item.id === id;
        });
    }

    getSelectValueIconUrl() {
        if (this.value) {

            const found = this.getChoice(this.value);
            if (found) {
                return found.iconUrl;
            }
        }
    }

    getSelectValueDisplayName() {
        if (this.value) {
            const found = this.getChoice(this.value);
            if (found) {
                return found.displayName || found.name;
            }
        }
    }


    writeValue(value) {
        if (value) {
            this.value = value;
        }
    }
}
