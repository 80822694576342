import {FlatTreeControl} from '@angular/cdk/tree';
import {
    Component,
    ElementRef,
    EventEmitter,
    Injectable,
    Input,
    OnChanges, OnInit,
    Output,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {BehaviorSubject, Observable, of as observableOf} from 'rxjs';
import {CdkDragDrop} from '@angular/cdk/drag-drop';
import * as uuid from 'uuid';
import {Device} from '../sensors.service';
import {cloneDeep, forEach, isEmpty, find, uniq, isEqual} from 'lodash';
import {Tree} from '@angular-devkit/schematics';
import {OrgService} from '../../orgs/org.service';
import {SelectionModel} from '@angular/cdk/collections';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {AppContext} from '../../app.context';
import {Roles} from '../../app.settings';
import { Router } from '@angular/router';


const NODE_TYPES = {
    asset: 'asset',
    device: 'device'
};

const DRAG_EVENT_ITEM_TYPES = {
    PALETTE_DEVICE: 0,
    PALETTE_ASSET: 1,
    TREE_NODE: 2
};

const DROP_POSITION = {
    ABOVE: 'above',
    BELOW: 'below',
    CENTER: 'center'
};

export class DragEvent {
    allowDrop: boolean;

    constructor(
        public type: number,
        public data: {
            item: any,
            previousIndex?: number,
            previousContainer?: any[]
        }
    ) {
    }
}

const INITIAL_TREE = [{
    'id': '5fa8f679-26c5-4458-9825-c245c71d122e',
    'name': 'Location1',
    'type': 'asset',
    'subtype': 'location'
}];

/**
 * File node data with nested structure.
 * Each node has a filename, and a type or a list of children.
 */
export class TreeNode {
    constructor(
        public id?: string,
        public name?: string,
        public type?: string,
        public subtype?: string,
        public children?: TreeNode[]
    ) {
    }
}

/** Flat node with expandable and level information */
export class TreeFlatNode {
    public edit: boolean;

    constructor(
        public expandable: boolean,
        public name: string,
        public level: number,
        public type: any,
        public id: string,
        public subtype?: string
    ) {
    }
}


function deviceToTreeNode(device: Device): TreeNode {
    return new TreeNode(device.id, device.name, NODE_TYPES.device);
}

function stripNode(treeNode: TreeNode) {
    const clone = cloneDeep(treeNode);
    if (clone.type === NODE_TYPES.device) {
        // We cannot store any device specific info with the device node.  Only the platform id.  The name
        // will get resolved at render time
        delete clone.name;
    }
    clone.children = clone.children ? clone.children.map((child) => {
        return stripNode(child);
    }) : null;
    return clone;
}

function prepareNodesForStorage(nodes: TreeNode[]) {
    return nodes.map((node) => {
        return stripNode(node);
    });
}

@Injectable()
export class TreeDatastore {
    dataChange = new BehaviorSubject<TreeNode[]>([]);

    get data(): TreeNode[] {
        return this.dataChange.value;
    }

    initialize(model: any, sensorList: Device[]) {
        const result = this.buildTreeNodes(model, sensorList);
        this.dataChange.next(result.treeNodes);
        return result.availableSensors;
    }

    buildTreeNode(nodeModel, sensorMap: Map<string, Device>): TreeNode | null {
        if (nodeModel.type === NODE_TYPES.device) {
            const device = sensorMap.get(nodeModel.id);
            if (!device) {
                return null;
            }
            sensorMap.delete(nodeModel.id);
            nodeModel.name = device.name;
        }

        const newTreeNode = new TreeNode(nodeModel.id, nodeModel.name, nodeModel.type, nodeModel.subtype);
        if (nodeModel.children) {
            const children: TreeNode[] = [];
            forEach(nodeModel.children, (childModel) => {
                const childTreeNode = this.buildTreeNode(childModel, sensorMap);
                if (childTreeNode) {
                    children.push(childTreeNode);
                }
            });
            newTreeNode.children = children;
        }
        return newTreeNode;
    }

    setNodes(treeNodes: TreeNode[]) {
        this.dataChange.next(treeNodes);
    }

    buildTreeNodes(model, sensorList: Device[]): any {
        const sensorMap = new Map<string, Device>();
        const treeNodes = [];
        forEach(sensorList, (sensor) => {
            sensorMap.set(sensor.id, sensor);
        });
        forEach(model, (node) => {
            const treeNode = this.buildTreeNode(node, sensorMap);
            if (treeNode) {
                treeNodes.push(treeNode);
            }
        });

        return {
            treeNodes: treeNodes,
            availableSensors: Array.from(sensorMap.values())
        };
    }

    insertItem(parent: TreeNode, newItem: TreeNode): TreeNode {
        if (parent) {
            if (!parent.children) {
                parent.children = [];
            }
            parent.children.push(newItem);
        } else {
            this.data.push(newItem);
        }
        this.dataChange.next(this.data);
        return newItem;
    }

    insertItemAbove(node: TreeNode, newItem: TreeNode): TreeNode {
        const parentNode = this.getParentFromNodes(node);
        if (parentNode != null) {
            parentNode.children.splice(parentNode.children.indexOf(node), 0, newItem);
        } else {
            this.data.splice(this.data.indexOf(node), 0, newItem);
        }
        this.dataChange.next(this.data);
        return newItem;
    }

    insertItemBelow(node: TreeNode, newItem: TreeNode): TreeNode {
        const parentNode = this.getParentFromNodes(node);
        if (parentNode != null) {
            parentNode.children.splice(parentNode.children.indexOf(node) + 1, 0, newItem);
        } else {
            this.data.splice(this.data.indexOf(node) + 1, 0, newItem);
        }
        this.dataChange.next(this.data);
        return newItem;
    }

    getParentFromNodes(node: TreeNode): TreeNode {
        for (let i = 0; i < this.data.length; ++i) {
            const currentRoot = this.data[i];
            const parent = this.getParent(currentRoot, node);
            if (parent != null) {
                return parent;
            }
        }
        return null;
    }

    getParent(currentRoot: TreeNode, node: TreeNode): TreeNode {
        if (currentRoot.children && currentRoot.children.length > 0) {
            for (let i = 0; i < currentRoot.children.length; ++i) {
                const child = currentRoot.children[i];
                if (child === node) {
                    return currentRoot;
                } else if (child.children && child.children.length > 0) {
                    const parent = this.getParent(child, node);
                    if (parent != null) {
                        return parent;
                    }
                }
            }
        }
        return null;
    }

    updateItem(node: TreeNode, name: string) {
        node.name = name;
        this.dataChange.next(this.data);
    }

    deleteItem(node: TreeNode) {
        this.deleteNode(this.data, node);
        this.dataChange.next(this.data);
    }

    copyPasteItem(from: TreeNode, to: TreeNode): TreeNode {
        const newItem = this.insertItem(to, cloneDeep(from));
        // if (from.children) {
        //     from.children.forEach(child => {
        //         this.copyPasteItem(child, newItem);
        //     });
        // }
        return newItem;
    }

    copyPasteItemAbove(from: TreeNode, to: TreeNode): TreeNode {
        const newItem = this.insertItemAbove(to, cloneDeep(from));
        // if (from.children) {
        //     from.children.forEach(child => {
        //         this.copyPasteItem(child, newItem);
        //     });
        // }
        return newItem;
    }

    copyPasteItemBelow(from: TreeNode, to: TreeNode): TreeNode {
        const newItem = this.insertItemBelow(to, cloneDeep(from));
        // if (from.children) {
        //     from.children.forEach(child => {
        //         this.copyPasteItem(child, newItem);
        //     });
        // }
        return newItem;
    }

    deleteNode(nodes: TreeNode[], nodeToDelete: TreeNode) {
        const index = nodes.indexOf(nodeToDelete, 0);
        if (index > -1) {
            nodes.splice(index, 1);
        } else {
            nodes.forEach(node => {
                if (node.children && node.children.length > 0) {
                    this.deleteNode(node.children, nodeToDelete);
                }
            });
        }
    }
}

/**
 * @title Tree with flat nodes
 */
@Component({
    selector: 'sensor-tree',
    templateUrl: 'sensor-tree.component.html',
    styleUrls: ['sensor-tree.component.scss'],
    providers: [TreeDatastore],
    encapsulation: ViewEncapsulation.None,
})
export class SensorTreeComponent implements OnChanges, OnInit {
    @Input() sensorList: Device[];
    @Output() selectionChanged = new EventEmitter();
    @Output() fetchSensors = new EventEmitter()
    @ViewChild('emptyItem', {static: false}) emptyItem: ElementRef;
    availableSensors: Device[] = [];

    flatNodeMap = new Map<TreeFlatNode, TreeNode>();
    nestedNodeMap = new Map<TreeNode, TreeFlatNode>();
    treeControl: FlatTreeControl<TreeFlatNode>;
    treeFlattener: MatTreeFlattener<TreeNode, TreeFlatNode>;
    dataSource: MatTreeFlatDataSource<TreeNode, TreeFlatNode>;
    expandedNodeSet = new Set<string>();
    checklistSelection = new SelectionModel<TreeFlatNode>(true);
    dragging = false;
    loading = false;
    expandTimeout: any;
    expandDelay = 300;
    dragEvent: DragEvent = null;
    dragNodeExpandOverNode: TreeNode;
    dragNodeExpandOverArea: string;
    sensorGroups = {};
    nameEdit = null;
    editable = false;
    canEdit = false;
    selectedNode = null;
    savedTreeCopy = null;
    savedAvailableSensorCopy = null;
    currentNodeEdit = null;
    dragOverBlank = false;
    isMobile = false;
    assetList = [{
        name: 'Location',
        subtype: 'location',
        matIcon: 'location_on'
    }, {
        name: 'Warehouse',
        subtype: 'warehouse',
        img: 'assets/warehouse.png'
    }, {
        name: 'Factory',
        subtype: 'factory',
        img: 'assets/factory.png'
    }, {
        name: 'Refrigerator',
        subtype: 'refrigerator',
        img: 'assets/fridge.png'
    }, {
        name: 'Freezer',
        subtype: 'freezer',
        img: 'assets/freezer.png'
    }, {
        name: 'HVAC',
        subtype: 'hvac',
        img: 'assets/hvac.png'
    }, {
        name: 'Generic Equipment',
        subtype: 'equipment',
        matIcon: 'settings'
    }];

    assetSubTypes = ["freezer", "hvac", "refrigerator"]
    NODE_TYPES = NODE_TYPES;

    deletedSensors = [];

    constructor(private treeDatastore: TreeDatastore,
                private orgService: OrgService,
                public appContext: AppContext,
                public router:Router,
                public breakpointObserver: BreakpointObserver) {
        this.treeFlattener = new MatTreeFlattener(this.transformer, this._getLevel,
            this._isExpandable, this._getChildren);
        this.treeControl = new FlatTreeControl<TreeFlatNode>(this._getLevel, this._isExpandable);
        this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
        treeDatastore.dataChange.subscribe(data =>this.rebuildTreeForData(data));
        breakpointObserver.observe([Breakpoints.Handset, Breakpoints.Small]).subscribe(result => {
            this.isMobile = result.matches;
        });
    }

    transformer = (node: TreeNode, level: number) => {
        const flatNode = new TreeFlatNode(!!node.children && !isEmpty(node.children), node.name, level, node.type, node.id, node.subtype);
        this.flatNodeMap.set(flatNode, node);
        this.nestedNodeMap.set(node, flatNode);
        return flatNode;
    };
    private _getLevel = (node: TreeFlatNode) => node.level;
    private _isExpandable = (node: TreeFlatNode) => node.expandable;
    private _getChildren = (node: TreeNode): Observable<TreeNode[]> => observableOf(node.children);
    hasChild = (_: number, _nodeData: TreeFlatNode) => _nodeData.expandable;
    nodeInEditMode = (_: number, _nodeData: TreeFlatNode) => {
        return _nodeData.edit;
    };

    /**
     * This constructs an array of nodes that matches the DOM,
     * and calls rememberExpandedTreeNodes to persist expand state
     */
    visibleNodes(): TreeNode[] {
        this.rememberExpandedTreeNodes(this.treeControl, this.expandedNodeSet);
        const result = [];

        function addExpandedChildren(node: TreeNode, expanded: Set<string>) {
            result.push(node);
            if (expanded.has(node.id)) {
                node.children.map(child => addExpandedChildren(child, expanded));
            }
        }

        this.dataSource.data.forEach(node => {
            addExpandedChildren(node, this.expandedNodeSet);
        });
        return result;
    }

    /**
     * The following methods are for persisting the tree expand state
     * after being rebuilt
     */

    rebuildTreeForData(data: any) {
        this.rememberExpandedTreeNodes(this.treeControl, this.expandedNodeSet);
        this.dataSource.data = data;
        this.forgetMissingExpandedNodes(this.treeControl, this.expandedNodeSet);
        this.expandNodesById(this.treeControl.dataNodes, Array.from(this.expandedNodeSet));
    }

    private rememberExpandedTreeNodes(
        treeControl: FlatTreeControl<TreeFlatNode>,
        expandedNodeSet: Set<string>
    ) {
        if (treeControl.dataNodes) {
            treeControl.dataNodes.forEach((node) => {
                if (treeControl.isExpandable(node) && treeControl.isExpanded(node)) {
                    // capture latest expanded state
                    expandedNodeSet.add(node.id);
                }
            });
        }
    }

    private forgetMissingExpandedNodes(
        treeControl: FlatTreeControl<TreeFlatNode>,
        expandedNodeSet: Set<string>
    ) {
        if (treeControl.dataNodes) {
            expandedNodeSet.forEach((nodeId) => {
                // maintain expanded node state
                if (!treeControl.dataNodes.find((n) => n.id === nodeId)) {
                    // if the tree doesn't have the previous node, remove it from the expanded list
                    expandedNodeSet.delete(nodeId);
                }
            });
        }
    }

    private expandNodesById(flatNodes: TreeFlatNode[], ids: string[]) {
        if (!flatNodes || flatNodes.length === 0) {
            return;
        }
        const idSet = new Set(ids);
        return flatNodes.forEach((node) => {
            if (idSet.has(node.id)) {
                this.treeControl.expand(node);
                let parent = this.getParentNode(node);
                while (parent) {
                    this.treeControl.expand(parent);
                    parent = this.getParentNode(parent);
                }
            }
        });
    }

    private getParentNode(node: TreeFlatNode): TreeFlatNode | null {
        const currentLevel = node.level;
        if (currentLevel < 1) {
            return null;
        }
        const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;
        for (let i = startIndex; i >= 0; i--) {
            const currentNode = this.treeControl.dataNodes[i];
            if (currentNode.level < currentLevel) {
                return currentNode;
            }
        }
        return null;
    }

    paletteDragStart($event, item, index, dataList, type) {
        $event.dataTransfer.setData('foo', 'bar');
        $event.dataTransfer.setDragImage(this.emptyItem.nativeElement, 0, 0);
        if (type === NODE_TYPES.asset) {
            this.dragEvent = new DragEvent(DRAG_EVENT_ITEM_TYPES.PALETTE_ASSET, {
                item: item
            });
        } else if (type === NODE_TYPES.device) {
            this.dragEvent = new DragEvent(DRAG_EVENT_ITEM_TYPES.PALETTE_DEVICE, {
                item: item,
                previousIndex: index,
                previousContainer: dataList
            });
        }
    }

    handleDragStart($event, treeNode: TreeFlatNode) {

        $event.dataTransfer.setData('foo', 'bar');
        $event.dataTransfer.setDragImage(document.createElement('img'), 0, 0);
        this.dragEvent = new DragEvent(DRAG_EVENT_ITEM_TYPES.TREE_NODE, {
            item: treeNode
        });
    }

    allowDrop(dragEvent: DragEvent, dropTargetNode?: TreeFlatNode, dropPosition?: string): boolean {
        // If there is noting in the tree and an asset is being dragged onto it, allow it
        if (!dropTargetNode) {
            return dragEvent.type === DRAG_EVENT_ITEM_TYPES.PALETTE_ASSET ||
                (dragEvent.type === DRAG_EVENT_ITEM_TYPES.TREE_NODE && dragEvent.data.item.type === NODE_TYPES.asset);
        }
        const centerDrop = dropPosition === DROP_POSITION.CENTER;
        if (dragEvent.type === DRAG_EVENT_ITEM_TYPES.PALETTE_ASSET) {
            // Don't allow an asset to be dropped into a device
            return dropTargetNode.type !== NODE_TYPES.device || !centerDrop;
        } else if (dragEvent.type === DRAG_EVENT_ITEM_TYPES.PALETTE_DEVICE
            || (dragEvent.type === DRAG_EVENT_ITEM_TYPES.TREE_NODE && dragEvent.data.item.type === NODE_TYPES.device)) {
            // Dont allow a sensor to be at the root and dont allow a sensor to be dropped onto another sensor
            return (dropTargetNode.type === NODE_TYPES.asset && centerDrop)
                || (dropTargetNode.type === NODE_TYPES.device && !centerDrop);
        }
        return true;
    }

    getDeviceStatus(flatNode: TreeFlatNode) {
        const device = this.getDevice(flatNode);
        return device ? device.status : null;
    }

    /**
     * Handles the drag event when the mouse is over the tree.  It always allows when NOT over a node
     * @param $event
     */
    handleBlankDragOver($event: any) {
        if (this.dragEvent && this.allowDrop(this.dragEvent)) {
            $event.preventDefault();
        }
    }

    handleDragLeave($event: any, flatNode: TreeFlatNode) {
        this.dragNodeExpandOverNode = null;
        this.dragNodeExpandOverArea = null;
    }

    handleTreeDragOver($event) {
        if (this.dragEvent && this.allowDrop(this.dragEvent)) {
            $event.preventDefault();
        } 
    }

    handleTreeDrop($event: any) {
        this.handleDrop($event);
    }

    handleDragOver($event: any, flatNode: TreeFlatNode) {
        $event.stopPropagation();
        let area = DROP_POSITION.CENTER;
        // Handle drag area
        const percentageX = $event.offsetX / $event.target.clientWidth;
        const percentageY = $event.offsetY / $event.target.clientHeight;
        if (percentageY < 0.25) {
            area = DROP_POSITION.ABOVE;
        } else if (percentageY > 0.75) {
            area = DROP_POSITION.BELOW;
        }

        this.dragNodeExpandOverArea = area;
        const dragNode: TreeFlatNode = this.dragEvent.data.item;
        const allowDrop = this.allowDrop(this.dragEvent, flatNode, area);
        if (!allowDrop) {
            this.dragNodeExpandOverArea = null;
            return;
        }

        $event.preventDefault();

        // Handle node expand
        if (flatNode === this.dragNodeExpandOverNode) {
            if (dragNode !== flatNode && flatNode.expandable && !this.treeControl.isExpanded(flatNode)) {
                this.expandTimeout = setTimeout(() => {
                    this.treeControl.expand(flatNode);
                }, this.expandDelay);
            }
        } else {
            this.dragNodeExpandOverNode = flatNode;
        }
    }

    /**
     * Handles a drop event on the tree NOT over a node
     * @param $event
     */
    handleBlankDrop($event: DragEvent) {
        this.handleDrop($event);
    }


    handleDrop($event, nodeUnderDrop?: TreeFlatNode) {
        $event.preventDefault();
        $event.stopPropagation();
        if (this.dragEvent) {
            if (this.dragEvent.type === DRAG_EVENT_ITEM_TYPES.PALETTE_DEVICE
                || this.dragEvent.type === DRAG_EVENT_ITEM_TYPES.PALETTE_ASSET) {

                let newNode = null;
                if (this.dragEvent.type === DRAG_EVENT_ITEM_TYPES.PALETTE_DEVICE) {
                    newNode = deviceToTreeNode(this.dragEvent.data.item);
                    const prevIndex = this.dragEvent.data.previousIndex;
                    const dataList = this.dragEvent.data.previousContainer;
                    dataList.splice(prevIndex, 1);
                } else {
                    newNode = new TreeNode(uuid(), this.dragEvent.data.item.name, NODE_TYPES.asset, this.dragEvent.data.item.subtype);
                }
                let newItem = null;
                const targetNode = this.flatNodeMap.get(nodeUnderDrop);
                if (this.dragNodeExpandOverArea === 'above') {
                    newItem = this.treeDatastore.insertItemAbove(targetNode, newNode);
                } else if (this.dragNodeExpandOverArea === 'below') {
                    newItem = this.treeDatastore.insertItemBelow(targetNode, newNode);
                } else {
                    newItem = this.treeDatastore.insertItem(targetNode, newNode);
                    const parent = this.treeDatastore.getParentFromNodes(newItem);
                    const flatParent = this.nestedNodeMap.get(parent);
                    this.treeControl.expand(flatParent);
                }
                if (newNode.type === NODE_TYPES.asset) {
                    const flatNode = this.nestedNodeMap.get(newItem);
                    this.editNodeName(flatNode);
                }

            } else if (this.dragEvent.type === DRAG_EVENT_ITEM_TYPES.TREE_NODE) {
                const dragNode = this.dragEvent.data.item;
                if (nodeUnderDrop !== dragNode) {
                    let newItem: TreeNode;
                    if (this.dragNodeExpandOverArea === 'above') {
                        newItem = this.treeDatastore.copyPasteItemAbove(this.flatNodeMap.get(dragNode),
                            this.flatNodeMap.get(nodeUnderDrop));
                    } else if (this.dragNodeExpandOverArea === 'below') {
                        newItem = this.treeDatastore.copyPasteItemBelow(this.flatNodeMap.get(dragNode),
                            this.flatNodeMap.get(nodeUnderDrop));
                    } else {
                        newItem = this.treeDatastore.copyPasteItem(this.flatNodeMap.get(dragNode),
                            this.flatNodeMap.get(nodeUnderDrop));
                    }
                    this.treeDatastore.deleteItem(this.flatNodeMap.get(dragNode));
                    this.treeControl.expandDescendants(this.nestedNodeMap.get(newItem));
                }
            }
        }
        this.cleanupDragEvent();

    }

    cleanupDragEvent() {
        this.dragEvent = null;
        this.dragNodeExpandOverArea = null;
        this.dragNodeExpandOverNode = null;
        if (this.expandTimeout) {
            clearTimeout(this.expandTimeout);
            this.expandTimeout = null;
        }
    }

    handleDragEnd($event, treeNode: TreeFlatNode) {
        this.cleanupDragEvent();
    }

    onEdit() {
        this.editable = true;
        this.selectedNode = null;
        this.savedTreeCopy = cloneDeep(this.treeDatastore.data);
        this.savedAvailableSensorCopy = cloneDeep(this.availableSensors);
    }

    onDelete() {
        const returnSensors = [];
        forEach(this.checklistSelection.selected, (flatNode: TreeFlatNode) => {
            // Return all sensors to the availableList
            const treeNode = this.flatNodeMap.get(flatNode);
            if (treeNode) {
                if (treeNode.type === NODE_TYPES.device) {
                    // Collect the id, and return
                    const found = find(this.sensorList, (sensor) => {
                        if(sensor.id === treeNode.id){
                            this.deletedSensors.push(sensor.id)
                            return sensor.id;
                        }
                    });
                    if (found) {
                        returnSensors.push(found);
                    }
                }
                this.treeDatastore.deleteItem(treeNode);
            }
        });
        this.setAvailableSensors(uniq(this.availableSensors.concat(returnSensors)));
        this.checklistSelection.clear();
    }

    isParentChecked(flatNode: TreeFlatNode) {
        const parent = this.getParentNode(flatNode);
        return parent ? this.checklistSelection.isSelected(parent) : false;
    }

    selectNode(flatNode: TreeFlatNode, select?: boolean) {
        if (select === undefined) {
            select = !this.checklistSelection.isSelected(flatNode);
        }
        if (select) {
            this.checklistSelection.select(flatNode);
        } else {
            this.checklistSelection.deselect(flatNode);
        }

        const treeNode: TreeNode = this.flatNodeMap.get(flatNode);
        forEach(treeNode.children, (child: TreeNode) => {
            this.selectNode(this.nestedNodeMap.get(child), select);
        });
    }

    onCancelEdit() {
        this.editable = false;
        this.treeDatastore.setNodes(this.savedTreeCopy);
        this.setAvailableSensors(this.savedAvailableSensorCopy);

        this.savedTreeCopy = null;
        this.savedAvailableSensorCopy = null;
        this.deletedSensors = [];
    }

    onExpandAll() {
        this.treeControl.expandAll();
    }

    onCollapseAll() {
        this.treeControl.collapseAll();
    }

    onSaveTree() {
        this.loading = true;
        this.orgService.upsertDeviceTree(prepareNodesForStorage(this.treeDatastore.data), this.deletedSensors).then(() => {
            this.editable = false;
            this.savedTreeCopy = null;
            this.savedAvailableSensorCopy = null;
            this.fetchSensors.emit();
        }).finally(() => {
            this.loading = false;
        });
    }

    editNodeName(treeNode: TreeFlatNode) {
        if (this.currentNodeEdit !== null) {
            this.currentNodeEdit.edit = false;
        }
        if (this.editable && treeNode.type === NODE_TYPES.asset) {
            treeNode.edit = true;
            this.currentNodeEdit = treeNode;
        }
    }

    applyNameEdit(flatNode: TreeFlatNode, newName: string) {
        this.treeDatastore.updateItem(this.flatNodeMap.get(flatNode), newName);
        flatNode.name = this.nameEdit;
        flatNode.edit = false;
        this.currentNodeEdit = null;
    }

    cancelNameEdit(node: TreeFlatNode) {
        node.edit = false;
        // this.nameEdit = null;
        this.currentNodeEdit = null;
    }

    getDevice(flatNode: TreeFlatNode) {
        return find(this.sensorList, (sensor) => {
            return sensor.id === flatNode.id;
        });
    }

    setAvailableSensors(sensors) {
        this.availableSensors = sensors;
        this.sensorGroups = {};
        this.availableSensors.forEach((device) => {
            const list = this.sensorGroups[device.typeName] = this.sensorGroups[device.typeName] || [];
            list.push(device);
        });
    }

    onViewAssetDoc(node: TreeFlatNode){
        this.router.navigate(['assets'],{queryParams: node}).finally();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.sensorList) {
            this.loading = true;
            const sensorList = changes.sensorList.currentValue;
            this.orgService.getDeviceTree().then((tree: any) => {
                const availableSensors = this.treeDatastore.initialize(tree && tree.tree.length > 0 ? tree.tree : [], sensorList);
                this.setAvailableSensors(availableSensors);
            }).finally(() => {
                this.loading = false;
            });
        }
    }

    ngOnInit(): void {
        this.appContext.isUserInRole([Roles.USER, Roles.ADMIN, Roles.OWNER]).then((edit) => {
            this.canEdit = edit;
        });
    }
}
