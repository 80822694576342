import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

const UNIT_TRANSFORM_MAP = {
    F: '℉',
    C: '℃'
}


@Pipe({
    name: 'unit'
})

export class UnitDisplayDirective implements PipeTransform {
    transform(unit: string): string {
        const displayValue = UNIT_TRANSFORM_MAP[unit.toUpperCase()];
        return displayValue || unit;
    }
}
