import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export class CustomValidators{

    static RequiredImage(): ValidatorFn {
        return (control: FormControl): ValidationErrors | null => {
            const acceptedImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
            const file = control.value;
            if (file) {
                const extension = file.type.toLowerCase()
                if (!acceptedImageTypes.includes(extension.toLowerCase())) {
                    return {invalidImage: 'only jpeg and png formats are supported'};
                }
                return null;
            }

            return null;
        }
    }
    
    static Coordinates(type: string): ValidatorFn {
        return (control: FormControl): ValidationErrors | null => {

            let error = {
                invalidCoordinates: 'Coordinates fall outside the allowed bounds'
            }

            let bounds = {
                longitude: {
                    min: -180,
                    max: 180
                },
                latitude: {
                    min: -90,
                    max: 90
                }
            }

            const coordinates = control.value;
            let hasProperCoordinates = true;

            if(type === 'longitude'){
                hasProperCoordinates = coordinates <= bounds.longitude.max && coordinates >= bounds.longitude.min;
            }
            else if(type === 'latitude'){
                hasProperCoordinates = coordinates <= bounds.latitude.max && coordinates >= bounds.latitude.min;
            }

            if(!hasProperCoordinates){
                error.invalidCoordinates = `Field must be between ${bounds[type].min} and ${bounds[type].max}`;
            }
            return hasProperCoordinates ? null: error
        };
    }
}