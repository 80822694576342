import {Component, OnInit, Query, ViewEncapsulation} from '@angular/core';
import {QUERY_JOB_STATES, QueryJob, QueryJobService} from './query-job.service';
import {AppContext} from '../app.context';
import {HttpClient} from '@angular/common/http';
import {MatDialog, MatDialogConfig, MatDialogRef, MatTableDataSource} from '@angular/material';
import {QueryBuilderDialogComponent} from './builder/query-builder-dialog.component';
import {SelectionModel} from '@angular/cdk/collections';
import {findIndex, values, forEach, keys} from 'lodash';
import * as moment from 'moment';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {ProgressBarComponent} from '../shared/progress-bar/progress-bar.component';
import {ProgressDialogComponent} from '../shared/dialogs/progress-dialog/progress-dialog.component';
import {ConfirmDialogComponent} from '../shared/dialogs/confirm/confirm-dialog.component';


@Component({
    selector: 'query-jobs',
    templateUrl: './queries.component.html',
    styleUrls: ['./queries.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
    host: {class: 'main-page-content'},
})
export class QueryJobsComponent implements OnInit {
    working = false;
    updateTimers = {};
    displayedColumns: string[] = ['select', 'name', 'dateSubmitted', 'status', 'actions'];
    dataSource = new MatTableDataSource<QueryJob>();
    selection = new SelectionModel<QueryJob>(true, []);
    expandedElement: QueryJob | null;

    constructor(private jobService: QueryJobService,
                private appContext: AppContext,
                private httpClient: HttpClient,
                private dialog: MatDialog) {}


    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    masterToggle() {
        this.isAllSelected() ?
            this.selection.clear() :
            this.dataSource.data.forEach(row => this.selection.select(row));
    }

    remove() {

        const deleteRef = this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            autoFocus: true,
            data: {
                title: 'Confirm Delete',
                message: 'Are you sure you want to remove the selected queries?'
            }
        });

        deleteRef.afterClosed().subscribe(async (result) => {
            if (result) {
                const progressData = {
                    message: 'Deleting query jobs...',
                    mode: 'determinate',
                    percent: 0
                }
                const selected = this.selection.selected;
                if (selected.length === 1) {
                    progressData.mode = 'indeterminate';
                }
                const progressRef: MatDialogRef<ProgressDialogComponent> = this.dialog.open(ProgressDialogComponent, {
                    disableClose: true,
                    'minWidth': '50%',
                    data: progressData
                });

                try {
                    for (let i = 0; i < selected.length; i++) {
                        const jobId = selected[i].id;
                        progressData.percent = (i + 1 / selected.length) * 100;
                        await this.jobService.deleteJob(jobId);
                        delete this.updateTimers[jobId];
                        const data = this.dataSource.data;
                        const idx = findIndex(data, (item) => {
                            return item.id === jobId;
                        })
                        if (idx > -1) {
                            data.splice(idx, 1);
                            this.dataSource.data = data;
                        }
                    }
                } catch (e) {
                    // Ignore, the service should produce an error
                } finally {
                    this.selection.clear();
                    progressRef.close();
                }

            }
        });
    }



    async add() {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.minWidth = '70%';
        dialogConfig.data = {};

        const dialogRef = this.dialog.open(QueryBuilderDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.working = true;
                this.jobService.submitJob(result).then(() => {
                    this.fetchJobs();
                }).finally(() => {
                    this.working = false;
                });
            }

        });
    }

    getSelected() {
        return this.selection.selected;
    }

    addStatusUpdater(job: QueryJob) {
        const jobId = job.id;
        const timer = setInterval(async () => {
            try {
                this.jobService.getJob(jobId).then((result) => {
                    if (result.status !== 'RUNNING' && result.status !== 'QUEUED') {
                        const data = this.dataSource.data;
                        const idx = findIndex(data, (item) => {
                            return item.id === jobId;
                        });
                        if (idx > -1) {
                            data.splice(idx, 1, result);
                            this.dataSource.data = data;
                        }
                        clearInterval(timer);
                        delete this.updateTimers[jobId];
                    }

                }, (err) => {

                });
            } catch (e) {
                console.error('E: ', e);
            }
        }, 2000);
        this.updateTimers[jobId] = timer;
    }

    /**
     * Method is use to download file.
     * @param data - Array Buffer data
     * @param type - type of the document.
     */
    downLoadFile(data: any, type: string, name: string) {
        const blob = new Blob([data], { type: type });
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(blob);
        downloadLink.download = name;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        downloadLink.parentNode.removeChild(downloadLink);
    }

    download($event: MouseEvent, job: QueryJob) {
        $event.stopPropagation();
        this.httpClient.get(job.results, {responseType: 'arraybuffer'}
        ).subscribe((response) => {
            this.downLoadFile(response, 'text/csv', job.name);
        });
    }

    _cancelStatusUpdaters() {
        keys(this.updateTimers, (key) => {
            const timer = this.updateTimers[key];
            if (timer) {
                clearInterval(timer);
                delete this.updateTimers[key];
            }
        });
    }

    fetchJobs(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.jobService.fetchJobs().then((results) => {
                this._cancelStatusUpdaters();
                results.forEach((result) => {
                    if (result.status === QUERY_JOB_STATES.RUNNING || result.status === QUERY_JOB_STATES.QUEUED) {
                        this.addStatusUpdater(result);
                    }
                })
                results.sort((a, b) => {
                    if (!a.dateSubmitted && !b.dateSubmitted) {
                        return 0;
                    }
                    if (!a.dateSubmitted) {
                        return 1;
                    }
                    if (!b.dateSubmitted) {
                        return -1;
                    }
                    const aMoment = moment(a.dateSubmitted);
                    const bMoment = moment(b.dateSubmitted);
                    return aMoment.isAfter(bMoment) ? -1 : bMoment.isAfter(aMoment) ? 1 : 0;
                });
                this.dataSource.data = results;
                resolve();
            }, reject);
        });
    }

    ngOnInit(): void {
        this.working = true;
        this.fetchJobs().finally(() => {
            this.working = false;
        });
    }
}
