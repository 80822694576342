import {ChangePasswordComponent} from './dialogs/change-password/change-password.component';
import {EditProfileComponent} from './dialogs/edit-profile/edit-profile.component';
import {NgModule} from '@angular/core';
import {LoadingComponent} from './loading/loading.component';
import {NgxSpinnerModule} from 'ngx-spinner';
import {CommonModule} from '@angular/common';
import {MatProgressSpinnerModule} from '@angular/material';
import {FlexLayoutModule} from '@angular/flex-layout';
import {ExpansionPanelComponent} from './expansion-panel/expansion-panel.component';
import {MaterialModule} from '../material/material.module';
import {IonicModule} from '@ionic/angular';
import {SchemaFieldSelectComponent} from './schema-field-select/schema-field-select.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {OrgDeviceTypeSelectComponent} from './org-device-type-select/org-device-type-select.component';
import {UserSelectComponent} from './user-select/user-select.component';
import {ChipComponent} from './chip/chip.component';
import {SearchComponent} from './search/search.component';
import {ChangePasswordMenuItemComponent} from './menu-items/change-password/change-password.component';
import {InternationalPhoneNumberModule} from 'ngx-international-phone-number';
import {EditProfileMenuItemComponent} from './menu-items/edit-profile/edit-profile.component';
import {PhoneComponent} from './phone/phone.component';
import {TagSelectorDialogComponent} from './dialogs/tag-selector/tag-selector-dialog.component';
import {TagSelectComponent} from './tag-select/tag-select.component';
import {DeviceidSelectComponent} from './deviceid-select/deviceid-select.component';
import {FormValidationService} from './form-validation.service';
import {MaterialElevationDirective} from './directives/material-elevation.directive';
import {MustNotMatchDirective} from './directives/must-not-match.directive';
import {MustMatchDirective} from './directives/must-match.directive';
import {PatternValidatorDirective} from './directives/pattern.directive';
import {ViewHostDirective} from './directives/view-host.directive';
import {AbstractNgModelComponent} from './control/control.component';
import {InputComponent} from './input/input.component';
import {ColorPickerModule} from 'ngx-color-picker';
import {ColorPickerControlComponent} from './color-picker/color-picker.component';
import {HighlightRangesComponent} from './highlight-ranges/highlight-ranges.component';
import {LocalDateDirective} from './directives/local-date.directive';
import {MessageDialogComponent} from './dialogs/message/message-dialog.component';
import {Message} from '@angular/compiler/src/i18n/i18n_ast';
import {TreeComponent} from './tree/tree.component';
import {MultiDeviceSelectComponent} from './multi-device-select/multi-device-select.component';
import {ProgressBarComponent} from './progress-bar/progress-bar.component';
import {ProgressDialogComponent} from './dialogs/progress-dialog/progress-dialog.component';
import {ToggleComponent} from './toggle/toggle.component';
import {AppRoutingModule} from '../app-routing.module';
import {RoutingStoreService} from './route-store.service';
import {UnitDisplayDirective} from './directives/unit-display.directive';
import {ProfileMenuItemComponent} from './menu-items/profile/profile-menu-item.component';
import {OrgMenuItemComponent} from './menu-items/org/org-menu-item.component';
import {LocalTimeDirective} from './directives/local-time.directive';
import {GraphLegendService} from './legend.service';
import {TruncateDirective} from './directives/truncate.directive';
import {FilterComponent, ItemFilterPipe} from './filter/filter.component';

@NgModule({
    // tslint:disable-next-line: max-line-length
    imports: [
        NgxSpinnerModule,
        MatProgressSpinnerModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        MaterialModule,
        IonicModule,
        CommonModule,
        FormsModule,
        AppRoutingModule,
        InternationalPhoneNumberModule,
        ColorPickerModule
    ],
    declarations: [
        LoadingComponent,
        ExpansionPanelComponent,
        SchemaFieldSelectComponent,
        // tslint:disable-next-line: max-line-length
        OrgDeviceTypeSelectComponent,
        UserSelectComponent,
        ChipComponent,
        SearchComponent,
        PhoneComponent,
        MustMatchDirective,
        MustNotMatchDirective,
        EditProfileComponent,
        ChangePasswordComponent,
        EditProfileMenuItemComponent,
        ChangePasswordMenuItemComponent,
        TagSelectorDialogComponent,
        TagSelectComponent,
        DeviceidSelectComponent,
        MaterialElevationDirective,
        PatternValidatorDirective,
        ViewHostDirective,
        AbstractNgModelComponent,
        InputComponent,
        ColorPickerControlComponent,
        HighlightRangesComponent,
        LocalDateDirective,
        MessageDialogComponent,
        TreeComponent,
        MultiDeviceSelectComponent,
        ProgressBarComponent,
        ProgressDialogComponent,
        ToggleComponent,
        UnitDisplayDirective,
        ProfileMenuItemComponent,
        OrgMenuItemComponent,
        LocalTimeDirective,
        TruncateDirective,
        FilterComponent,
        ItemFilterPipe,
    ],
    providers: [FormValidationService, RoutingStoreService, GraphLegendService],
    exports: [
        LoadingComponent,
        ExpansionPanelComponent,
        SchemaFieldSelectComponent,
        // tslint:disable-next-line: max-line-length
        OrgDeviceTypeSelectComponent,
        UserSelectComponent,
        ChipComponent,
        SearchComponent,
        PhoneComponent,
        MustMatchDirective,
        MustNotMatchDirective,
        EditProfileComponent,
        ChangePasswordComponent,
        EditProfileMenuItemComponent,
        ChangePasswordMenuItemComponent,
        TagSelectorDialogComponent,
        TagSelectComponent,
        DeviceidSelectComponent,
        MaterialElevationDirective,
        PatternValidatorDirective,
        ViewHostDirective,
        AbstractNgModelComponent,
        InputComponent,
        ColorPickerControlComponent,
        HighlightRangesComponent,
        LocalDateDirective,
        MessageDialogComponent,
        TreeComponent,
        MultiDeviceSelectComponent,
        ProgressBarComponent,
        ProgressDialogComponent,
        ToggleComponent,
        UnitDisplayDirective,
        ProfileMenuItemComponent,
        OrgMenuItemComponent,
        LocalTimeDirective,
        TruncateDirective,
        FilterComponent,
    ]
})
export class SharedModule {
}
