import {Component, ElementRef, forwardRef, Input, ViewEncapsulation} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material';
import {TagSelectorDialogComponent} from '../dialogs/tag-selector/tag-selector-dialog.component';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {get, set, findIndex} from 'lodash';

@Component({
    selector: 'tag-select',
    templateUrl: './tag-select.component.html',
    styleUrls: ['./tag-select.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class TagSelectComponent {
    @Input() selectedDeviceTypeId;
    @Input() tags;
    @Input() label;
    @Input() removable = true;
    constructor(private dialog: MatDialog) {}

    _findTagIndex(tagToFind) {
        return findIndex(this.tags, function(t) {
            return t.key === tagToFind.key && t.value === tagToFind.value;
        });
    }

    onRemove(tag, idx) {
        this.tags.splice(idx, 1);
    }

    selectTag(evt: MouseEvent): void {
        if (this.selectedDeviceTypeId) {
            const dialogConfig = new MatDialogConfig();
            const target = new ElementRef(evt.currentTarget);
            dialogConfig.hasBackdrop = true;
            dialogConfig.backdropClass = 'transparent';
            dialogConfig.disableClose = true;
            dialogConfig.data = {
                trigger: target,
                deviceTypeId: this.selectedDeviceTypeId
            }

            const self = this;
            const dialogRef = this.dialog.open(TagSelectorDialogComponent, dialogConfig);
            dialogRef.backdropClick().subscribe(() => {
                dialogRef.close();
            });

            dialogRef.afterClosed().subscribe(function (result) {
                if (result) {
                    const idx = self._findTagIndex(result);
                    if (idx === -1) {
                        // const newTag = {};
                        self.tags.push(result);
                    }
                }

            });
        }
    }
}
