import { NgModule } from '@angular/core';
import {CommonModule, TitleCasePipe} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { IonicModule } from '@ionic/angular';
import { NgxLoadingModule } from 'ngx-loading';
import { MaterialModule} from '../material/material.module';
import {SharedModule} from '../shared/shared.module';
import {NgMatSearchBarModule} from 'ng-mat-search-bar';
import {FilterComponent} from '../shared/filter/filter.component';
import { FormatJson } from '../pipes/capitalize-json.pipe';
import { AnimatedDigitComponent } from "../shared/animated-counter/animated-counter.component";
import { StatisticsComponent } from './statistics/statistics.component'
import {OverallStatisticsComponent } from './overall/overall-statistics.component';
import { LocationWiseComponent } from './location-wise/location-wise.component';
import { StatisticsService } from './statistics.service';



const routes: Routes = [
  {
    path: '',
    component: StatisticsComponent
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    FlexLayoutModule,
    NgxLoadingModule,
    MaterialModule,
    SharedModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
    NgMatSearchBarModule,
  ],
  providers: [StatisticsService],
  entryComponents: [FilterComponent],
  declarations: [StatisticsComponent,OverallStatisticsComponent,LocationWiseComponent,FormatJson,AnimatedDigitComponent]
})
export class StatisticsPageModule {}