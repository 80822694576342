import { ControlValueAccessor } from '@angular/forms';
import { Component, Input } from '@angular/core';

@Component({ template: '' })
export class AbstractNgModelComponent<T = any> implements ControlValueAccessor {
    @Input()
    set value(value: T) {
        this._value = value;
        this.notifyValueChange();
    }

    get value(): T {
        return this._value;
    }

    onChange: (value: T) => {};
    onTouched: () => {};

    protected _value: T;

    notifyValueChange(): void {
        if (this.onChange) {
            this.onChange(this.value);
        }
    }

    writeValue(value: T): void {
        this._value = value;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
}
