import {isEmpty, forEach} from 'lodash';

export class UrlUtils {
    static mapToQueryString(map: any) {
        let qString = '';
        if (map && !isEmpty(map)) {
            forEach(map, (value, key) => {
                qString += `${key}=${value}` + '&';
            });
            qString = qString.substring(0, qString.length - 1);
        }
        return qString;
    }
}
