import {Component, forwardRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor, FormArray, FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    NgForm,
    ValidationErrors,
    Validator, Validators
} from '@angular/forms';
import {FormValidationService} from '../form-validation.service';
import {Subscription} from 'rxjs';
import {forEach} from 'lodash';

interface IHighlightRange {
    start: number;
    end: number;
    color: string;
}

@Component({
    selector: 'highlight-range',
    templateUrl: './highlight-ranges.component.html',
    styleUrls: ['./highlight-ranges.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => HighlightRangesComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => HighlightRangesComponent),
            multi: true,
        }
    ]
})
export class HighlightRangesComponent implements OnInit, ControlValueAccessor, Validator, OnDestroy {
    @Input() ranges: IHighlightRange[];
    rangesForm =  new FormArray([]);
    onChange: (value: any) => {};
    onTouched: () => {};
    formSubscription: Subscription;
    constructor(private formValidationService: FormValidationService) {}

    ngOnInit(): void {
    }

    writeValue(value: any): void {
        this.ranges = value || [];
        this.setupForm(value);

        this.formSubscription = this.rangesForm.valueChanges.subscribe((formValueChange) => {
            forEach(this.rangesForm.controls, (rangeForm: FormGroup, idx) => {
                const range = this.ranges[idx];
                range.start = rangeForm.get('start').value;
                range.end = rangeForm.get('end').value;
                range.color = rangeForm.get('color').value;
            });
            this.onChange(this.ranges);
        });
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    removeRange(idx) {
        this.ranges.splice(idx, 1);
        this.rangesForm.removeAt(idx);
    }

    setupForm(ranges) {
        forEach(ranges, (range, idx) => {
            this.addFormGroupForRange(range);
        });
    }

    addFormGroupForRange(range) {
        const newGroup = new FormGroup({
            start: new FormControl(range.start, [Validators.required]),
            end: new FormControl(range.end, [Validators.required]),
            color: new FormControl(range.color || '', [Validators.required])
        })
        this.rangesForm.push(newGroup);
    }

    onColorChanged($event, idx) {
        (<FormGroup>this.rangesForm.at(idx)).get('color').setValue($event);
    }

    addRange() {
        const range = {
            start: undefined,
            end: undefined,
            color: ''
        };

        this.ranges.push(range);
        this.addFormGroupForRange(range);

    }

    validate(control: AbstractControl): ValidationErrors | null {
        return this.formValidationService.collectControlErrors(this.rangesForm);
    }

    ngOnDestroy(): void {
        if (this.formSubscription) {
            this.formSubscription.unsubscribe();
        }
    }

}
