import {Component, forwardRef, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, NgForm, ValidationErrors, Validator} from '@angular/forms';
import {MonitorFactory} from '../../../monitor.factory';
import {cloneDeep} from 'lodash';
import {FormValidationService} from '../../../../shared/form-validation.service';

@Component({
    selector: 'multi-condition-editor',
    templateUrl: './multi-condition-editor.component.html',
    styleUrls: ['./multi-condition-editor.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MultiConditionEditorComponent),
            multi: true
        }, {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => MultiConditionEditorComponent),
            multi: true,
        }]
})

export class MultiConditionEditorComponent implements OnInit, ControlValueAccessor, Validator {
    conditions;
    @ViewChild('allConditionsForm', {static: true}) allConditionsForm: NgForm;
    onChange: any = () => {
    }
    onTouched: any = () => {
    }
    constructor(private conditionFactory: MonitorFactory,
                private formValidation: FormValidationService) {}

    ngOnInit(): void {

        this.allConditionsForm.valueChanges.subscribe(val => {
            this.onChange(this.conditions);
        });
    }

    // initCondition() {
    //     this.newCondition = this.conditionFactory.newCondition();
    // }

    registerOnChange(fn) {
        this.onChange = fn;
    }

    registerOnTouched(fn) {
        this.onTouched = fn;
    }

    registerOnValidatorChange(fn: () => void): void {
    }

    validate(control: AbstractControl): ValidationErrors | null {

        return this.formValidation.collectFormErrors(this.allConditionsForm);
    }

    writeValue(obj: any): void {
        if (obj) {
            this.conditions = obj;
            if (this.conditions.length === 0) {
                this.addCondition();
            }
        }
    }

    removeCondition(index) {
        this.conditions.splice(index, 1);
    }

    addCondition() {
        this.conditions.push(this.conditionFactory.newCondition());
        // this.initCondition();
        // this.onChange(this.conditions);

    }
}
