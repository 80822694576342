import {AbstractGraphDataProvider, IGraph, ITimeRange} from '../graph.component';
import {XYSeriesGraphSpec} from './xyseries.graph.spec';
import {IDataSeries, IDataSeriesQuery, IDataSeriesQueryResult} from '../../deviceData/device-data.service';
import * as moment from 'moment';
import {forEach, find} from 'lodash';
import {Injectable} from '@angular/core';
import {SensorService} from '../../sensors/sensors.service';


@Injectable()
export class XYGraphDataProvider extends AbstractGraphDataProvider<IDataSeriesQueryResult> {

    merge(current: IDataSeriesQueryResult, increment: IDataSeriesQueryResult): IDataSeriesQueryResult {
        forEach(increment.responses, (iResponse) => {
            const deviceId = iResponse.device.id;
            const foundResponse = find(current.responses, (cResponse) => {
              return cResponse.device.id === deviceId;
            });
            if (foundResponse) {
                foundResponse.times = foundResponse.times.concat(iResponse.times);
                forEach(iResponse.series, (iSeries: IDataSeries) => {
                    const foundSeries = find(foundResponse.series, (series: IDataSeries) => {
                        return series.field === iSeries.field && series.platformDeviceId === iSeries.platformDeviceId;
                    });
                    if (foundSeries) {
                        foundSeries.values = foundSeries.values.concat(iSeries.values);
                    }
                });
            }
        })
        return current;
    }

    query(graph: IGraph, fromTimestamp: string): Promise<IDataSeriesQueryResult> {
        // Bundle up the metrics
        const spec = <XYSeriesGraphSpec>graph.spec;
        const query: IDataSeriesQuery = {
            requests: []
        };
        return new Promise((resolve, reject) => {
            forEach(spec.data.y.metrics, metric => {
                const pDid = metric.platformDeviceId;
                if (!pDid) {
                    throw new Error(`Metric is missing platform device id: ${JSON.stringify(metric)}`);
                }
                let found = find(query.requests, (request) => {
                    return request.platformDeviceId === pDid;
                });

                if (!found) {
                    found = {
                        platformDeviceId: pDid,
                        deviceTimestamp: {
                            $gte: fromTimestamp
                        },
                        fields: []
                    };
                    query.requests.push(found);
                }

                const fieldPath = metric.fieldPath;
                const fields = found.fields;
                if (fields.indexOf(fieldPath) === -1) {
                    fields.push(fieldPath);
                }
            });

            this.deviceDataService.seriesQuery(query).then(resolve, reject);
        });

    }



}

