import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AppContext} from '../../app.context';
import {SensorService} from '../sensors.service';
import {NgForm} from '@angular/forms';
import {cloneDeep, isEqual, extend, set} from 'lodash';
import {MessageService} from '../../error/message.service';

class Tag {
    key: string;
    value: string;
}


@Component({
    selector : 'sensor-editor',
    templateUrl: './sensor-editor.component.html',
    styleUrls: ['./sensor-editor.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class SensorEditorComponent implements OnInit {
    copy = null;
    oldTag: Tag;
    newTag: Tag = {key: '', value: ''};
    sensor = null;
    notFound = false;
    sensorId = null;
    constructor(private router: Router,
                private route: ActivatedRoute,
                public sensorService: SensorService,
                public messageService: MessageService) {

    }

    tagOperation: string = "Add";

    working = false;

    ngOnInit(): void {
        const self = this;
        this.sensorId = this.route.snapshot.paramMap.get('sensorId');
        this.working = true;
        this.sensorService.getDevice(this.sensorId).then(function(fetched) {
            self.notFound = false;
            self.sensor = fetched;
            self.copy = cloneDeep(self.sensor);
        }, function(err) {
            if (err.status === 404) {
                self.notFound = true;
                console.log(err);
                self.messageService.handleError(err.error.message, 'Device Not Found');
            }
        }).finally(function() {
            self.working = false;
        });
    }

    cancel() {
        this.close();
    }

    close() {
        this.router.navigate(['sensors']).finally();
    }

    removeTag(tag: Tag) {
        delete this.copy.tags[tag.key];
    }

    clickTag(tag: Tag){
        this.newTag = {
            key: tag.key,
            value: tag.value
        }

        this.oldTag= {
            key: tag.key,
            value: tag.value
        }
        this.tagOperation = "Update";
    }

    exitTagUpdate(){
        this.newTag = {key: '', value: ''};
        this.tagOperation = "Add";
    }

    editTag(){
        delete this.copy.tags[this.oldTag.key];
        set(this.copy, `tags.${this.newTag.key}`, this.newTag.value);
        this.tagOperation = "Add";
        this.newTag = {key: '', value: ''};
    }

    addTag() {
        if(this.tagOperation === "Update")
            this.editTag();
        else{
            set(this.copy, `tags.${this.newTag.key}`, this.newTag.value);
            this.newTag = {key: '', value: ''};
        }
    }

    save(updateForm: NgForm) {
        if (!updateForm.dirty || isEqual(this.sensor, this.copy)) {
            this.close();
        } else {
            // Save, then close
            this.working = true;
            const self = this;
            this.sensorService.update(this.copy).then(function(updated) {
                self.close();
            }).finally(function() {
                self.working = false;
            });
        }
    }
}
