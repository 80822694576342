import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MessageService} from '../error/message.service';
import {OrgService} from '../orgs/org.service';
import {AppContext} from '../app.context';
import {IntegrationsService} from './integrations.service';
import {get} from 'lodash';
import {MatDialog, MatDialogConfig} from '@angular/material';
import {ConfirmDialogComponent} from '../shared/dialogs/confirm/confirm-dialog.component';
import {environment} from '../../environments/environment';

@Component({
    selector: 'integration',
    templateUrl: './integration.component.html',
    styleUrls: ['./integration.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class IntegrationComponent implements OnInit {
    @Input() title;
    @Input() icon;
    @Input() description;
    // @Output() configure = new EventEmitter();
    // @Output() install = new EventEmitter();
    // @Output() remove = new EventEmitter();
    @Input() model;
    working = false;
    constructor(private orgService: OrgService,
                private dialog: MatDialog,
                private messageService: MessageService,
                private appContext: AppContext,
                private router: Router) {}

    ngOnInit() {

    }

    onConfigure() {
        this.router.navigate(['integrations', this.model.installed.id, 'configure']).finally();
    }

    async onRemove() {

        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            title: 'Confirm Delete',
            message: 'Are you sure you want to uninstall this integration?'
        };

        const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
        const self = this;
        dialogRef.afterClosed().subscribe(async (result) => {
            if (result) {
                this.working = true;
                this.orgService.deleteIntegration(this.model.installed.id).then(() => {
                    delete this.model.installed;
                    this.messageService.handleSuccess('The integration has been successfully uninstalled');
                }).finally(() => {
                    this.working = false;
                });
            }
        });
    }

    onInstall() {
        // This can be refactored to an auth handler servicve
        if (this.model.authType === 'oauth2') {
            this.appContext.getUser().then((user) => {
                const clientDetails = get(this.model, `auth.client.${environment.env}`);
                const authInfo = this.model.auth;
                if (!authInfo) {
                    return this.messageService.handleError(`The integration is missing the authorization info.  Consult support for further assistance.`, 'Missing Info');
                }
                if (!clientDetails) {
                    return this.messageService.handleError(`The integration is missing the application client info.  Consult support for further assistance.`, 'Missing Info');
                }
                if (clientDetails) {
                    const url = `${authInfo.authUrl}?audience=${authInfo.audience}&client_id=${clientDetails.clientId}&scope=${authInfo.scopes.join(' ')}&redirect_uri=${clientDetails.callbackUrl}&state=${user.userName}&response_type=code&prompt=consent`;
                    window.location.href = url;
                }
            });
        }
    }
}
