import {Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, Router} from '@angular/router';
import {OrgService} from '../org.service';
import {Form, NgForm} from '@angular/forms';
import {MatTabChangeEvent, MatTabGroup} from '@angular/material';
import {AppContext} from '../../app.context';
import {Roles} from '../../app.settings';

@Component({
    selector: 'edit-org',
    templateUrl: './edit-org.component.html',
    styleUrls: ['./edit-org.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class OrgEditor implements OnInit {
    org;
    working = false;
    selectedIdx = 0;
    hasPermission = true;
    constructor(private route: ActivatedRoute,
                private orgService: OrgService,
                private router: Router,
                private appContext: AppContext) {
        // this.router.routeReuseStrategy.shouldReuseRoute = function(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot) {
        //     return future.routeConfig === curr.routeConfig;
        // };
        // this.appContext.org.subscribe((newMembership) => {
        //     this.init();
        // });
    }

    init() {

        this.appContext.isUserInRole([Roles.ADMIN, Roles.OWNER]).then((answer) => {
            this.hasPermission = answer;
            if (this.hasPermission) {
                const tab = this.route.snapshot.queryParamMap.get('tab');
                this.appContext.getOrganization().then((membership) => {
                    this.working = true;
                    this.orgService.getOrg(membership.orgId, true).then((org) => {
                        this.org = org;
                        this.selectTab(tab);
                        // this.setTabInUrl(tab);
                    }).finally(() => {
                        this.working = false;
                    });
                });
            }
        });


    }

    selectTab(name) {
        // let idx = 0;
        // switch (name) {
        //     case 'general':
        //         idx = 0;
        //         break;
        //     case 'users':
        //         idx = 1;
        //         break;
        //     case 'integrations':
        //         idx = 2;
        //         break;
        //     case 'clientids':
        //         idx = 3;
        //         break;
        //
        // }
        // this.selectedIdx = idx;
    }

    setTabInUrl(name) {
        // this.router.navigate(
        //     [],
        //     {
        //         relativeTo: this.route,
        //         queryParams: { tab: name.toLowerCase() },
        //         queryParamsHandling: 'merge'
        //     });
    }

    ngOnInit() {
        this.init();
    }

    onTabChanged(event: MatTabChangeEvent) {
        // this.setTabInUrl(event.tab.textLabel.replace(/\s/g, '').toLowerCase());
    }
}
