import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppSettings, Services} from '../app.settings';
import {AppContext} from '../app.context';
import {User} from '../users/users.service';
import {OrgMembership} from '../orgs/org.service';
import {forEach} from 'lodash';
import * as moment from 'moment';
import {UnitConversionFactory} from './unitConversion.factory';

export interface IDeviceDataQueryItem {
    platformDeviceId: string;
    query: {
        selector?: any;
        fields: string[];
    };
}

export interface IDeviceDataQuery {
    deviceTimestamp?: {
        $gt: string;
    };
    $and?: IDeviceDataQueryItem[];

    keys?: {
        deviceTimestamp: any,
        platformDeviceId: string
    };
    selector?: any;
    fields?: string[];
}

export interface ILastValueQuery {
    platformDeviceId: string;
    fields?: string[];
}

export interface ILastValueQueryResult {
    item: any;
}

export interface IDeviceDataQueryResultItem {
    platformDeviceId: string;
    Items: any[];
}

export interface IDeviceDataQueryResult {
    groups?: IDeviceDataQueryResultItem[];
    Items: any[];
}

export interface IDataSeriesQuery {
    requests: {
        platformDeviceId: string;
        deviceTimestamp: string;
        fields: string[];
    }[];
}

export interface IDataSeries {
    platformDeviceId: string;
    field: string;
    unit?: string;
    values: any[];
}

export interface IDataSeriesDevice {
    typeId: string;
    deviceId: string;
    name: string;
    id: string;
    orgId: string;
}

export interface IDataSeriesQueryResult {
    responses: {
        device: IDataSeriesDevice,
        series: IDataSeries[],
        times: string[]
    }[];
}

@Injectable()
export class DeviceDataService {

    constructor(
        private http: HttpClient,
        private appSettings: AppSettings,
        private appContext: AppContext,
        private unitConversionFactory: UnitConversionFactory) {
    }

    query(searchQuery: IDeviceDataQuery): Promise<any> {
        const self = this;
        return new Promise(function(resolve, reject) {
            self.appContext.getOrganization().then(function(orgMembership: OrgMembership) {
                const endpoint = self.appSettings.getEndpoint(Services.DEVICE_DATA_FIND, {orgId : orgMembership.orgId});
                self.http.post(endpoint, searchQuery)
                    .subscribe(function (value: User) {
                        if (value) {
                            resolve(value);
                        } else {
                            // Something is wrong on the backend
                            reject(new Error('Url ' + endpoint + ' returned OK yet has null data?'));
                        }
                    }, function(err) {
                        reject(err);
                    });
            }, reject);
        });
    }

    seriesQuery(seriesQuery: IDataSeriesQuery): Promise<IDataSeriesQueryResult> {
        return new Promise((resolve, reject) => {
            this.appContext.getOrganization().then((orgMembership: OrgMembership) => {
                const endpoint = this.appSettings.getEndpoint(Services.SERIES_QUERY, {orgId : orgMembership.orgId});
                this.http.post(endpoint, seriesQuery)
                    .subscribe(async (value: IDataSeriesQueryResult) => {
                        // Try to convert values to the preferred format
                        for (let j = 0; j < value.responses.length; j++) {
                            const result = value.responses[j];
                            const typeId = result.device.typeId;
                            for (let i = 0; i < result.series.length; i++) {
                                const series = result.series[i];
                                const field = series.field;
                                const converter = await this.unitConversionFactory.getUnitConverter(field, typeId);
                                if (converter) {
                                    series.unit = converter.getUnit();
                                    series.values = series.values.map((v) => {
                                        return converter.convert(v);
                                    });
                                }
                            }
                        }
                        resolve(value);
                    }, reject);
            }, reject);
        });
    }

    lastValueQuery(lastValueQuery: ILastValueQuery): Promise<ILastValueQueryResult> {
        return new Promise((resolve, reject) => {
            this.appContext.getOrganization().then((orgMembership: OrgMembership) => {
                const endpoint = this.appSettings.getEndpoint(Services.DEVICE_DATA_FIND, {orgId : orgMembership.orgId});
                // Inject the timestamps and other query parms
                const now = moment();
                const query = {
                    keys: {
                        platformDeviceId: lastValueQuery.platformDeviceId,
                        deviceTimestamp: {
                            $lt: now.toISOString()
                        }
                    },
                    limit: 1,
                    scanIndexForward: false,
                    fields: lastValueQuery.fields
                };
                this.http.post(endpoint, query)
                    .subscribe(function (value: any) {
                        if (value) {
                            resolve({
                                item: value.Items.length > 0 ? value.Items[0] : null
                            });
                        }
                    }, function(err) {
                        reject(err);
                    });
            }, reject);
        });
    }
}

