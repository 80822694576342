import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {NgxLoadingModule} from 'ngx-loading';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MaterialModule} from '../material/material.module';
import {SharedModule} from '../shared/shared.module';
import {AngularResizedEventModule} from 'angular-resize-event';
import {DeviceMetricBindAreaComponent} from './builder/device-metric-bind-area/device-metric-bind-area.component';
import {DeviceMetricBindComponent} from './builder/device-metric-bind/device-metric-bind.component';
import {GraphBuilderStepComponent} from './builder/step/graph-builder-step.component';
import {GraphContainerComponent} from './container/graph-container.component';
import {ChartJSGraphComponent} from './engines/chartjs/chartjs-graph.component';
import {GraphToolItemComponent} from './container/tool-item/graph-tool-item.component';
import {D3GraphComponent} from './engines/d3/d3-graph.component';
import {GraphEditorComponent} from './editor/graph-editor.component';
import {DeclarativeGraphBuilderComponent} from './builder/declarative/declarative-graph-builder.component';
import {GraphBuilderSpecStepComponent} from './builder/declarative/step/graph-builder-spec-step.component';
import {GraphTypeRegistryService} from './graph-type.registry';
import {AbstractGraphBuilder, AbstractGraphDataProvider} from './graph.component';
import {XYGraphDataProvider} from './xyseries/xyseries.dataprovider';
import {PopoverModule} from 'ngx-smart-popover';
import {GaugeDataProvider} from './gauge/gauge.dataprovider';
import {GaugesModule} from 'ng-canvas-gauges';
import {ChartJSLineGraphComponent} from './engines/chartjs/line/chartjs-line-graph.component';
import {NgxChartLineGraphComponent} from './engines/ngx-charts/line/ngx-chart-line.component';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {ColorPickerModule} from 'ngx-color-picker';
import {RadialGaugeViewComponent} from './gauge/radial/radial-gauge.component';
import {LinearGaugeViewComponent} from './gauge/linear/linear-gauge.component';
import {AbstractGaugeViewComponent} from './gauge/gauge.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        NgxLoadingModule,
        FlexLayoutModule,
        MaterialModule,
        ReactiveFormsModule,
        SharedModule,
        AngularResizedEventModule,
        PopoverModule,
        GaugesModule,
        NgxChartsModule,
        ColorPickerModule
    ],
    providers: [
        AbstractGraphDataProvider,
        XYGraphDataProvider,
        GaugeDataProvider,
        GraphTypeRegistryService
    ],
    exports: [
        GraphContainerComponent, GraphEditorComponent
    ],
    declarations: [
        DeviceMetricBindAreaComponent, DeviceMetricBindComponent, GraphBuilderStepComponent,
        GraphContainerComponent, ChartJSGraphComponent, GraphToolItemComponent,
        D3GraphComponent, GraphEditorComponent, DeclarativeGraphBuilderComponent, GraphBuilderSpecStepComponent,
        ChartJSLineGraphComponent, AbstractGraphDataProvider,
        XYGraphDataProvider, AbstractGraphBuilder, GaugeDataProvider,
        NgxChartLineGraphComponent, RadialGaugeViewComponent, LinearGaugeViewComponent, AbstractGaugeViewComponent
    ],
    entryComponents: [
        D3GraphComponent,
        DeclarativeGraphBuilderComponent,
        ChartJSLineGraphComponent,
        NgxChartLineGraphComponent,
        RadialGaugeViewComponent,
        LinearGaugeViewComponent
    ]
})
export class GraphModule {
}
