import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppSettings, Services} from '../app.settings';
import {AppContext} from '../app.context';

export interface INamedQueryComparisonOperator {
    $gt?: string;
    $gte?: string;
    $eq?: string;
    $lt?: string;
    $lte?: string;
}

export const QUERY_JOB_STATES = {
    RUNNING: 'RUNNING',
    QUEUED: 'QUEUED',
    SUCCEEDED: 'SUCCEEDED',
    FAILED: 'FAILED',
    CANCELLED: 'CANCELLED'
}

export interface INamedQuery {
    name: string;
    query: {
        selector: {
            orgId: string,
            deviceTypeId: string,
            platformDeviceId?: string,
        },
        fields: string[]
    };
}

export interface QueryJob {
    name: string;
    dateSubmitted: any;
    status: string;
    id: string;
    query: string;
    results: string;
    timer: any;
}

@Injectable()
export class QueryJobService {

    constructor(
        private http: HttpClient,
        private appSettings: AppSettings,
        private appContext: AppContext) {
    }

    fetchJobs(): Promise<QueryJob[]> {
        return new Promise(async (resolve, reject) => {
            const userId = await this.appContext.getUserId();
            const orgId = await this.appContext.getOrgId();
            const endpoint = this.appSettings.getEndpoint(Services.QUERY_JOBS, {userId: userId});
            this.http.get(endpoint, {params: {orgId: orgId}})
                .subscribe(function (value: any) {
                    resolve(value.Items as QueryJob[]);
                }, reject);
        });
    }

    submitJob(query) {
        return new Promise(async (resolve, reject) => {
            const userId = await this.appContext.getUserId();
            const endpoint = this.appSettings.getEndpoint(Services.QUERY_JOBS, {userId: userId});
            this.http.post(endpoint, query)
                .subscribe(function (value: any) {
                    resolve();
                }, reject);
        });
    }

    getJob(jobId: string): Promise<QueryJob> {
        return new Promise(async (resolve, reject) => {
            const userId = await this.appContext.getUserId();
            const endpoint = this.appSettings.getEndpoint(Services.QUERY_JOB, {userId: userId, jobId: jobId});
            this.http.get(endpoint)
                .subscribe(function (value: any) {
                    resolve(value.Item as QueryJob);
                }, reject);
        });
    }

    deleteJob(jobId: string): Promise<any> {
        return new Promise(async (resolve, reject) => {
            const userId = await this.appContext.getUserId();
            const endpoint = this.appSettings.getEndpoint(Services.QUERY_JOB, {userId: userId, jobId: jobId});
            this.http.delete(endpoint)
                .subscribe(function (value: any) {
                    resolve();
                }, reject);
        });
    }
}
