import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {AppContext} from '../app.context';
import {Monitor, MonitorsService} from './monitors.service';
import {MatDialog, MatDialogConfig, MatTableDataSource} from '@angular/material';
import {EditMonitorComponent} from './edit/edit-monitor.component';
import {MessageService} from '../error/message.service';
import {ConfirmDialogComponent} from '../shared/dialogs/confirm/confirm-dialog.component';
import {Roles} from '../app.settings';
import {OrgUser} from '../orgs/org.service';
import {forEach} from 'lodash';
import {SelectionModel} from '@angular/cdk/collections';

@Component({
    selector : 'monitors',
    templateUrl: './monitors.component.html',
    styleUrls: ['./monitors.component.scss'],
    encapsulation: ViewEncapsulation.None,
    host: {class: 'main-page-content'},
})

export class MonitorsComponent implements OnInit {
    loading = false;
    monitors = [];
    working = false;
    canEdit = false;
    dataSource = new MatTableDataSource<Monitor>();
    displayedColumns: string[] = ['select', 'name', 'status'];
    selection = new SelectionModel<Monitor>(true, []);
    constructor(private router: Router,
                private monitorsService: MonitorsService,
                private messageService: MessageService,
                private dialog: MatDialog,
                private appContext: AppContext) {}

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    masterToggle() {
        this.isAllSelected() ?
            this.selection.clear() :
            this.dataSource.data.forEach(row => this.selection.select(row));
    }

    isAllSelectedDisabled() {
        let allDisabled = true;
        if (this.selection.selected.length === 0) {
            allDisabled = false;
        } else {

            forEach(this.selection.selected, (monitor) => {
                if (monitor.enabled) {
                    allDisabled = false;
                    return false;
                }
            });
        }
        return allDisabled;
    }

    isAllSelectedEnabled() {
        let allEnabled = true;
        if (this.selection.selected.length === 0) {
            allEnabled = false;
        }
        forEach(this.selection.selected, (monitor) => {
            if (!monitor.enabled) {
                allEnabled = false;
                return false;
            }
        });
        return allEnabled;
    }


    fetchMonitors(showLoading?) {
        const self = this;
        if (showLoading) {
            this.loading = true;
        }
        this.monitorsService.getMonitors().then((monitors) => {
            this.dataSource.data = monitors.sort(function(a, b) {
                return a.name.localeCompare(b.name);
            });
        }).finally(function() {
            self.loading = false;
        });
    }

    async enableOrDisable() {
        this.working = true;
        try {
            for (const monitor of this.selection.selected) {
                const originalVal = monitor.enabled;
                monitor.enabled = !monitor.enabled;
                try {
                    await this.monitorsService.updateMonitor(monitor);
                } catch (e) {
                    monitor.enabled = originalVal;
                }
            }
        } finally {
            this.working = false;
            this.selection.clear();
        }

    }

    delete() {
        const monitor = this.selection.selected[0];
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            title: 'Confirm Delete',
            message: 'Are you sure you want to remove the selected monitor?'
        };

        const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
        const self = this;
        dialogRef.afterClosed().subscribe(async function (result) {
            if (result) {
                self.working = true;
                self.monitorsService.deleteMonitor(monitor.id).then(function() {
                    self.messageService.handleSuccess(`The monitor '${monitor.name}' was successfully deleted!`);
                    self.selection.clear();
                }).finally(function() {
                    self.fetchMonitors();
                    self.working = false;
                });
            }
        });
    }

    edit() {
        const monitor = this.selection.selected[0];
        this.router.navigate(['monitors', monitor.id, 'edit'], {state: monitor}).finally();
    }

    createMonitor() {
        this.router.navigate(['monitors', 'create']).finally();
    }

    ngOnInit(): void {
        this.fetchMonitors(true);
        this.appContext.isUserInRole([Roles.ADMIN, Roles.OWNER, Roles.USER]).then((answer) => {
            this.canEdit = answer;
        });
    }

}
