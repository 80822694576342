import {
    Component,
    EventEmitter,
    forwardRef,
    Input,
    OnChanges, OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {OrgDeviceType} from '../../../orgs/org.service';
import {MatDialog, MatDialogConfig} from '@angular/material';

import {AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, NgForm, ValidationErrors, Validator} from '@angular/forms';
import {get, set} from 'lodash';
import {FormValidationService} from '../../../shared/form-validation.service';
import {Device} from '../../../sensors/sensors.service';
import {DeviceMetric} from '../../graph.component';

export interface DeviceMetricConfig {
    fieldPath?: string;
}

@Component({
    selector: 'device-metric-bind',
    templateUrl: './device-metric-bind.component.html',
    styleUrls: ['./device-metric-bind.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DeviceMetricBindComponent),
            multi: true
        }, {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => DeviceMetricBindComponent),
            multi: true,
        }
    ]
})
export class DeviceMetricBindComponent implements OnInit, ControlValueAccessor, Validator, OnDestroy {

    @Input('value') deviceMetric: DeviceMetric;

    @Output() metricChanged = new EventEmitter<DeviceMetric>();

    @ViewChild('metricForm', {static: true}) metricForm: NgForm;
    formChangeSubscription;
    onChange: any = () => { };
    onTouched: any = () => { };

    constructor(private dialog: MatDialog,
                private formValidation: FormValidationService) {
        this.deviceMetric = {} as DeviceMetric;
    }

    validate(control: AbstractControl): ValidationErrors {
        return this.formValidation.collectFormErrors(this.metricForm);
    }

    onModelChange($event) {
        this.onChange(this.deviceMetric);
        this.onTouched();
        // Only fire if there is a type, id, and field path and valid form
        if (this.metricForm.valid
            && get(this.deviceMetric, 'sensor.typeId')
            && get(this.deviceMetric, 'sensor.id')
            && get(this.deviceMetric, 'config.fieldPath')) {
            this.metricChanged.emit(this.deviceMetric);
        }
    }

    ngOnInit() {

        this.formChangeSubscription = this.metricForm.valueChanges.subscribe(val => {
            this.onChange(this.deviceMetric);
            this.onTouched();
        });
    }

    setDisabledState(isDisabled: boolean): void {}

    writeValue(value) {
        if (value) {
            this.deviceMetric = value;
        }
    }

    registerOnChange(fn) {
        this.onChange = fn;
    }

    registerOnTouched(fn) {
        this.onTouched = fn;
    }

    ngOnDestroy(): void {
        if (this.formChangeSubscription) {
            this.formChangeSubscription.unsubscribe();
        }
    }
}
