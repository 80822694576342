import { Injectable } from '@angular/core';
import {Router, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot} from '@angular/router';
import {AppContext} from '../app.context';
import {Roles} from '../app.settings';
import {Observable} from 'rxjs';


@Injectable()
export class AdminGuardService implements CanActivate {

    constructor(public appContext: AppContext) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>|boolean {
        return new Observable<boolean>((observer) => {
            this.appContext.isUserInRole([Roles.READ_ONLY]).then((answer) => {
                observer.next(answer);
            }, () => {
                observer.next(false);
            });
        });
    }

}
