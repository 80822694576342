import {Component, OnChanges, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {GraphLegendService, IGraphLegend} from '../../shared/legend.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'graph-legend',
    templateUrl: './legend.component.html',
    styleUrls: ['./legend.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class GraphLegendComponent implements OnInit, OnDestroy {
    legendSubscription: Subscription;
    legend: IGraphLegend;
    constructor(private legendService: GraphLegendService) {
        this.legendSubscription = this.legendService.legend.subscribe((newLegend) => {
            this.legend = newLegend;
        });
    }
    ngOnDestroy(): void {
        if (this.legendSubscription) {
            this.legendSubscription.unsubscribe();
        }
    }

    ngOnInit(): void {
    }

}
