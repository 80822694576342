import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { MessageService } from 'src/app/error/message.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ChangePasswordComponent } from '../../dialogs/change-password/change-password.component';


@Component({
  selector: 'change-password-menu-item',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ChangePasswordMenuItemComponent implements OnInit {

  @Input() user;

    constructor(
        private messageService: MessageService,
        private dialog: MatDialog,
        ) {}

        changePassword() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            user: this.user
        };
        const dialogRef = this.dialog.open(ChangePasswordComponent, dialogConfig);
        const self = this;
        dialogRef.afterClosed().subscribe(async function (result) {
            if (result) {
                const message = 'The Password has been successfully updated!';
                self.messageService.handleSuccess(message);
            }
        });
    }

  ngOnInit() {}

}
