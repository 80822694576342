import {Component, forwardRef, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, NgForm, ValidationErrors, Validator} from '@angular/forms';
import {MonitorFactory} from '../../../monitor.factory';
import {isEmpty} from 'lodash';
import {FormValidationService} from '../../../../shared/form-validation.service';

@Component({
    selector: 'single-condition-editor',
    templateUrl: './single-condition-editor.component.html',
    styleUrls: ['./single-condition-editor.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SingleConditionEditorComponent),
            multi: true
        }, {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => SingleConditionEditorComponent),
            multi: true,
        }]
})

export class SingleConditionEditorComponent implements OnInit, ControlValueAccessor, Validator {
    conditions;
    currentCondition = null;

    onChange: any = () => {
    }
    onTouched: any = () => {
    }
    @ViewChild('conditionForm', {static: true}) conditionForm: NgForm;
    constructor(private monitorFactory: MonitorFactory,
                private formValidation: FormValidationService) {}

    ngOnInit(): void {
        this.conditionForm.valueChanges.subscribe(val => {
            this.onChange(this.conditions);
        });
    }

    registerOnChange(fn) {
        this.onChange = fn;
    }

    registerOnTouched(fn) {
        this.onTouched = fn;
    }


    registerOnValidatorChange(fn: () => void): void {
    }

    setDisabledState(isDisabled: boolean): void {
    }

    validate(control: AbstractControl): ValidationErrors | null {
        return this.formValidation.collectFormErrors(this.conditionForm);
    }

    writeValue(value: any): void {
        if (value) {
            this.conditions = value;
            if (isEmpty(this.conditions)) {
                this.conditions.push(this.monitorFactory.newCondition());
            }
            this.currentCondition = this.conditions[0];
        }
    }
}
