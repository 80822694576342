import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {StatisticsService} from '../statistics.service';

@Component({
  selector: 'app-overall-statistics',
  templateUrl: './overall-statistics.component.html',
  styleUrls: ['./overall-statistics.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OverallStatisticsComponent implements OnInit {

  constructor(private statisticsService: StatisticsService) {}

  @Input()   statistics = {
    "Locations": -1,
    "Buildings": -1,
    "Assets": -1,
    "ActiveAlerts": -1,
    "CriticalAssets": -1,
    "OfflineAssets": -1
  };

  ngOnInit():void {
 
  }
}