import { Injectable } from '@angular/core';
import { MapsAPILoader } from '@agm/core';

import { filter, catchError, tap, map, switchMap } from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {fromPromise} from 'rxjs/internal-compatibility';


declare var google: any;

export interface Location {
    lat: number;
    lng: number;
    address?: string;
}

export enum CoordinateMethod {
    MANUAL = 'manual',
    MAP = 'map'
}

@Injectable()
export class GeocodeService {
    private geocoder: any;
    private geoCache = {};
    constructor(private mapLoader: MapsAPILoader) {}

    private initGeocoder() {
        this.geocoder = new google.maps.Geocoder();
    }

    private waitForMapsToLoad(): Promise<boolean> {
        if (!this.geocoder) {
            return new Promise((resolve, reject) => {
                this.mapLoader.load().then(() => {
                    this.initGeocoder();
                    resolve(true);
                });
            });
        }
        return Promise.resolve(true);
    }

    geocodeAddress(location: string): Promise<Location> {
        return new Promise((resolve, reject) => {
            this.waitForMapsToLoad().then(() => {
                const cachedValue = this.geoCache[location];
                if (cachedValue) {
                    return resolve(cachedValue);
                }
                this.geocoder.geocode({'address': location}, (results, status) => {
                    if (status === google.maps.GeocoderStatus.OK) {
                        const geoLocation = {
                            lat: results[0].geometry.location.lat(),
                            lng: results[0].geometry.location.lng(),
                            address: results[0].formatted_address
                        };
                        this.geoCache[location] = geoLocation;
                        resolve(geoLocation);
                    } else {
                        console.log('Error - ', results, ' & Status - ', status);
                        reject({ lat: 0, lng: 0 });
                    }
                });
            });
        });
    }
}