import {Component, OnInit, Type, ViewEncapsulation} from '@angular/core';
import {IGraphBuilderSpec, IGraphContext, IGraphTypeDescriptor, IGraphView} from '../../graph.component';
import * as uuid from 'uuid';
import {
    AbstractGaugeViewComponent, BaseBuilder,
    BaseBuilderOptionsStep,
    GaugeGraphSpec, GaugeGraphTypeDescriptor,
    SelectMetricBuilderStep
} from '../gauge.component';
import {LinearGauge} from 'canvas-gauges';


@Component({
    selector: 'sen-linear-gauge',
    templateUrl: './linear-gauge.component.html',
    styleUrls: ['./linear-gauge.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class LinearGaugeViewComponent extends AbstractGaugeViewComponent implements OnInit {
    gaugeId = uuid();
    gauge;
    timestamp;
    buildGauge(options: any, value: any, timestamp: any) {
        this.timestamp = timestamp;
        if (this.gauge) {
            this.gauge.update(options);
        } else {
            options.renderTo = this.gaugeId;
            this.gauge = new LinearGauge(options);

            this.gauge.draw();
        }
        this.gauge.value = value;
    }
}

export class LinearGaugeGraphTypeDescriptor extends GaugeGraphTypeDescriptor {
    constructor() {
        super('linear-gauge', 'Linear Gauge', LinearGaugeViewComponent, 'linear-gauge.png');
    }
}

