import {Component, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Device} from '../sensors.service';

@Component({
    selector : 'sensor-detail',
    templateUrl: './sensor-detail.component.html',
    styleUrls: ['./sensor-detail.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class SensorDetailComponent {
    @Input() device: Device = null;
    @Input() tagFilter = null;
    constructor() {}


}
