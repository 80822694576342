import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppSettings, Services} from '../app.settings';
import {IDashboard} from '../dashboards/dashboards.service';
import {map, publishReplay, refCount, shareReplay} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Injectable()
export class DeviceTypeService {
    schemaCache: { [key: string]: Observable<any> } = {};
    deviceTypesCache: Observable<any>;
    constructor(private http: HttpClient, private appSettings: AppSettings) {}

    getDashboard(typeId): Promise<IDashboard> {
        const endpoint = this.appSettings.getEndpoint(Services.DEVICE_TYPE_DASHBOARD, {deviceTypeId: typeId});
        const self = this;
        return new Promise(function(resolve, reject) {
            self.http.get(endpoint)
                .subscribe(function (value: any) {
                    if (value) {
                        value.preset = true;
                        resolve(value as IDashboard);
                    } else {
                        // Something is wrong on the backend
                        reject(new Error('Url ' + endpoint + ' returned OK yet has null data?'));
                    }
                }, function(err) {
                    reject(err);
                });
        });

    }

    getSchema(typeId): Promise<any> {
        const endpoint = this.appSettings.getEndpoint(Services.SCHEMA, {typeId: typeId});
        let obs = this.schemaCache[typeId];
        if (!obs) {
            obs = this.http.get(endpoint).pipe(
                map(res => res),
                publishReplay(1),
                refCount());
            this.schemaCache[typeId] = obs;
        }
        return new Promise((resolve, reject) => {
            obs.subscribe((value) => {
                resolve(value);
            }, reject);
        });
    }

    getDeviceTypes(): Promise<any> {
        const endpoint = this.appSettings.getEndpoint(Services.DEVICE_TYPES);
        if (!this.deviceTypesCache) {
            this.deviceTypesCache = this.http.get(endpoint).pipe(
                map(res => res),
                publishReplay(1),
                refCount());
        }

        return new Promise((resolve, reject) => {
            this.deviceTypesCache.subscribe(function (value: any) {
                    resolve(value.Items);
                }, reject);
        });
    }


}
