// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import {NgxLoggerLevel} from 'ngx-logger';

export const environment = {
  production: false,
  gtmId: 'GTM-PNDXVKV',
  gaId: 'UA-144613536-4',
  env: 'prod',
  logConfig: undefined,
  services : {
    url : 'https://api.m-plus.io'
  }
};

/*
 *List of URLs for different testing purposes only
 * https://staging.api.sentrics.io' //Staging env
 * https://d4zijf30w0.execute-api.me-south-1.amazonaws.com/prod //API Gateway of Prod env
 * https://api.m-plus.io  // Prod env
 * https://test.api.sentrics.io //Test env
*/

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.