import {RouterModule, Routes} from '@angular/router';
import {SensorsPage} from '../sensors/sensors.page';
import {NgModule} from '@angular/core';
import {CommonModule, TitleCasePipe} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {FlexLayoutModule} from '@angular/flex-layout';
import {NgxLoadingModule} from 'ngx-loading';
import {MaterialModule} from '../material/material.module';
import {SharedModule} from '../shared/shared.module';
import {QueryJobsComponent} from './queries.component';
import {QueryJobService} from './query-job.service';
import {QueryBuilderDialogComponent} from './builder/query-builder-dialog.component';
import {NgxDaterangepickerMd} from 'ngx-daterangepicker-material';
import {QueryBuilderModule} from 'angular2-query-builder';
import {ProgressDialogComponent} from '../shared/dialogs/progress-dialog/progress-dialog.component';

const routes: Routes = [
    {
        path: '',
        component: QueryJobsComponent
    }
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        FlexLayoutModule,
        NgxLoadingModule,
        MaterialModule,
        SharedModule,
        ReactiveFormsModule,
        QueryBuilderModule,
        NgxDaterangepickerMd.forRoot(),
        RouterModule.forChild(routes)
    ],
    providers: [QueryJobService],
    declarations: [QueryJobsComponent, QueryBuilderDialogComponent],
    entryComponents: [QueryBuilderDialogComponent, ProgressDialogComponent]
})
export class QueryJobsModule {}
