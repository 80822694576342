import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, Validators} from '@angular/forms';
import {Component, forwardRef, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {AbstractNgModelComponent} from '../control/control.component';

@Component({
    selector: 'color-picker',
    templateUrl: './color-picker.component.html',
    styleUrls: ['./color-picker.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ColorPickerControlComponent),
            multi: true
        }
    ]
})
export class ColorPickerControlComponent implements OnInit, ControlValueAccessor {
    @Input() required: boolean;
    @Input() placeholder: string;
    @Input() label: string;
    colorInput = new FormControl('', []);
    onChange: (value: any) => {};
    onTouched: () => {};
    color = '';
    onChanged($event) {
        this.color = $event;
        this.colorInput.setValue($event);
        this.onChange($event);
    }

    ngOnInit(): void {

    }

    writeValue(value: any): void {
        this.color = value ? value : '';
        this.colorInput.setValue(value);
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

}
