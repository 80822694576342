import { NewUser, UserService } from './../../users/users.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'src/app/error/message.service';
import { OrgService } from 'src/app/orgs/org.service';
import { merge, pick } from 'lodash';
import {AppContext} from '../../app.context';

@Component({
    selector: 'create-or-join',
    templateUrl: './create-join.component.html',
    styleUrls: ['./create-join.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CreateOrJoinComponent {

}
