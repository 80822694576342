import { NgModule } from '@angular/core';
import {CommonModule, TitleCasePipe} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { IonicModule } from '@ionic/angular';
import { NgxLoadingModule } from 'ngx-loading';
import { MaterialModule} from '../material/material.module';
import {SharedModule} from '../shared/shared.module';
import {ProfileComponent} from './profile.component';

const routes: Routes = [
    {
        path: '',
        component: ProfileComponent
    }
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        FlexLayoutModule,
        NgxLoadingModule,
        MaterialModule,
        SharedModule,
        ReactiveFormsModule,
        RouterModule.forChild(routes)
    ],
    providers: [],
    declarations: [ProfileComponent]
})
export class ProfileModule {}
