import { forEach, isNil, keys, startsWith } from 'lodash';
import { OrgService } from './../../orgs/org.service';
import { MessageService } from 'src/app/error/message.service';
import { SensorService, Device } from './../sensors.service';
import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output, Pipe, PipeTransform, ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { Router } from '@angular/router';
import {MatInput, MatSelect} from '@angular/material';
import {AbstractControl} from '@angular/forms';
import {DeviceTypeService} from '../../deviceType/deviceType.service';

@Pipe({
    name: 'typeNameGroup'
})

export class TypeNameFilterPipe implements PipeTransform {
    transform(name: string, group: string): string {
        if (startsWith(name, group)) {
            return name.substring(group.length).trim();
        }
        return name;
    }
}


@Component({
  selector: 'register-sensor',
  templateUrl: './register-sensor.component.html',
  styleUrls: ['./register-sensor.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RegisterSensorComponent implements OnInit {
  working = false;
  types = null;
  typeGroups = null;
  type = 'Select';
  selectedType = null;
  device = {} as Device;
  @ViewChild('nameRef', {static: false}) public nameCtrl: AbstractControl;
  constructor(
    private sensorService: SensorService,
    private deviceTypeService: DeviceTypeService,
    private messageService: MessageService,
    private orgService: OrgService,
    private router: Router
  ) {}

  cancel() {
    this.router.navigate(['sensors']).finally();
  }

  ok() {
    this.working = true;
    this.sensorService.registerDevice(this.device).then(() => {
      this.messageService.handleSuccess(
        'Device has been registered successfully!'
      );
      this.router.navigate(['sensors']).finally();
    }).finally(() => {
        this.working = false;
    });
  }

  normalizeManufacturer(man) {
      if (!man) {
          return man;
      }
      const match = /[C|c]orp/.exec(man);
      if (match) {
          return man.substring(0, match.index).trim();
      }
      return man;
  }

  onDeviceTypeSelected() {
      this.device.typeId = this.selectedType.id;
      this.computeDeviceName();
  }

  ngOnInit() {
    this.device.deviceId = '';
    this.device.typeId = '';
    this.device.name = '';
    this.working = true;
    Promise.all([this.deviceTypeService.getDeviceTypes()]).then((results) => {
        this.types = results[0].filter(function(item) {
            return !isNil(item.name);
        });
        const groups = {};
        forEach(this.types, (type) => {
            const groupName = this.normalizeManufacturer(type.manufacturer) || 'Unknown';
            const list = groups[groupName] = groups[groupName] || [];
            list.push(type);
        });
        const sortedKeys = keys(groups).sort((a, b) => {
            return a.localeCompare(b);
        });
        const sortedGroups = {};
        forEach(sortedKeys, (key) => {
            sortedGroups[key] = groups[key].sort((a, b) => {
                return a.name.localeCompare(b.name);
            });
        });
        this.typeGroups = sortedGroups;

    }).finally(() => {
        this.working = false;
    });
  }

  computeDeviceName() {
    const self = this;
    if (this.nameCtrl.pristine) {
        const found = self.types.find(function(type) {
            return type.id === self.device.typeId;
        });
        // This hasnt been typed in so feel free to set the name
        this.device.name = (found ? found.name + ' ' : '') + (this.device.deviceId ? this.device.deviceId : '');
    }

  }
}
