import {Component, Inject, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatButton, MatDialogRef} from '@angular/material';

const DEFAULT_PATTERN = {
    expression : '.*',
    error : 'Invalid pattern'
};

@Component({
    selector: 'input-dialog',
    templateUrl: './input-dialog.component.html',
    styleUrls: ['./input-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class InputDialogComponent {
    title: string;
    label: string;
    description: string;
    input = null;
    placeholder = null;
    pattern = null;
    type = null;

    constructor(
        private dialogRef: MatDialogRef<InputDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data) {
        this.title = data.title;
        this.description = data.description;
        this.label = data.label;
        this.placeholder = data.placeholder || 'Enter value';
        this.pattern = data.pattern || DEFAULT_PATTERN;
        this.type = data.type || 'text';
    }

    ok() {
        this.dialogRef.close(this.input);
    }

    cancel() {
        this.dialogRef.close();
    }
}
