import {Component, Input, ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'org-editor-page',
    templateUrl: './edit-page.component.html',
    styleUrls: ['./edit-page.component.scss'],
    encapsulation: ViewEncapsulation.None
})



export class OrgEditorPage {
    @Input('title') title;
    @Input('description') description;
    constructor() {}
}
