import {HttpClient} from '@angular/common/http';
import {AppSettings, Services} from '../app.settings';
import {Injectable} from '@angular/core';
import {get} from 'lodash';

export interface UserPreferences {
    defaultOrg: string;
}

@Injectable()
export class PreferencesService {
    constructor(
        private http: HttpClient,
        private appSettings: AppSettings) {
    }

    async getPreferences(userId): Promise<any> {
        const self = this;
        return new Promise(function (resolve, reject) {
            const endpoint = self.appSettings.getEndpoint(Services.PREFERENCES, {userId: userId});

            self.http.get(endpoint)
                .subscribe(function (value: any) {
                    resolve(get(value, 'Item.preferences'));
                }, function(err) {
                    if (err.status === 404) {
                        return resolve({});
                    }
                    reject(err);
                });

        });
    }

    updatePreferences(userId, preferences): Promise<any> {
        const self = this;
        return new Promise(function (resolve, reject) {
            const endpoint = self.appSettings.getEndpoint(Services.PREFERENCES, {userId: userId});
            self.http.put(endpoint, preferences)
                .subscribe(function () {
                    resolve();
                }, reject);
        });

    }

}
