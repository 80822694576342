import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';
import {AlarmsService} from './alarms.service';
import {AppSettings} from '../app.settings';


@Injectable()
export class AlarmUpdateService {
    private alertsSource = new Subject<any>();
    public alerts = this.alertsSource.asObservable();
    constructor(private alarmService: AlarmsService,
                private appSettings: AppSettings) {
        // this.poll();
    }

    poll() {
        // const self = this;
        // const fn = self.poll.bind(this);
        // self.alarmService.getAlarms().then(function(alarms) {
        //     const alarmMenuItem = AppSettings.MENU_ITEMS.find(function(item) {
        //         return item.id === AppSettings.MENU_ITEM_IDS.MONITORS;
        //     });
        //     alarmMenuItem.badgeCount = alarms.length;
        //     self.alertsSource.next(alarms);
        //     setTimeout(fn, 8000);
        // });
    }
}
