import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {isArray} from 'lodash';
import {MatTableDataSource} from '@angular/material';
import {QueryJob} from '../../../queries/query-job.service';
import {SelectionModel} from '@angular/cdk/collections';
import {Device} from '../../sensors.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';

@Component({
    selector : 'sensor-group',
    templateUrl: './sensor-group.component.html',
    styleUrls: ['./sensor-group.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ]
})

export class SensorGroupComponent implements OnInit, OnDestroy {
    @Input() group: any;
    @Input() level = 1;
    @Input() tagFilter = null;
    @Input() groupName;
    @Output() selectionChanged = new EventEmitter();
    displayedColumns: string[] = ['select', 'name', 'status', 'actions'];
    dataSource = new MatTableDataSource<Device>();
    selection = new SelectionModel<Device>(true, []);
    expandedElement: Device | null;
    selectionChangeSubscription: Subscription;
    icon = true;

    constructor(private router: Router) {}

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    masterToggle() {
        this.isAllSelected() ?
            this.selection.clear() :
            this.dataSource.data.forEach(row => this.selection.select(row));
    }

    ngOnInit(): void {
        if (this.group) {
            this.group.sort((a, b) => {
                return a.name.localeCompare(b.name);
            });

            this.dataSource.data = this.group;
        }

        this.selectionChangeSubscription = this.selection.changed.asObservable().subscribe((event) => {
            this.selectionChanged.emit(event);
        });
    }

    ngOnDestroy(): void {
        if (this.selectionChangeSubscription) {
            this.selectionChangeSubscription.unsubscribe();
        }
    }

    showGraph($event, sensor) {
        $event.stopPropagation();

        this.router.navigate(['/dashboards', 'deviceType', sensor.typeId, 'device', sensor.id]).finally();
    }
}
