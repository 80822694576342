import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {Routes, RouterModule} from '@angular/router';
import {IonicModule} from '@ionic/angular';
import {CustomDashboardPage, DashboardPage, DeviceDashboardPage, DeviceTypeDashboardPage} from './dashboard.page';
import {NgxLoadingModule} from 'ngx-loading';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MaterialModule} from '../material/material.module';
import {LoggerModule} from 'ngx-logger';
import {DashboardsLogService} from './dashboard.logger';
import {DeviceTypeService} from '../deviceType/deviceType.service';
import {MessageService} from '../error/message.service';
import {environment} from '../../environments/environment';
import {DeviceDataService} from '../deviceData/device-data.service';
import {SharedModule} from '../shared/shared.module';
import {DashboardsService} from './dashboards.service';
import {AngularResizedEventModule} from 'angular-resize-event';
import {DashboardListComponent} from './list/dashboard-list.component';
import {CreateDashboardComponent} from './create/create-dashboard.component';
import {GraphModule} from '../graph/graph.module';
import {GraphContainerComponent} from '../graph/container/graph-container.component';
import {GraphEditorComponent} from '../graph/editor/graph-editor.component';
import {NgGridModule} from 'angular2-grid';
import {LayoutModule} from '@angular/cdk/layout';
import {GraphDialogComponent} from './graph-dialog/graph-dialog.component';
import {DashboardGridLayoutComponent} from './layout/grid/dashboard-grid-layout.component';
import {GridsterModule} from 'angular2gridster';
import {DashboardGridsterLayoutComponent} from './layout/gridster/dashboard-gridster-layout.component';
import {GraphLegendService} from '../shared/legend.service';
import {GraphLegendComponent} from './legend/legend.component';
import {ResizableModule} from 'angular-resizable-element';


const routes: Routes = [
    {path: '', redirectTo: 'home', pathMatch: 'full'},
    {path: 'home', component: DashboardPage}
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild(routes),
        NgxLoadingModule,
        FlexLayoutModule,
        MaterialModule,
        LoggerModule.forRoot(environment.logConfig),
        ReactiveFormsModule,
        SharedModule,
        AngularResizedEventModule,
        GraphModule,
        NgGridModule,
        LayoutModule,
        GridsterModule.forRoot(),
        ResizableModule
    ],
    providers: [
        DashboardsLogService,
        DeviceTypeService,
        MessageService,
        DeviceDataService,
        DashboardsService,
        DatePipe
    ],
    exports: [
        CustomDashboardPage
    ],
    declarations: [
        DashboardListComponent,
        DashboardPage,
        CreateDashboardComponent, CustomDashboardPage, DeviceTypeDashboardPage, DeviceDashboardPage,
        GraphDialogComponent, DashboardGridLayoutComponent, DashboardGridsterLayoutComponent, GraphLegendComponent
    ],
    entryComponents: [
        GraphEditorComponent,
        GraphContainerComponent,
        CreateDashboardComponent,
        GraphDialogComponent
    ]
})
export class DashboardsPageModule {
}
