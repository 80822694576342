import {Injectable} from '@angular/core';

@Injectable()
export class AppState {
    _state = {};

    // return a clone of state
    get state() {
        return this._state = this.clone(this._state);
    }

    private clone(object): any {
        return JSON.parse(JSON.stringify(object));
    }

    get(prop?: any) {
        const state = this.state;
        return state.hasOwnProperty(prop) ? state[prop] : undefined; // Why return state on false?
    }

    set(prop: string, value: any) {
        // update the specified state property with the value
        return this._state[prop] = value;
    }
}
