import {Component, ElementRef, forwardRef, Inject, Input, OnInit, Pipe, PipeTransform, ViewEncapsulation} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {cloneDeep, forEach, find} from 'lodash';
import {MAT_CHECKBOX_CLICK_ACTION, MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef} from '@angular/material';
import {SelectionModel} from '@angular/cdk/collections';

export interface FilterItem {
    value: string;
    displayName: string;
}

export interface FilterGroup {
    name: string;
    items: FilterItem[];
}

@Component({
    selector : 'filter',
    templateUrl: './filter.component.html',
    styleUrls: ['./filter.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {provide: MAT_CHECKBOX_CLICK_ACTION, useValue: 'noop'}
    ]
})

export class FilterComponent implements OnInit {
    private triggerElementRef;
    filterGroups: FilterGroup[];
    filterSearchTerm: string;
    private selectionModel = new SelectionModel<FilterItem>(true);
    constructor(private dialogRef: MatDialogRef<FilterComponent>,
                @Inject(MAT_DIALOG_DATA) data: { trigger: ElementRef, filterGroups: FilterGroup[], appliedFilters: FilterItem[] }) {
        this.triggerElementRef = data.trigger;
        this.filterGroups = data.filterGroups;
        forEach(data.appliedFilters, (f) => {
            this.selectionModel.select(f);
        });
    }

    isSelected(item) {
        return this.selectionModel.isSelected(item);
    }

    ngOnInit(): void {
        const matDialogConfig: MatDialogConfig = new MatDialogConfig();
        const rect = this.triggerElementRef.nativeElement.getBoundingClientRect();
        const windowWidth = window.innerWidth;
        const width = rect.left < 400 ? rect.left - 20 : 400;
        matDialogConfig.position = { right: `${windowWidth - rect.left}px`, top: `${rect.bottom}px` };
        matDialogConfig.width = width + 'px';

        this.dialogRef.updateSize(matDialogConfig.width, matDialogConfig.height);
        this.dialogRef.updatePosition(matDialogConfig.position);
    }

    onApply() {
        this.dialogRef.close({
            selectedFilters: this.selectionModel.selected
        });
    }

    onCancel() {
        this.dialogRef.close();
    }

    onItemClicked(item) {
        this.selectionModel.toggle(item);
    }

}

@Pipe({
    name: 'itemFilter'
})
export class ItemFilterPipe implements PipeTransform {
    transform(items: any[], searchText: string): any[] {
        if (!items) {
            return [];
        }
        if (!searchText) {
            return items;
        }

        items = cloneDeep(items);

        items.filter(function(filter) {
            if (filter.name.toLowerCase().indexOf(searchText.toLowerCase()) >  -1) {
                return true;
            }

            filter.items = filter.items.filter(function(item) {
                return item.displayName.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
            });

            if (filter.items.length > 0) {
                return true;
            }

            return false;
        });
        return items;
    }
}

