import {Component, forwardRef, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {DeviceTypeService} from '../../deviceType/deviceType.service';
import {Org, OrgService, OrgUser} from '../../orgs/org.service';
import {MatSelect} from '@angular/material';
import {find} from 'lodash';

@Component({
    selector : 'user-select',
    templateUrl: './user-select.component.html',
    styleUrls: ['./user-select.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => UserSelectComponent),
            multi: true
        }
    ]
})

export class UserSelectComponent implements OnInit, ControlValueAccessor {
    users;
    loading = false;
    @Input('value') selectedUsers;
    @Input() selectWidth;
    @ViewChild('userSelect', {static: false}) public selectCtl: MatSelect;
    selectedUserIds;
    onChange: any = () => { };
    onTouched: any = () => { };
    constructor(private orgService: OrgService) {
    }

    ngOnInit(): void {
        const self = this;
        this.loading = true;
        this.orgService.getOrg(true).then(function(org: Org) {
            self.users = org.users.sort(function(a: OrgUser, b: OrgUser) {
                return a.name.localeCompare(b.name);
            });
            if (self.selectedUserIds) {
                self.selectedUserIds.forEach(function (userId) {
                    const found = find(self.users, function (user) {
                        return user.userName === userId;
                    });
                    if (found) {
                        self.selectedUsers.push(found);
                    }
                });
            }
        }).finally(function() {
            self.loading = false;
        });
        if (!this.selectedUsers) {
            this.selectedUsers = [];
        }
    }

    onSelectionChange(event) {
        if (this.selectedUsers.indexOf(event.value) === -1) {
            this.selectedUsers.push(event.value);
        }
        this.selectCtl.value = null;
        this.selectedUsersChanged();
    }

    onRemoveUser (user) {
        const idx = this.selectedUsers.indexOf(user);
        if (idx > -1) {
            this.selectedUsers.splice(idx, 1);
        }
        this.selectedUsersChanged();
    }

    private selectedUsersChanged() {
        this.onChange(this.selectedUsers.map(function (user) {
            return user.userName;
        }));
        this.onTouched();
    }

    registerOnChange(fn) {
        this.onChange = fn;
    }

    registerOnTouched(fn) {
        this.onTouched = fn;
    }

    writeValue(value) {
        if (value) {
            this.selectedUserIds = value;
            const self = this;

        }
    }

}
