import {Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation} from '@angular/core';
import {Device} from '../sensors.service';
import {GeocodeService} from '../../services/geocode.service';
import {forEach, get, find} from 'lodash';
import { Router } from '@angular/router';
import { LayerService } from '../layers.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ConfirmDialogComponent } from 'src/app/shared/dialogs/confirm/confirm-dialog.component';
import { MessageService } from 'src/app/error/message.service';


declare const google: any;

const DEFAULT_MARKER_ICON_URL = '/assets/icon/sensor_marker_icon.png';
const SELECTED_MARKER_ICON_URL = '/assets/icon/sensor_marker_selected_icon.png';
const DEFAULT_BUILDING_ICON_URL ='/assets/icon/layer_icon.png';

const signatures = {
    iVBORw0KGgo: "image/png",
    "/9j/": "image/jpg"
  };

interface Marker {
    lat: number;
    lng: number;
    label?: string;
    address?: string;
    icon?: string;
    draggable?: boolean
}

interface Layer {
    id: string;
    name: string;
    coordinates: {
        longitude: number,
        latitude: number
    };
    icon?: string;
    image?: string | File;
    draggable: boolean;
}

interface mapEvent {
    coords: any;
    placeId? : string;
}
//declare const google: any;

@Component({
    selector: 'sensor-map',
    templateUrl: './sensor-map.component.html',
    styleUrls: ['./sensor-map.component.scss'],
    encapsulation: ViewEncapsulation.None,

})


export class SensorMapComponent implements OnInit, OnChanges {
    @Input() sensorList: Device[];
    @Output() selectionChanged = new EventEmitter();

    overlay;
    infoWindowOpened = null
    previous_info_window = null
    selectedMarker: Marker;
    locationSensors: Device[] = [];
    locationToDevicesMap = {};
    locationAddress;
    loading = false;
    selectedIdx = 0;
    // Map
    map: any;
    zoom = 4;
    // initial center position for the map
    lng = 54.1112;
    lat = 23.4113;
    // center position of the first device
    deviceLat: number;
    deviceLng: number;
    // center position of the first layer
    layerLat: number;
    layerLng: number;

    markers: Marker[] = [];
    layers: Layer[] = [];

    showDevices: boolean = true;
    showLayers: boolean = false;

    constructor(
        private geoCoderService: GeocodeService,
        private router: Router,
        private layerService: LayerService,
        private dialog: MatDialog,
        private messageService: MessageService,) {

    }

    ngOnInit(): void {}


    clickedMarker(label: string, index: number) {
        const marker = this.markers[index];
        this.applyMarkerFilter(marker);
    }

    applyMarkerFilter(marker: Marker) {
        if (this.selectedMarker) {
            this.selectedMarker.icon = DEFAULT_MARKER_ICON_URL;
        }
        this.selectedMarker = marker;
        if (this.selectedMarker) {
            const key = this.markerToLocationKey(this.selectedMarker);
            this.locationSensors = this.locationToDevicesMap[key] || [];
            this.locationAddress = this.selectedMarker.address;
            this.selectedMarker.icon = SELECTED_MARKER_ICON_URL;
        } else {
            this.locationSensors = [];
            this.locationAddress = null;
        }
    }

    markerDragEnd(m: Marker, $event: MouseEvent) {
        console.log('dragEnd', m, $event);
    }

    onSelectionChanged($event) {
        this.selectionChanged.emit($event);
    }

    markerToLocationKey(marker: any) {
        return `${marker.lat},${marker.lng}`;
    }

    changeTab(event){
        const tab = event.tab.textLabel;
        this.map.setZoom(4)
        if(tab == "Devices"){
            this.showLayers = false;
            this.showDevices = true;

            // Change initial coordinates
            this.lng = this.deviceLng !== null ? this.deviceLng : this.lng;
            this.lat = this.deviceLat !== null ? this.deviceLat : this.lat;
        }
        else if(tab == "Layers"){
            this.showDevices = false;
            this.showLayers = true;
            
            // Change initial coordinates
            this.lng = this.layerLng !== null ? this.layerLng : this.lng;
            this.lat = this.layerLat !== null ? this.layerLat : this.lat;
        }
    }

    async initGeolocations() {
        this.markers = [];
        this.locationToDevicesMap = {};
        let markerLabelCode = 'A'.charCodeAt(0);
        
        //Layers Init
        await this.layerService.queryLayers().then((layers)=>{
            layers.Items.forEach(layer => {
                layer.icon = DEFAULT_BUILDING_ICON_URL;
                this.layers.push(layer);
            });

            //Saves coordinates of the first layer
            if(this.layers.length > 0){
                let firstLayer =this.layers[0];
                this.layerLat = firstLayer.coordinates.latitude;
                this.layerLng = firstLayer.coordinates.longitude;
            }
        })
        //Sensors Init
        for (let i = 0; i < this.sensorList.length; i++) {
            const device = this.sensorList[i];
            const geo = get(device, 'tags.Geo', get(device, 'tags.geo'));
            if (geo) {
                try {
                    const location = await this.geoCoderService.geocodeAddress(geo);
                    const key = this.markerToLocationKey(location);
                    const deviceList = this.locationToDevicesMap[key] = this.locationToDevicesMap[key] || [];
                    if (deviceList.length === 0) {
                        const marker = {
                            lat: location.lat,
                            lng: location.lng,
                            // label: String.fromCharCode(markerLabelCode),
                            address: location.address,
                            icon: DEFAULT_MARKER_ICON_URL,
                            draggable: true
                        };
                        this.markers.push(marker);
                        markerLabelCode++;
                    }
                    deviceList.push(device);

                } catch (e) {
                    // Do nothing if a location couldnt be geocoded
                }
            }
        }
        //Sets new initial coordinates based on the first marker coordinates
        if(this.markers.length > 0){

            let firtMarker =this.markers[0];
            console.log("firtMarker: ", firtMarker)
            this.deviceLat = firtMarker.lat;
            this.deviceLng = firtMarker.lng;

            this.lng = this.deviceLng;
            this.lat = this.deviceLat;
        }
        // Preserve the old selection only if it exists in the new markers
        if (this.selectedMarker) {
            this.selectedMarker = find(this.markers, (m: Marker) => {
                return m.lng === this.selectedMarker.lng && m.lat === this.selectedMarker.lat;
            });
        }
    }

    mapReady(event: any) {
        this.map = event;
        this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(document.getElementById('addLayer'));
    }

    closeInfoWindow(infoWindow){
        if (this.previous_info_window == null)
        this.previous_info_window = infoWindow;
        else{
        this.infoWindowOpened = infoWindow
        this.previous_info_window.close()
        }
        this.previous_info_window = infoWindow
    }

    addLayer(){
        this.router.navigate(['layers', 'add']).finally();
    }

    goToLayer(id): void {
        let layer = this.layers.find((layer)=> layer.id === id)
        this.map.setZoom(16);
        this.map.setCenter({lat: layer.coordinates.latitude, lng: layer.coordinates.longitude});
    
    }

    openLayer(id): void{
        let layer = this.layers.find((layer)=> layer.id === id)
        this.router.navigate(['layers', id]).finally();
    }
    
    onEditLayer(id) {
        this.router.navigate(['layers', id, 'edit']).finally();
    } 
    
    deleteLayer(id):void{

        let layer = this.layers.find((layer)=> layer.id === id)
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            title: 'Confirm Delete',
            message: 'Are you sure you want to remove this layer?'
        };

        const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
        const self = this;
        dialogRef.afterClosed().subscribe(async (result) => {
            if (result) {
                this.loading = true;
                try {
                    await this.layerService.deleteLayer(id);
                    const idx = this.layers.indexOf(layer);
                    if (idx > -1) {
                        this.layers.splice(idx, 1);
                    }
                    self.messageService.handleSuccess('Successfully removed layer');
                    this.map.setZoom(this.zoom);
                    this.map.setCenter({lat: this.lat, lng: this.lng});
                } catch (e) {
                    self.messageService.handleError(e.message, 'Error Deleting');
                } finally {
                    this.loading = false;
                }
            }
        });
    }


  dataURItoBlob(dataURI) {
    //converts base64 string to blob - will after be converted back to a file
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/png' });    
    return blob;
 }

  detectMimeType(b64) {
    //get the MineType (png, jpg etc...) - for now only 3 types are allows for images - Check CustomValidators class 
    for (var s in signatures) {
      if (b64.indexOf(s) === 0) {
        return signatures[s];
      }
    }
  }
        
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.sensorList) {
            this.loading = true;
            this.initGeolocations().then(() => {
                this.applyMarkerFilter(this.selectedMarker);
            }).finally(() => {
                this.loading = false;
            });
        }
    }
}