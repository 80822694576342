import { User } from './../../users/users.service';
import {Component, OnInit, Output, EventEmitter, Input, ViewEncapsulation} from '@angular/core';
import {AppSettings, MenuExtension, Roles} from '../../app.settings';
import { AuthService } from '../../auth/auth.service';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {AppContext} from '../../app.context';
import {MatDialog, MatDialogConfig, MatSlideToggleChange} from '@angular/material';
import {ListDialogComponent} from '../../shared/dialogs/list/list-dialog.component';
import { find, isNil, forEach } from 'lodash';
import {AlarmUpdateService} from '../../alarms/alarm-update.service';
import { MessageService } from 'src/app/error/message.service';
import {filter, map} from 'rxjs/operators';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit {
  menuItems = AppSettings.MENU_ITEMS.slice(0);
  @Output() public sidenavToggle = new EventEmitter();
  @Input() selectedPage: string;
  user = null;
  selectedOrg = null;
  loading = false;
  orgId = null;
  showEditOrg = false;
  pageTitle;
  constructor(
      private router: Router,
      private appContext: AppContext,
      private messageService: MessageService,
      private activatedRoute: ActivatedRoute,
      private auth: AuthService,
      private dialog: MatDialog,
      private alarmUpdateService: AlarmUpdateService

  ) {

    this.appContext.user.subscribe(async (user) => {
      this.user = user;
    });

    this.appContext.org.subscribe(async (org) => {
      this.selectedOrg = org;
    });
  }

  isAuthenticated() {
    return this.auth.isAuthenticated();
  }

  ngOnInit() {
    const subscription = this.router.events
        .subscribe((event) => {
          if (event instanceof NavigationEnd) {
            if (this.activatedRoute.firstChild) {
              const child = this.activatedRoute.firstChild;
              let pageTitle = null;
              if (child.snapshot.data) {
                pageTitle = child.snapshot.data['title'];
              }
              this.pageTitle = pageTitle;
            }

            const urlDelimiters = new RegExp(/[?//,;&:#$+=]/);
            let urlToSearch = event.url;
            if (urlToSearch === '/' || urlToSearch.length === 0) {
              urlToSearch = event.urlAfterRedirects;
            }
            const currentUrlPath = urlToSearch.slice(1).split(urlDelimiters)[0];
            let found = null;
            forEach(this.menuItems, (item) => {
              if (item.href === currentUrlPath) {
                found = item.id;
                return false;
              }
            });
            this.selectedPage = found;
            this.menuItems.forEach((item) => {
              if (item.href === currentUrlPath) {
                this.selectedPage = item.id;
              }
            });
          }
        });
  }


  public onToggleSidenav = () => {
    this.sidenavToggle.emit();
  }

}
