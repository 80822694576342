import {Injectable} from '@angular/core';
import {AbstractControl, FormGroup, NgForm, ValidationErrors} from '@angular/forms';
import {forEach, merge, isEmpty, values} from 'lodash';

@Injectable()
export class FormValidationService {

    isFormGroup(control: AbstractControl): control is FormGroup {
        return !!(<FormGroup>control).controls;
    }


    collectControlErrors(control: AbstractControl): ValidationErrors | null {
        const self = this;
        if (this.isFormGroup(control)) {
            return Object.entries(control.controls)
                .reduce(
                    (acc, [key, childControl]) => {
                        const childErrors = self.collectControlErrors(childControl);
                        if (childErrors && !isEmpty(childErrors)) {
                            acc = {...acc, [key]: childErrors};
                        }
                        return acc;
                    },
                    null
                );
        } else {
            const errors = {};
            forEach(control.errors, function (value, key) {
                errors[key] = {valid: false};
            });
            return errors;
        }
    }

    collectFormErrors(form: NgForm): ValidationErrors | null {
        const self = this;
        const errors = {};
        forEach(values(form.controls), function (control) {
            merge(errors, self.collectControlErrors(control));
        });
        return isEmpty(errors) ? null : errors;
    }

}
