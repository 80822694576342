import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import { environment } from '../../environments/environment';
import {Observable, throwError} from 'rxjs';
import {startsWith} from 'lodash';
import {AuthService} from './auth.service';
import {catchError, map} from 'rxjs/operators';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {MessageService} from '../error/message.service';


@Injectable()
export class AddAuthTokenInterceptor implements HttpInterceptor {
    path = null;
    constructor(private auth: AuthService,
                public router: Router,
                private messageService: MessageService) {
        // I could not find a better way to this without subscribing to this event.  It seems activatedroute.snapshot.firstChild is always null
        const self = this;
        this.router.events
            .subscribe(function(event) {
                if (event instanceof NavigationEnd) {
                    const urlDelimiters = new RegExp(/[?//,;&:#$+=]/);
                    self.path = event.url.slice(1).split(urlDelimiters)[0];
                }
            });
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.auth.isExistsAndExpired()) {
            // Maybe refresh ?
            this.auth.logout();
            this.router.navigate(['login'],
                { queryParams: { returnUrl: this.router.url, cause: 'expiredToken' }}).finally();
        } else {
            if (this.auth.isAuthenticated()) {
                if (startsWith(req.url, environment.services.url)) {
                    req = req.clone({
                        setHeaders: {
                            Authorization: `${this.auth.getAuthToken()}`
                        }
                    });
                }
            }
            const self = this;
            return next.handle(req).pipe(
                map((event: any) => {
                    return event;
                }),
                catchError((error) => {
                    // For some reason cognito will return a 401 and not return the proper CORS header
                    if (error.status === 401 || error.status === 0) {
                        // Check to see if we have a token
                        if (this.auth.isAuthenticated()) {
                            this.auth.logout();
                        }

                        if (this.path !== 'login') {
                            this.router.navigate(['login'],
                                { queryParams: { returnUrl: this.router.url, cause: 'authRequired' }}).finally();
                        }


                    } else {
                        // I think we should let the caller handle 404's.  404's can be an expected status code
                        if (error.status !== 404) {
                            this.messageService.handleError(error, '');
                        }
                    }
                    return throwError(error);
                }));
        }
    }

}
