import {Injectable} from '@angular/core';
import {SensorService} from '../sensors/sensors.service';
import {get, isString, forEach} from 'lodash';
import {DeviceTypeService} from '../deviceType/deviceType.service';
import {AppContext} from '../app.context';
import {TemperatureConverterFactory} from './converters/temperature';
import {IUnitConverterFactory} from './converters/converter';


const converters = [new TemperatureConverterFactory()];


@Injectable()
export class UnitConversionFactory {
    constructor(private sensorService: SensorService,
                private deviceTypeService: DeviceTypeService,
                private appContext: AppContext) {}

    async getUnitConverter(fieldPath: string, deviceTypeId: string) {
        const prefs = await this.appContext.getUserPreferences();
        const schema = await this.deviceTypeService.getSchema(deviceTypeId);
        let unitConverter = null;
        if (schema) {
            const def = get(schema, fieldPath);
            if (def) {
                const unit = def.unit;

                forEach(converters, (factory: IUnitConverterFactory) => {
                    const converter = factory.getUnitConverter(prefs, unit);
                    if (converter) {
                        unitConverter = converter;
                        return false;
                    }
                });
            }
        }
        return unitConverter;
    }
}
