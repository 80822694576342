import {Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material';
import {SelectionModel} from '@angular/cdk/collections';
import {ControlValueAccessor, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';



export class FieldNode {
    children: FieldNode[];
    item: string;
    path: string;
}


export class FieldFlatNode {
    path: string;
    item: string;
    level: number;
    expandable: boolean;
}

@Component({
    selector: 'sen-tree',
    templateUrl: './tree.component.html',
    styleUrls: ['./tree.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TreeComponent),
            multi: true
        }
    ]
})
export class TreeComponent implements OnInit, OnChanges, ControlValueAccessor {
    @Input() model;
    @Input() filter;
    @Output() selectionChanged = new EventEmitter();

    onChange: (value: any) => {};
    onTouched: () => {};
    treeControl: FlatTreeControl<FieldFlatNode>;
    flatNodeMap = new Map<FieldFlatNode, FieldNode>();
    nestedNodeMap = new Map<FieldNode, FieldFlatNode>();
    treeFlattener: MatTreeFlattener<FieldNode, FieldFlatNode>;
    dataSource: MatTreeFlatDataSource<FieldNode, FieldFlatNode>;
    checklistSelection = new SelectionModel<FieldFlatNode>(true);

    constructor() {
        this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel,
            this.isExpandable, this.getChildren);
        this.treeControl = new FlatTreeControl<FieldFlatNode>(this.getLevel, this.isExpandable);
        this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
        this.checklistSelection.changed.subscribe((change) => {
            const selectedPaths = [];
            this.checklistSelection.selected.forEach((selectedNode: FieldFlatNode) => {
                if (!selectedNode.expandable) {
                    selectedPaths.push(selectedNode.path);
                }
            });
            this.selectionChanged.emit(selectedPaths);
            this.onTouched();
            this.onChange(selectedPaths);
        });
    }

    ngOnInit(): void {
    }

    buildMessageTree(obj: {[key: string]: any}, level: number, path?: string[]): FieldNode[] {
        path = path || [];
        return Object.keys(obj).reduce<FieldNode[]>((accumulator, key) => {

            const value = obj[key];
            path.push(key);
            const node = new FieldNode();
            node.item = key;
            node.path = path.join('.');
            if (value != null) {
                if (typeof value === 'object') {
                    const children = this.buildMessageTree(value, level + 1, path);
                    node.children = children.length === 0 ? null : children.sort((a, b) => {
                        return a.item.localeCompare(b.item);
                    });
                } else {
                    node.item = value;
                }
            }
            path.pop();
            return accumulator.concat(node);

        }, []);
    }

    transformer = (node: FieldNode, level: number) => {
        const existingNode = this.nestedNodeMap.get(node);
        const flatNode = existingNode && existingNode.item === node.item
            ? existingNode
            : new FieldFlatNode();
        flatNode.item = node.item;
        flatNode.level = level;
        flatNode.path = node.path;
        flatNode.expandable = !!node.children;
        this.flatNodeMap.set(flatNode, node);
        this.nestedNodeMap.set(node, flatNode);
        return flatNode;
    }

    /** Whether all the descendants of the node are selected. */
    descendantsAllSelected(node: FieldFlatNode): boolean {
        const descendants = this.treeControl.getDescendants(node);
        const descAllSelected = descendants.every(child =>
            this.checklistSelection.isSelected(child)
        );
        return descAllSelected;
    }

    /** Whether part of the descendants are selected */
    descendantsPartiallySelected(node: FieldFlatNode): boolean {
        const descendants = this.treeControl.getDescendants(node);
        const result = descendants.some(child => this.checklistSelection.isSelected(child));
        return result && !this.descendantsAllSelected(node);
    }

    /** Toggle the to-do item selection. Select/deselect all the descendants node */
    parentItemSelectionToggle(node: FieldFlatNode): void {
        this.checklistSelection.toggle(node);
        const descendants = this.treeControl.getDescendants(node);
        this.checklistSelection.isSelected(node)
            ? this.checklistSelection.select(...descendants)
            : this.checklistSelection.deselect(...descendants);

        // Force update for the parent
        descendants.every(child =>
            this.checklistSelection.isSelected(child)
        );
        this.checkAllParentsSelection(node);

    }


    /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
    leafItemSelectionToggle(node: FieldFlatNode): void {
        this.checklistSelection.toggle(node);
        this.checkAllParentsSelection(node);
    }

    /* Checks all the parents when a leaf node is selected/unselected */
    checkAllParentsSelection(node: FieldFlatNode): void {
        let parent: FieldFlatNode | null = this.getParentNode(node);
        while (parent) {
            this.checkRootNodeSelection(parent);
            parent = this.getParentNode(parent);
        }
    }

    /** Check root node checked state and change it accordingly */
    checkRootNodeSelection(node: FieldFlatNode): void {
        const nodeSelected = this.checklistSelection.isSelected(node);
        const descendants = this.treeControl.getDescendants(node);
        const descAllSelected = descendants.every(child =>
            this.checklistSelection.isSelected(child)
        );
        if (nodeSelected && !descAllSelected) {
            this.checklistSelection.deselect(node);
        } else if (!nodeSelected && descAllSelected) {
            this.checklistSelection.select(node);
        }
    }

    /* Get the parent node of a node */
    getParentNode(node: FieldFlatNode): FieldFlatNode | null {
        const currentLevel = this.getLevel(node);

        if (currentLevel < 1) {
            return null;
        }

        const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

        for (let i = startIndex; i >= 0; i--) {
            const currentNode = this.treeControl.dataNodes[i];

            if (this.getLevel(currentNode) < currentLevel) {
                return currentNode;
            }
        }
        return null;
    }

    getLevel = (node: FieldFlatNode) => node.level;
    isExpandable = (node: FieldFlatNode) => node.expandable;

    getChildren = (node: FieldNode): FieldNode[] => node.children;

    hasChild = (_: number, _nodeData: FieldFlatNode) => _nodeData.expandable;

    hasNoContent = (_: number, _nodeData: FieldFlatNode) => _nodeData.item === '';

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.model) {
            this.dataSource.data = this.buildMessageTree(changes.model.currentValue, 0);
        }
    }

    writeValue(value: any): void {
        // For now there wont be any value, in the future if one is required, this needs to
        // be implemented
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
}
