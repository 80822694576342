import {Component, OnDestroy, OnInit} from '@angular/core';
import {OrgService} from '../../orgs/org.service';
import {DashboardsService, IDashboard} from '../dashboards.service';
import {forEach, findIndex} from 'lodash';
import {Router} from '@angular/router';
import {MatDialog, MatDialogConfig, MatTableDataSource} from '@angular/material';
import {CreateDashboardComponent} from '../create/create-dashboard.component';
import {ConfirmDialogComponent} from '../../shared/dialogs/confirm/confirm-dialog.component';
import {Monitor} from '../../monitors/monitors.service';
import {SelectionModel} from '@angular/cdk/collections';
import {Roles} from '../../app.settings';
import {AppContext} from '../../app.context';

@Component({
    selector: 'dashboard-list',
    templateUrl: './dashboard-list.component.html',
    styleUrls: ['./dashboard-list.component.scss'],
})
export class DashboardListComponent implements OnInit, OnDestroy {
    dashboards = [];
    loading;
    working;
    canEdit = false;
    dataSource = new MatTableDataSource<IDashboard>();
    displayedColumns: string[] = ['select', 'name', 'scope'];
    selection = new SelectionModel<IDashboard>(true, []);
    constructor(private orgService: OrgService,
                private router: Router,
                private dialog: MatDialog,
                private dashboardService: DashboardsService,
                private appContext: AppContext) {

    }

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    masterToggle() {
        this.isAllSelected() ?
            this.selection.clear() :
            this.dataSource.data.forEach(row => this.selection.select(row));
    }

    ngOnDestroy(): void {
    }

    async removeSelected() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            title: 'Confirm Delete',
            message: 'Are you sure you want to remove the selected dashboards?'
        };

        const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
        const self = this;
        dialogRef.afterClosed().subscribe(async (result) => {
            if (result) {
                try {
                    self.working = true;
                    for (const dash of this.selection.selected) {
                        await self.dashboardService.deleteDashboard(dash.id);
                    }
                } catch (e) {

                } finally {
                    this.selection.clear();
                    self.working = false;
                    self.init(true);
                }

            }
        });
    }

    createDashboard() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = '400px';
        const self = this;
        const dialogRef = this.dialog.open(CreateDashboardComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                self.working = true;
                self.dashboardService.createDashboard(result.scope, result.name).then(function (response) {
                    self.dashboardClicked(response);
                }).finally(function () {
                    self.working = false;
                });
            }
        });
    }

    canDelete() {
        return this.selection.selected.length > 0 && this.selection.selected.filter(function(dash) {
            return dash.preset;
        }).length === 0;
    }

    dashboardClicked(dashboard) {
        if (dashboard.preset) {
            this.router.navigate(['/dashboards', 'deviceType' , dashboard.id ]);
        } else {
            this.router.navigate(['/dashboards', dashboard.id]);
        }
    }

    async shareSelected() {
        try {
            this.working = true;
            for (const dash of this.selection.selected) {
                await this.dashboardService.shareDashboard(dash.id);
                const fetched = await this.dashboardService.getDashboard(dash.id);
                const idx = findIndex(this.dashboards, function (item) {
                    return item.id === dash.id;
                });
                if (idx > -1) {
                    this.dashboards.splice(idx, 1, fetched);
                }
            }
        } catch (e) {

        } finally {
            this.working = false;
            this.selection.clear();
        }
    }

    canShare() {
        const selected = this.selection.selected;
        let allShareable = true;
        if (selected.length === 0) {
            allShareable = false;
        } else {

            forEach(selected, function (dash) {
                if (dash.scopeLevel !== 'private') {
                    allShareable = false;
                    return false;
                }
            });
        }
        return allShareable;
    }

    async init(hideWorking?) {
        let fetchedDash = [];
        if (!hideWorking) {
            this.loading = true;
        }
        return Promise.all([this.orgService.getDeviceTypes(), this.dashboardService.getDashboards()]).then((results) => {
            fetchedDash = fetchedDash.concat(results[1].sort(function(a, b) {
                return a.name.localeCompare(b.name);
            }));
            fetchedDash = fetchedDash.concat(results[0].map(function(dash) {
                dash.preset = true;
                return dash;
            }).sort(function(a , b) {
                return a.name.localeCompare(b.name);
            }))
            this.dataSource.data = fetchedDash;
            this.dashboards = fetchedDash;
        }).finally(() => {
            this.loading = false;
        });
    }

    ngOnInit(): void {
        this.init();
        this.appContext.isUserInRole([Roles.OWNER, Roles.ADMIN]).then((answer) => {
            this.canEdit = answer;
        });
    }
}
