import {AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {ITimeRange, TIME_DURATION_CHOICES, TIME_DURATIONS} from '../../graph/graph.component';

const HEADER_HEIGHT = 60;

@Component({
    selector: 'graph-dialog',
    templateUrl: './graph-dialog.component.html',
    styleUrls: ['./graph-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class GraphDialogComponent implements OnInit, OnDestroy{
    graph;
    graphHeight;
    timeRange: ITimeRange;
    timeChoices = TIME_DURATION_CHOICES;
    selectedTime;
    constructor(private dialogRef: MatDialogRef<GraphDialogComponent>,
                @Inject(MAT_DIALOG_DATA) data) {
        this.graph = data.graph;
        this.timeRange = data.timeRange;

    }

    ngOnDestroy(): void {
    }

    ngOnInit(): void {
        this.selectedTime = this.timeRange.duration;
    }

    onChangeTime() {
        this.timeRange = {
            duration: this.selectedTime
        };
    }

    close() {
        this.dialogRef.close();
    }
}
