import {Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {ModuleLogger} from '../error/module-logger';


@Injectable()
export class DashboardsLogService  extends ModuleLogger {

    constructor(logger: NGXLogger) {
        super(logger, 'Dashboards');
    }

}
