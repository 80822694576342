import {
    AfterViewInit,
    Component,
    EventEmitter,
    forwardRef,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewEncapsulation
} from '@angular/core';
import {DeviceTypeService} from '../../deviceType/deviceType.service';
import {forEach, has, isObject, find, startCase, keys, uniqWith} from 'lodash';
import {flatten} from 'flat';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
    selector : 'schema-field-select',
    templateUrl: './schema-field-select.component.html',
    styleUrls: ['./schema-field-select.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SchemaFieldSelectComponent),
            multi: true
        }
    ]
})

export class SchemaFieldSelectComponent implements OnInit, ControlValueAccessor, OnChanges {
    @Input() flatten: boolean;
    @Input() deviceTypeId;
    @Input('value') selectedField;
    @Input() floatLabel = 'always';
    @Input() label;
    @Input() message;
    groups = null;
    loading = false;
    @Output() selectionChange = new EventEmitter();
    @Output() ngModelChange = new EventEmitter();

    onChange: any = () => { };
    onTouched: any = () => { };
    constructor(private deviceTypeService: DeviceTypeService) {}

    onSelectionChanged() {
        this.selectionChange.emit(this.selectedField);
    }

    onNgModelChange($event) {
        this.ngModelChange.emit($event);
    }



    ngOnInit(): void {
    }

    // Drive off Input changes
    loadSchema(): void {
        const self = this;
        this.loading = true;
        this.groups = [];
        this.deviceTypeService.getSchema(this.deviceTypeId).then(function(schema) {
            const messageFields = uniqWith(keys(flatten(schema.message)).map(function(field) {
                const reduced = field.split('.').slice(0, -1).join('.');
                return {
                    path: `message.${reduced}`,
                    displayName: reduced
                };
            }), (a, b) => {
                return a.displayName === b.displayName;
            }).sort(function(a, b) {
                return a.displayName.localeCompare(b.displayName);
            });
            self.groups = self.groups.concat(messageFields);

            // break message into groups

            // forEach(schema, function(type, key) {
            //     // This will always have 'type' as the last segment, and we dont want that
            //     const segments = key.split('.').slice(0, -1);
            //
            //     const model = {};
            //     model[segments.join('.')] = {
            //         type: type
            //     };
            //     let viewValue = segments[0];
            //     let groupName = 'default';
            //     if (segments.length > 1) {
            //         groupName = startCase(segments[0]);
            //         viewValue = segments.slice(1).join('.');
            //     }
            //     const option = {value : model, viewValue: viewValue};
            //     if (groupName === 'default') {
            //         // Just add straight into the groups
            //         option.viewValue = startCase(option.viewValue);
            //         self.groups.push(option);
            //     } else {
            //         let found = find(self.groups, function (item) {
            //             return item.name === groupName;
            //         });
            //         if (!found) {
            //             found = {name: groupName, items: []};
            //             self.groups.push(found);
            //         }
            //         found.items.push(option);
            //     }
            // });

        }).finally(function() {
            self.loading = false;
        });
    }


    get value() {
        return this.selectedField;
    }

    set value(val) {
        this.selectedField = val;
        this.onChange(val);
        this.onTouched();
    }

    registerOnChange(fn) {
        this.onChange = fn;
    }

    registerOnTouched(fn) {
        this.onTouched = fn;
    }

    writeValue(value) {
        this.selectedField = value;
    }

    ngOnChanges(changes: SimpleChanges): void {
        const change = changes['deviceTypeId'];
        if (change && change.currentValue) {
            // If deviceTypeId changes re-query the schema
            this.loadSchema();
        }
    }

}
