import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'integration-links',
    templateUrl: './integration-links.component.html',
    styleUrls: ['./integration-links.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class IntegrationLinksComponent implements OnInit {
    @Input() links;
    @Input() compact = true;
    @Input() showText = true;
    @Input() showIcon = true;
    constructor() {

    }

    ngOnInit(): void {
    }

}
