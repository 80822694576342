import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'sen-chip',
    templateUrl: './chip.component.html',
    styleUrls: ['./chip.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ChipComponent implements OnInit {
    @Input() name;
    @Input() value;
    @Input() removable;
    @Input() disabled;
    @Output() removeChip = new EventEmitter();
    @Output() clickChip = new EventEmitter();
    constructor() { }

    onRemoveChip() {
        this.removeChip.emit({
            name: this.name,
            value: this.value
        });
    }

    onClickChip(){

        if(this.disabled === false){
            this.clickChip.emit({
                name: this.name,
                value: this.value
            })
        }
    }

    ngOnInit() {
    }

}
