import { JoinExistingOrgComponent } from './login/join-existing-org/join-existing-org.component';
import { ConfirmPasswordResetComponent } from './login/confirm-password-reset/confirm-password-reset.component';
import { EditProfileComponent } from './shared/dialogs/edit-profile/edit-profile.component';
import { ChangePasswordComponent } from './shared/dialogs/change-password/change-password.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { MatTabsModule, MatSidenavModule, MatToolbarModule } from '@angular/material';
import { BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { LayoutComponent } from './layout/layout.component';
import { FlexLayoutModule} from '@angular/flex-layout';
import { MaterialModule } from './material/material.module';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AppState } from './app.global';
import { HomePageModule } from './home/home.module';
import { SensorsPageModule } from './sensors/sensors.module';
import { DashboardsPageModule } from './dashboards/dashboard.module';
import { StatisticsPageModule } from './statistics/statistics.module';
import { AssetsModule } from './assets/assets.modules';

import { UsersPageModule } from './users/users.module';
import { AuthModule } from './auth/auth.module';
import { LoginPageModule } from './login/login.module';


import { AppContext } from './app.context';
import { AppSettings } from './app.settings';
import { ToastrModule } from 'ngx-toastr';
import { ConfirmDialogComponent} from './shared/dialogs/confirm/confirm-dialog.component';
import { InputDialogComponent} from './shared/dialogs/input/input-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPopperModule } from 'ngx-popper';
import {PreferencesService} from './preferences/preferences.service';
import { ListDialogComponent} from './shared/dialogs/list/list-dialog.component';
/**
 * Font awesome
 */
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {AddAuthTokenInterceptor} from './auth/add-auth-token.interceptor';

import {NgxSpinnerModule} from 'ngx-spinner';
import {NavigationModule} from './navigation/navigation.module';
import {OrgService} from './orgs/org.service';
import {OrgModule} from './orgs/org.module';
import {IntegrationsModule} from './integrations/integrations.module';
import {QueryJobsModule} from './queries/queries.module';
import {NgxDaterangepickerMd} from 'ngx-daterangepicker-material';
import {ProfileModule} from './profile/profile.module';
import {DeviceDataModule} from './deviceData/device-data.module';
import {MonitorsModule} from './monitors/monitors.module';
import {AlarmsModule} from './alarms/alarms.module';
import {SplashScreen} from '@ionic-native/splash-screen';
import {AgmCoreModule} from '@agm/core';
import {GeocodeService} from './services/geocode.service';
library.add(fas, far, fab);
@NgModule({
  declarations: [AppComponent, LayoutComponent,
    ConfirmDialogComponent, InputDialogComponent, ListDialogComponent],
  // tslint:disable-next-line: max-line-length
  entryComponents: [ConfirmDialogComponent, InputDialogComponent, ListDialogComponent, EditProfileComponent, ChangePasswordComponent, ConfirmPasswordResetComponent, JoinExistingOrgComponent],
  imports: [
    MaterialModule,
    FlexLayoutModule,
    MatToolbarModule,
    BrowserAnimationsModule,
    MatTabsModule,
    MatSidenavModule,
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    FontAwesomeModule,
    HomePageModule,
    SensorsPageModule,
    DashboardsPageModule,
    StatisticsPageModule,
    AssetsModule,
    QueryJobsModule,
    UsersPageModule,
    AuthModule,
    LoginPageModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPopperModule,
    NgxSpinnerModule,
    NavigationModule,
    OrgModule,
    IntegrationsModule,
    ProfileModule,
    DeviceDataModule,
    MonitorsModule,
    AlarmsModule,
    NgxDaterangepickerMd.forRoot(),
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
      preventDuplicates: true
    }),
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.threeBounce,
      backdropBackgroundColour: 'rgba(0,0,0,0.1)',
      backdropBorderRadius: '4px',
      primaryColour: '#ffffff',
      secondaryColour: '#ffffff',
      tertiaryColour: '#ffffff',
      fullScreenBackdrop : true
    }),
    AgmCoreModule.forRoot({
      //apiKey: 'AIzaSyBNxqWEnIhC-h29XMnDG_uYVZBOnwAYaFA'
      apiKey: 'AIzaSyAtysUjWx5XfrLxAXYzLREhnQ450PR76lY'
    })
  ],
  providers: [
    StatusBar,
    SplashScreen,
    AppState,
    AppContext,
    AppSettings,
    PreferencesService,
    GeocodeService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddAuthTokenInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
