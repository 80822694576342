import { environment } from '../environments/environment';
import { template } from 'lodash';

const MENU_ITEM_IDS = {
    SENSORS : 'sensors',
    DASHBOARDS : 'dashboards',
    MONITORS : 'monitors',
    ALARMS : 'alarms',
    USERS : 'users',
    LOGOUT : 'logout',
    LOGIN : 'login',
    ORG : 'org',
    CHANGE_ORG: 'change-org',
    EDIT_ORG: 'edit-org',
    QUERY_MANAGEMENT: 'query-man',
    STATISTICS: 'statistics'
};

export const Services = {
    WHOAMI : 'whoami',
    LOGIN : 'login',
    ORGS : 'orgs',
    ORG: 'org',
    ORG_USERS: 'orgUsers',
    ORG_USER: 'orgUser',
    SENSORS : 'sensors',
    SENSOR: 'sensor',
    LAYERS: 'layers',
    LAYER: 'layer',
    DASHBOARDS: 'dashboards',
    DASHBOARD: 'dashboard',
    DASHBOARD_COUNT: 'dashboard_count',
    DASHBOARD_SHARE: 'dashboard_share',
    DEVICE_TYPES: 'deviceTypes',
    DEVICE_TYPE: 'deviceType',
    DEVICE_TYPE_DASHBOARD: 'deviceTypeDashboard',
    DEVICE_DATA_FIND : 'device-data-find',
    PREFERENCES: 'preferences',
    ALARMS: 'alarms',
    ALARM: 'alarm',
    ALARM_HISTORY: 'alarm-history',
    ALARM_SCHEMA: 'alarm-schema',
    ALARM_RESOLVE: 'alarm_resolve',
    MONITORS: 'monitors',
    MONITOR: 'monitor',
    SCHEMA: 'schema',
    ORG_DEVICE_TYPES: 'orgDeviceTypes',
    QUERYABLE_ATTRIBUTES: 'queryableAttributes',
    USER: 'user',
    CHANGE_PASSWORD: 'change-password',
    REQUEST_PASSWORD_RESET: 'request-password-reset',
    RESET_PASSWORD: 'reset-password',
    INVITE_REQUESTS: 'invite-requests',
    INVITE_REQUEST: 'invite-request',
    APPROVE_REQUEST: 'approve-invite-request',
    USERS: 'users',
    INVITES: 'invites',
    ACCEPT_INVITE: 'accept-invite',
    REGISTER_ACCEPT_INVITE: 'register-accept-invite',
    INVITE_USER: 'invite-user',
    PLATFORM_INTEGRATIONS: 'platform-integrations',
    PLATFORM_INTEGRATION: 'platform-integration',
    ORG_INTEGRATIONS: 'org-integrations',
    ORG_INTEGRATION: 'org-integration',
    INCIDENT_MANAGEMENT_PROJECTS: 'im-projects',
    INCIDENT_MANAGEMENT_USERS: 'im-users',
    INCIDENT_MANAGEMENT_RECORD: 'im-record',
    INCIDENT_MANAGEMENT_RECORD_TYPES: 'im-record-types',
    INCIDENT_MANAGEMENT_RECORD_TYPE_SCHEMA: 'im-record-type-schema',
    CLIENT_IDS: 'client-ids',
    CLIENT_ID: 'client-id',
    GRAPHS: 'graphs',
    GRAPH: 'graph',
    SERIES_QUERY: 'series-query',
    QUERY_JOBS: 'query-jobs',
    QUERY_JOB: 'query-job',
    DEVICE_TREE: 'device-tree',
    STATISTICS: 'statistics',
    LOCATION_STATISTICS: 'location-statistics'
};

export const Roles = {
    OWNER : 'Owner',
    ADMIN : 'Admin',
    USER: 'User',
    READ_ONLY : 'ReadOnly'
};

export interface MenuExtension {
    name: string;
    id: string;
    href?: string;
    ionIcon?: string;
    matIcon?: string;
    children?: MenuExtension[];
    authState?: boolean;
    badgeCount?: number;
}

export class AppSettings {
    public static MENU_ITEM_IDS = MENU_ITEM_IDS;
    public static MENU_ITEMS: MenuExtension[] =  [
    {
        name: 'Statistics',
        id: MENU_ITEM_IDS.STATISTICS,
        matIcon: 'assessment',
        href: '/statistics'
    },
    {
        name : 'Sensors',
        id : MENU_ITEM_IDS.SENSORS,
        href: '/sensors',
        ionIcon : 'logo-rss',
    }, {
        name : 'Dashboards',
        id : MENU_ITEM_IDS.DASHBOARDS,
        href: '/dashboards',
        matIcon : 'dashboard',
    }, {
        name : 'Monitors',
        id : MENU_ITEM_IDS.MONITORS,
        href: '/monitors',
        ionIcon : 'desktop',
    }, {
        name : 'Alarms',
        id : MENU_ITEM_IDS.ALARMS,
        href: '/alarms',
        matIcon : 'notifications_active',
    }, {
        name : 'Query Management',
        id : MENU_ITEM_IDS.QUERY_MANAGEMENT,
        href: '/queries',
        matIcon : 'query_builder',
    },
    {
        name: 'Organization Settings',
        id: MENU_ITEM_IDS.ORG,
        matIcon: 'settings',
        href: '/edit-org'
    }];

    private endpoints = null;

    getEndpoints(): Object {

        if (!this.endpoints) {
           this.endpoints = {};
           this.endpoints[Services.WHOAMI] = `${environment.services.url}/whoami`;
           this.endpoints[Services.LOGIN] = `${environment.services.url}/auth/login`;
           this.endpoints[Services.ORGS] = `${environment.services.url}/orgs`;
           this.endpoints[Services.ORG] = environment.services.url + '/orgs/${orgId}';
           this.endpoints[Services.CLIENT_IDS] = environment.services.url + '/orgs/${orgId}/client-ids';
           this.endpoints[Services.CLIENT_ID] = environment.services.url + '/orgs/${orgId}/client-ids/${clientId}';
           this.endpoints[Services.ORG_USERS] = environment.services.url + '/orgs/${orgId}/users';
           this.endpoints[Services.ORG_DEVICE_TYPES] = environment.services.url + '/orgs/${orgId}/device-types';
           this.endpoints[Services.ORG_USER] = environment.services.url + '/orgs/${orgId}/users/${userId}';
           this.endpoints[Services.SENSORS] = environment.services.url + '/orgs/${orgId}/devices';
           this.endpoints[Services.SENSOR] = environment.services.url + '/orgs/${orgId}/devices/${deviceId}';
           this.endpoints[Services.LAYERS] = environment.services.url + '/orgs/${orgId}/layers';
           this.endpoints[Services.LAYER] = environment.services.url + '/orgs/${orgId}/layers/${layerId}';
           this.endpoints[Services.DASHBOARDS] = environment.services.url + '/orgs/${orgId}/dashboards';
           this.endpoints[Services.DASHBOARD] = environment.services.url + '/orgs/${orgId}/dashboards/${dashId}';
           this.endpoints[Services.DEVICE_DATA_FIND] = environment.services.url + '/orgs/${orgId}/device-data/_find';
           this.endpoints[Services.SERIES_QUERY] = environment.services.url + '/orgs/${orgId}/device-data/series/_find';
           this.endpoints[Services.DEVICE_TYPES] = environment.services.url + '/device-types';
           this.endpoints[Services.DEVICE_TYPE] = environment.services.url + '/device-types/${typeId}';
           this.endpoints[Services.SCHEMA] = environment.services.url + '/device-types/${typeId}/schema';
           this.endpoints[Services.DEVICE_TYPE_DASHBOARD] = environment.services.url + '/device-types/${deviceTypeId}/dashboard';
           this.endpoints[Services.PREFERENCES] = environment.services.url + '/users/${userId}/preferences';
           this.endpoints[Services.ALARMS] = environment.services.url + '/orgs/${orgId}/alarms';
           this.endpoints[Services.ALARM_HISTORY] = environment.services.url + '/orgs/${orgId}/alarm-history';
           this.endpoints[Services.ALARM] = environment.services.url + '/orgs/${orgId}/alarms/${alarmId}';
           this.endpoints[Services.ALARM_SCHEMA] = environment.services.url + '/alarm-schema';
           this.endpoints[Services.ALARM_RESOLVE] = environment.services.url + '/orgs/${orgId}/alarms/${alarmId}/_resolve';
           this.endpoints[Services.MONITORS] = environment.services.url + '/orgs/${orgId}/monitors';
           this.endpoints[Services.MONITOR] = environment.services.url + '/orgs/${orgId}/monitors/${monitorId}';
           this.endpoints[Services.DASHBOARD_COUNT] = environment.services.url + '/orgs/${orgId}/dashboards/_count';
           this.endpoints[Services.DASHBOARD_SHARE] = environment.services.url + '/orgs/${orgId}/dashboards/${dashId}/_share';
           this.endpoints[Services.STATISTICS] = environment.services.url + '/orgs/${orgId}/statistics';
           this.endpoints[Services.LOCATION_STATISTICS] = environment.services.url + '/orgs/${orgId}/statistics/${location}';
           // tslint:disable-next-line: max-line-length
           this.endpoints[Services.QUERYABLE_ATTRIBUTES] = environment.services.url + '/orgs/${orgId}/device-types/${deviceTypeId}/queryableAttributes';
           this.endpoints[Services.USER] = environment.services.url + '/users/${userId}';
           this.endpoints[Services.CHANGE_PASSWORD] = environment.services.url + '/users/${userId}/change-password';
           this.endpoints[Services.REQUEST_PASSWORD_RESET] = environment.services.url + '/users/request-password-reset';
           this.endpoints[Services.RESET_PASSWORD] = environment.services.url + '/users/reset-password';
           this.endpoints[Services.INVITE_REQUESTS] = environment.services.url + '/invite-requests';
           this.endpoints[Services.INVITE_REQUEST] = environment.services.url + '/invite-requests/${requestId}';
           this.endpoints[Services.APPROVE_REQUEST] = environment.services.url + '/invite-requests/${requestId}/approve';
           this.endpoints[Services.USERS] = environment.services.url + '/users';
           this.endpoints[Services.INVITES] = environment.services.url + '/invites/${inviteId}';
           this.endpoints[Services.ACCEPT_INVITE] = environment.services.url + '/invites/${inviteId}/accept';
           this.endpoints[Services.REGISTER_ACCEPT_INVITE] = environment.services.url + '/invites/${inviteId}/register-accept';
           this.endpoints[Services.INVITE_USER] = environment.services.url + '/invites';
           this.endpoints[Services.PLATFORM_INTEGRATIONS] = environment.services.url + '/integrations';
           this.endpoints[Services.PLATFORM_INTEGRATION] = environment.services.url + '/integrations/${intId}';
           this.endpoints[Services.ORG_INTEGRATIONS] = environment.services.url + '/orgs/${orgId}/integrations';
           this.endpoints[Services.ORG_INTEGRATION] = environment.services.url + '/orgs/${orgId}/integrations/${intId}';
           this.endpoints[Services.INCIDENT_MANAGEMENT_PROJECTS] = environment.services.url + '/orgs/${orgId}/incident-management/${intId}/projects';
           this.endpoints[Services.INCIDENT_MANAGEMENT_USERS] = environment.services.url + '/orgs/${orgId}/incident-management/${intId}/users';
           this.endpoints[Services.INCIDENT_MANAGEMENT_RECORD_TYPES] = environment.services.url + '/orgs/${orgId}/incident-management/${intId}/projects/${projectId}/record-types';
           this.endpoints[Services.INCIDENT_MANAGEMENT_RECORD_TYPE_SCHEMA] = environment.services.url + '/orgs/${orgId}/incident-management/${intId}/projects/${projectId}/record-types/${recordTypeId}/schema';
           this.endpoints[Services.INCIDENT_MANAGEMENT_RECORD] = environment.services.url + '/orgs/${orgId}/incident-management/${intId}/incident/${incidentId}';
           this.endpoints[Services.GRAPHS] = environment.services.url + '/orgs/${orgId}/dashboards/${dashId}/graphs';
           this.endpoints[Services.GRAPH] = environment.services.url + '/orgs/${orgId}/dashboards/${dashId}/graphs/${graphId}';
           this.endpoints[Services.QUERY_JOBS] = environment.services.url + '/users/${userId}/queries';
           this.endpoints[Services.QUERY_JOB] = environment.services.url + '/users/${userId}/queries/${jobId}';
           this.endpoints[Services.DEVICE_TREE] = environment.services.url + '/orgs/${orgId}/device-tree';
        }
        return this.endpoints;
    }

    getEndpointRoot(): string {
        return environment.services.url;
    }

    getEndpoint(service, args?: any): string {
        const endpoint = this.getEndpoints()[service];
        if (args === undefined) {
            return endpoint;
        }
        const compiled = template(endpoint);
        return compiled(args);
    }
}
