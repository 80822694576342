import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {Form, FormControl, FormGroup, NgForm, Validators} from '@angular/forms';
import {OrgService} from '../../org.service';
import {isEqual, cloneDeep, pick, merge} from 'lodash';
import {MessageService} from '../../../error/message.service';

const PREFERENCES = {
    NOTIFY_OFFLINE_DEVICES: 'notifyOfflineDevices',
    AUTO_REGISTER_DEVICES: 'autoRegisterDevices',
    DEFAULT_SENSOR_LAYOUT: 'defaultSensorLayout'
};

const DEFAULT_PREFERENCES = {};

DEFAULT_PREFERENCES[PREFERENCES.NOTIFY_OFFLINE_DEVICES] = true;
DEFAULT_PREFERENCES[PREFERENCES.AUTO_REGISTER_DEVICES] = false;
DEFAULT_PREFERENCES[PREFERENCES.DEFAULT_SENSOR_LAYOUT] = 'List';

@Component({
    selector: 'edit-org-general',
    templateUrl: './edit-org-general.component.html',
    styleUrls: ['./edit-org-general.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class EditOrgGeneral implements OnInit, OnChanges {

    orgForm: FormGroup;
    sensorLayouts = ['List', 'Tree', 'Map'];
    @Input() org;
    working = false;
    constructor(private route: ActivatedRoute,
                private orgService: OrgService,
                private router: Router,
                private messageService: MessageService) {
        this.orgForm = new FormGroup({
            orgName: new FormControl('', [Validators.required]),
            offlineNotify: new FormControl('', []),
            autoRegisterDevices: new FormControl('', []),
            defaultSensorLayout: new FormControl('', [])
        });
    }

    ngOnInit() {

    }

    ok() {
        if (this.orgForm.dirty) {

            this.org.name = this.orgForm.controls.orgName.value;
            this.org.preferences[PREFERENCES.NOTIFY_OFFLINE_DEVICES] = this.orgForm.controls.offlineNotify.value;
            this.org.preferences[PREFERENCES.AUTO_REGISTER_DEVICES] = this.orgForm.controls.autoRegisterDevices.value;
            this.org.preferences[PREFERENCES.DEFAULT_SENSOR_LAYOUT] = this.orgForm.controls.defaultSensorLayout.value;
            this.working = true;
            this.orgService.updateOrg(pick(this.org, ['id', 'name', 'preferences'])).then(() => {
                this.messageService.handleSuccess(`The organization was saved successfully`);
                this.orgForm.markAsPristine();
            }).finally(() => {
                this.working = false;
            });
        }
    }

    cancel() {
        this.router.navigate(['sensors']).finally();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.org && !isEqual(changes.org.currentValue, changes.org.previousValue)) {
            this.orgForm.controls.orgName.setValue(this.org.name);

            const prefs = this.org.preferences = merge(cloneDeep(DEFAULT_PREFERENCES) , this.org.preferences || {});
            this.orgForm.controls.offlineNotify.setValue(prefs[PREFERENCES.NOTIFY_OFFLINE_DEVICES]);
            this.orgForm.controls.autoRegisterDevices.setValue(prefs[PREFERENCES.AUTO_REGISTER_DEVICES]);
            this.orgForm.controls.defaultSensorLayout.setValue(prefs[PREFERENCES.DEFAULT_SENSOR_LAYOUT]);
        }
    }
}
