import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { AppContext } from '../app.context';
import { AppSettings,Services } from '../app.settings';

@Injectable()
export class StatisticsService {

  constructor( private http: HttpClient,private appContext: AppContext,private appSettings: AppSettings){}


  fetchStatistics(){
    return new Promise(async (resolve, reject) => {
      const userId = await this.appContext.getUserId();
      const orgId = await this.appContext.getOrgId();
      const endpoint = this.appSettings.getEndpoint(Services.STATISTICS, {orgId: orgId});
      this.http.get(endpoint, {headers: {"Authorization": userId}})
          .subscribe(function (value: any) {
              resolve(value);
          }, reject);
    });
  }

  fetchLocationStatistics(id: string){
    return new Promise(async (resolve, reject) => {
      const userId = await this.appContext.getUserId();
      const orgId = await this.appContext.getOrgId();
      const endpoint = this.appSettings.getEndpoint(Services.LOCATION_STATISTICS, {orgId: orgId, location: id});
      this.http.get(endpoint, {headers: {"Authorization": userId}})
          .subscribe(function (value: any) {
              resolve(value);
          }, reject);
    });
  }

}
