import { AcceptInviteRequestComponent } from './accept-invite-request/accept-invite-request.component';
import { ApproveInviteRequestComponent } from './approve-invite-request/approve-invite-request.component';
import { JoinExistingOrgComponent } from './join-existing-org/join-existing-org.component';
import { ConfirmPasswordResetComponent } from './confirm-password-reset/confirm-password-reset.component';
import { RequestPasswordResetComponent } from './request-password-reset/request-password-reset.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { InternationalPhoneNumberModule } from 'ngx-international-phone-number';
import { LoginPage } from './login.page';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MaterialModule} from '../material/material.module';
import {SigninComponent} from './signin/signin.component';
import {RegisterComponent} from './register/register.component';
import {SharedModule} from '../shared/shared.module';
import {NgxLoadingModule} from 'ngx-loading';
import {CreateOrJoinComponent} from './create-join/create-join.component';
import {LoginContainerComponent} from './common/login-container.component';

const routes: Routes = [
  {
    path: '',
    component: LoginPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MaterialModule,
    SharedModule,
    InternationalPhoneNumberModule,
    NgxLoadingModule,
    RouterModule.forChild(routes)
  ],
  // tslint:disable-next-line: max-line-length
  declarations: [LoginPage, SigninComponent, RegisterComponent, RequestPasswordResetComponent, ConfirmPasswordResetComponent, JoinExistingOrgComponent,
    ApproveInviteRequestComponent, AcceptInviteRequestComponent, CreateOrJoinComponent, LoginContainerComponent]
})
export class LoginPageModule {}
