import {Component, Inject, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {ProgressBarComponent} from '../../progress-bar/progress-bar.component';

@Component({
    selector: 'progress-dialog',
    templateUrl: './progress-dialog.component.html',
    styleUrls: ['./progress-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ProgressDialogComponent {
    data: any;
    constructor(
        private dialogRef: MatDialogRef<ProgressBarComponent>,
        @Inject(MAT_DIALOG_DATA) data) {
        this.data = data;

    }
}
