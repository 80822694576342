import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material';
import {cloneDeep} from 'lodash';

@Component({
  selector: 'app-add-layer-device',
  templateUrl: './add-layer-device.component.html',
  styleUrls: ['./add-layer-device.component.scss'],
})
export class AddLayerDeviceComponent implements OnInit {

  chosenSensor;
  searchResults;
  searchValue: string = '';
  constructor(private dialogRef: MatDialogRef<AddLayerDeviceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
) { }

  ngOnInit() {
    this.searchResults = cloneDeep(this.data);
  }

  search(){
    this.searchResults = this.data.filter(device=> device.name.toLowerCase().includes(this.searchValue.toLowerCase()));
  }

  resolve(){
    this.dialogRef.close({
      chosenSensor: this.chosenSensor
  });
  }

}
