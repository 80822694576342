import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {
  ApiAuthLoginResponseError,
  IApiAuthLoginRequest,
  IApiAuthLoginResponse,
  IApiOrgPostRequest,
  IApiOrgPostResponse,
  IApiOrgsGetResponse,
  LoginFailureClassifications
} from './iapi-definitions';
import {environment} from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ApiRequestsService {

  constructor(private http: HttpClient) {
  }

  registerOrganization(request: IApiOrgPostRequest): Promise<IApiOrgPostResponse> {
    const promise = new Promise<IApiOrgPostResponse>((resolve, reject) => {
      this.standardCreateResourceHttpHandler(this.buildUrl('/orgs'), request, resolve, reject);
    });
    return promise;
  }

  getOrganization(orgId: string): Promise<IApiOrgsGetResponse> {
    const promise = new Promise<IApiOrgsGetResponse>((resolve, reject) => {
      let params = new HttpParams();
      params = params.append('includeUsers', 'true');
      this.standardGetResourceHttpHandler(this.buildUrl('/orgs/' + orgId), {params: params}, resolve, reject);
    });
    return promise;
  }


  buildUrl(path: string): string {
    return `${environment.services.url}` + path;
  }

  standardGetResourceHttpHandler(
      url: string,
      options: {
        headers?: HttpHeaders,
        params?: HttpParams,
      },
      resolve: any, reject: any) {
    // TODO add auditing
    this.http.get(url, options)
        .subscribe(value => {
          if (value) {
            resolve(value);
          } else {
            // Something is wrong on the backend
            reject(new Error('Url ' + url + ' returned OK yet has null data?'));
          }
        }, error1 => {
          reject(error1);
        });
  }

  standardCreateResourceHttpHandler<T>(url: string, postData: T, resolve: any, reject: any) {
    // TODO add auditing
    this.http.post(url, postData)
        .subscribe(value => {
          resolve(value);
        }, error1 => {
          reject(error1);
        });
  }

  login(request: IApiAuthLoginRequest): Promise<IApiAuthLoginResponse> {
    let body;
    if (request.newPassword !== null && request.newPassword.length > 0) {
      body = new HttpParams()
          .set('userid', request.userid)
          .set('password', request.password)
          .set('newPassword', request.newPassword);
    } else {
      // Does the sever care if newPassword='' to not send t?
      body = new HttpParams()
          .set('userid', request.userid)
          .set('password', request.password);
    }

    const promise = new Promise<IApiAuthLoginResponse>((resolve, reject) => {
      this.http.post(
          this.buildUrl('/auth/login'),
          body.toString(),
          {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded')
          })
          .subscribe(value => {
            if (value.hasOwnProperty('idToken')) {
              const ret: IApiAuthLoginResponse = {
                idToken: value['idToken'],
              };
              resolve(ret);
            } else {
              reject(new ApiAuthLoginResponseError(
                  LoginFailureClassifications.GENERAL,
                  new Error('server returned 200 from login without idToken' )));
            }
          }, error1 => {
            // See if we can get specific domain knowledge about the error
            let reason = 'GENERAL';
            if (error1.error && error1.error.reason) {
              reason = error1.error.reason;
            }
            reject(new ApiAuthLoginResponseError(
                ApiAuthLoginResponseError.mapClassification(reason),
                error1));
          });
    });
    return promise;
  }

}
