import { NgModule } from '@angular/core';
import { AuthService } from './auth.service';
import { AuthGuardService } from './auth-guard.service';
import {AdminGuardService} from './admin-guard.service';

@NgModule({
    providers: [
       AuthService,
       AuthGuardService,
       AdminGuardService
    ]
})
export class AuthModule {}
