import { isNil } from 'lodash';
import { User } from './users.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { AppSettings, Services } from '../app.settings';
import { OrgMembership } from '../orgs/org.service';

export interface User {
  userName: string;
  name: string;
  phoneNumber: string;
  email: string;
  systemRole: string;
  orgMemberships: [OrgMembership];
}

export interface NewUser {
  options: {
    notification: string;
  };
  user: {
    phone: string;
    name: string;
    email: string;
    password: string
  };
}

@Injectable()
export class UserService {
  constructor(private http: HttpClient, private appSettings: AppSettings) {  }
  whoami(): Promise<User> {
    const endpoint = this.appSettings.getEndpoint(Services.WHOAMI);
    const self = this;
    return new Promise(function(resolve, reject) {
      self.http.get(endpoint).subscribe(
        function(value: User) {
          if (value) {
            resolve(value);
          } else {
            // Something is wrong on the backend
            reject(
              new Error('Url ' + endpoint + ' returned OK yet has null data?')
            );
          }
        },
        function(err) {
          reject(err);
        }
      );
    });
  }

  async createUser(newuser: NewUser): Promise<any> {
    const endpoint = this.appSettings.getEndpoint(Services.USERS);
    const self = this;
    return new Promise(function(resolve, reject) {
      self.http.post(endpoint, newuser)
      .subscribe(
        function(value: any) {
          resolve();
        },
        function(err) {
          reject(err);
        }
      );
    });
  }

  async getUserDetails(userId): Promise<any> {
    const endpoint = this.appSettings.getEndpoint(Services.USER, {userId: userId});
    const self = this;
    return new Promise(function(resolve, reject) {
        self.http.get(endpoint)
            .subscribe(function (result: any) {
                if (result.Item) {
                    resolve(result.Item);
                }
            }, function(err) {
                reject(err);
            });
    });
}

  async update(userId, user: User): Promise<any> {
    const endpoint = this.appSettings.getEndpoint(Services.USER, {
      userId: userId
    });
    const self = this;
    return new Promise(function(resolve, reject) {
      self.http
        .put(endpoint, {
          email: user.email,
          phone: user.phoneNumber,
          name: user.name
        })
        .subscribe(
          function(value: any) {
            resolve();
          },
          function(err) {
            reject(err);
          }
        );
    });
  }

  async changePassword(password, newpassword, user: User): Promise<any> {
    const endpoint = this.appSettings.getEndpoint(Services.CHANGE_PASSWORD, {
      userId: user.userName
    });
    const self = this;
    return new Promise(function(resolve, reject) {
      self.http
        .post(endpoint, {
          userId: user.email,
          oldPassword: password,
          newPassword: newpassword
        })
        .subscribe(
          function(value: any) {
            resolve();
          },
          function(err) {
            reject(err);
          }
        );
    });
  }

  async sendLoginLink(username: string): Promise<any> {
    const endpoint = this.appSettings.getEndpoint(
      Services.REQUEST_PASSWORD_RESET
    );
    const self = this;
    console.log(' username>>> ' + username);
    return new Promise(function(resolve, reject) {
      self.http.post(endpoint, { email: username }).subscribe(
        function(value: any) {
          resolve();
        },
        function(err) {
          reject(err);
        }
      );
    });
  }

  async resetPassword(resettoken: string, username: string): Promise<any> {
    const endpoint = this.appSettings.getEndpoint(Services.RESET_PASSWORD);
    const self = this;
    console.log(' resetToken>>> ' + resettoken + 'Email>>> ' + username);
    return new Promise(function(resolve, reject) {
      self.http
        .post(endpoint, { email: username, resetToken: resettoken })
        .subscribe(
          function(value: any) {
            resolve(value);
          },
          function(err) {
            reject(err);
          }
        );
    });
  }

  async inviteRequest(username: string, email: string, adminEmail: string): Promise<any> {
    const endpoint = this.appSettings.getEndpoint(Services.INVITE_REQUESTS);
    const self = this;
    return new Promise(function(resolve, reject) {
      self.http
      .post(endpoint, {email: email, name: username, adminEmail: adminEmail})
      .subscribe(
        function(value: any) {
          resolve(value);
        },
        function(err) {
          reject(err);
        }
      );
    });
  }
}
