import {Directive, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, Validator, ValidatorFn} from '@angular/forms';
import {isNil} from 'lodash';

@Directive({
    selector: '[pattern]',
    providers: [{provide: NG_VALIDATORS, useExisting: PatternValidatorDirective, multi: true}]
})
export class PatternValidatorDirective implements Validator {
    @Input('pattern') pattern: string;

    validate(control: AbstractControl): {[key: string]: any} | null {
        return this.pattern ? this.patternValidator(new RegExp(this.pattern, 'i'))(control)
            : null;
    }

    patternValidator(nameRe: RegExp): ValidatorFn {
        return (control: AbstractControl): {[key: string]: any} | null => {
            if (isNil(control.value)) {
                return null;
            }
            const regextest = nameRe.test(control.value);
            return (!regextest) ? {'pattern': {value: control.value}} : null;
        };
    }
}
