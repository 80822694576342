/**
 * Defines all interfaces and abstract classes that interact with a data graph
 */

import {Component, EventEmitter, Injector, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, Type} from '@angular/core';
import {AbstractControl, ControlValueAccessor, FormGroup, ValidationErrors, Validator} from '@angular/forms';
import {DeviceDataService, IDeviceDataQuery, IDeviceDataQueryResult} from '../deviceData/device-data.service';
import {extend, forEach, find, isEqual} from 'lodash';
import * as moment from 'moment';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';

export class NoDataError extends Error {
    constructor(message?: string) {
        super(message); // 'Error' breaks prototype chain here
        Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
    }
}


export const GRAPH_ACTION_IDS = {
    EXPAND: 'expand',
    DELETE: 'delete',
    EDIT: 'edit',
    REFRESH: 'refresh'
};

export const TIME_DURATIONS = {
    FIFTEEN_MINS : '15m',
    ONE_HOUR : '1h',
    FOUR_HOURS : '4h',
    ONE_DAY : '1d',
    TWO_DAYS : '2d',
    ONE_WEEK : '1w',
    ONE_MONTH : '1M'
};

export const TIME_DURATION_CHOICES = [{
    display: 'The Past 15 Minutes',
    value: TIME_DURATIONS.FIFTEEN_MINS
}, {
    display: 'The Past Hour',
    value: TIME_DURATIONS.ONE_HOUR
}, {
    display: 'The Past 4 Hours',
    value: TIME_DURATIONS.FOUR_HOURS
}, {
    display: 'The Past Day',
    value: TIME_DURATIONS.ONE_DAY
}, {
    display: 'The Past 2 Days',
    value: TIME_DURATIONS.TWO_DAYS
}, {
    display: 'The Past Week',
    value: TIME_DURATIONS.ONE_WEEK
}, {
    display: 'The Past Month',
    value: TIME_DURATIONS.ONE_MONTH
}];

/**
 * A graph specification is the data model of a graph.  This will vary greatly between graphs.  This attempts to
 * include the required attributes of a base graph.
 */
export class GraphSpec {
    id?: string;
    graphId?: string;
    title: string;
    type: string;
    layout: IGraphLayout;
    ordinal?: number;
    constructor(desc?: any) {
        if (desc) {
            extend(this, desc);
        }
    }
}

export const COMPACT_GRAPH_HEIGHT = 190;
export const COMPACT_GRAPH_TOOLBAR_HEIGHT = 40;
export const COMPACT_GRAPH_CONTAINER_HEIGHT = COMPACT_GRAPH_HEIGHT + COMPACT_GRAPH_TOOLBAR_HEIGHT;

/**
 * Specifies a time range.  It could a time range with a to/from, or it could be a
 * simple duration
 */
export interface ITimeRange {
    range?: {
        to: string;
        from: string;
    };
    duration?: string;

}

export interface IGraphBuilderSpecField {
    type: string;
    label?: string;
    specPath: string;
    name?: string;
    multiple?: boolean;
    required?: boolean;
}

export interface IGraphBuilderSpecStep {
    title: string;
    fields: IGraphBuilderSpecField[];
}

export interface IGraphBuilderSpec {
    steps: IGraphBuilderSpecStep[];
}

export interface IGraphBuilder {
    builderForm: FormGroup;
    graphSpec: GraphSpec;
    startStepNumber: number;
    getNumberOfSteps(): number;
}

export interface IDeclarativeGraphBuilder extends IGraphBuilder {
    graphBuilderSpec: IGraphBuilderSpec;
}

/**
 * A graph data provider is able to take a graph spec and convert it into a query to send to the device data service
 */
export interface IGraphDataProvider<T> {
    query(graph: IGraph, fromTimestamp: string, toTimestamp?: string): Promise<T>;
}

/**
 * A graph action is an action that can be performed on a graph.  The graph will simply call its run() function.
 * The graph will be a receiver of the actions.  There should not be a tight coupling between the graph and the action.
 */
export interface IGraphAction {
    id: string;
    name: string;
    ionIcon?: string;
    matIcon?: string;
    children?: IGraphAction[];
    run?(graph: IGraph, view: IGraphView<any>): Promise<any>;
}

/**
 * This is a UI element which will wrapper a graph view
 */
export interface IGraphContainer {
    graph: IGraph;
    compact: boolean;
    timeRange: ITimeRange;
    actions: IGraphAction[];
}

export interface IGraphView<T> {
    graph: IGraph;
    timeRange: ITimeRange;
    height?: number;
    width?: number;
    dataProvider: IGraphDataProvider<T>;
    loading: EventEmitter<boolean>;
    working: boolean;
    compact: boolean;
    preview: boolean;
    isMobile: boolean;
    refresh(): void;
    setTimeRange(timeRange: ITimeRange): void;
    setGraph(graph: IGraph): void;
    setWidth(newWidth: number): void;
}

export class IGraphLayout {
    cols: number;
}

export interface IGraphContext {
    deviceTypeId: string;
    platformDeviceIds: string[];
}

export class GraphContext implements IGraphContext {
    constructor(public deviceTypeId: string,
                public platformDeviceIds: string[]) {}
}

export interface IGraphTypeDescriptor<T extends GraphSpec> {
    parent?: string;
    name: string;
    id: string;
    colWidth: number;
    image?: string;
    builder: {
        specification?: IGraphBuilderSpec;
        component?: Type<IGraphBuilder>;
    };
    resolve(graphJson: T, graphContext: IGraphContext): T;
    getViewComponent(): Type<IGraphView<any>>;
    newSpec(): T;
    filterActions?(actions: IGraphAction[]): IGraphAction[];
}

function convertTimeRangeToTimestamp(timeRange: ITimeRange) {
    if (!timeRange) {
        timeRange = {
            duration: '1h'
        };
    }
    if (timeRange.duration) {
        const momentStr = timeRange.duration;
        const from = moment();
        const timeUnit: moment.unitOfTime.DurationConstructor =
            <moment.unitOfTime.DurationConstructor>momentStr.substr(momentStr.length - 1);
        const timeAmt: number = parseInt(momentStr.substr(0, momentStr.length - 1), 10);
        from.subtract(timeAmt, timeUnit);
        return from.toISOString();
    }
    throw new Error(`Time range not recognized`);

}

/**
 * An IGraph represents the graph spec model in addition to runtime contexts.
 */
export interface IGraph {
    spec: GraphSpec;
    typeDescriptor: IGraphTypeDescriptor<GraphSpec>;
    layout: any;
}

@Component({template: ''})
export class AbstractGraphDataProvider<T> implements IGraphDataProvider<T> {
    protected deviceDataService: DeviceDataService = null;
    constructor(private injector: Injector) {
        this.deviceDataService = <DeviceDataService>injector.get(DeviceDataService);
    }

    query(graph: IGraph, fromTimestamp: string, toTimestamp?: string): Promise<T> {
        throw new Error(`Subclass should needs to implement`);
    }

    getDeviceTimestampFromRange(timeRange: ITimeRange) {
        return {
            $gt: convertTimeRangeToTimestamp(timeRange)
        };
    }

}

export class Graph implements IGraph {
    layout: any;
    constructor(public spec: GraphSpec,
                public typeDescriptor: IGraphTypeDescriptor<GraphSpec>
                ) {}
}

export interface DeviceMetric {
    deviceTypeId: string;
    platformDeviceId: string;
    fieldPath: string;
}

export abstract class AbstractGraphView<T> implements IGraphView<T>, OnInit, OnDestroy {
    graph: IGraph;
    loading = new EventEmitter<boolean>();
    noData = false;
    private timer;
    height: number;
    width: number;
    timeRange: ITimeRange;
    working = false;
    compact = false;
    preview = false;
    isMobile = false;
    currentDuration;
    currentResultSet;

    graphUpdated = new EventEmitter<IGraph>();
    protected constructor(public dataProvider: IGraphDataProvider<T>,
                          public breakpointObserver: BreakpointObserver) {
        breakpointObserver.observe([Breakpoints.Handset, Breakpoints.Small]).subscribe(result => {
            this.isMobile = result.matches;
        });
    }

    setupRefreshTimer() {
        const now = moment();
        const queryTime = moment(convertTimeRangeToTimestamp(this.timeRange));
        const duration = moment.duration(now.diff(queryTime)).asMinutes();
        if (this.currentDuration !== duration) {
            this.currentDuration = duration;
            if (this.timer) {
                clearInterval(this.timer);
            }
            if (duration <= 60 || this.shouldAlwaysRefresh()) {
                this.timer = setInterval(() => {
                    this.refresh();
                }, 30000);
            }
        }
    }

    ngOnInit(): void {
        // this.timer = setInterval(() => {
        //     this.update();
        // }, 60000);
        this.setupRefreshTimer();
        this.refresh();
    }

    ngOnDestroy(): void {
        if (this.timer) {
            clearInterval(this.timer);
        }
    }

    /**
     * This should be called on a timer.  A slice of data from the last watermark is fetched
     */
    // update() {
    //     if (this.watermark) {
    //         this.dataProvider.query(this.graph, this.watermark.toISOString()).then((queryResult) => {
    //             this.onDataUpdateReceived(queryResult, this.graph);
    //         });
    //         this.watermark = moment();
    //     }
    // }

    refresh() {
        if (!this.working) {
            // this.watermark = moment();
            this.working = true;
            this.loading.emit(true);
            this.noData = false;
            this.dataProvider.query(this.graph, convertTimeRangeToTimestamp(this.timeRange)).then((queryResult) => {
                try {
                    if (!isEqual(queryResult, this.currentResultSet)) {
                        this.currentResultSet = queryResult;
                        this.onDataReceived(queryResult, this.graph);
                    }
                } catch (e) {
                    console.error(e);
                }
            }).finally(() => {
                this.working = false;
                this.loading.emit(false);
            });
        }
    }

    protected onDataReceived(resultSet: T, graph: IGraph) {
        throw new Error('Subclass needs to implement onDataReceived');
    }

    protected onDataUpdateReceived(resultSet: T, graph: IGraph) {
        throw new Error('Subclass needs to implement onDataUpdateReceived');
    }

    protected onGraphUpdated() {
        // This is meant for the subclasses to notify them that the graph was updated
    }

    protected onDimChanged() {
        // This is meant for the subclasses to notify them that the graph was updated
    }

    protected shouldAlwaysRefresh() {
        return false;
    }

    setTimeRange(timeRange: ITimeRange): void {
        this.timeRange = timeRange;
        this.setupRefreshTimer();
        this.refresh();
    }

    setGraph(graph: IGraph): void {
        this.graph = graph;
        this.onGraphUpdated();
        this.refresh();
    }

    setWidth(newWidth: number): void {
        this.width = newWidth;
        this.onDimChanged();
    }
}

@Component({ template: '' })
export class AbstractGraphBuilder implements IGraphBuilder {
    graphSpec: GraphSpec;
    builderForm: FormGroup;
    startStepNumber: number;
    constructor() {
        this.builderForm = new FormGroup({});
        this.startStepNumber = 0;
    }

    getNumberOfSteps(): number {
        throw new Error(`Subclass must implement getNumberOfSteps`);
    }




}
