import {Component, OnInit, Type, ViewEncapsulation} from '@angular/core';
import {IGraphBuilderSpec, IGraphContext, IGraphTypeDescriptor, IGraphView} from '../../graph.component';
import {ILastValueQueryResult} from '../../../deviceData/device-data.service';
import {LinearGauge, RadialGauge} from 'canvas-gauges';
import * as uuid from 'uuid';
import {
    AbstractGaugeViewComponent, BaseBuilder,
    BaseBuilderOptionsStep,
    GaugeGraphSpec, GaugeGraphTypeDescriptor,
    SelectMetricBuilderStep
} from '../gauge.component';


@Component({
    selector: 'sen-radial-gauge',
    templateUrl: './radial-gauge.component.html',
    styleUrls: ['./radial-gauge.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class RadialGaugeViewComponent extends AbstractGaugeViewComponent implements OnInit {
    gaugeId = uuid();
    gauge;
    timestamp;
    buildGauge(options: any, value: any, timestamp: any) {
        this.timestamp = timestamp;
        if (this.gauge) {
            this.gauge.update(options);
        } else {
            options.renderTo = this.gaugeId;
            this.gauge = new RadialGauge(options);
            this.gauge.draw();
        }
        this.gauge.value = value;
    }
}

export class RadialGaugeGraphTypeDescriptor extends GaugeGraphTypeDescriptor {
    constructor() {
        super('radial-gauge', 'Radial Gauge', RadialGaugeViewComponent, 'radial-gauge.png');
    }
}
