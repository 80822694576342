

/**
 * Did the server respond?
 */
export enum ErrorClassifications {
    Unknown, ServerResponseUnderstood, ServerResponseUnexpected
}

export interface IApiOrgPostRequest {
    account: {
        name: string;
    };
    owner: {
        user: {
            email: string;
            name: string;
            phone: string;
        }
        options: {
            notification: string;
        };
    };
}

export interface IApiOrgPostResponse {
    /**
     * Account name?.
     */
    name: string;
    id: string;
    owner: {
        id: string;
    };
}

export interface IApiAuthLoginRequest {
    userid: string;
    password: string;
    newPassword: string;
}


export interface IApiAuthLoginResponse {
    idToken: string;
}


export enum LoginFailureClassifications {
    NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED',
    GENERAL = 'GENERAL'
}

export class ApiAuthLoginResponseError {

    public static mapClassification(anyText: string): LoginFailureClassifications {
        const mayBeClassification: LoginFailureClassifications | undefined = (<any>LoginFailureClassifications)[anyText];
        if (mayBeClassification !== undefined) {
            return mayBeClassification;
        } else {
            // Server is sending currently unsupported classification - we should add UI support
            return LoginFailureClassifications.GENERAL;
        }
    }

    constructor(
        private reason: LoginFailureClassifications,
        private stdError: any) {
    }

    getErrorClassification(): LoginFailureClassifications {
        return this.reason;
    }

    getErrorMsg(): string {
        if (this.stdError.hasOwnProperty('error') &&
            this.stdError['error'].hasOwnProperty('message')) {
            return this.stdError.error.message;
        } else {
            return this.stdError.message;
        }
    }

}

export interface IOrgUser {
    userName: string;
    name: string;
    email: string;
}

export interface IApiOrgsGetResponse {
    Item: {
        id: string;
        name: string;
        clientIds: string[];
        users: IOrgUser[]
    };
}
