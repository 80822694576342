import {Component, Inject, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatButton, MatDialogRef} from '@angular/material';

const DEFAULT_PATTERN = {
    expression : '.*',
    error : 'Invalid pattern'
};

@Component({
    selector: 'list-dialog',
    templateUrl: './list-dialog.component.html',
    styleUrls: ['./list-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ListDialogComponent {
    title: string;
    label: string;
    description: string;
    models: any[];
    selectedModel = null;
    constructor(
        private dialogRef: MatDialogRef<ListDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data) {
        this.title = data.title;
        this.description = data.description;
        this.title = data.title;
        this.models = data.models;
    }

    ok() {
        this.dialogRef.close(this.selectedModel);
    }

    cancel() {
        this.dialogRef.close();
    }
}
