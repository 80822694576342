import {NGXLogger} from 'ngx-logger';

export class ModuleLogger {
    constructor(private logger: NGXLogger, private subSystem) {
    }

    trace(message: any, ...additional: any[]): void {
        this.logger.trace(this.subSystem + '::' + message, additional);
    }
    debug(message: any, ...additional: any[]): void {
        this.logger.debug(this.subSystem + '::' + message, additional);
    }
    info(message: any, ...additional: any[]): void {
        this.logger.info(this.subSystem + '::' + message, additional);
    }
    log(message: any, ...additional: any[]): void {
        this.logger.log(this.subSystem + '::' + message, additional);
    }
    warn(message: any, ...additional: any[]): void {
        this.logger.warn(this.subSystem + '::' + message, additional);
    }
    error(message: any, ...additional: any[]): void {
        this.logger.error(this.subSystem + '::' + message, additional);
    }
    fatal(message: any, ...additional: any[]): void {
        this.logger.fatal(this.subSystem + '::' + message, additional);
    }
}
