import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {RouterModule} from '@angular/router';
import {NgxLoadingModule} from 'ngx-loading';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MaterialModule} from '../material/material.module';
import {LoggerModule} from 'ngx-logger';
import {environment} from '../../environments/environment';
import {SharedModule} from '../shared/shared.module';
import {OrgService} from './org.service';
import {OrgEditor} from './edit/edit-org.component';
import {EditOrgGeneral} from './edit/general/edit-org-general.component';
import {EditOrgIntegrations} from './edit/integrations/edit-org-int.component';
import {IntegrationsModule} from '../integrations/integrations.module';
import {EditOrgClientIdsComponent} from './edit/clientids/edit-clientids.component';
import {OrgEditorPage} from './edit/page/edit-page.component';
import {EditOrgUsersComponent} from './edit/users/users.component';
import {ClipboardModule} from 'ngx-clipboard';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        NgxLoadingModule,
        FlexLayoutModule,
        MaterialModule,
        ReactiveFormsModule,
        SharedModule,
        IntegrationsModule,
        ClipboardModule
    ],
    providers: [
        OrgService,
    ],
    exports: [],
    declarations: [OrgEditor, EditOrgGeneral, EditOrgIntegrations, EditOrgClientIdsComponent, OrgEditorPage, EditOrgUsersComponent],
    entryComponents: []
})
export class OrgModule {
}
