import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {MessageService} from '../../../error/message.service';
import {OrgService} from '../../../orgs/org.service';
import {AppContext} from '../../../app.context';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'integration-oauth2',
    templateUrl: './integration-oauth2.component.html'
})

export class IntegrationOAuth2Component implements OnInit {
    working = false;
    user = null;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private messageService: MessageService,
        private orgService: OrgService,
        private appContext: AppContext
    ) {}

    goToIntegrationsEditor() {
        this.router.navigate(['edit-org'], {queryParams : {tab: 'integrations'}}).finally();
    }

    ngOnInit() {
        const code = this.route.snapshot.queryParamMap.get('code');
        const state = this.route.snapshot.queryParamMap.get('state');
        const intId = this.route.snapshot.params.intId;
        this.working = true;
        this.appContext.getUser().then((user) => {
            if (user.userName !== state) {
                return this.messageService.handleError(`The application state does not match.  The integration cannot proceed.  Contact the admin for further assistance.` , 'Integration Error');
            }
            this.orgService.createIntegration({
                auth: {
                    code: code
                },
                stage: environment.env,
                platformIntId: intId
            }).then(() => {
                this.messageService.handleSuccess(`The integration with Jira Service Desk has been installed`);
                this.goToIntegrationsEditor();
            }, () => {
                this.goToIntegrationsEditor();
            }).finally(() => {
                this.working = false;
            });
        });
    }
}
