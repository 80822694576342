import {Component, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {template} from 'lodash';
import {ActivatedRoute, Router} from '@angular/router';
import {OrgService} from '../../orgs/org.service';
import { FormControl, Validators, FormBuilder, FormGroup, ReactiveFormsModule, NgForm } from '@angular/forms';
import {IntegrationsService} from '../integrations.service';


@Component({
    selector: 'integration-configuration',
    templateUrl: './integration-configure.component.html',
    styleUrls: ['./integration-configure.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class IntegrationConfigComponent implements OnInit {
    @ViewChild('configForm', {static: false}) configForm: NgForm;
    integration;
    working = false;
    constructor(private orgService: OrgService,
                private platformIntegrationService: IntegrationsService,
                private activeRoute: ActivatedRoute) {}

    ngOnInit(): void {
        this.working = true;

        this.orgService.getIntegration(this.activeRoute.snapshot.params.intId , {expand: 'type'}).then((int) => {
            this.integration = int;
        }).finally(() => {
            this.working = false;
        });
    }

    cancel() {
        console.log('Cancel....');
    }
}
