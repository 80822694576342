import { forEach, cloneDeep, isNil, get } from 'lodash';
import { Pipe, PipeTransform } from '@angular/core';

function inspectFields(object, fields, searchText) {
    let match = false;
    forEach(fields, function(field) {
        const value = get(object, field);
        if (value && value.toLowerCase().includes(searchText)) {
            match = true;
            return false;
        }
    });
    return match;
}


@Pipe({
  name: 'sensor-search-filter'
})
export class SensorSearchFilterPipe implements PipeTransform {

  transform(items: any[], searchText: string): any[] {

      if (!items) {
          return [];
      }

      if (isNil(searchText) || searchText.trim().length === 0) {
          return items;
      }
      searchText = searchText.toLocaleLowerCase();

      return items.filter(sensor => {
          let tagFound = false;
          forEach(sensor.tags, function(value, key) {
              if (value.toLowerCase().includes(searchText)) {
                  tagFound = true;
                  return false;
              }
          });
          if (tagFound) {
              return true;
          }
          return inspectFields(sensor, ['name', 'id', 'deviceId', 'status'], searchText);
      });
  }


}
