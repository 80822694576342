import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {Routes, RouterModule} from '@angular/router';
import {MaterialModule} from '../material/material.module';
import {FlexLayoutModule} from '@angular/flex-layout';
import {IonicModule} from '@ionic/angular';



import {SharedModule} from '../shared/shared.module';

import {NgxLoadingModule} from 'ngx-loading';
import {AlarmUpdateService} from '../alarms/alarm-update.service';

import {TagSelectorDialogComponent} from '../shared/dialogs/tag-selector/tag-selector-dialog.component';

import {IntegrationsModule} from '../integrations/integrations.module';
import {AlarmsService} from './alarms.service';
import {AlarmsComponent} from './alarms.component';
import {AlarmHistoryComponent} from './history/alarm-history.component';
import {CommentDialogComponent } from './comment-dialog/comment-dialog.component';

const routes: Routes = [
    {
        path: '',
        component: AlarmsComponent
    }
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild(routes),
        FlexLayoutModule,
        MaterialModule,
        NgxLoadingModule,
        SharedModule,
        IntegrationsModule
    ],
    providers: [AlarmsService, AlarmUpdateService],
    declarations: [AlarmsComponent, AlarmHistoryComponent,CommentDialogComponent],
    entryComponents: [CommentDialogComponent]
})
export class AlarmsModule {
}
