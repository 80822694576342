import {Component, Inject, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatButton, MatDialogRef} from '@angular/material';

@Component({
    selector: 'message-dialog',
    templateUrl: './message-dialog.component.html',
    styleUrls: ['./message-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class MessageDialogComponent {
    title: string;
    message: string;
    constructor(
        private dialogRef: MatDialogRef<MessageDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data) {
        this.title = data.title;
        this.message = data.message;
    }

    ok() {
        this.dialogRef.close(true);
    }
}
