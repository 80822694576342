import {NewUser, UserService} from './../../users/users.service';
import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MessageService} from 'src/app/error/message.service';
import {OrgService} from 'src/app/orgs/org.service';
import {merge, pick, isEmpty} from 'lodash';
import {AppContext} from '../../app.context';
import {AuthService} from '../../auth/auth.service';

@Component({
    selector: 'accept-invite-request',
    templateUrl: './accept-invite-request.component.html',
    styleUrls: ['./accept-invite-request.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AcceptInviteRequestComponent implements OnInit {
    loading = false;
    accepting = false;
    token;
    inviteId;
    user = {
        name: '',
        phone: '',
        email: '',
        password: ''
    };
    phone;
    password;
    newPassword;
    notFound = false;
    needsRegistration = false;
    invite;
    input = true;
    login = false;
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private messageService: MessageService,
        private orgService: OrgService,
        private userService: UserService,
        private appContext: AppContext,
        private authService: AuthService
    ) {
    }

    ngOnInit() {
        const self = this;
        self.loading = true;
        this.route.queryParamMap.subscribe(queryParams => {
            this.inviteId = queryParams.get('id');
            this.token = queryParams.get('token');
        });
        this.orgService
            .getInvite(this.inviteId)
            .then(
                value => {
                    this.invite = value;
                    merge(this.user, pick(this.invite, ['name', 'email']));
                    if (isEmpty(this.user.name, true) || this.user.name === null) {
                        this.input = false;
                    }
                    this.needsRegistration = !this.invite.userExists;
                    if (!this.needsRegistration) {
                        this.accept();
                    }
                },
                response => {
                    if (response.status === 404) {
                        this.notFound = true;
                    }
                }
            )
            .finally(() => {
                this.loading = false;
            });
    }

    accept() {
        this.loading = true;
        this.orgService
            .acceptInvite(this.inviteId, this.token, this.invite.email)
            .then(
                result => {
                    this.appContext.reload().then(() => {
                        this.router.navigate(['/statistics']);
                    }, (err) => {
                        // We can probably eat this.  Reloading should be something implied, not something the user
                        // initiated or can fix
                    });

                },
                response => {
                    // Handle me
                }
            ).finally(() => {
            this.loading = false;
        });
    }

    confirm() {
        const newUser = {
            phone: this.user.phone,
            name: this.user.name,
            email: this.user.email,
            password: this.user.password
        };
        this.loading = true;
        this.orgService
            .acceptInvite(this.inviteId, this.token, this.invite.email, newUser).then((value) => {
            this.login = true;
            this.loading = true;
            this.authService.login(this.user.email, this.user.password).then(() => {
                this.router.navigate(['/statistics']).finally();
            }).finally(() => {
                this.login = false;
                this.loading = false;
            });
        }).finally(() => {
            this.loading = false;
        });
    }
}
