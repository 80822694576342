import {get, isString, forEach} from 'lodash';

export interface IUnitConverter {
    convert(value: any): any;
    getUnit(): string;
}

export interface IUnitConverterFactory {
    getUnitConverter(prefs, unit): IUnitConverter;
}

export abstract class AbstractUnitConverterFactory implements IUnitConverterFactory {

    units: string[] = [];

    propertyKey: string;

    abstract buildUnitConverter(preferredUnit, fieldUnit): IUnitConverter;

    constructor(units, propertyKey) {
        if (isString(units)) {
            units = [units];
        }
        this.units = units;
        this.propertyKey = propertyKey;
    }

    getUnitConverter(prefs, fieldUnit): IUnitConverter | null {
        if (this.units.indexOf(fieldUnit) === -1) {
            return null;
        }
        const preferredUnit = get(prefs, this.propertyKey);
        return preferredUnit && preferredUnit.toUpperCase() !== fieldUnit.toUpperCase()
            ? this.buildUnitConverter(preferredUnit, fieldUnit) : null;
    }
}
