import {Component, forwardRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR, NgForm,
    ValidationErrors,
    Validator
} from '@angular/forms';
import {GraphSpec, IGraphBuilderSpecStep} from '../../../graph.component';
import {forEach, get, set, isUndefined} from 'lodash';
import {FormValidationService} from '../../../../shared/form-validation.service';

@Component({
    selector: 'graph-builder-spec-step',
    templateUrl: './graph-builder-spec-step.component.html',
    styleUrls: ['./graph-builder-spec-step.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => GraphBuilderSpecStepComponent),
            multi: true
        }, {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => GraphBuilderSpecStepComponent),
            multi: true,
        }
    ]
})
export class GraphBuilderSpecStepComponent implements ControlValueAccessor, Validator, OnInit, OnDestroy {
    @Input() stepNumber;
    @Input() stepModel: IGraphBuilderSpecStep;
    graphSpec;
    formChangeSubscription;
    stepForm =  new FormGroup({});
    onChange: any = () => { };
    onTouched: any = () => { };

    constructor(private formValidation: FormValidationService) {
    }

    registerOnChange(fn) {
        this.onChange = fn;
    }

    registerOnTouched(fn) {
        this.onTouched = fn;
    }

    writeValue(value: GraphSpec): void {
        if (value) {
            this.graphSpec = value;
            // Setup the form group using the design spec and the values from the graph spec
            forEach(this.stepModel.fields, (field) => {
                const control = this.stepForm.controls[field.specPath];
                if (control) {
                    const controlValue = get(this.graphSpec, field.specPath);
                    if (!isUndefined(controlValue)) {
                        control.setValue(controlValue);
                    }
                }
            });
            this.formChangeSubscription = this.stepForm.valueChanges.subscribe(val => {
                forEach(val, (formValue, key) => {
                    set(this.graphSpec, key, formValue);
                });
                this.onChange(this.graphSpec);
                this.onTouched();
            });
        }
    }

    validate(control: AbstractControl): ValidationErrors {
        return this.formValidation.collectControlErrors(this.stepForm);
    }

    ngOnInit(): void {
        forEach(this.stepModel.fields, (field) => {
            const c = new FormControl();
            this.stepForm.addControl(field.specPath, c);
        });
    }

    ngOnDestroy(): void {
        if (this.formChangeSubscription) {
            this.formChangeSubscription.unsubscribe();
        }
    }
}
