import {ActivatedRoute, Router} from '@angular/router';
import {OrgService} from '../../org.service';
import {MessageService} from '../../../error/message.service';
import {forEach, findIndex} from 'lodash';
import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material';
import {AddUserComponent} from '../../../users/add/add-user.component';
import {InputDialogComponent} from '../../../shared/dialogs/input/input-dialog.component';
import {ConfirmDialogComponent} from '../../../shared/dialogs/confirm/confirm-dialog.component';

@Component({
    selector: 'edit-org-clientids',
    templateUrl: './edit-clientids.component.html',
    styleUrls: ['./edit-clientids.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class EditOrgClientIdsComponent implements OnInit {
    @Input() org;
    working = false;
    clientIds = [];
    constructor(private route: ActivatedRoute,
                private orgService: OrgService,
                private messageService: MessageService,
                private dialog: MatDialog) {}

    getSelected() {
        return this.clientIds.filter(function(model) {
            return model.selected;
        });
    }

    selectAll() {
        const select = this.getSelected().length === 0;
        forEach(this.clientIds, function(model) {
            model.selected = select;
        });
    }

    _sort() {
        this.clientIds.sort(function(a, b) {
            return a.name.localeCompare(b.name);
        });
    }

    add() {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = '400px';
        dialogConfig.data = {
            title : 'New Client Id',
            description: 'Enter the new client id'
        };

        const dialogRef = this.dialog.open(InputDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.working = true;
                this.orgService.addClientId(result).then(() => {
                    this.clientIds.push({
                        name: result,
                        selected: false
                    });
                    this._sort();
                }).finally(() => {
                    this.working = false;
                });
            }

        });
    }

    async remove() {

        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            title: 'Confirm Delete',
            message: 'Are you sure you want to remove the selected client id(s)?'
        };

        const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
        const self = this;
        dialogRef.afterClosed().subscribe(async (result) => {
            if (result) {
                this.working = true;
                try {
                    for (const model of this.getSelected()) {
                        await this.orgService.removeClientId(model.name);
                        const idx = this.clientIds.indexOf(model);
                        if (idx > -1) {
                            this.clientIds.splice(idx, 1);
                        }
                    }
                } catch (e) {

                } finally {
                    this.working = false;
                }
            }
        });
    }

    ngOnInit(): void {

        forEach(this.org.clientIds, (id) => {
            this.clientIds.push({
                name: id,
                selected: false
            });
        });

        this._sort();
    }
}
