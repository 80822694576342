import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {fromEvent} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, map} from 'rxjs/operators';

@Component({
    selector: 'search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SearchComponent implements OnInit {
    @ViewChild('searchInput', {static: true}) searchInput: ElementRef;
    @Output() search = new EventEmitter();
    @Input() debounce = 0;
    searchTerm;
    constructor() { }


    startSearch() {
        this.search.emit(this.searchTerm);
    }

    ngOnInit() {
        fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
            // get value
            map((event: any) => {
                return event.target.value;
            }) , filter(res => res.length > 0) , debounceTime(this.debounce) , distinctUntilChanged((prev, curr) => {
                return this.searchTerm !== curr;
            })
        ).subscribe((text: string) => {
            this.startSearch();
        });
    }

    onKeydown($event) {
        this.startSearch();
    }

    clear() {
        this.searchTerm = '';
        this.startSearch();
    }
}
