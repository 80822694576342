import {Component, Injector, OnInit, Type, ViewEncapsulation} from '@angular/core';
import {IDataSeriesQueryResult, ILastValueQueryResult} from '../../deviceData/device-data.service';
import {
    AbstractGraphView,
    DeviceMetric, GRAPH_ACTION_IDS,
    GraphSpec,
    IGraph, IGraphAction,
    IGraphBuilderSpec,
    IGraphBuilderSpecStep, IGraphContext,
    IGraphTypeDescriptor, IGraphView
} from '../graph.component';
import * as uuid from 'uuid';
import {GaugeDataProvider} from './gauge.dataprovider';
import {RadialGauge} from 'canvas-gauges';
import {BreakpointObserver} from '@angular/cdk/layout';
import {get, range, forEach, isEqual, isString} from 'lodash';
import {RadialGaugeViewComponent} from './radial/radial-gauge.component';
import {query} from '@angular/animations';

const DEFAULT_WIDTH = 300;

@Component({template: ''})
export class AbstractGaugeViewComponent extends AbstractGraphView<ILastValueQueryResult> implements OnInit {
    // protected options: any = null;
    protected value = null;
    protected timestamp = null;

    constructor(private injector: Injector) {
        super(<GaugeDataProvider>injector.get(GaugeDataProvider), <BreakpointObserver>injector.get(BreakpointObserver));
    }

    dataReceived(queryResult: ILastValueQueryResult, graph: IGraph) {
        const spec = <GaugeGraphSpec>graph.spec;
        const metric = get(spec, 'value.metric');
        if (queryResult.item) {
            this.value = get(queryResult.item, metric.fieldPath);
            this.timestamp = get(queryResult.item, 'deviceTimestamp');
        }
        const options = this.buildOptions();
        this.buildGauge(options, this.value, this.timestamp);
    }

    onDataUpdateReceived(queryResult: ILastValueQueryResult, graph: IGraph) {
        this.dataReceived(queryResult, graph);
    }

    onDataReceived(queryResult: ILastValueQueryResult, graph: IGraph): Promise<any> {
        this.dataReceived(queryResult, graph);
        return Promise.resolve();
    }

    protected onDimChanged() {
    }

    protected buildGauge(options: any, value: any, timestamp: any) {

    }

    protected shouldAlwaysRefresh() {
        return true;
    }

    /**
     * Builds on options object using the known base properties, but delegates to the subclasses to add their own
     * unique properties
     */
    private buildOptions() {
        const spec = <GaugeGraphSpec>this.graph.spec;
        const newOptions: any = {
            width: this.width || DEFAULT_WIDTH,
            height: this.height,
            units: get(spec, 'options.units'),
            colorUnits: get(spec, 'options.unitsColor'),
            title: get(spec, 'options.title'),
            colorTitle: get(spec, 'options.titleColor'),
            minValue: get(spec, 'options.minValue') || 0,
            valueBox: get(spec, 'options.valueBox'),
            maxValue: get(spec, 'options.maxValue') || 100,
            minorTicks: 2,
            strokeTicks: true,
            ticksAngle: 270,
            colorPlate: get(spec, 'options.colorPlate'),
            borderShadowWidth: 0,
            borders: false,
            needleType: 'arrow',
            needleWidth: 2,
            colorStrokeTicks: get(spec, 'options.tickColor'),
            colorMinorTicks: get(spec, 'options.tickColor'),
            colorNumbers: get(spec, 'options.colorNumbers'),
            colorBarProgress: get(spec, 'options.progressBarColor'),
            colorBar: get(spec, 'options.barColor'),
            animationDuration: 1500,
            animationRule: 'linear',
            barWidth: 10
        }
        const majorTickInterval = get(spec, 'options.majorTickInterval') || 10;
        newOptions.majorTicks = range(newOptions.minValue, parseInt(newOptions.maxValue, 10) + parseInt(majorTickInterval, 10), majorTickInterval);
        const highlightRanges = get(spec, 'options.highlightRanges') || [];
        const optionHighlights = [];
        forEach(highlightRanges, (highlightRange) => {
            optionHighlights.push({
                from: highlightRange.start,
                to: highlightRange.end,
                color: highlightRange.color
            });
        })
        newOptions.highlights = optionHighlights;
        return newOptions;
    }

    protected onGraphUpdated() {
        const options = this.buildOptions();
        this.buildGauge(options, this.value, this.timestamp);
    }

    ngOnInit(): void {
        // this.buildOptions();
        super.ngOnInit();
    }
}



export class GaugeGraphSpec extends GraphSpec {
    value: {
        metric: DeviceMetric
    };
    options: {
        units?: string;
        minValue?: number;
        maxValue?: number;
        majorTickInterval?: number;
        colorPlate?: string;
        tickColor?: string;
        colorNumbers?: string;
        valueBox?: boolean;
    };

    constructor(desc?: any) {
        super(desc);
        this.options = {
            minValue: 0,
            maxValue: 100,
            majorTickInterval: 10,
            colorPlate: '#ffffff'
        };
    }
}
export const BaseBuilderOptionsStep = {
    title: 'Options',
    fields: [{
        type: 'input',
        label: 'Units',
        specPath: 'options.units'
    }, {
        type: 'input',
        label: 'Title',
        specPath: 'options.title'
    }, {
        type: 'input',
        label: 'Min Value',
        specPath: 'options.minValue'
    }, {
        type: 'input',
        label: 'Max Value',
        specPath: 'options.maxValue'
    }, {
        type: 'input',
        label: 'Major Tick Interval',
        specPath: 'options.majorTickInterval'
    }, {
        type: 'color-picker',
        label: 'Title color',
        specPath: 'options.titleColor'
    }, {
        type: 'color-picker',
        label: 'Units color',
        specPath: 'options.unitsColor'
    }, {
        type: 'color-picker',
        label: 'Background color',
        specPath: 'options.colorPlate'
    }, {
        type: 'color-picker',
        label: 'Tick mark color',
        specPath: 'options.tickColor'
    }, {
        type: 'color-picker',
        label: 'Number color',
        specPath: 'options.colorNumbers'
    }, {
        type: 'color-picker',
        label: 'Progress Bar color',
        specPath: 'options.barColor'
    }, {
        type: 'color-picker',
        label: 'Progress color',
        specPath: 'options.progressBarColor'
    }, {
        type: 'boolean',
        label: 'Show value box',
        specPath: 'options.valueBox'
    }, {
        type: 'highlight-ranges',
        specPath: 'options.highlightRanges'
    }]
} as IGraphBuilderSpecStep;

export const SelectMetricBuilderStep = {
    title: 'Select Metric',
    fields: [{
        type: 'metric-input',
        specPath: 'value.metric'
    }]
};

const GAUGE_GRAPH_NUM_COL = 3;

export const BaseBuilder = {
    specification: {
        steps: [SelectMetricBuilderStep, BaseBuilderOptionsStep],
        constants: [{
            specPath: 'layout.columns',
            value: GAUGE_GRAPH_NUM_COL
        }]
    } as IGraphBuilderSpec
};

export abstract class GaugeGraphTypeDescriptor implements IGraphTypeDescriptor<GaugeGraphSpec> {
    parent = 'Gauges';
    name;
    id;
    builder;
    graphViewComponent: Type<IGraphView<any>>;
    colWidth = GAUGE_GRAPH_NUM_COL;
    image;
    protected constructor(id: string, name: string, viewComponent: Type<IGraphView<any>>, image: string, builder?: IGraphBuilderSpec) {
        this.name = name;
        this.id = id;
        this.builder = builder || BaseBuilder;
        this.graphViewComponent = viewComponent;
        this.image = image;
    }
    resolve(graphJson: GaugeGraphSpec, graphContext: IGraphContext): GaugeGraphSpec {
        return graphJson;
    }

    getViewComponent(): Type<IGraphView<any>> {
        return this.graphViewComponent;
    }

    newSpec(): GaugeGraphSpec {
        return new GaugeGraphSpec();
    }

    filterActions(actions: IGraphAction[]): IGraphAction[] {
        return actions.filter((action) => {
            return action.id !== GRAPH_ACTION_IDS.EXPAND;
        });
    }
}


