import {HttpClient} from '@angular/common/http';
import {AppSettings, Services} from '../app.settings';
import {AppContext} from '../app.context';
import {Inject, Injectable} from '@angular/core';
import {User} from '../users/users.service';
import {forEach} from 'lodash';
import {Observable} from 'rxjs';
import {UrlUtils} from '../shared/url-utils';
import {map, publishReplay, refCount} from 'rxjs/operators';
import { OrgService } from '../orgs/org.service';

export interface Device {
    clientId: string;
    deviceId: string;
    id: string;
    name: string;
    orgId: string;
    tags: any;
    typeId: string;
    typeName: string;
    lastActivityTimestamp: string;
    status: string;
}

@Injectable()
export class SensorService {
    // By orgId
    sensorCache: { [key: string]: Observable<any> } = {};

    orgService;
    constructor(
        private http: HttpClient,
        private appSettings: AppSettings,
        private appContext: AppContext) {}


    async querySensors(query?): Promise<any> {
        const orgId = await this.appContext.getOrgId();
        if (!orgId) {
            return Promise.reject(`Org could not be obtained`);
        }
        const endpoint = this.appSettings.getEndpoint(Services.SENSORS, {orgId: orgId});
        const qString = UrlUtils.mapToQueryString(query);
        const cacheKey = endpoint + (qString.length > 0 ? '?' : '') + qString;
        let obs = this.sensorCache[cacheKey];

        if (!obs) {
            obs = this.http.get(endpoint, {params: query || {}}).pipe(
                map(res => res),
                publishReplay(1),
                refCount());
            this.sensorCache[cacheKey] = obs;
        }

        return new Promise((resolve, reject) => {
            obs.subscribe(function (value: any) {
                resolve(value);
            }, reject);
        });
    }

    update(device: Device): Promise<Device> {
        return new Promise((resolve, reject) => {
            this.appContext.getOrganization().then((orgMembership) => {
                const endpoint = this.appSettings.getEndpoint(Services.SENSOR, {orgId: orgMembership.orgId, deviceId: device.id});
                this.http.put(endpoint, device)
                    .subscribe((value: Device) => {
                        this._clearCache();
                        resolve(value);
                    }, reject);
            }, reject);
        });

    }

    registerDevice(device: Device): Promise<Device> {

        return new Promise((resolve, reject) => {
            this.appContext.getOrganization().then((orgMembership) => {
                const endpoint = this.appSettings.getEndpoint(Services.SENSORS, {orgId: orgMembership.orgId});
                this.http.post(endpoint, device)
                    .subscribe((value: any) => {
                        this._clearCache();
                        resolve(value);
                    }, reject);
            });
        });
    }

    getDevice(deviceId: string): Promise<Device> {

        return new Promise((resolve, reject) => {
            this.appContext.getOrganization().then((orgMembership) => {
                const endpoint = this.appSettings.getEndpoint(Services.SENSOR, {orgId: orgMembership.orgId, deviceId: deviceId});
                this.http.get(endpoint)
                    .subscribe(function (value: any) {
                        resolve(value.Item);
                    }, function (err) {
                        reject(err);
                    });
            }, reject);
        });

    }

    deleteSensor(deviceId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.appContext.getOrganization().then((orgMembership) => {
                const endpoint = this.appSettings.getEndpoint(Services.SENSOR, {orgId: orgMembership.orgId, deviceId: deviceId});
                this.http.delete(endpoint)
                    .subscribe((value: any) => {
                        this._clearCache();
                        resolve();
                    }, reject);
            }, reject);
        });
    }

    _clearCache() {
        this.sensorCache = {};
    }

}
