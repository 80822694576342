import {Component, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {forEach, find, cloneDeep, findIndex} from 'lodash';
import {MatDialog, MatDialogConfig, MatTableDataSource} from '@angular/material';
import {Router} from '@angular/router';
import {SelectionModel} from '@angular/cdk/collections';
import {OrgService, OrgUser, PendingInvite} from '../../org.service';
import {AppContext} from '../../../app.context';
import {MessageService} from '../../../error/message.service';
import {Roles} from '../../../app.settings';
import {AddUserComponent} from '../../../users/add/add-user.component';
import {ConfirmDialogComponent} from '../../../shared/dialogs/confirm/confirm-dialog.component';
import {EditUserComponent} from '../../../users/edit/edit-user.component';


@Component({
    selector: 'edit-org-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class EditOrgUsersComponent implements OnInit {
    @Input() org;
    loading = false;
    user = null;
    orgMembership = null;
    canEdit = false;
    usersDataSource = new MatTableDataSource<OrgUser>();
    usersDisplayedColumns: string[] = ['select', 'name', 'email', 'role'];
    userSelection = new SelectionModel<OrgUser>(true, []);

    inviteDataSource = new MatTableDataSource<PendingInvite>();
    inviteDisplayColumns: string[] = ['email', /*'name',*/'inviteUrl', 'actions'];

    constructor(private appContext: AppContext,
                private messageService: MessageService,
                private orgService: OrgService,
                private dialog: MatDialog,
                private router: Router) {
        // this.router.routeReuseStrategy.shouldReuseRoute = function () {
        //     return false;
        // };

    }

    isAllSelected() {
        const numSelected = this.userSelection.selected.length;
        const numRows = this.usersDataSource.data.length;
        return numSelected === numRows;
    }

    masterToggle() {
        this.isAllSelected() ?
            this.userSelection.clear() :
            this.usersDataSource.data.forEach(row => this.userSelection.select(row));
    }

    search(searchTerm) {
        console.log('Implement filter for: ', searchTerm);
    }

    refresh(showLoading?) {
        this.userSelection.clear();
        if (showLoading) {
            this.loading = true;
        }
        try {
            this.orgService.getOrg(this.org.id, true).then(async (org) => {
                this.org = org;
                this.org.users.sort(function (a, b) {
                    return a.name.localeCompare(b.name);
                });

                const found = find(this.org.users, (user) => {
                    return user.userName === this.user.userName;
                });

                if (found) {
                    this.orgMembership = found;
                    this.canEdit = await this.appContext.isUserInRole([Roles.ADMIN, Roles.OWNER], this.orgMembership);
                }

                this.usersDataSource.data = this.org.users;
                this.inviteDataSource.data = this.org.invites;
            }).finally(() => {
                this.loading = false;
            });
        } catch (e) {
            this.loading = false;
        }
    }

    addUser() {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            orgId : this.org.id
        };
        const self = this;
        const dialogRef = this.dialog.open(AddUserComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                self.messageService.handleSuccess('An invite was sent to the ' + result );
                self.refresh(false);
            }

        });
    }

    verifyDelete(users) {
        const owners = users.filter(function (user) {
            return user.role === Roles.OWNER;
        });
        if (owners.length > 0) {
            // check to see if there is at least an owner left
            const found = find(this.org.users, function (user) {
                return users.indexOf(user) === -1 && user.role === Roles.OWNER;
            });
            if (!found) {
                this.messageService.handleError('Cannot delete all of the owner(s) of the organization', 'Error deleting users');
                return false;
            }
        }
        return true;
    }

    removeUser(user) {
        if (this.verifyDelete([user])) {
            const dialogConfig = new MatDialogConfig();

            dialogConfig.disableClose = true;
            dialogConfig.autoFocus = true;
            dialogConfig.data = {
                title: 'Confirm Delete',
                message: 'Are you sure you want to remove the user?'
            };

            const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
            const self = this;
            const selectedUsers = this.userSelection.selected;
            dialogRef.afterClosed().subscribe(async (result) => {
                if (result) {
                    await self.orgService.removeUserFromOrg(user.userName, self.org.id);
                    let message = 'The user has been successfully removed!';
                    if (selectedUsers.length > 1) {
                        message = 'The users have been successfully removed!';
                    }
                    self.messageService.handleSuccess(message);
                    self.refresh(false);
                }
            });
        }
    }


    removeSelectedUsers() {
        if (this.verifyDelete(this.userSelection.selected)) {
            const dialogConfig = new MatDialogConfig();

            dialogConfig.disableClose = true;
            dialogConfig.autoFocus = true;
            dialogConfig.data = {
                title: 'Confirm Delete',
                message: 'Are you sure you want to remove the selected users?'
            };

            const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
            const self = this;
            const selectedUsers = this.userSelection.selected;
            dialogRef.afterClosed().subscribe(async (result) => {
                if (result) {
                    for (const user of selectedUsers) {
                        await self.orgService.removeUserFromOrg(user.userName, self.org.id);
                    }
                    let message = 'The user has been successfully removed!';
                    if (selectedUsers.length > 1) {
                        message = 'The users have been successfully removed!';
                    }
                    self.messageService.handleSuccess(message);
                    self.refresh(false);
                }
            });
        }

    }

    editUser() {
        const dialogConfig = new MatDialogConfig();

        const selectedUsers = this.userSelection.selected;
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            users: selectedUsers,
            orgUsers: this.org.users,
            orgId: this.org.id
        };

        const dialogRef = this.dialog.open(EditUserComponent, dialogConfig);
        const self = this;
        dialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                let message = 'The user has been successfully saved!';
                if (selectedUsers.length > 1) {
                    message = 'The users have been successfully saved!';
                }

                self.messageService.handleSuccess(message);
                self.refresh(false);
            }
        });
    }




    ngOnInit() {
        this.loading = true;
        this.appContext.getUser().then((user) => {
            this.user = user;
            this.refresh(true);
        }).finally(() => {
            this.loading = false;
        });
    }

    deleteInvite(inviteToDelete) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            title: 'Confirm Delete',
            message: `Are you sure you want to delete the invitation for user '${inviteToDelete.email}'?`
        };

        const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(async (result) => {
            if (result) {
                this.loading = true;
                this.orgService.deleteInvite(inviteToDelete.id).then(() => {
                    const idx = this.org.invites.indexOf(inviteToDelete);
                    if (idx > -1) {
                        this.org.invites.splice(idx, 1);
                        this.inviteDataSource.data = this.org.invites;
                    }

                }).finally(() => {
                    this.loading = false;
                });
            }
        });
    }

    copied($event) {
        this.messageService.handleSuccess('Invite url copied');
    }
}
