import { MessageService } from 'src/app/error/message.service';
import { UserService } from './../../../users/users.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit {
  phone;
  email;
  selectedValue;
  user = null;

  constructor(
    private userService: UserService,
    private messageService: MessageService,
    private dialogRef: MatDialogRef<EditProfileComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.user = data.user;
    this.phone = this.user.phoneNumber;
  }

  cancel() {
    this.dialogRef.close();
  }

  async ok() {
    try {
      await this.userService.update(this.user.userName, this.user);
      this.dialogRef.close(true);
    } catch (e) {
      this.messageService.handleError(e, 'Error updating values');
    }
  }

  ngOnInit() {}
}
