import {Component, Inject, Injectable, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Roles} from '../../app.settings';
import {values} from 'lodash';
import {OrgService} from '../../orgs/org.service';
import {MessageService} from '../../error/message.service';
import { UserService } from '../users.service';

@Component({
    selector: 'add-user-dialog',
    templateUrl: './add-user.component.html',
    styleUrls: ['./add-user.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AddUserComponent {
    input: string;
    selectedRole = null;
    orgId = null;
    user = null;
    working = false;
    roles = values(Roles);
    constructor(
        private dialogRef: MatDialogRef<AddUserComponent>,
        private orgService: OrgService,
        private messageService: MessageService,
        @Inject(MAT_DIALOG_DATA) data) {
            this.orgId = data.orgId;
    }

    async ok() {
        const self = this;
        try {
            this.working = true;
            await this.orgService.inviteUser(this.input, this.orgId, this.selectedRole);
            self.dialogRef.close(this.input);
        } catch (e) {
            // Ignore, just dont close
        } finally {
            this.working = false;
        }

    }

    cancel() {
        this.dialogRef.close();
    }
}
