import { AcceptInviteRequestComponent } from './login/accept-invite-request/accept-invite-request.component';
import { ApproveInviteRequestComponent } from './login/approve-invite-request/approve-invite-request.component';
import { RequestPasswordResetComponent } from './login/request-password-reset/request-password-reset.component';
import { RegisterComponent } from './login/register/register.component';
import { JoinExistingOrgComponent } from './login/join-existing-org/join-existing-org.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SensorsPage } from './sensors/sensors.page';
import { HomePage } from './home/home.page';
import {CustomDashboardPage, DashboardPage, DeviceDashboardPage, DeviceTypeDashboardPage} from './dashboards/dashboard.page';
import { UsersPage } from './users/users.page';
import { LoginPage } from './login/login.page';
import { ConfirmPasswordResetComponent } from './login/confirm-password-reset/confirm-password-reset.component';
import { AuthGuardService as AuthGuard} from './auth/auth-guard.service';
import {DashboardListComponent} from './dashboards/list/dashboard-list.component';
import {EditMonitorComponent} from './monitors/edit/edit-monitor.component';
import {CreateMonitorComponent} from './monitors/create/create-monitor.component';
import {RegisterSensorComponent} from './sensors/register/register-sensor.component';
import {SensorEditorComponent} from './sensors/edit/sensor-editor.component';
import {CreateOrJoinComponent} from './login/create-join/create-join.component';
import {OrgEditor} from './orgs/edit/edit-org.component';
import {IntegrationOAuth2Component} from './integrations/auth/oauth2/integration-oauth2.component';
import {IntegrationConfigComponent} from './integrations/configure/integration-configure.component';
import {AdminGuardService} from './auth/admin-guard.service';
import {QueryJobsComponent} from './queries/queries.component';
import {ProfileComponent} from './profile/profile.component';
import {AlarmsComponent} from './alarms/alarms.component';
import {MonitorsComponent} from './monitors/monitors.component';
import { StatisticsComponent } from './statistics/statistics/statistics.component';
import { LayerFormComponent } from './sensors/map/layer-form/layer-form.component';
import { LayerComponent } from './sensors/map/layer/layer.component';
import { AssetDocumentationComponent } from './assets/asset-documentation/asset-documentation.component';

const routes: Routes = [
    { path: '' , redirectTo: 'statistics', pathMatch: 'full'},
    { path: 'home', component: HomePage },
    { path: 'sensors', component: SensorsPage, data: {title: 'Sensors'}, canActivate: [AuthGuard], runGuardsAndResolvers: 'always' },
    { path: 'sensors/register', component: RegisterSensorComponent, data: {title: 'Register Sensor'}, canActivate: [AuthGuard], runGuardsAndResolvers: 'always' },
    { path: 'sensors/:sensorId/edit', component: SensorEditorComponent, data: {title: 'Edit Sensor'}, canActivate: [AuthGuard], runGuardsAndResolvers: 'always' },
    { path: 'assets', component: AssetDocumentationComponent, canActivate: [AuthGuard], runGuardsAndResolvers: 'always' },
    { path: 'layers/add', component: LayerFormComponent, data: {title: 'Add Layer'}, canActivate: [AuthGuard], runGuardsAndResolvers: 'always' },
    { path: 'layers/:layerId/edit', component: LayerFormComponent, data: {title: 'Edit Layer'}, canActivate: [AuthGuard], runGuardsAndResolvers: 'always' },
    { path: 'layers/:layerId', component: LayerComponent, canActivate: [AuthGuard], runGuardsAndResolvers: 'always' },
    { path: 'dashboards', component: DashboardListComponent, data: {title: 'Dashboards'}, canActivate: [AuthGuard], runGuardsAndResolvers: 'always' },
    { path: 'dashboards/:dashboardId', component: CustomDashboardPage, data: {title: 'Dashboards'}, canActivate: [AuthGuard], runGuardsAndResolvers: 'always' },
    { path: 'dashboards/deviceType/:deviceTypeId/device/:platformDeviceId', data: {title: 'Dashboards'}, component: DeviceDashboardPage, canActivate: [AuthGuard], runGuardsAndResolvers: 'always' },
    { path: 'dashboards/deviceType/:deviceTypeId', component: DeviceTypeDashboardPage, data: {title: 'Dashboards'}, canActivate: [AuthGuard], runGuardsAndResolvers: 'always' },
    { path: 'monitors/:monitorId/edit', component: EditMonitorComponent, canActivate: [AuthGuard], runGuardsAndResolvers: 'always'},
    { path: 'monitors/create', component: CreateMonitorComponent, canActivate: [AuthGuard], runGuardsAndResolvers: 'always'},
    { path: 'monitors', component: MonitorsComponent, data: {title: 'Monitors'}, canActivate: [AuthGuard], runGuardsAndResolvers: 'always' },
    { path: 'statistics', component: StatisticsComponent, canActivate: [AuthGuard], runGuardsAndResolvers: 'always'},
    { path: 'alarms', component: AlarmsComponent, data: {title: 'Alarms'}, canActivate: [AuthGuard], runGuardsAndResolvers: 'always' },
    { path: 'login', component: LoginPage , runGuardsAndResolvers: 'always'},
    { path: 'register', component: RegisterComponent, runGuardsAndResolvers: 'always'},
    { path: 'forgot-password', component: RequestPasswordResetComponent, runGuardsAndResolvers: 'always'},
    { path: 'confirm-password-reset', component: ConfirmPasswordResetComponent , runGuardsAndResolvers: 'always'},
    { path: 'join-org', component: JoinExistingOrgComponent, runGuardsAndResolvers: 'always'},
    { path: 'create-or-join', component: CreateOrJoinComponent, runGuardsAndResolvers: 'always'},
    { path: 'approve-request', component: ApproveInviteRequestComponent, canActivate: [AuthGuard], runGuardsAndResolvers: 'always'},
    { path: 'accept-invite', component: AcceptInviteRequestComponent, runGuardsAndResolvers: 'always'},
    { path: 'edit-org', component: OrgEditor, data: {title: 'Organization Settings'}, canActivate: [AuthGuard], runGuardsAndResolvers: 'always'},
    { path: 'integrations/:intId/oauth2/callback', component: IntegrationOAuth2Component, canActivate: [AuthGuard], runGuardsAndResolvers: 'always'},
    { path: 'integrations/:intId/configure', component: IntegrationConfigComponent, canActivate: [AuthGuard], runGuardsAndResolvers: 'always'},
    { path: 'queries', component: QueryJobsComponent, data: {title: 'Queries'}, canActivate: [AuthGuard], runGuardsAndResolvers: 'always'},
    { path: 'profile', component: ProfileComponent, data: {title: 'Profile'}, canActivate: [AuthGuard], runGuardsAndResolvers: 'always'},
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {  useHash: false, onSameUrlNavigation: 'reload' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
