import {Component, OnDestroy, OnInit} from '@angular/core';
import {isNil, isEmpty, keys, values, cloneDeep, set, get, find, forEach, omit, startCase, indexOf} from 'lodash';
import {MonitorFactory} from '../monitor.factory';

const DEFAULT_MONITOR = {
    analytics: {
        type: 'metric',
        config: {
            conditions : [],
        }
    },
    notifications: {}
}

@Component({
    selector: 'create-monitor',
    templateUrl: './create-monitor.component.html',
    styleUrls: ['./create-monitor.component.scss'],
})
export class CreateMonitorComponent {
    monitor;
    constructor(monitorFactory: MonitorFactory) {
        this.monitor = monitorFactory.newMonitor();
    }

}
