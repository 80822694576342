import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { get, isString, isObject } from 'lodash';

@Injectable()
export class MessageService {
    constructor(private toastr: ToastrService) {}
    public handleError(errObj, title, options?) {
        if (arguments.length === 1) {
            title = '';
        }
        if (arguments.length === 2 && isObject(title)) {
            options = title;
            title = '';
        }
        let message = null;
        if (isString(errObj)) {
            message = errObj;
        } else {
            message = get(errObj, 'error.message', get(errObj, 'message' , 'Unknown error'));
        }

        this.toastr.error(message, title, options || {});
        console.error(message, errObj);
    }

    public handleSuccess(message, title?, options?) {
        if (arguments.length === 1) {
            title = '';
        }
        if (arguments.length === 2 && isObject(title)) {
            options = title;
            title = '';
        }
        this.toastr.success(message, title, options || {});
    }


}
