import {Component, OnInit, Type, ViewEncapsulation} from '@angular/core';
import {ChartJSGraphComponent} from '../engines/chartjs/chartjs-graph.component';
import {XYGraphDataProvider} from './xyseries.dataprovider';
import {DeviceMetric, IGraph, IGraphBuilderSpec, IGraphContext, IGraphTypeDescriptor, IGraphView} from '../graph.component';
import {ChartOptions} from '../engines/chartjs/chartjs';
import {IAxis, IScaling, XYSeriesGraphSpec} from './xyseries.graph.spec';
import {IDataSeries, IDataSeriesDevice, IDataSeriesQueryResult} from '../../deviceData/device-data.service';
import {ChartJSLineGraphComponent} from '../engines/chartjs/line/chartjs-line-graph.component';
import {NgxChartLineGraphComponent} from '../engines/ngx-charts/line/ngx-chart-line.component';


export class XYGraphTypeDescriptor implements IGraphTypeDescriptor<XYSeriesGraphSpec> {
    name = 'Timeseries';
    id = 'XY';
    colWidth = 6;
    image = 'line-chart.png';
    builder =  {
        specification: {
            steps: [{
                title: 'Select Metrics to Plot ',
                fields: [{
                    type: 'metrics-input',
                    specPath: 'data.y.metrics'
                }]
            }],
            constants: [{
                specPath: 'xyVersion',
                value: '2'
            }, {
                specPath: 'data.x.field',
                value: 'deviceTimestamp'
            }, {
                specPath: 'axes',
                value: [
                    { name: 'Time', scaling: 'time'}
                ]
            }]
        } as IGraphBuilderSpec
    };
    resolve (graphJson: XYSeriesGraphSpec, graphContext: IGraphContext): XYSeriesGraphSpec {
        if (graphContext) {
            // We must make a copy of metricTemplates for every platformDeviceId in our context
            const metricTemplates: DeviceMetric[] =  JSON.parse(JSON.stringify(graphJson.data.y.metrics));

            graphJson.data.y.metrics = [];
            graphContext.platformDeviceIds.forEach(platformDeviceId => {
                metricTemplates.forEach(metricTemplate => {
                    graphJson.data.y.metrics.push({
                        fieldPath: metricTemplate.fieldPath,
                        deviceTypeId: graphContext.deviceTypeId,
                        platformDeviceId: platformDeviceId
                    });
                });
            });
        }
        return graphJson;
    }

    getViewComponent(): Type<IGraphView<any>> {
        return NgxChartLineGraphComponent;
        // return ChartJSLineGraphComponent;
    }

    newSpec(): XYSeriesGraphSpec {
        return new XYSeriesGraphSpec();
    }
}
