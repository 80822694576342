import {
    Component, ElementRef,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {AppSettings, Roles} from '../app.settings';
import {AppContext} from '../app.context';
import {SensorService, Device} from './sensors.service';

import {forEach, find, get, findIndex} from 'lodash';

import {animate, style, transition, trigger} from '@angular/animations';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfirmDialogComponent} from '../shared/dialogs/confirm/confirm-dialog.component';
import {MatDialog, MatDialogConfig, MatSelect} from '@angular/material';
import {MessageService} from '../error/message.service';
import {TitleCasePipe} from '@angular/common';
import {SensorSearchFilterPipe} from './sensor-search.filter.pipe';
import {SelectionModel} from '@angular/cdk/collections';
import {TagSelectorDialogComponent} from '../shared/dialogs/tag-selector/tag-selector-dialog.component';
import {FilterComponent} from '../shared/filter/filter.component';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {OrgService} from '../orgs/org.service';


const GROUP_BY_DEFAULTS = {
    LOCATION: 'Location',
    CATEGORY: 'Category'
};

const VIEW_MODE = {
    LIST: 'list',
    MAP: 'map',
    TREE: 'tree'
};

const WILDCARD = '\\.*';

@Component({
    selector: 'app-sensors',
    templateUrl: './sensors.page.html',
    styleUrls: ['./sensors.page.scss'],
    animations: [
        trigger('slideInOut', [
            transition(':enter', [
                style({transform: 'translateX(100%)'}),
                animate('200ms ease-in', style({transform: 'translateX(0%)'}))
            ])
        ])
    ],
    encapsulation: ViewEncapsulation.None,
    host: {class: 'main-page-content'},
})
export class SensorsPage implements OnInit {
    public static PAGE_ID = AppSettings.MENU_ITEM_IDS.SENSORS;
    private fullSensorList = [];
    public filteredSensorList;
    public loading = false;
    canEdit = false;
    objectKeys = Object.keys;
    allFilters = [];
    appliedFilter = [];
    filterSearchTerm = null;
    searchText = null;
    hasGeo = false;
    viewMode = VIEW_MODE.LIST;
    selection = new SelectionModel<Device>(true, []);
    constructor(public appContext: AppContext,
                public sensorService: SensorService,
                private route: ActivatedRoute,
                private router: Router,
                private dialog: MatDialog,
                private messageService: MessageService,
                private titleCasePipe: TitleCasePipe,
                private sensorSearchFilter: SensorSearchFilterPipe,
                public breakpointObserver: BreakpointObserver,
                public orgService: OrgService) {}

    canShowExperimentalFeatures() {
        return this.appContext.isShowExperimentalFeatures();
    }

    onFilterChanged(event: any) {
        this.applyFilters();
    }

    applyFilters() {
        const filtered = this.fullSensorList.filter((sensor) => {
            if (this.appliedFilter.length === 0) {
                return true;
            }

            let include = false;
            forEach(sensor.tags, (value, name) => {
                const found = find(this.appliedFilter, function(f) {
                    return (f.key === name && f.value === value);
                });
                if (found) {
                    include = true;
                    return false;
                }
            });
            return include;
        });
        this.filteredSensorList = this.sensorSearchFilter.transform(filtered, this.searchText);
    }

    filterDialogOpenedChange(event: any) {
        this.filterSearchTerm = null;
    }

    onSelectionChanged(event) {
        this.selection.clear();
        forEach(event, (device) => {
            this.selection.select(device);
        });
    }

    onRemoveFilter(filter) {
        const idx = findIndex(this.appliedFilter, function(f) {
            return f.key === filter.key && f.value === filter.value;
        });
        if (idx > -1) {
            this.appliedFilter.splice(idx, 1);
        }
        this.applyFilters();
    }

    buildFilters() {
        const filterMap = {};
        forEach(this.fullSensorList || [], function (sensor) {
            const tags = sensor.tags;
            if (tags) {
                forEach(tags, function (value, key) {
                    const members = filterMap[key] = filterMap[key] || [];
                    if (members.indexOf(value) === -1) {
                        members.push(value);
                    }
                });
            }
        });
        this.allFilters = [];
        forEach(filterMap, (members, key) => {
            members.sort(function(a: string, b: string) {
                return a.localeCompare(b);
            });

            const keyDisplayName = key === 'Category' ? 'Type' : key;
            this.allFilters.push({
                name: this.titleCasePipe.transform(keyDisplayName),
                items: members.map(function(m) {
                    return {
                        displayName: m,
                        value: m,
                        key: key
                    };
                })
            });
        });
        this.allFilters.sort(function (f1, f2) {
            if (f1.name === 'Type') {
                return -1;
            } else if (f2.name === 'Type') {
                return 1;
            }
            return f1.name.localeCompare(f2.name);
        });
    }

    private loadSensors(): Promise<Device[]> {
        return new Promise((resolve, reject) => {
            this.sensorService.querySensors().then((sensorList) => {
                this.fullSensorList = sensorList.Items;
                this.hasGeo = this.hasGeoLocations();
                this.buildFilters();
                this.applyFilters();
                resolve();
            }, () => {});
        });
    }

    async onRequestFetch(){
        this.loading = true;
        await this.loadSensors();
        this.loading = false;
    }

    ngOnInit() {
        this.loading = true;
        Promise.all([this.loadSensors(), this.appContext.isUserInRole([Roles.OWNER, Roles.ADMIN]), this.orgService.getOrg(false)]).then((results) => {
            this.canEdit = results[1];
            const preferredViewMode = get(results[2], 'preferences.defaultSensorLayout', VIEW_MODE.LIST).toLowerCase();
            const view = this.route.snapshot.queryParamMap.get('view') || preferredViewMode;
            if (view) {
                this.viewMode = view;
            }
        }).finally(() => {
            this.loading = false;
        });

    }

    onRegisterSensor() {
        this.router.navigate(['sensors', 'register']).finally();
    }

    onViewModeChanged(mode) {
        this.viewMode = mode;
        this.router.navigate(
            [],
            {
                relativeTo: this.route,
                queryParams: { view: mode },
                queryParamsHandling: 'merge'
            });
    }

    onDeleteSensor() {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            title: 'Confirm Delete',
            message: 'Are you sure you want to remove the selected sensors(s)?'
        };

        const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
        const self = this;
        dialogRef.afterClosed().subscribe(async (result) => {
            if (result) {
                this.loading = true;
                try {
                    for (const sensor of this.selection.selected) {
                        await self.sensorService.deleteSensor(sensor.id);
                        const idx = this.fullSensorList.indexOf(sensor);
                        if (idx > -1) {
                            this.fullSensorList.splice(idx, 1);
                        }
                    }
                } catch (e) {
                    self.messageService.handleError(e.message, 'Error Deleting');
                } finally {
                    this.buildFilters();
                    this.applyFilters();
                    this.loading = false;
                }
            }
        });
    }

    onEditSensor() {
        this.router.navigate(['sensors', this.selection.selected[0].id, 'edit']).finally();
    }

    search($event) {
        this.searchText = $event;
        this.applyFilters();
    }

    onKeydown($event) {
        if ($event.key === 'Backspace') {
            this.searchText = '';
        }
    }

    hasGeoLocations() {
        let hasGeo = false;
        forEach(this.fullSensorList, (device) => {
            if (get(device, 'tags.Geo', get(device, 'tags.geo'))) {
                hasGeo = true;
                return false;
            }
        })
        return hasGeo;
    }

    openFilterDialog(evt: MouseEvent) {
        const dialogConfig = new MatDialogConfig();
        const target = new ElementRef(evt.currentTarget);
        dialogConfig.hasBackdrop = true;
        dialogConfig.backdropClass = 'transparent';
        dialogConfig.disableClose = true;
        // dialogConfig.maxHeight = '500px';
        dialogConfig.data = {
            trigger: target,
            filterGroups: this.allFilters,
            appliedFilters: this.appliedFilter
        };

        const self = this;
        const dialogRef = this.dialog.open(FilterComponent, dialogConfig);
        dialogRef.backdropClick().subscribe(() => {
            dialogRef.close();
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.appliedFilter = result.selectedFilters;
                this.applyFilters();
            }

        });
    }
}