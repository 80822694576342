import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {AppContext} from '../app.context';
import {OrgService, OrgUser, PendingInvite} from '../orgs/org.service';
import {forEach, find, cloneDeep, findIndex} from 'lodash';
import {Roles} from '../app.settings';
import {MatDialog, MatDialogConfig, MatTableDataSource} from '@angular/material';
import {EditUserComponent} from './edit/edit-user.component';
import {MessageService} from '../error/message.service';
import {ConfirmDialogComponent} from '../shared/dialogs/confirm/confirm-dialog.component';
import {InputDialogComponent} from '../shared/dialogs/input/input-dialog.component';
import {AddUserComponent} from './add/add-user.component';
import {Router} from '@angular/router';
import {SelectionModel} from '@angular/cdk/collections';


@Component({
    selector: 'app-users',
    templateUrl: './users.page.html',
    styleUrls: ['./users.page.scss'],
    encapsulation: ViewEncapsulation.None,
    host: {class: 'main-page-content'},
})
export class UsersPage implements OnInit {
    loading = false;
    user = null;
    selectedOrg = null;
    currentEditOrg = null;
    orgMembership = null;
    availableOrgChoices = null;
    canEdit = false;
    usersDataSource = new MatTableDataSource<OrgUser>();
    usersDisplayedColumns: string[] = ['select', 'name', 'email', 'role'];
    userSelection = new SelectionModel<OrgUser>(true, []);

    inviteDataSource = new MatTableDataSource<PendingInvite>();
    inviteDisplayColumns: string[] = ['email', /*'name',*/ 'actions'];

    constructor(private appContext: AppContext,
                private messageService: MessageService,
                private orgService: OrgService,
                private dialog: MatDialog,
                private router: Router) {
        // this.router.routeReuseStrategy.shouldReuseRoute = function () {
        //     return false;
        // };

    }

    isAllSelected() {
        const numSelected = this.userSelection.selected.length;
        const numRows = this.usersDataSource.data.length;
        return numSelected === numRows;
    }

    masterToggle() {
        this.isAllSelected() ?
            this.userSelection.clear() :
            this.usersDataSource.data.forEach(row => this.userSelection.select(row));
    }

    search(searchTerm) {
        console.log('Implement filter for: ', searchTerm);
    }

    refresh(showLoading?) {
        this.userSelection.clear();
        if (showLoading) {
            this.loading = true;
        }
        try {
            this.orgService.getOrg(this.selectedOrg.id, true).then(async (org) => {
                this.currentEditOrg = org;
                this.currentEditOrg.users.sort(function (a, b) {
                    return a.name.localeCompare(b.name);
                });

                const found = find(this.currentEditOrg.users, (user) => {
                    return user.userName === this.user.userName;
                });

                if (found) {
                    this.orgMembership = found;
                    this.canEdit = await this.appContext.isUserInRole([Roles.ADMIN, Roles.OWNER], this.orgMembership);
                }

                this.usersDataSource.data = this.currentEditOrg.users;
                this.inviteDataSource.data = this.currentEditOrg.invites;
            }).finally(() => {
                this.loading = false;
            });
        } catch (e) {
            this.loading = false;
        }
    }

    addUser() {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            orgId : this.selectedOrg.id
        };
        const self = this;
        const dialogRef = this.dialog.open(AddUserComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                self.messageService.handleSuccess('An invite was sent to the ' + result );
                self.refresh(false);
            }

        });
    }

    onSelectionChanged() {
        this.refresh(true);
    }

    removeUser() {
        const owners = this.currentEditOrg.users.filter(function (user) {
            return user.selected && user.role === Roles.OWNER;
        });
        let deleteReason = null;
        if (owners.length > 0) {
            // check to see if there is at least an owner left
            const found = find(this.currentEditOrg.users, function (user) {
                return !user.selected && user.role === Roles.OWNER;
            });
            if (!found) {
                deleteReason = 'Cannot delete all of the owner(s) of the organization';
            }
        }
        if (deleteReason) {
            this.messageService.handleError(deleteReason, 'Error deleting users');
        } else {
            const dialogConfig = new MatDialogConfig();

            dialogConfig.disableClose = true;
            dialogConfig.autoFocus = true;
            dialogConfig.data = {
                title: 'Confirm Delete',
                message: 'Are you sure you want to remove the selected users?'
            };

            const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
            const self = this;
            const selectedUsers = this.userSelection.selected;
            dialogRef.afterClosed().subscribe(async (result) => {
                if (result) {
                    for (const user of selectedUsers) {
                        await self.orgService.removeUserFromOrg(user.userName, self.selectedOrg.id);
                    }
                    let message = 'The user has been successfully removed!';
                    if (selectedUsers.length > 1) {
                        message = 'The users have been successfully removed!';
                    }
                    self.messageService.handleSuccess(message);
                    self.refresh(false);
                }
            });
        }

    }

    editUser() {
        const dialogConfig = new MatDialogConfig();

        const selectedUsers = this.userSelection.selected;
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            users: selectedUsers,
            orgUsers: this.currentEditOrg.users,
            orgId: this.selectedOrg.id
        };

        const dialogRef = this.dialog.open(EditUserComponent, dialogConfig);
        const self = this;
        dialogRef.afterClosed().subscribe(function (result) {
           if (result) {
               let message = 'The user has been successfully saved!';
               if (selectedUsers.length > 1) {
                   message = 'The users have been successfully saved!';
               }

               self.messageService.handleSuccess(message);
               self.refresh(false);
           }
        });
    }


    selectOrg(org) {
        this.selectedOrg = org;
        this.refresh(true);
    }

    ngOnInit() {
        let foundOrg = null;
        this.loading = true;
        Promise.all([this.appContext.getUser(), this.appContext.getOrganization()]).then(async (results) => {
            this.user = results[0];
            const loggedInOrg = results[1];
            this.availableOrgChoices = this.user.orgMemberships.map(function(membership) {
                return {
                    id : membership.orgId,
                    name : membership.orgName
                };
            });

            foundOrg = find(this.availableOrgChoices, (choice) => {
                return choice.id === loggedInOrg.orgId;
            });


        }).finally(() => {
            this.loading = false;
            if (foundOrg) {
                this.selectOrg(foundOrg);
            }
        });
    }

    deleteInvite(inviteToDelete, idx) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            title: 'Confirm Delete',
            message: `Are you sure you want to delete the invitation for user '${inviteToDelete.email}'?`
        };

        const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(async (result) => {
            if (result) {
                this.loading = true;
                this.orgService.deleteInvite(inviteToDelete.id).then(() => {
                    this.currentEditOrg.invites.splice(idx, 1);
                }).finally(() => {
                    this.loading = false;
                });
            }
        });
    }
}
