import {Component, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {MonitorsService} from '../monitors.service';
import {OrgService} from '../../orgs/org.service';
import {DeviceTypeService} from '../../deviceType/deviceType.service';
import {isNil, isEmpty, keys, values, cloneDeep, set, get, find, forEach, omit, startCase, indexOf} from 'lodash';
import {flatten} from 'flat';
import {Router} from '@angular/router';
import {ConfirmDialogComponent} from '../../shared/dialogs/confirm/confirm-dialog.component';
import {MatDialog, MatDialogConfig} from '@angular/material';
import {MessageService} from '../../error/message.service';
import {AbstractControl} from '@angular/forms';

const CONDITIONS_PATH = 'analytics.config.conditions';

const ESCALATION_INTERVALS = [{
    displayValue: '10 minutes',
    value: '10'
}, {
    displayValue: '20 minutes',
    value: '20'
}, {
    displayValue: '30 minutes',
    value: '10'
}, {
    displayValue: '1 hour',
    value: '60'
}];

const NOTIFY_METHODS = {
    EMAIL : {
        value: 'email', displayName: 'Email'
    },
    SMS: {
        value: 'sms', displayName: 'SMS'
    }
};

const WINDOW_CHOICES = [{
    displayName: '1 minute',
    value: 1
},
    {
        displayName: '5 minutes',
        value: 5
    },
    {
        displayName: '10 minutes',
        value: 10
    },
    {
        displayName: '15 minutes',
        value: 15
    },
    {
        displayName: '30 minutes',
        value: 30
    },
    {
        displayName: '1 hour',
        value: 60
    },
    {
        displayName: '2 hours',
        value: 120
    }
];

const NOTIFICATION_LEVELS = {
    FIRST: 'first',
    ESCALATION: 'escalation'
};

const FILTERS_PATH = 'analytics.config.filters';
const ALERT_TYPE_PATH = 'analytics.config.alertType';
const OMIT_ATTRIBUTES_LIST = ['tags.Category', 'platformDeviceId'];

@Component({
    selector: 'monitor-editor',
    templateUrl: './monitor-editor.component.html',
    styleUrls: ['./monitor-editor.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class MonitorEditorComponent implements OnInit {
    @Input() monitor;
    @Input() title;
    @Input() isNew;
    escalationIntervals = ESCALATION_INTERVALS;
    notifyMethods = values(NOTIFY_METHODS);
    firstLevelNotificationUsers;
    escalationNotificationUsers;
    working = false;
    constructor(
        private router: Router,
        private monitorsService: MonitorsService,
        private dialog: MatDialog,
        private orgService: OrgService,
        private deviceTypeService: DeviceTypeService,
        private messageService: MessageService) {
    }

    ok() {
        const self = this;
        this.working = true;
        this.monitorsService.upsertMonitor(this.monitor).then(() => {
            self.messageService.handleSuccess(`The monitor was ${this.monitor.id ? 'updated' : 'created'}`);
            self.router.navigate(['monitors']).finally();
        }).finally(function () {
            self.working = false;
        });
    }

    cancel() {
        this.router.navigate(['monitors']).finally();
    }

    ngOnInit(): void {
        if (!this.monitor.notifyMethod) {
            this.monitor.notifyMethod = NOTIFY_METHODS.EMAIL.value;
        }
        this.initNotifications();
    }


    mapNotificationUsers(level) {
        const list = get(this.monitor, `notifications.levels.${level}.users`, []);
        return {
            level: level,
            users: list.map(function (user) {
                return user.id;
            })
        };
    }

    initNotifications() {
        this.firstLevelNotificationUsers = this.mapNotificationUsers(NOTIFICATION_LEVELS.FIRST);
        this.escalationNotificationUsers = this.mapNotificationUsers(NOTIFICATION_LEVELS.ESCALATION);
    }

    notifyUserListChanged(notification) {
        const path = `notifications.levels.${notification.level}.users`;
        const listToAdd = [];
        forEach(notification.users, function (userId) {
            listToAdd.push({
                id: userId
            });
        });
        set(this.monitor, path, listToAdd);
    }
}
