import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {AppContext} from '../../app.context';
import {AppSettings, Roles} from '../../app.settings';
import {forEach, startsWith} from 'lodash';
import {NavigationEnd, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {AuthService} from '../../auth/auth.service';

@Component({
    selector: 'full-sidenav',
    templateUrl: './full-sidenav.component.html',
    styleUrls: ['./full-sidenav.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class FullSidenavComponent implements OnInit, OnDestroy {
    menuItems = AppSettings.MENU_ITEMS.slice(0);
    navSubscription: Subscription;
    selectedPage;
    user;
    constructor(private appContext: AppContext,
                private router: Router,
                private auth: AuthService) {
        this.appContext.user.subscribe((user) => {
            this.user = user;
        });
    }

    isAuthenticated() {
        return this.auth.isAuthenticated();
    }

    ngOnInit(): void {
        const subscription = this.router.events
            .subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    const urlDelimiters = new RegExp(/[?//,;&:#$+=]/);
                    let urlToSearch = event.url;
                    if (urlToSearch === '/' || urlToSearch.length === 0) {
                        urlToSearch = event.urlAfterRedirects;
                    }
                    const currentUrlPath = urlToSearch.slice(1).split(urlDelimiters)[0];
                    let found = null;
                    forEach(this.menuItems, (item) => {
                        let normalizedHref = item.href;
                        if (startsWith(normalizedHref, '/')) {
                            normalizedHref = normalizedHref.substring(1);
                        }
                        if (normalizedHref === currentUrlPath) {
                            found = item.id;
                            return false;
                        }
                    });
                    this.selectedPage = found;
                }
            });
    }

    ngOnDestroy(): void {
        if (this.navSubscription) {
            this.navSubscription.unsubscribe();
        }
    }

}
