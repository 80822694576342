import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {AppContext} from '../../../app.context';
import {OrgMembership} from '../../../orgs/org.service';

@Component({
    selector: 'org-menu-item',
    templateUrl: './org-menu-item.component.html',
    styleUrls: ['./org-menu-item.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class OrgMenuItemComponent implements OnInit {
    user;
    selectedOrg;
    @Output() orgChanged = new EventEmitter<OrgMembership>();
    constructor(
        private appContext: AppContext
    ) {
        this.appContext.user.subscribe(async (user) => {
            this.user = user;
        });
        this.appContext.org.subscribe(async (org) => {
            this.selectedOrg = org;
        });
    }

    changeOrg(membership) {
        if (membership.orgId !== this.selectedOrg.orgId) {
            this.appContext.setOrganization(membership);
            this.orgChanged.emit(membership);
        }
    }

    ngOnInit(): void {
        Promise.all([this.appContext.getUser(), this.appContext.getOrganization()]).then((results) => {
            this.user = results[0];
            this.selectedOrg = results[1];
        });
    }

}
