import {Injectable} from '@angular/core';
import {AbstractGraphDataProvider, IGraph, ITimeRange} from '../graph.component';
import {IDataSeriesQueryResult, ILastValueQueryResult} from '../../deviceData/device-data.service';
import {XYSeriesGraphSpec} from '../xyseries/xyseries.graph.spec';
import {GaugeGraphSpec} from './gauge.component';
import {get} from 'lodash';

@Injectable()
export class GaugeDataProvider extends AbstractGraphDataProvider<ILastValueQueryResult> {
    query(graph: IGraph, fromTimestamp: string): Promise<ILastValueQueryResult> {
        const spec = <GaugeGraphSpec>graph.spec;
        const metric = get(spec, 'value.metric');
        return this.deviceDataService.lastValueQuery({
            platformDeviceId: metric.platformDeviceId,
            fields: [metric.fieldPath, 'deviceTimestamp']
        });
    }
}
