import {Component, forwardRef, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
@Component({
    selector: 'graph-builder-step',
    templateUrl: './graph-builder-step.component.html',
    styleUrls: ['./graph-builder-step.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class GraphBuilderStepComponent {
    @Input() stepNumber;
    @Input() title;
    constructor() {}
}
