import {Component, Inject, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {NgModel} from '@angular/forms';
import {DashboardScope, DashboardsService} from '../dashboards.service';

@Component({
    selector: 'create-dashboard',
    templateUrl: './create-dashboard.component.html',
    styleUrls: ['./create-dashboard.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class CreateDashboardComponent implements OnInit {
    name;
    scope = DashboardScope.ORG;
    scopeChoices = [{name: 'Public' , value: DashboardScope.ORG}, {name: 'Private', value: DashboardScope.PRIVATE}];
    nameChanged: Subject<string> = new Subject<string>();
    @ViewChild('inputRef', {static: false}) dashNameCtlModel: NgModel;

    constructor(
        private dialogRef: MatDialogRef<CreateDashboardComponent>,
        private dashboardService: DashboardsService,
        @Inject(MAT_DIALOG_DATA) data) {
        const self = this;
        this.nameChanged.pipe(
            debounceTime(300), // wait 300ms after the last event before emitting last event
            distinctUntilChanged()) // only emit if value is different from previous value
            .subscribe(function(value) {
                // this.name = value;
                if (value.length > 0) {
                    // Call the dashboard service and g
                    dashboardService.count({name: value}).then(function(result) {
                        self.dashNameCtlModel.control.setErrors(result > 0 ? {'exists': true} : null);
                    }, function(err) {

                    });
                }

            });

    }

    ok() {
        this.dialogRef.close({
            name: this.name,
            scope: this.scope
        });
    }

    cancel() {
        this.dialogRef.close();
    }

    changed(text: string) {
        this.nameChanged.next(text);
    }

    ngOnInit(): void {
    }
}
