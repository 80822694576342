import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { template } from 'lodash';

const GTM_SCRIPT = template('(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({\'gtm.start\':\n' +
    'new Date().getTime(),event:\'gtm.js\'});var f=d.getElementsByTagName(s)[0],\n' +
    'j=d.createElement(s),dl=l!=\'dataLayer\'?\'&l=\'+l:\'\';j.async=true;j.src=\n' +
    '\'https://www.googletagmanager.com/gtm.js?id=\'+i+dl;f.parentNode.insertBefore(j,f);\n' +
    '})(window,document,\'script\',\'dataLayer\',\'${gtmId}\');');

const GA_GTM_URL = template('https://www.googletagmanager.com/gtag/js?id=${gaId}');
const GA_INIT_SCRIPT = template('(function(i,s,o,g,r,a,m){i[\'GoogleAnalyticsObject\']=r;i[r]=i[r]||function(){\n' +
    '        (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),\n' +
    '      m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)\n' +
    '    })(window,document,\'script\',\'https://www.google-analytics.com/analytics.js\',\'ga\');\n' +
    '\n' +
    '    ga(\'create\', \'${gaId}\', \'auto\');');

if (environment.production) {
  enableProdMode();
}

const gtmScriptToLoad = GTM_SCRIPT({gtmId : environment.gtmId});
const script = document.createElement('script');
script.innerHTML = gtmScriptToLoad;
document.head.appendChild(script);

const gaCode = document.createElement('script');
gaCode.setAttribute('src', GA_GTM_URL({gaId : environment.gaId}));
gaCode.setAttribute('async', '');
document.head.appendChild(gaCode);

const gaInitScript = document.createElement('script');
gaInitScript.innerHTML = GA_INIT_SCRIPT({gaId : environment.gaId});
document.head.appendChild(gaInitScript);


platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
