import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import * as moment from 'moment';
import {Alarm, AlarmsService} from './alarms.service';
import {AppContext} from '../app.context';
import {MatDialog, MatDialogConfig, MatTableDataSource, MatPaginator} from '@angular/material';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { CommentDialogComponent } from './comment-dialog/comment-dialog.component';



@Component({
    selector: 'alarms',
    templateUrl: './alarms.component.html',
    styleUrls: ['./alarms.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
    host: {class: 'main-page-content'},
})

export class AlarmsComponent implements OnInit, AfterViewInit {
    loading = false;
    loadingHistory = false;
    alarms = [];
    history = [];
    showHistory = false;
    working;
    dataSource = new MatTableDataSource<Alarm>();
    fetchingHistory = false;
    displayedColumns: string[] = ['level', 'monitor', 'devices', 'time', 'status', 'actions'];
    expandedElement: Alarm | null;
    @ViewChild(MatPaginator, {static : false}) paginator: MatPaginator;

    ngAfterViewInit() {
      this.dataSource.paginator = this.paginator;
    }

    constructor(private router: Router,
                public dialog: MatDialog,
                private alarmsService: AlarmsService,
                private appContext: AppContext) {
    }

    setAlarms(alarms) {
        alarms.sort(function (a, b) {
            if (a.status === b.status) {
                const bDate = moment(b.dateTriggered);
                const aDate = moment(a.dateTriggered);
                return bDate.isSame(aDate) ? 0 : bDate.isBefore(aDate) ? -1 : 1;
            }
            if (a.status === 'triggered' && b.status !== 'triggered') {
                return -1;
            } else if (a.status !== 'triggered' && b.status === 'triggered') {
                return 1;
            } else {
                return 0;
            }
        });
        this.alarms = alarms;
        this.dataSource.data = alarms;
    }

    async fetchAlarms(showLoading?) {
        const alarmPromise = this.alarmsService.getAlarms();
        const promises = [alarmPromise];
        if (this.showHistory) {
            const historyPromise = this.alarmsService.getAlarmHistory();
            promises.push(historyPromise);
        }

        this.loading = true;
        return new Promise((resolve, reject) => {
            return Promise.all(promises).then((results) => {
                const allResults = results[0].concat(results.length === 2 ? results[1] : []);
                this.setAlarms(allResults);
                resolve(true);
            }, reject).finally(() => {
                this.loading = false;
            });
        });

    }

    fetchHistory() {
        this.fetchingHistory = true;
        return this.alarmsService.getAlarmHistory().then((history) => {
            const allAlarms = this.alarms.concat(history);
            this.setAlarms(allAlarms);
        }).finally(() => {
            this.fetchingHistory = false;
        });
    }

    removeHistory() {
        const filtered = this.alarms.filter((alarm) => {
            return alarm.status !== 'resolved';
        });
        this.setAlarms(filtered);
    }

    showHistoryClicked() {
        this.showHistory = !this.showHistory;
        setTimeout(() => {
            if (this.showHistory) {
                this.fetchHistory();
            } else {
                this.removeHistory();
            }
        }, 0);

    }

    resolve($event: MouseEvent, alarm: any){

        $event.stopPropagation();
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = '400px';
        const self = this;
        const dialogRef = this.dialog.open(CommentDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
            self.loading = true;
            if (result) {
                this.alarmsService.resolveAlarm(alarm.id, result.comment).then(() => {
                    this.fetchAlarms();
                });
            }else self.loading = false;
        });
    }

    ngOnInit(): void {
        this.fetchAlarms();
    }

}
