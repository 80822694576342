import {Component, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {AbstractGraphDataProvider, AbstractGraphView, COMPACT_GRAPH_HEIGHT, IGraph} from '../../graph.component';
import {Chart} from 'chart.js';
import {BreakpointObserver} from '@angular/cdk/layout';

@Component({
    selector: 'chartjs-graph',
    templateUrl: './chartjs-graph.component.html',
    styleUrls: ['./chartjs-graph.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ChartJSGraphComponent<T> extends AbstractGraphView<T> implements OnInit {
    @ViewChild('chartRef', {static: true}) private chartRef;
    @Input() chart: Chart;
    constructor(dataProvider: AbstractGraphDataProvider<T>,
                breakpointObserver: BreakpointObserver) {
        super(dataProvider, breakpointObserver);
    }

    ngOnInit(): void {
        super.ngOnInit();

    }

    protected onDataReceived(resultSet: T, graph: IGraph) {
        this.buildChart(resultSet, graph).then((newChart) => {
            if (this.chart) {
                this.chart.destroy();
            }
            this.chart = this.chart = new Chart(this.chartRef.nativeElement, newChart);

        });
    }

    buildChart(resultSet: T, graph: IGraph): Promise<any> {
        throw new Error(`Subclass needs to implement`);
    }
}
