import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {COMPACT_GRAPH_CONTAINER_HEIGHT, GraphSpec, IGraph, IGraphAction, ITimeRange} from '../../../graph/graph.component';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {forEach, get, set, find, cloneDeep, isEqual} from 'lodash';
import {GridListItem, GridsterComponent, GridsterItemPrototypeDirective} from 'angular2gridster';
import {IDashboardLayout} from '../../dashboard.page';
import {GraphTypeRegistryService} from '../../../graph/graph-type.registry';
import {Observable} from 'rxjs';
import {MatDialog, MatDialogConfig} from '@angular/material';
import {GraphEditorComponent} from '../../../graph/editor/graph-editor.component';
import * as uuid from 'uuid';

interface IGridItem {
    graph: IGraph;
    layout?: any;
}

interface IPosition {
    x: number;
    y: number;
}

const FULL_SCREEN_COLS = 12;
const NUM_OF_GRAPHS_PER_ROW = 2;
const DEFAULT_COLS = 6;

const SMALL_COL = 1;
const MED_COL = 6;
const LARGE_COL = 12;

@Component({
    selector: 'dashboard-gridster-layout',
    templateUrl: './dashboard-gridster-layout.component.html',
    styleUrls: ['./dashboard-gridster-layout.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DashboardGridsterLayoutComponent implements OnInit, OnChanges {
    @Input() graphs: IGraph[];
    @Input() actions: IGraphAction[];
    @Input() timeRange: ITimeRange;
    @Input() edit = false;
    @Output() addGraph = new EventEmitter();
    @Output() ordinalChanged = new EventEmitter();
    @Output() onGraphHover = new EventEmitter();
    @ViewChild('gridster', {static: false}) gridster: GridsterComponent;
    graphTypes;
    graphPaletteItemStyle = {
        'font-size': '10px',
        padding: '10px',
        height: '85px',
        width: '100px',
        'border-radius': '6px',
        'background-color': '#fff',
        'color': '#000'
    };

    graphContainerHeight = COMPACT_GRAPH_CONTAINER_HEIGHT;
    // This is the default num cols in gridster
    numCols = 5;
    savedGridItems;
    gridsterOptions = {
        // lanes: 12, // how many lines (grid cells) dashboard has
        direction: 'vertical', // items floating direction: vertical/horizontal/none
        cellHeight: COMPACT_GRAPH_CONTAINER_HEIGHT + 20,
        shrink: true,
        floating: false, // default=true - prevents items to float according to the direction (gravity)
        dragAndDrop: false, // possible to change items position by drag n drop
        resizable: false, // possible to resize items by drag n drop by item edge/corner
        useCSSTransforms: true, // Uses CSS3 translate() instead of position top/left - significant performance boost.
        responsiveDebounce: 200,
        responsiveView: true,
        tolerance: 'fit',
        responsiveSizes: true, // allow to set different item sizes for different breakpoints
    };

    constructor(protected breakpointObserver: BreakpointObserver,
                private graphTypeRegistry: GraphTypeRegistryService,
                protected dialog: MatDialog) {
        this.graphTypes = graphTypeRegistry.getAll();
        breakpointObserver.observe([Breakpoints.Medium]).subscribe(result => {
            if (result.matches) {
                this._setNumCols(MED_COL);
            }
        });
        breakpointObserver.observe([Breakpoints.Handset, Breakpoints.Small]).subscribe(result => {
            if (result.matches) {
                this.gridsterOptions.dragAndDrop = false;
                this._setNumCols(SMALL_COL);
            }
        });
        breakpointObserver.observe([Breakpoints.Large]).subscribe(result => {
            if (result.matches) {
                this._setNumCols(LARGE_COL);
            }
        });
        breakpointObserver.observe([Breakpoints.XLarge]).subscribe(result => {
            if (result.matches) {
                this._setNumCols(LARGE_COL);
            }
        });
    }

    _setNumCols(num) {
        if (num !== this.numCols) {
            this.gridster.setOption('lanes', num);
            this.numCols = num;
            this.layout();
        }
    }

    getNumCols() {
        return this.numCols;
    }

    layout(graphChange?) {
        let currentColIdx = 0;
        let currentRow = 0;
        const totalScreenCols = this.getNumCols();
        forEach(this.graphs, (graph, idx) => {
            const spec = graph.spec;
            const layout = {} as any;
            let colWidth = get(spec, 'layout.columns', DEFAULT_COLS);
            if (colWidth > totalScreenCols) {
                colWidth = totalScreenCols;
            }
            if ((colWidth + currentColIdx) > totalScreenCols) {
                currentRow++;
                currentColIdx = 0;
            }
            layout.x = currentColIdx;
            layout.y = currentRow;
            layout.w = colWidth;
            layout.h = 1;
            graph.layout = layout;
            currentColIdx += colWidth;
        });
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.graphs) {
            this.layout(true);
        }
        if (changes.edit && this.gridster) {
            this.gridster.setOption('dragAndDrop', changes.edit.currentValue);
        }
    }

    sortGridItems(items) {
        return items.sort((a, b) => {
            if (a.x <= b.x && a.y <= b.y) {
                return -1;
            }
            return 1;
        });
    }

    fireOrdinalChanged(item: GridListItem, newOrdinal) {
        const changeEvent = {
            graph: item.itemComponent.options.graph,
            newOrdinal: newOrdinal
        };
        this.ordinalChanged.emit(changeEvent);
    }

    gridItemChange($event) {
        if (this.edit) {
            const newOrdinal = this.findOrdinal($event.item.itemComponent, this.gridster.gridster.items);
            this.fireOrdinalChanged($event.item, newOrdinal);
        }
    }

    showGraphEditor(type: string): Observable<any> {
        const dialogHeight = window.innerHeight - 40;
        const dialogWidth = window.innerWidth - 40;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            type: type
        };
        dialogConfig.minWidth = '75%';
        const dialogRef = this.dialog.open(GraphEditorComponent, dialogConfig);
        return dialogRef.afterClosed();
    }

    startDrag($event) {
        $event.item.itemPrototype.$element.style.border = '2px dashed var(--sentrics-primary)';
        $event.item.itemPrototype.$element.style.width = '200px';
        $event.item.itemPrototype.$element.style.height = '100px';
        // Save a copy of the gridster items so we can rollback if needed
        forEach(this.gridster.gridster.items, (item) => {
            item.uuid = uuid.v4();
        });
        this.savedGridItems = cloneDeep(this.gridster.gridster.items);
    }

    findOrdinal(position: IPosition, gridItems: GridListItem[]) {
        let itemCnt = 0;
        const sorted = this.sortGridItems(gridItems);
        let newOrdinal = sorted.length;
        const numCols = this.getNumCols();
        forEach(sorted, (item: GridListItem, idx) => {
            if (position.x <= item.x && position.y <= item.y || position.x > item.x && position.y < item.y) {
                newOrdinal = itemCnt;
                return false;
            }
            itemCnt++;

        });
        return newOrdinal;
    }

    onNewGraphDropped($event) {
        this.showGraphEditor($event.item.itemPrototype.config.type.id).subscribe((result) => {
            if (!result) {
                // To rollback the add transaction, we take the saved grid items, and apply the coordinates to them
                // and use the idx as the ordinal.  It is assumed that they are already sorted
                forEach(this.savedGridItems, (item, idx) => {
                    const found = find(this.gridster.gridster.items, (gridItem) => {
                        return gridItem.uuid === item.uuid;
                    });
                    if (found) {
                        found.x = item.x;
                        found.y = item.y;
                        this.fireOrdinalChanged(found, idx);
                    }
                });
                this.gridster.reflowGridster(false);
                this.savedGridItems = null;
            } else {
                const insertIdx = this.findOrdinal($event.item.itemPrototype, this.gridster.gridster.items);
                this.addGraph.emit({
                    spec: result.graphSpec,
                    insertIdx: insertIdx
                });
            }
        });
    }

    over(e) {
        const t = e.item.calculateSize(e.gridster);
        e.item.itemPrototype.$element.querySelector('.gridster-item-inner').style.width = t.width + 'px';
        e.item.itemPrototype.$element.querySelector('.gridster-item-inner').style.height = t.height + 'px';
        e.item.itemPrototype.$element.classList.add('is-over');
    }

    out(e) {
        e.item.itemPrototype.$element.querySelector('.gridster-item-inner').style.width = '';
        e.item.itemPrototype.$element.querySelector('.gridster-item-inner').style.height = '';
        e.item.itemPrototype.$element.classList.remove('is-over');
    }
}
