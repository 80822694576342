import { FormsModule } from '@angular/forms';
import { InternationalPhoneNumberModule } from 'ngx-international-phone-number';
import {NgModule} from '@angular/core';
import {HeaderComponent} from './header/header.component';
import {SidenavListComponent} from './sidenav-list/sidenav-list.component';
import {IonicModule} from '@ionic/angular';
import {CommonModule} from '@angular/common';
import {MaterialModule} from '../material/material.module';
import {FlexLayoutModule} from '@angular/flex-layout';
import {AppRoutingModule} from '../app-routing.module';
import {SharedModule} from '../shared/shared.module';
import {FullSidenavComponent} from './full-sidenav/full-sidenav.component';
import {NavMenuItemComponent} from './menu-item/nav-menu-item.component';

@NgModule({
    // tslint:disable-next-line: max-line-length
    imports: [IonicModule, CommonModule, MaterialModule, FlexLayoutModule, AppRoutingModule, FormsModule, SharedModule],
    declarations: [HeaderComponent, SidenavListComponent, FullSidenavComponent, NavMenuItemComponent],
    exports: [HeaderComponent, SidenavListComponent, FullSidenavComponent, NavMenuItemComponent]
})

export class NavigationModule {}
