import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {NgxLoadingModule} from 'ngx-loading';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MaterialModule} from '../material/material.module';
import {SharedModule} from '../shared/shared.module';
import { AssetDocumentationComponent } from './asset-documentation/asset-documentation.component';
import { LightboxModule } from 'ngx-lightbox';
        
@NgModule({
    imports: [
        
        CommonModule,
        FormsModule,
        IonicModule,
        NgxLoadingModule,
        FlexLayoutModule,
        MaterialModule,
        ReactiveFormsModule,
        SharedModule,
        LightboxModule
    ],
    providers: [
        //asset service
    ],
    exports: [],
    declarations: [AssetDocumentationComponent],
    entryComponents: [AssetDocumentationComponent]
})
export class AssetsModule {
}
