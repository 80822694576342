import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material';
import {EditProfileComponent} from '../../dialogs/edit-profile/edit-profile.component';
import {ActivatedRoute, Router} from '@angular/router';
import {AppContext} from '../../../app.context';
import {MessageService} from '../../../error/message.service';
import {AuthService} from '../../../auth/auth.service';
import {AlarmUpdateService} from '../../../alarms/alarm-update.service';

@Component({
    selector: 'edit-profile-menu-item',
    templateUrl: './edit-profile.component.html',
    styleUrls: ['./edit-profile.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class EditProfileMenuItemComponent implements OnInit {
    @Input() user;

    constructor(
        private messageService: MessageService,
        private dialog: MatDialog,
        ) {}

    editProfile() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            user: this.user
        };
        const dialogRef = this.dialog.open(EditProfileComponent, dialogConfig);
        const self = this;
        dialogRef.afterClosed().subscribe(async function (result) {
            if (result) {
                const message = 'The user has been successfully updated!';
                self.messageService.handleSuccess(message);
            }
        });
    }

    ngOnInit(): void {
    }

}
