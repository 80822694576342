import { Component, OnInit} from '@angular/core';
import { UserService } from 'src/app/users/users.service';
import { MessageService } from '../../error/message.service';
import { Router } from '@angular/router';

@Component({
  selector: 'request-password-reset',
  templateUrl: './request-password-reset.component.html',
  styleUrls: ['./request-password-reset.component.scss']
})
export class RequestPasswordResetComponent implements OnInit {
  username;
  loading;

  constructor(
    private userService: UserService,
    private messageService: MessageService,
    private router: Router
  ) {}

  requestpasswordreset() {
    this.userService
      .sendLoginLink(this.username)
      .then(() => {
        this.messageService.handleSuccess('Thanks! Please check ' + this.username + ' for a link to reset your password.');
      })
      .catch(e => {
        this.messageService.handleError(e, 'Error updating values');
      });
  }

  ngOnInit() {
    this.username = '';
  }
}
