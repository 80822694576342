import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {AbstractGraphDataProvider, AbstractGraphView, IGraph, NoDataError} from '../../../graph.component';
import {IDataSeries, IDataSeriesDevice, IDataSeriesQueryResult} from '../../../../deviceData/device-data.service';
import {XYGraphDataProvider} from '../../../xyseries/xyseries.dataprovider';
import {ColorHelper, LineChartComponent} from '@swimlane/ngx-charts';
import {XYSeriesGraphSpec} from '../../../xyseries/xyseries.graph.spec';
import {isEmpty, forEach} from 'lodash';
import * as moment from 'moment';
import {BreakpointObserver} from '@angular/cdk/layout';
import {GraphLegendService} from '../../../../shared/legend.service';

const MESSAGE_DOT = 'message.';
const LEGEND_LABEL_LINE_HEIGHT = 35;
const DEFAULT_COLOR_SCHEME = 'vivid';

@Component({
    selector: 'ngx-chart-line-graph',
    templateUrl: './ngx-chart-line.component.html',
    styleUrls: ['./ngx-chart-line.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NgxChartLineGraphComponent extends AbstractGraphView<IDataSeriesQueryResult> implements OnInit {
    @ViewChild('graphContainer', {static: false}) private graphContainerElement: ElementRef;
    @ViewChild('graphElement', {static: false}) private graphElement: LineChartComponent;
    legend: true;
    labels: string[] = null;
    floatingColors: ColorHelper = null;
    multi: any[] = [];
    view: number[] = undefined;
    showXAxis = true;
    showYAxis = true;
    gradient = false;
    showLegend = true;
    showXAxisLabel = false;
    xAxisLabel = 'Number';
    showYAxisLabel = false;
    yAxisLabel = 'Color Value';
    legendTitle = '';
    roundDomains = true;
    activeEntries: any = [];
    timeline = false;
    dims: number[];
    colorScheme = DEFAULT_COLOR_SCHEME;
    resultSet: IDataSeriesQueryResult;
    // line, area
    autoScale = true;

    constructor(dataProvider: XYGraphDataProvider,
                breakpointObserver: BreakpointObserver,
                private legendService: GraphLegendService) {
        super(dataProvider, breakpointObserver);
    }

    onResize($event?) {
        if (this.graphContainerElement) {
            let containerDimensions = this.graphContainerElement.nativeElement.getBoundingClientRect();
            if ($event) {
               containerDimensions = {
                   height: $event.newHeight,
                   width: $event.newWidth

               };
            }
            this.dims = [containerDimensions.width, containerDimensions.height];
            this.view = [this.dims[0], this.dims[1] - (!this.compact ? this.computeLegendHeight() : 10)];
        }
    }

    ngOnInit(): void {
        this.timeline = !this.compact;
        this.onResize();
        super.ngOnInit();

    }

    dataReceived(resultSet: IDataSeriesQueryResult) {
        this.resultSet = resultSet;
        const datasets = [];

        resultSet.responses.forEach((response) => {
            response.series.forEach((series) => {
                const newDataset = this.buildDataset(response.device, series, response.times);
                if (newDataset) {
                    datasets.push(newDataset);
                }
            });
        });
        this.labels = datasets.map((dataset) => {
            return dataset.name;
        });
        this.floatingColors = new ColorHelper(DEFAULT_COLOR_SCHEME, 'ordinal', this.labels);
        this.multi = datasets;
        this.noData = isEmpty(datasets);
    }

    protected onDataUpdateReceived(updatedResultSet: IDataSeriesQueryResult, graph: IGraph) {
        this.dataReceived(this.resultSet ? (<XYGraphDataProvider>this.dataProvider).merge(this.resultSet, updatedResultSet) : updatedResultSet);
    }

    protected onDataReceived(resultSet: IDataSeriesQueryResult, graph: IGraph) {
        this.dataReceived(resultSet);
    }

    onLabelClicked($event) {
    }

    onLabelActivate(item) {
        this.deactivateAll();

        const idx = this.activeEntries.findIndex(d => {
            return d.name === item.name && d.value === item.value;
        });
        if (idx > -1) {
            return;
        }
        this.activeEntries = [item];
    }

    onLabelDeactivate(item) {
        const idx = this.activeEntries.findIndex(d => {
            return d.name === item.name && d.value === item.value;
        });

        this.activeEntries.splice(idx, 1);
        this.activeEntries = [...this.activeEntries];
    }

    deactivateAll() {
        this.activeEntries = [];
    }

    private buildDataset (device: IDataSeriesDevice, dataSeries: IDataSeries, times: string[]) {
        if (!times || isEmpty(times)) {
            return null;
        }
        let fieldText = dataSeries.field;
        if (fieldText.startsWith(MESSAGE_DOT)) {
            fieldText = fieldText.substr(MESSAGE_DOT.length);
        }

        const dataset: any = {
            name: `${device.name} (${fieldText})`,
            series: []
        };

        for (let i = 0; i < times.length; i++) {
            dataset.series.push({
                name : new Date(times[i]),
                value: dataSeries.values[i],
                unit: dataSeries.unit,
                tooltipDate: moment(times[i]).format('MM/DD/YYYY hh:mm:ssa')
            });
        }
        return dataset;
    }

    private computeLegendHeight() {
        // const width = this.graphContainerElement.nativeElement.getBoundingClientRect().width;
        // Assume it takes 390px per label on graph
        const numSeries = (<XYSeriesGraphSpec>this.graph.spec).data.y.metrics.length;
        // console.log(Math.ceil(numSeries * 370), width , LEGEND_LABEL_LINE_HEIGHT);
        return Math.ceil((numSeries * 300) / this.dims[0]) * LEGEND_LABEL_LINE_HEIGHT;
    }

    onHoverEnter() {
        if (this.floatingColors) {
        const labels = [];
            forEach(this.floatingColors.domain, (domain, idx) => {
                labels.push({
                    color: this.floatingColors.colorDomain[idx],
                    name: domain
                });
            })

            this.legendService.setFocusedGraphLegend({
                labels: labels
            });
        }

    }

    onHoverLeave() {
        this.legendService.setFocusedGraphLegend(null);
    }
}
