import {Component, Inject, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatCheckboxChange, MatDialogRef} from '@angular/material';
import {AppContext} from '../../app.context';
import {INamedQuery} from '../query-job.service';
import {forEach, isObject, cloneDeep, keys, find, isNil, isEmpty, merge} from 'lodash';
import {DeviceTypeService} from '../../deviceType/deviceType.service';
import * as moment from 'moment';
import {FormArray, FormControl, FormGroup, Validator, Validators} from '@angular/forms';
import {QueryBuilderConfig} from 'angular2-query-builder';
import {flatten} from 'flat';
import {Subscription} from 'rxjs';


function convertCondition(condition) {
    switch (condition) {
        case 'and' :
            return '$and';
        case 'or' :
            return '$or';
        default:
            throw new Error(`Unknown condition: ${condition}`);

    }
}

function convertOperator(op) {
    switch (op) {
        case '=' :
            return '$eq';
        case '>':
            return '$gt';
        case '<':
            return '$lt';
        case '>=' :
            return '$gte';
        case '<=' :
            return '$lte';
        default:
            throw new Error(`Unknown operator: ${op}`);
    }
}

function processFilterGroup(group) {
    const expressions = [];
    const condition = convertCondition(group.condition);
    forEach(group.rules, (rule) => {
        let expression = null;
        if (rule.condition) {
            expression = processFilterGroup(rule);
        } else {
            const op = convertOperator(rule.operator);
            const opAndValue = {};
            if (!isNil(rule.value)) {
                opAndValue[op] = rule.value;
                expression = {};
                expression['message.' + rule.field] = opAndValue;
            }
        }
        if (expression) {
            expressions.push(expression);
        }
    });
    const result = {};
    if (expressions.length > 0) {
        result[condition] = expressions;
    }
    return result;

}

@Component({
    selector: 'query-builder-dialog',
    templateUrl: './query-builder-dialog.component.html',
    styleUrls: ['./query-builder-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class QueryBuilderDialogComponent implements OnInit, OnDestroy {
    title: string;
    message: string;
    namedQuery: INamedQuery;
    orgId: string;
    schema: any;
    queryForm: FormGroup;
    today = moment().utc();
    filters;
    validForm = false;
    formSubscription: Subscription;
    filterConfig: QueryBuilderConfig;
    lastMonth = moment().subtract(1, 'month').startOf('month');
    dateRange: { startDate: moment.Moment, endDate: moment.Moment };
    ranges: any = {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    };
    devices: string[] = [];

    constructor(
        private dialogRef: MatDialogRef<QueryBuilderDialogComponent>,
        private appContext: AppContext,
        private deviceTypeService: DeviceTypeService,
        @Inject(MAT_DIALOG_DATA) data) {
        this.namedQuery = {
            name: undefined,
            query: {
                selector: {
                    orgId: undefined,
                    deviceTypeId: undefined
                },
                fields: []
            }
        };
        this.dateRange = {startDate: this.lastMonth, endDate: this.today};
        this.queryForm = new FormGroup({
            queryName: new FormControl('', [Validators.required]),
            deviceTypeId: new FormControl('', [Validators.required]),
            dateRange: new FormControl('', [Validators.required]),
            devices: new FormControl('', []),
            fields: new FormControl('', [Validators.required]),
        });
    }

    ok() {
        // Put everything together, orgId, deviceTypeId, etc...
        const query = {
            orgId: this.orgId,
            deviceTypeId: this.queryForm.controls['deviceTypeId'].value,
        };
        const selectedDevices = this.queryForm.controls['devices'].value;
        if (selectedDevices && selectedDevices.length > 0) {
            query['platformDeviceId'] = selectedDevices;
        }

        const selectedDateRange = this.queryForm.controls['dateRange'].value;
        if (this.queryForm.controls['filters']) {
            const selectedFilters = this.queryForm.controls['filters'].value;
            if (selectedFilters) {
                const convertedFilters = processFilterGroup(selectedFilters);
                if (!isEmpty(convertedFilters)) {
                    merge(query, convertedFilters);
                }
            }
        }
        const and = query['$and'] = query['$and'] || [];
        and.push({
            $and: [{date: {$gte: selectedDateRange.startDate.format('YYYY-MM-DD')}},
                {date: {$lte: selectedDateRange.endDate.format('YYYY-MM-DD')}}]
        });

        const fields = this.queryForm.controls['fields'].value.map((field) => {
            const index = field.indexOf('.');
            return field.substring(index + 1);
        });
        this.namedQuery.query = {selector: query, fields: fields};

        this.dialogRef.close(this.namedQuery);
    }

    cancel() {
        this.dialogRef.close();
    }

    _removeMessageLeafAttributes(obj) {
        function hasChildren(child) {
            let hasThem = false;
            forEach(child, (value, key) => {
                if (isObject(value)) {
                    hasThem = true;
                    return false;
                }
            });
            return hasThem;
        }

        forEach(obj, (value, key) => {
            if (isObject(value)) {
                if (hasChildren(value)) {
                    this._removeMessageLeafAttributes(value);
                } else {
                    obj[key] = {};
                }
            }
        });

    }


    deviceTypeChanged(deviceTypeId) {
        this.namedQuery.query.fields = [];
        this.deviceTypeService.getSchema(deviceTypeId).then((schema) => {
            // There is type info and other possible metadata about a schema field sitting on the leafs
            // That will appear as leafs on the tree in the ui.  These need to be removed.  They are not necessary
            // for field selection
            const clone = cloneDeep(schema);
            this._removeMessageLeafAttributes(clone);

            this.schema = clone;


            const flattenedMessageKeys = keys(flatten(cloneDeep(clone.message)));
            const flattened = {};
            forEach(flattenedMessageKeys.sort(), (key) => {
                flattened[key] = {
                    name: key,
                    type: 'number'
                };
            });
            this.filterConfig = {fields: flattened};
            this.filters = null;
        });
    }

    ngOnInit(): void {
        this.appContext.getOrgId().then((orgId) => {
            this.orgId = orgId;
            this.namedQuery.query.selector.orgId = orgId;
        });
        this.formSubscription = this.queryForm.valueChanges.subscribe((formValueChange) => {
            setTimeout(() => {
                this.validForm = this.queryForm.valid;
            }, 0);
        });
    }

    addFilters() {
        this.filters = {
            condition: 'and',
            rules: []
        };
        this.queryForm.addControl('filters', new FormControl('', []));
    }

    clearFilters() {
        this.queryForm.removeControl('filters');
        this.filters = null;

    }

    ngOnDestroy(): void {
        if (this.formSubscription) {
            this.formSubscription.unsubscribe();
        }
    }

}
