import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppSettings, Services} from '../app.settings';
import {AppContext} from '../app.context';
import {OrgMembership} from '../orgs/org.service';
import {environment} from '../../environments/environment';
import {GraphSpec} from '../graph/graph.component';




function parseObjectProperties(obj, parse) {
    for (const k in obj) {
        if (typeof obj[k] === 'object' && obj[k] !== null) {
            parseObjectProperties(obj[k], parse);
        } else if (obj.hasOwnProperty(k)) {
            parse(k, obj);
        }
    }
}

function encodeEmpty(key, obj) {
    const value = obj[key];
    if (typeof value === 'string' || value instanceof String) {
        if (value === '') {
            obj[key] = '__EMPTY__';
        }
    }
}

function decodeEmpty(key, obj) {
    const value = obj[key];
    if (typeof value === 'string' || value instanceof String) {
        if (value === '__EMPTY__') {
            obj[key] = '';
        }
    }
}

export enum DashboardScope {
    PRIVATE = 'private',
    ORG = 'org'
}

export interface IDashboard {
    id: string;
    name: string;
    graphs: GraphSpec[];
    preset?: boolean;
    modifiable?: boolean;
}

export interface ICustomDashboard extends IDashboard {
    id: string;
    author: string;
    orgId: string;
    scopeLevel: DashboardScope;
}

@Injectable()
export class DashboardsService {

    constructor(
        private http: HttpClient,
        private appSettings: AppSettings,
        private appContext: AppContext) {
    }

    getDashboards(): Promise<IDashboard[]> {
        return new Promise<IDashboard[]>((resolve, reject) => {
            this.appContext.getOrganization().then((orgMembership: OrgMembership) => {
                const endpoint = this.appSettings.getEndpoint(Services.DASHBOARDS, {orgId: orgMembership.orgId});
                this.http.get(endpoint).subscribe(createReply => {
                    parseObjectProperties(createReply, decodeEmpty);
                    resolve(<IDashboard[]> createReply['Items']);
                }, error1 => {
                    reject(error1);
                });
            }).catch(reason => {
                reject(reason);
            }).finally();
        });
    }

    updateDashboard(dashboard: IDashboard): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {
            const orgId = await this.appContext.getOrgId();
            const endpoint = this.appSettings.getEndpoint(Services.DASHBOARD, {
                orgId: orgId,
                dashId: dashboard.id
            });
            parseObjectProperties(dashboard.graphs, encodeEmpty);
            this.http.put(endpoint, dashboard).subscribe(value => {
                resolve(value);
            }, error1 => {
                reject(error1);
            });
        });
    }

    createDashboard(scope: DashboardScope, name: string, graphs?: GraphSpec[]): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.appContext.getOrganization().then((orgMembership: OrgMembership) => {
                const endpoint = this.appSettings.getEndpoint(Services.DASHBOARDS, {orgId: orgMembership.orgId});

                parseObjectProperties(graphs, encodeEmpty);

                const postData = {
                    scopeLevel: scope.toString(),
                    name: name
                };
                if (graphs) {
                    postData['graphs'] = graphs;
                }
                this.http.post(endpoint, postData).subscribe(value => {
                    resolve(value);
                }, error1 => {
                    reject(error1);
                });
            }).finally();
        });
    }

    async shareDashboard(dashId: string): Promise<void> {
        const orgId = await this.appContext.getOrgId();
        return new Promise<void>((resolve, reject) => {
            const endpoint = this.appSettings.getEndpoint(Services.DASHBOARD_SHARE, {
                orgId: orgId,
                dashId: dashId
            });
            this.http.put(endpoint, {}).subscribe(() => {
                resolve();
            }, reject);
        });

    }

    deleteDashboard(dashId: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.appContext.getOrganization().then((orgMembership: OrgMembership) => {
                const endpoint = this.appSettings.getEndpoint(Services.DASHBOARD, {
                    orgId: orgMembership.orgId,
                    dashId: dashId
                });
                this.http.delete(endpoint).subscribe(() => {
                    resolve();
                }, error1 => {
                    reject(error1);
                });
            }).finally();
        });
    }

    getDashboard(dashId: string): Promise<IDashboard> {
        return new Promise<IDashboard>((resolve, reject) => {
            this.appContext.getOrganization().then((orgMembership: OrgMembership) => {
                const endpoint = this.appSettings.getEndpoint(Services.DASHBOARD, {
                    orgId: orgMembership.orgId,
                    dashId: dashId
                });
                this.http.get(endpoint).subscribe((response: any) => {
                    parseObjectProperties(response, decodeEmpty);
                    const dashboard = response.Item;
                    dashboard.modifiable = true;
                    resolve(dashboard);
                }, error1 => {
                    // It's possible someone on the same ID is altering the dashboard?
                    reject(error1);
                });
            }).finally();
        });
    }

    count(query): Promise<any> {
        return new Promise((resolve, reject) => {
            this.appContext.getOrganization().then((orgMembership: OrgMembership) => {
                const endpoint = this.appSettings.getEndpoint(Services.DASHBOARD_COUNT, {
                    orgId: orgMembership.orgId
                });
                this.http.get(endpoint, {params: query || {}}).subscribe((result: any) => {
                    resolve(result.count);
                }, reject);
            }).finally();
        });
    }

    deleteGraph(dashId, graphId): Promise<any> {
        return new Promise((resolve, reject) => {
            this.appContext.getOrganization().then((orgMembership: OrgMembership) => {
                const endpoint = this.appSettings.getEndpoint(Services.GRAPH, {
                    orgId: orgMembership.orgId,
                    dashId: dashId,
                    graphId: graphId
                });
                this.http.delete(endpoint).subscribe((result: any) => {
                    resolve();
                }, reject);
            }).finally();
        });
    }

    addGraph(dashId: string, graphSpec: GraphSpec): Promise<GraphSpec> {
        return new Promise((resolve, reject) => {
            this.appContext.getOrganization().then((orgMembership: OrgMembership) => {
                const endpoint = this.appSettings.getEndpoint(Services.GRAPHS, {
                    orgId: orgMembership.orgId,
                    dashId: dashId
                });
                this.http.post(endpoint, graphSpec).subscribe((graphSpecWithId: any) => {
                    resolve(graphSpecWithId);
                }, reject);
            }).finally();
        });
    }

    updateGraph(dashId: string, graphSpec: GraphSpec): Promise<GraphSpec> {
        return new Promise((resolve, reject) => {
            this.appContext.getOrganization().then((orgMembership: OrgMembership) => {
                const endpoint = this.appSettings.getEndpoint(Services.GRAPH, {
                    orgId: orgMembership.orgId,
                    dashId: dashId,
                    graphId: graphSpec.id
                });
                this.http.put(endpoint, graphSpec).subscribe((graphSpecWithId: any) => {
                    resolve(graphSpecWithId);
                }, reject);
            }).finally();
        });
    }

}

