
import {OrgDeviceType} from '../../../orgs/org.service';
import {Component, EventEmitter, forwardRef, Input, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation} from '@angular/core';
import {cloneDeep, find, forEach, values, isEqual, merge, isNil, isUndefined} from 'lodash';
import {Device, SensorService} from '../../../sensors/sensors.service';
import {AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, NgForm, ValidationErrors, Validator} from '@angular/forms';
import {FormValidationService} from '../../../shared/form-validation.service';
import {DeviceMetric} from '../../graph.component';


@Component({
    selector: 'device-metric-bind-area',
    templateUrl: './device-metric-bind-area.component.html',
    styleUrls: ['./device-metric-bind-area.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DeviceMetricBindAreaComponent),
            multi: true
        }, {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => DeviceMetricBindAreaComponent),
            multi: true,
        }
    ]
})
export class DeviceMetricBindAreaComponent implements OnInit, ControlValueAccessor, Validator {

    @ViewChild('metricAreaForm', {static: true}) metricAreaForm: NgForm;
    @Input() deviceMetrics: DeviceMetric[];
    @Input() multiple: true;
    @Output() metricChanged = new EventEmitter<void>();

    onChange: any = () => { };
    onTouched: any = () => { };

    constructor(private formValidation: FormValidationService) {}

    findDuplicates(controlToFind) {
        const valueToMatch = controlToFind.value;
        const matches = {};
        forEach(this.metricAreaForm.controls, function(control, name) {
            if (control !== controlToFind) {
                const controlValue = control.value;

                if (isEqual(controlValue, valueToMatch)) {
                    matches[name] = control;
                }
            }
        });
        return matches;
    }

    validate(control: AbstractControl): ValidationErrors {
        const dupMap = {};
        forEach(this.metricAreaForm.controls, (child, name) => {
            const errors = child.errors;
            if (errors) {
                delete errors.duplicate;
            }
            if (dupMap[name]) {
                return true;
            }
            merge(dupMap, this.findDuplicates(child));
        });
        forEach(dupMap, function(value, key) {
            const errors = value.errors || {};
            errors.duplicate = true;
            value.setErrors(errors);
        });
        return this.formValidation.collectFormErrors(this.metricAreaForm);
    }

    ngOnInit() {
        if (isNil(this.multiple)) {
            this.multiple = true;
        }
        this.metricAreaForm.valueChanges.subscribe(val => {
            this.onChange(this.deviceMetrics);
        });
    }

    addMetric(newMetric) {
        this.deviceMetrics.push({} as DeviceMetric);
        this.onChange(this.deviceMetrics);
    }

    deleteMetric(metricToDelete, index) {
        this.deviceMetrics.splice(index, 1);
        // this.onChange(this.deviceMetrics);
        this.onMetricChanged(this.deviceMetrics);
    }

    /**
     * This only gets called when there is a valid device metric change
     */
    onMetricChanged($event) {
        this.metricChanged.emit($event);
    }

    writeValue(value) {
        if (!isUndefined(value) && !isEqual(value, this.deviceMetrics)) {
            this.deviceMetrics = value || [];

            if (this.deviceMetrics.length === 0) {
                this.deviceMetrics.push({} as DeviceMetric);
            } else {
                this.onMetricChanged(this.deviceMetrics);
            }
        }
    }

    registerOnChange(fn) {
        this.onChange = fn;
    }

    registerOnTouched(fn) {
        this.onTouched = fn;
    }
}
