import {Component, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {template, find, get, merge, values, forEach, set, cloneDeep} from 'lodash';
import {Router} from '@angular/router';
import {IncidentManagementService} from '../incident-management.service';
import {OrgService} from '../../../orgs/org.service';
import {FormControl, FormGroup, NgForm, Validators} from '@angular/forms';
import {MessageService} from '../../../error/message.service';
import {AlarmsService} from '../../../alarms/alarms.service';


@Component({
    selector: 'incident-configuration',
    templateUrl: './incident-configure.component.html',
    styleUrls: ['./incident-configure.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class IncidentManagementConfigComponent implements OnInit {
    @Input() integration;
    @Input() title;
    @Input() description;
    projects = null;
    users = null;
    loading = false;
    working = false;
    recordTypes = null;
    alarmSchema = null;
    recordTypeSchema = null;
    loadingSchema = false;
    loadingRecordTypes = null;
    configForm;
    objectValues = values;
    constructor(private incidentManService: IncidentManagementService,
                private orgService: OrgService,
                private router: Router,
                private messageService: MessageService,
                private alarmService: AlarmsService) {
        this.configForm = new FormGroup({
            recordType: new FormControl(),
            project: new FormControl()
        });
    }

    ngOnInit(): void {
        if (!this.title) {
            this.title = get(this.integration, 'type.title') + ' Configuration';
        }
        if (!this.description) {
            this.description = get(this.integration, 'type.configure.description');
        }
        this.integration.targetSettings = this.integration.targetSettings || {};
        this.loading = true;
        const projectId = get(this.integration, 'targetSettings.project');
        this.configForm.get('recordType').setValue(get(this.integration, 'targetSettings.recordType'));
        this.configForm.get('project').setValue(projectId);

        const jobs = [this.incidentManService.getProjects(this.integration.id),
            this.incidentManService.getUsers(this.integration.id), this.alarmService.getAlarmSchema(), this.loadSchema()];

        if (projectId) {
            jobs.push(this.fetchRecordTypes(projectId));
        }

        Promise.all(jobs).then((results: any[]) => {
            this.projects = results[0].sort(function(a, b) {
                return a.name.localeCompare(b.name);
            });
            this.users = results[1].sort(function(a, b) {
                return a.displayName.localeCompare(b.displayName);
            });

            this.alarmSchema = results[2];
        }).finally(() => {
            this.loading = false;
        });
    }

    async fetchRecordTypes(projectId) {
        this.recordTypes = [];
        this.loadingRecordTypes = true;
        return new Promise((resolve, reject) => {
            this.incidentManService.getRecordTypes(this.integration.id, projectId).then((results) => {
                this.recordTypes = results.sort(function(a, b) {
                    return a.name.localeCompare(b.name);
                });
                resolve(this.recordTypes);
            }, reject).finally(() => {
                this.loadingRecordTypes = false;
            });
        });

    }

    getControlValue(controlId) {
        const control = this.configForm.controls[controlId];
        return control ? control.value : null;
    }

    addMapping(newMapping) {
        const fieldMappings = get(this.integration, 'targetSettings.fieldMappings', []);

        const idx = fieldMappings.findIndex(function(item) {
            return item.target === newMapping.target;
        });

        if (idx > -1) {
            fieldMappings.splice(idx, 1);
        }

        fieldMappings.push(newMapping);

        set(this.integration, 'targetSettings.fieldMappings', fieldMappings);
    }

    removeMapping(targetId) {
        const fieldMappings = get(this.integration, 'targetSettings.fieldMappings', []);

        const idx = fieldMappings.findIndex(function(item) {
            return item.target === targetId;
        });

        if (idx > -1) {
            fieldMappings.splice(idx, 1);
        }
        set(this.integration, 'targetSettings.fieldMappings', fieldMappings);
    }

    setDefaultValueMapping(targetFieldId) {
        const controlValue = this.getControlValue(targetFieldId);
        if (controlValue) {
            const fieldDef = this.recordTypeSchema[targetFieldId];
            this.addMapping({
                default: {
                    id: controlValue
                },
                target: fieldDef.id
            });
        } else {
            this.removeMapping(targetFieldId);
        }
    }

    setRecordMapping(targetFieldId) {
        const controlValue = this.getControlValue(targetFieldId);
        const fieldDef = this.recordTypeSchema[targetFieldId];
        if (controlValue) {
            this.addMapping({
                source: controlValue,
                target: fieldDef.id
            });
        } else {
            this.removeMapping(fieldDef.id);
        }
    }

    getFieldMappingSourceValue(targetId) {
        const fieldMappings = get(this.integration, 'targetSettings.fieldMappings', []);
        const found = find(fieldMappings, function(field) {
            return field.target === targetId;
        });
        if (found) {
            if (found.default) {
                return get(found, 'default.id' , get(found, 'default'));
            } else if (found.source) {
                return found.source;
            }
        }
    }

    onProjectChange(event: any) {
        this.fetchRecordTypes(event.value);
    }

    async loadSchema() {
        const projectId = this.configForm.get('project').value;
        const recordTypeId = this.configForm.get('recordType').value;
        return new Promise((resolve, reject) => {
            if (projectId && recordTypeId) {
                this.loadingSchema = true;
                this.incidentManService.getRecordTypeSchema(this.integration.id, projectId, recordTypeId).then((schema) => {
                    forEach(schema, (field, key) => {
                        const value = this.getFieldMappingSourceValue(field.id);
                        const fc = new FormControl(value || '', field.required ? Validators.required : null);
                        this.configForm.addControl(field.id, fc);
                    });
                    this.recordTypeSchema = schema;
                    resolve();
                }, reject).finally(() => {
                    this.loadingSchema = false;
                });
            } else {
                resolve();
            }
        });


    }

    cancel() {
        this.router.navigate(['edit-org'], {queryParams: {tab: 'integrations'}});
    }

    save() {
        this.working = true;
        set(this.integration, 'targetSettings.project', this.configForm.controls.project.value);
        set(this.integration, 'targetSettings.recordType', this.configForm.controls.recordType.value);
        const clone = cloneDeep(this.integration);
        delete clone.type;
        this.orgService.updateIntegration(clone).then(() => {
            this.configForm.markAsPristine();
            this.messageService.handleSuccess(`The configuration was updated successfully`);
        }).finally(() => {
            this.working = false;
        });

    }

}
