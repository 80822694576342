import {DeviceMetric, GraphSpec} from '../graph.component';
import {forEach, get} from 'lodash';


export class XYSeriesGraphSpec extends GraphSpec {
    data: IFieldBoundDataSeries;
    axes: IAxis[] = [];
}

export enum IScaling {
    LINEAR = 'linear',
    LOGARITHMIC = 'logarithmic',
    TIME = 'time'
}

export interface IAxis {
    id?: string;
    /**
     * Time, Temperature, Pressure
     */
    name?: string;
    /**
     * F, C, Hz
     */
    units?: string;
    /**
     * Additional information when hovered.
     */
    description?: string;

    scaling?: IScaling;

    recommendedMin?: number;
    recommendedMax?: number;
}

export interface ILabel {
    name: string;
    abbreviation?: string;
    description?: string;
}

/**
 * This binds a device field and its observations to a chart axis.
 * For instance, to create a simple line graph one could map
 * 'deviceTimestamp' to the X-axis and 'message.nose.sensorValue' to the Y-axis.
 */
export interface IFieldBoundDataSeries {
    x: {
        field: string;
        label: ILabel;
    };
    y: IYField;
}


export interface IYField {
    metrics: DeviceMetric[];
//    label: ILabel;
    /**
     * Required for multiple Y-axes
     */
    axisId?: string;
}

