import {Component, forwardRef, OnChanges, OnInit, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {MatSlideToggleChange} from '@angular/material';

@Component({
    selector: 'sen-toggle',
    templateUrl: './toggle.component.html',
    styleUrls: ['./toggle.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ToggleComponent),
            multi: true
        }
    ]
})
export class ToggleComponent implements OnInit, ControlValueAccessor {
    onChange: (value: any) => {};
    onTouched: () => {};
    checked: boolean;
    // required: boolean;
    constructor() {

    }

    ngOnInit(): void {
    }

    writeValue(value: any): void {
        this.checked = value;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    onToggleChange($event: MatSlideToggleChange) {
        this.checked = !this.checked;
        this.onChange(this.checked);
        this.onTouched();
    }

}
