import { AppState } from './app.global';
import {User, UserService} from './users/users.service';
import {Injectable, OnInit} from '@angular/core';
import {Org, OrgMembership, OrgService} from './orgs/org.service';
import { find, extend, isEqual } from 'lodash';
import {AuthService} from './auth/auth.service';
import {Subject} from 'rxjs';
import {PreferencesService, UserPreferences} from './preferences/preferences.service';
import {ActivatedRoute, PRIMARY_OUTLET, Router, UrlSegment, UrlSegmentGroup} from '@angular/router';

@Injectable()
export class AppContext {
    private userSource = new Subject<User>();
    private orgSource = new Subject<OrgMembership>();
    private experimentalSource = new Subject<boolean>();
    public user = this.userSource.asObservable();
    public org = this.orgSource.asObservable();
    public experimental = this.experimentalSource.asObservable();
    private _user = null;
    private _prefs: UserPreferences;
    private _org = null;
    private fetchUserPromise: Promise<User>;
    // This is used because it looks like promise does not expose a state to use
    // to see if there is a request in flight
    private fetchInFlight = false;
    private showExperimental = false;
    constructor(
        public global: AppState,
        private auth: AuthService,
        public userService: UserService,
        private preferencesService: PreferencesService,
        private router: Router
    ) {

        if (this.auth.isAuthenticated()) {
            this.onLogin();
        }
        const self = this;
        this.auth.authState.subscribe(function(loggedIn) {
            if (!loggedIn) {
                self.onLogout();
            } else {
                self.onLogin();
            }
        });
    }



    private fetchUser(force?): Promise<User> {
        if (this._user && !force) {
            return Promise.resolve(this._user);
        }
        if (this.fetchUserPromise && this.fetchInFlight) {
            return this.fetchUserPromise;
        }
        this.fetchInFlight = true;
        const self = this;
        this.fetchUserPromise = new Promise<User>(function(resolve, reject) {
            self.userService.whoami().then(function(user) {
                self.preferencesService.getPreferences(user.userName).then(function(prefs) {
                    self._prefs = prefs;
                    self.setUser(user);
                    user.orgMemberships.sort(function(a, b) {
                        return a.orgName.localeCompare(b.orgName);
                    });

                    if (user.orgMemberships.length > 0) {
                        let found = find(user.orgMemberships, function(item) {
                            return item.orgId === self._prefs.defaultOrg;
                        })
                        if (!found) {
                            found = user.orgMemberships[0];
                        }
                        self.setOrganization(found);
                    }
                    resolve(user);
                });
            }, reject).finally(function() {
                self.fetchInFlight = false;
            });
        });
        return this.fetchUserPromise;
    }

    private setUser(user) {
        this._user = user;
        this.userSource.next(user);
    }

    private onLogin() {
        this.fetchUser();

    }

    onLogout() {
        this.setUser(null);
        this.setOrganization(null);
    }

    async getUser(): Promise<User> {
        if (this._user) {
            return Promise.resolve(this._user);
        }
        return this.fetchUser();
    }

    async getUserId() {
        const user = await this.getUser();
        return user.userName;
    }

    async getOrganization(): Promise<OrgMembership> {
        if (this._org) {
            return Promise.resolve(this._org);
        }
        const self = this;
        // @ts-ignore
        return new Promise(function(resolve, reject) {
            self.fetchUser().then(function(user) {
                resolve(self._org);
            }, reject);
        });


    }

    setShowExperimentalFeatures(show: boolean) {
        this.showExperimental = show;
        this.experimentalSource.next(show);
    }

    isShowExperimentalFeatures() {
        return this.showExperimental;
    }

    setOrganization(org: OrgMembership) {
        const orgChange = this._org && !isEqual(this._org, org);
        this._org = org;
        this.orgSource.next(org);
        if (org) {
            if (this._prefs.defaultOrg !== org.orgId) {
                this._prefs.defaultOrg = org.orgId;
                // Do this in background;
                this.updateUserPreferences(this._prefs);
            }
        }

        if (org && orgChange) {
            // const tree = this.router.parseUrl(this.router.url);
            // const g: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
            // const s: UrlSegment[] = g.segments;
            // const path = [];
            // s.forEach(function(item) {
            //    path.push(item.path);
            // });

            const currentUrl = this.router.url;
            this.router.navigateByUrl('/home', {skipLocationChange: true}).then(() => {
                // console.log('Url: ', currentUrl);
                this.router.navigate([currentUrl]).finally();
            });
            // window.location.reload();
            // this.router.navigate(['sensors'], {queryParams: {'refresh':  '1'}}).finally();
        }
    }

    async getOrgId() {
        const orgMembership = await this.getOrganization();
        return orgMembership.orgId;

    }

    async getUserPreferences() {
        return this.fetchUser().then((user) => {
            return this._prefs;
        });
    }

    /**
     * I don't think we really need to expose this.  This is really only necessary because we want to
     * remember the last org we visited.  In the future, I can see the need for a preferences page, where the
     * edit/save lifecycle is well defined.  Right now, this is being done in the background implicitly
     * @param prefs
     */
    updateUserPreferences(prefs): Promise<void> {
        this._prefs = extend(this._prefs, prefs);
        return this.preferencesService.updatePreferences(this._user.userName, this._prefs);
    }

    /**
     * Reloads the logged in user
     */
    reload(): Promise<User> {
        return this.fetchUser(true);
    }

    async isUserInRole(rolesToCheck: string[], orgToCheck?: OrgMembership) {
        if (!orgToCheck) {
            orgToCheck = await this.getOrganization();
        }
        if (orgToCheck) {
            return rolesToCheck.indexOf(orgToCheck.role) > -1;
        }
        return false;
    }
}
