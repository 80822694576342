import {Component, Inject, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';
import {IncidentManagementService} from '../incident-management.service';

@Component({
    selector: 'im-record-dialog',
    templateUrl: './im-record-dialog.component.html',
    styleUrls: ['./im-record-dialog.component.scss'],
})

export class IncidentManagementRecordDialogComponent {
    link;
    recordFetched;
    constructor(
        private dialogRef: MatDialogRef<IncidentManagementRecordDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data) {
            this.link = data.link;
    }

    close() {
        this.dialogRef.close();
    }
}
