import {
    Component,
    EventEmitter,
    forwardRef,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {OrgService} from '../../../../orgs/org.service';
import {forEach, merge, isEmpty, get} from 'lodash';
import {
    AbstractControl,
    ControlValueAccessor,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR, NgForm,
    ValidationErrors,
    Validator
} from '@angular/forms';
import {DeviceTypeService} from '../../../../deviceType/deviceType.service';

const COMPARISON_MAP = [{
    displayName: 'above',
    value: '$gt'
}, {
    displayName: 'below',
    value: '$lt'
}];

const AGGREGATION_CHOICES = [{
    displayName: 'at least once',
    value: '$once'
}, {
    displayName: 'at least twice',
    value: '$twice'
}, {
    displayName: 'on average',
    value: '$avg'
}, {
    displayName: 'in total',
    value: '$sum'
}];

const WINDOW_CHOICES = [{
    displayName: '1 minute',
    value: 1
},
    {
        displayName: '5 minutes',
        value: 5
    },
    {
        displayName: '10 minutes',
        value: 10
    },
    {
        displayName: '15 minutes',
        value: 15
    },
    {
        displayName: '30 minutes',
        value: 30
    },
    {
        displayName: '1 hour',
        value: 60
    },
    {
        displayName: '2 hours',
        value: 120
    }
];

function isFormGroup(control: AbstractControl): control is FormGroup {
    return !!(<FormGroup>control).controls;
}

function collectErrors(control: AbstractControl): any | null {
    if (isFormGroup(control)) {
        return Object.entries(control.controls)
            .reduce(
                (acc, [key, childControl]) => {
                    const childErrors = collectErrors(childControl);
                    if (childErrors) {
                        acc = {...acc, [key]: childErrors};
                    }
                    return acc;
                },
                null
            );
    } else {
        const errors = {};
        forEach(control.errors, function (value, key) {
            errors[key] = {valid: false};
        });
        return errors;
    }
}

@Component({
    selector: 'condition-selector',
    templateUrl: './condition-selector.component.html',
    styleUrls: ['./condition-selector.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ConditionSelectorComponent),
            multi: true
        }, {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => ConditionSelectorComponent),
            multi: true,
        }]
})

export class ConditionSelectorComponent implements OnInit, ControlValueAccessor, Validator {
    // tslint:disable-next-line: no-input-rename
    @Input() model;
    @Input() expanded;
    @Input() removable;
    @Input() filter: boolean;
    @Input() deviceid: boolean;
    @ViewChild('metricConditionForm', {static: true}) metricConditionForm: NgForm;
    queryableAttributes;
    comparisonChoices = COMPARISON_MAP;
    aggregationChoices = AGGREGATION_CHOICES;
    uom;
    @Output() remove = new EventEmitter();

    onChange: any = () => {
    }
    onTouched: any = () => {
    }

    constructor(private deviceTypeService: DeviceTypeService) {}

    validate(control: AbstractControl): ValidationErrors {
        const errors = {};
        forEach(this.metricConditionForm.controls, function (c) {
            merge(errors, collectErrors(c));
        });
        return isEmpty(errors) ? null : errors;
    }

    stopPropogation(event: MouseEvent) {
        event.stopPropagation();
    }

    onRemove(event: MouseEvent) {
        event.stopPropagation();
        this.remove.emit(this);
    }


    ngOnInit(): void {
        if (this.model) {
            if (!this.model.metric) {
                this.model.metric = {};
            }
            if (this.model.condition) {
                this.model.condition = {};
            }
            if (this.model.thresholdValues) {
                this.model.thresholdValues = {};
            }
        }

        if (this.expanded === undefined) {
            this.expanded = false;
        }

        this.metricConditionForm.valueChanges.subscribe(val => {
            this.onChange(this.model);
        });
    }

    onFieldSelected(field) {
        this.deviceTypeService.getSchema(this.model.deviceTypeId).then((schema) => {
            if (schema) {
                const fieldDef = get(schema, field);
                if (fieldDef) {
                    this.uom = fieldDef.unit;
                }
            }
        });
    }

    registerOnChange(fn) {
        this.onChange = fn;
    }

    registerOnTouched(fn) {
        this.onTouched = fn;
    }

    writeValue(value: any): void {
        if (value) {
            this.model = value;
        }
    }
}
