import {Pipe, PipeTransform} from '@angular/core';
import * as moment from './local-time.directive';

@Pipe({
    name: 'truncate'
})
export class TruncateDirective implements PipeTransform {

    transform(string: string, length: number): string {
        return string ? string.substring(0, length) + '...' : null;
    }


}
