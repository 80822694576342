import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {NgxLoadingModule} from 'ngx-loading';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MaterialModule} from '../material/material.module';
import {SharedModule} from '../shared/shared.module';
import {IntegrationsService} from './integrations.service';
import {IntegrationOAuth2Component} from './auth/oauth2/integration-oauth2.component';
import {IntegrationComponent} from './integration.component';
import {IntegrationConfigComponent} from './configure/integration-configure.component';
import {IncidentManagementConfigComponent} from './incident/configure/incident-configure.component';
import {IncidentManagementService} from './incident/incident-management.service';
import {IncidentSelectComponent} from './incident/select/incident-select.component';
import {IncidentManagementLink} from './incident/link/im-link.component';
import {IncidentManagementRecordDialogComponent} from './incident/dialog/im-record-dialog.component';
import {IncidentManagementRecordViewComponent} from './incident/record/im-record-view.component';
import {IntegrationLinksComponent} from './links/integration-links.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        NgxLoadingModule,
        FlexLayoutModule,
        MaterialModule,
        ReactiveFormsModule,
        SharedModule
    ],
    providers: [
        IntegrationsService, IncidentManagementService
    ],
    exports: [IntegrationComponent, IntegrationConfigComponent,
        IncidentManagementConfigComponent, IncidentManagementLink, IntegrationLinksComponent],
    declarations: [IntegrationOAuth2Component, IntegrationComponent,
        IntegrationConfigComponent, IncidentManagementConfigComponent, IncidentSelectComponent, IncidentManagementLink, IncidentManagementRecordViewComponent, IncidentManagementRecordDialogComponent, IntegrationLinksComponent],
    entryComponents: [IncidentManagementRecordDialogComponent]
})
export class IntegrationsModule {
}
